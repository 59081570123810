import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API } from '@/shared/api/Api';
import { useMemo } from 'react';
import { AxiosError, DeckTransfersPayload } from '../api/ApiTypings';

export type ErrorType = {
    response: {
        data: {
            message: string;
            detail: string;
        };
    };
};

export const useTransferDeckMutation = () => {
    return useMutation<void, ErrorType, any>({
        mutationFn: (payload: { deckId: string; userId: string }) => {
            const { deckId, userId } = payload;
            return API.transferDeck(deckId, userId);
        },
    });
};

export const useDeckTransfers = (
    status?: string,
    type?: string,
    ordering?: string,
    pageSize?: number,
    page?: number
) => {
    const deckTransferKey = useMemo(() => {
        const uniqueId = Date.now(); // Or use any other unique identifier
        return [
            'deckTransfers',
            status,
            type,
            ordering,
            pageSize,
            page,
            uniqueId,
        ];
    }, [status, type, ordering, pageSize, page]);

    return useQuery<DeckTransfersPayload, AxiosError>({
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey: deckTransferKey,
        queryFn: () => API.getTransfers(status, type, ordering, pageSize, page),
    });
};

export const useApproveDeckTransferMutation = () => {
    const queryClient = useQueryClient();
    return useMutation<void, ErrorType, any>({
        mutationFn: (transferId: string) => {
            return API.acceptTransfer(transferId);
        },
        onSuccess() {
            // @ts-ignore
            queryClient.invalidateQueries('deckTransfers');
        },
    });
};

export const useDenyDeckTransferMutation = () => {
    const queryClient = useQueryClient();
    return useMutation<void, ErrorType, any>({
        mutationFn: (transferId: string) => {
            return API.denyTransfer(transferId);
        },
        onSuccess() {
            // @ts-ignore
            queryClient.invalidateQueries('deckTransfers');
        },
    });
};

export const useCancelDeckTransferMutation = () => {
    const queryClient = useQueryClient();
    return useMutation<void, ErrorType, any>({
        mutationFn: (transferId: string) => {
            return API.cancelTransfer(transferId);
        },
        onSuccess() {
            // @ts-ignore
            queryClient.invalidateQueries('deckTransfers');
        },
    });
};
