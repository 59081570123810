import AlliancesActions, { AlliancesStateType } from '@/shared/redux/alliances';
import { RootState } from '@/shared/redux/RootState';
import React, { useEffect, useState } from 'react';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import AlliancesSearchForm, {
    defaultValues,
} from '@/components/alliances/AlliancesSearchForm/AlliancesSearchForm';
import AlliancesTable from '@/components/alliances/AlliancesTable/AlliancesTable';

import './AlliancesPage.scss';
import PaginatedTable from '@/components/common/tables/PaginatedTable';
import { AsyncStatus } from '@/shared/redux/Async';
import Breadcrumbs from '../common/Breadcrumbs';

export type Props = WithNamespaces & {
    alliances: AlliancesStateType['userAlliances'];
    getAlliances: typeof AlliancesActions['userAlliancesRequest'];
    resetAllianceCreationState: typeof AlliancesActions['createAllianceReset'];
    resetAllianceUpdateState: typeof AlliancesActions['updateAllianceReset'];
};

const ROWS_PER_PAGE = 10;

export const AlliancesPage = (props: Props) => {
    const {
        getAlliances,
        resetAllianceCreationState,
        resetAllianceUpdateState,
        alliances,
        t,
    } = props;
    const [searchParams, setSearchParams] = useState(defaultValues);
    const [page, setPage] = useState(0);

    useEffect(() => {
        resetAllianceCreationState();
        resetAllianceUpdateState();
    }, []);

    useEffect(() => {
        getAlliances(searchParams, {
            page,
            pageSize: ROWS_PER_PAGE,
        });
    }, [searchParams, page]);

    const onSortChange = () => {
        setSearchParams((prevState) => {
            const newOrdering = prevState.ordering === '-created_at' ? 'created_at' : '-created_at';
            return { ...prevState, ordering: newOrdering };
          });
    };

    return (
        <div className="alliances-page">
            <Breadcrumbs />
            <div className="alliances-page__header">
                <h1 className="keyforge-heading-1">
                    {t('alliances.your-alliances.header', {
                        defaultValue: 'Your alliances',
                    })}
                </h1>
                <Link
                    to="/alliances/forge"
                    className="btn btn-medium alliances__forge-btn"
                >
                    {t('alliances.forge.btn-forge', {
                        defaultValue: 'Forge Alliance',
                    })}
                </Link>
            </div>

            <AlliancesSearchForm
                onSubmit={(params) => {
                    setSearchParams(params);
                    setPage(0);
                }}
            />

            <PaginatedTable
                currentPage={page}
                pageCount={Math.ceil(alliances.totalCount / ROWS_PER_PAGE)}
                onPageChange={setPage}
                isLoading={alliances.__status === AsyncStatus.Pending}
                showTopPagination={false}
            >
                <AlliancesTable alliances={alliances.list} sorting={searchParams.ordering} onSortChange={onSortChange}/>
            </PaginatedTable>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    alliances: state.alliances.userAlliances,
});

const mapDispatchToProps = {
    getAlliances: AlliancesActions.userAlliancesRequest,
    resetAllianceCreationState: AlliancesActions.createAllianceReset,
    resetAllianceUpdateState: AlliancesActions.updateAllianceReset,
};

export default withNamespaces()(
    connect(mapStateToProps, mapDispatchToProps)(AlliancesPage)
);
