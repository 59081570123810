import React, { useState } from 'react';
import PaginatedTable from '@/components/common/tables/PaginatedTable';
import { useGetUserPayments } from '@/shared/queries/payments';
import { withNamespaces } from 'react-i18next';
import UserTransactionsList from './UserTransactionsList';
import { useSelector } from 'react-redux';
import { getApiToken } from '@/shared/redux/Selectors';
import { RootState } from '@/redux/RootState';

const UserTransactions = ({ t }) => {
    const apiToken = useSelector((state) => getApiToken(state as RootState));
    const [currentPage, setCurrentPage] = useState(1);
    const onPageChange = (page) => setCurrentPage(page);

    const { data: userTransactions, isLoading } = useGetUserPayments(
        currentPage,
        5,
        {
            enabled: apiToken.length > 0,
        }
    );

    return (
        <div className="transactions">
            {!isLoading && userTransactions?.count > 0 ? (
                <PaginatedTable
                    currentPage={currentPage}
                    pageCount={Math.ceil(userTransactions?.count / 5)}
                    onPageChange={(page) => onPageChange(page)}
                    isLoading={isLoading}
                >
                    <UserTransactionsList
                        transactions={userTransactions.data}
                    />
                </PaginatedTable>
            ) : (
                <div className="transactions__empty">
                    <h3 className="transactions__empty-heading keyforge-heading-2">
                        {t('transactions.hint.no-transactions', {
                            defaultValue: 'You have no transactions',
                        })}
                    </h3>
                </div>
            )}
        </div>
    );
};

export default withNamespaces()(UserTransactions);
