import React, { PureComponent } from 'react';
import Tippy from '@tippy.js/react';

import ClientSideOnly from '@/components/common/ClientSideOnly';
import { isBrowser } from '@/shared/services/Utils';

export interface Props {
    children: React.ReactElement;
    content: React.ReactElement;
    onToggle: (isDropdownVisible: boolean) => void;
}

export class NavigationDropdown extends PureComponent<Props> {
    public render() {
        const { children, content } = this.props;

        return (
            <ClientSideOnly>
                <Tippy
                    content={content}
                    animateFill={false}
                    duration={0}
                    distance={20}
                    interactive={true}
                    placement="bottom"
                    theme="keyforge-dropdown"
                    trigger="click"
                    onShow={this.onShow}
                    onHide={this.onHide}
                    // enable on tablet size and bigger
                    isEnabled={isBrowser && window.innerWidth >= 768}
                >
                {children}
                </Tippy>
            </ClientSideOnly>
        );
    }

    private onShow = () => this.props.onToggle(true);
    private onHide = () => this.props.onToggle(false);
}

export default NavigationDropdown;
