import React, { useState, useEffect } from 'react';

import SectionLead from '@/components/common/SectionLead';
import { SearchDeckInput } from '../my_decks/SearchDeckInput';
import DecksFiltersContainer from '@/components/common/decks_filters/DecksFiltersContainer';
import { DeckSearchOrder, DecksSearchFilters } from '@/shared/api/ApiTypings';
import PaginatedTable from '@/components/common/tables/PaginatedTable';
import { PaginatedListHeader } from '../my_decks/PaginatedListHeader';
import DeckList from '@/components/common/deck_lists/DeckList';
import { useFriendDecks, useFriends } from '../../../shared/queries/friends';
import { DefaultFiltersState } from '../my_decks/DecksPageBase';

import './FriendsDecksPage.scss';
import { SelectItem } from '@/components/common/inputs/FormSelectBox';
import { DeckBase, House } from '@/shared/typings';
import { assoc } from 'ramda';
import Breadcrumbs from '@/components/common/Breadcrumbs';

const PageSize = 10;
let friendsArray: SelectItem[] = [];

export const FriendsDecksPage = () => {
    const [className] = useState<string>('friends-decks-page');
    const [searchPlaceholder] = useState<string>('Input deck name, card name');
    const [userHasDecks] = useState<boolean>(true);
    const [filters, setFilters] = useState<any>(DefaultFiltersState);
    const [searchFriendText, setSearchFriendText] = useState<string>('');
    const [friendDecks, setFriendDecks] = useState<DeckBase[]>();
    const [paginationPage, setPaginationPage] = useState<number>(0);

    // useFriendsQueryFilters (change will not trigger fetchData)
    const [friendIdFilter, setFriendIdFilter] = useState<string>('');
    const [searchDeckTextFilter, setSearchDeckTextFilter] = useState<string>(
        ''
    );
    const [expansionIdFilter, setExpansionIdFilter] = useState<any>('');
    const [housesFilter, setHousesFilter] = useState<string[]>([]);
    const [orderingFilter, setOrderingFilter] = useState<DeckSearchOrder>(
        '-date'
    );
    // useFriendsQueryStates (change will trigger fetchData)
    const [friendId, setFriendId] = useState<string>('');
    const [searchDeckText, setSearchDeckText] = useState<string>('');
    const [expansionId, setExpansionId] = useState<any>('');
    const [houses, setHouses] = useState<any>([]);
    const [ordering, setOrdering] = useState<DeckSearchOrder>('-date');
    const [page, setPage] = useState<number>(1);
    const {
        data: decks,
        isLoading: isDecksLoading,
        isFetched: isDecksFetched,
    } = useFriendDecks(
        searchDeckText,
        expansionId,
        false,
        houses,
        ordering,
        PageSize,
        page,
        friendId
    );
    const {
        data: friends,
        isLoading: isFriendsLoading,
        isFetched: isFriendsFetched,
    } = useFriends();
    const [allFriends, setAllFriends] = useState<SelectItem[]>();

    useEffect(() => {
        const friendDecksArray: DeckBase[] = [];
        if (isDecksFetched && decks) {
            const houseData = decks._linked.houses;
            decks.data.map((deck) => {
                const houses: House[] = [];
                deck._links.houses.map((houseId) => {
                    const house = houseData.find((h) => h.id === houseId);
                    houses.push({
                        id: house.id,
                        name: house.name,
                        image: house.image,
                        inFilters: house.in_filters,
                    });
                });
                const newObj: any = {
                    id: deck.id,
                    name: deck.name,
                    expansion: deck.expansion.set_id,
                    houses: houses,
                    powerLevel: 0,
                    wins: 0,
                    losses: 0,
                    isMyDeck: false,
                    isOnWatchlist: deck.is_on_my_watchlist,
                    owner: deck.owner,
                };
                friendDecksArray.push(newObj);
            });
        }

        setFriendDecks(friendDecksArray);
    }, [isDecksFetched]);

    useEffect(() => {
        isFriendsFetched &&
            friends &&
            friends.invited_by.map((user) => {
                friendsArray.push({
                    label: user.username,
                    value: user.id,
                });
            });
        setAllFriends(friendsArray);

        return () => {
            friendsArray = [];
        };
    }, [isFriendsFetched]);

    const handleSearchTextChange = (e: any): void => {
        setSearchDeckTextFilter(e.target.value);
    };

    const applyNewFilters = () => {
        setHousesFilter(filters.houses);
        setFilters({
            searchText: searchDeckTextFilter,
            sorting: orderingFilter,
            houses: housesFilter,
            expansionId: expansionIdFilter,
            favoriteOnly: false,
        });
    };

    const handleSearchButton = () => {
        setFriendId(friendIdFilter);
        setSearchDeckText(searchDeckTextFilter);
        setExpansionId(expansionIdFilter);
        setHouses(housesFilter.join(','));
        setOrdering(orderingFilter);
    };

    const setFriendsFilter = (text: string) => {
        const filteredFriends = friendsArray.filter((friend) =>
            friend.label.toLowerCase().includes(text.toLowerCase())
        );
        setAllFriends(filteredFriends);
        setSearchFriendText(text);
    };

    const onFriendPick = (chosenFriendId: string) => {
        const chosenFriend = friendsArray.find(
            (friend) => friend.value === chosenFriendId
        );
        const chosenFriendName = chosenFriend ? chosenFriend.label : '';
        setSearchFriendText(chosenFriendName);
        setFriendIdFilter(chosenFriendId);
        applyNewFilters();
    };

    const handleFiltersChange = (filters: DecksSearchFilters) => {
        setExpansionIdFilter(filters.expansionId);
        setFilters(filters);
        setHousesFilter(filters.houses);
    };

    const handleFiltersReset = () => {
        setFilters(DefaultFiltersState);
        setSearchFriendText('');
        setSearchDeckTextFilter('');
        setOrderingFilter('-date');
        setHousesFilter([]);
        setExpansionIdFilter('');
        setFriendIdFilter('');
    };

    const onPageChange = (chosenPage: number) => {
        setPage(chosenPage + 1);
        setPaginationPage(chosenPage);
    };

    const onSortChange: any = (sorting: DeckSearchOrder) => {
        setOrdering(sorting);
        const newSorting =
            sorting === filters.sorting ? DefaultFiltersState.sorting : sorting;
        const newFilters = assoc('sorting', newSorting, filters);
        setFilters(newFilters);
    };

    const decksPageCount = () => Math.ceil(decks?.count / PageSize);

    return (
        <div className="friend-decks-page-page">
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <SectionLead>Friends' Decks</SectionLead>
            <div className="page-content-layout">
                <Breadcrumbs />
                <div className="friend-decks-page__inputs">
                    <SearchDeckInput
                        className={className}
                        searchPlaceholder={searchPlaceholder}
                        searchText={searchDeckTextFilter}
                        handleSearchTextChange={handleSearchTextChange}
                        applyNewFilters={handleSearchButton}
                        disableSearch={false}
                    />
                </div>

                <div
                    className="friend-decks-page__filters"
                    style={{ padding: '32px' }}
                >
                    <DecksFiltersContainer
                        onSearch={handleSearchButton}
                        onReset={handleFiltersReset}
                        onChange={handleFiltersChange}
                        value={filters}
                        disableToggleBtn={!userHasDecks}
                        searchFriends
                        allFriends={allFriends}
                        onFriendPick={onFriendPick}
                        searchFriendsText={searchFriendText}
                        setSearchFriendsText={setFriendsFilter}
                    />
                </div>
                <div className="friend-decks-page__decks-list">
                    {friendDecks?.length > 0 ? (
                        <PaginatedTable
                            header={
                                <PaginatedListHeader
                                    className={className}
                                    decksCount={decks?.count}
                                />
                            }
                            currentPage={paginationPage}
                            pageCount={decksPageCount()}
                            onPageChange={onPageChange}
                            isLoading={isDecksLoading || isFriendsLoading}
                            showTopPagination={true}
                        >
                            <DeckList
                                decks={friendDecks}
                                onSortChange={onSortChange}
                                sorting={filters.sorting}
                                showUsernameColumn={true}
                            />
                        </PaginatedTable>
                    ) : (
                        <div className="my-decks-page__no-decks">
                            <p className="my-decks-page__no-decks-description">
                                Your friend's deck list is currently empty
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default FriendsDecksPage;
