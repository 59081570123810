import React from 'react';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import AembershardIcon from '../shards/AembershardIcon';
import capitalize from 'lodash/capitalize';

interface UserTransactionsListItemProps extends WithNamespaces {
    transaction: Record<string, any>;
}

const UserTransactionsListItem = ({
    t,
    transaction,
}: UserTransactionsListItemProps) => {
    return (
        <tr className="kf-table__row">
            <td className="kf-table__cell transaction-list__date">
                {t('transactions.table.value.date', {
                    defaultValue: '{{date, shortDate}}',
                    date: new Date(transaction.created_at),
                })}
            </td>
            <td className="kf-table__cell transaction-list__location">
                {transaction.type || 'Æmber Purchase'}
            </td>
            <td className="kf-table__cell transaction-list__description"></td>
            <td className="kf-table__cell transaction-list__shards transaction-list__shards-changed">
                ${transaction.total_cents_amount / 100}
            </td>
            <td
                className="kf-table__cell transaction-list__shards transaction-list__shards-total"
                style={{ display: 'flex', flexDirection: 'row' }}
            >
                <div
                    style={{
                        width: '3rem',
                        height: '3rem',
                        marginRight: '0.5rem',
                    }}
                >
                    <AembershardIcon />
                </div>
                {transaction.quantity * +process.env.AEMBER_AMOUNT_IN_PACK}
            </td>
            <td className="kf-table__cell transaction-list__shards transaction-list__shards-changed">
                {capitalize(transaction.payment_status)}
            </td>
            <td className="transaction-list__mobile-shards">
                <div>
                    {t('transactions.table.header.shards-changed', {
                        defaultValue: 'Cost',
                    })}{' '}
                    <strong>${transaction.cost}</strong>
                </div>
                <div>
                    {t('transactions.table.header.shards-balance', {
                        defaultValue: 'Amount',
                    })}{' '}
                    <strong>{transaction.amount || 0}</strong>
                </div>
            </td>
        </tr>
    );
};

export default withNamespaces()(UserTransactionsListItem);
