import './AembershardIcon.scss';

import React from 'react';

interface Props {
    piecesCount?: number; // 0 - 3, parts of aembershard, determines icon
}

export function AembershardIcon({ piecesCount = 3 }: Props) {
    return (
        <span
            className={`aembershard-icon aembershard-icon--${piecesCount}of3`}
        />
    );
}

export default AembershardIcon;
