import './NoteModal.scss';

import React, { Component, SyntheticEvent } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { equals, assocPath } from 'ramda';

import Modal from '@/components/common/Modal';
import ErrorModal from '@/components/common/ErrorModal';
import TextInput from '@/components/common/inputs/TextInput';
import DiscardChangesModal from './DiscardChangesModal';
import NoteDeleteModal from './NoteDeleteModal';
import { DecksActions, LegacyDecksActions } from '@/shared/redux/decks';
import { RootState } from '@/shared/redux/RootState';
import { AsyncStatus } from '@/shared/redux/Async';
import { Deck, Note } from '@/shared/typings';

export interface Props extends WithNamespaces {
    isOpen: boolean;
    openMode: 'content' | 'edit';
    note: Note;
    deck: Deck;
    onClose: () => void;
    noteUpdateStatus: AsyncStatus;
    isLegacy: boolean;
    requestNoteUpdate: (typeof DecksActions)['updateNoteRequest'];
}

interface State {
    editMode: boolean;
    internalNote: Note; // note used for editing
    unmodifiedNote: Note; // note used for checking changes
    isErrorModalOpen: boolean;
    isDiscardChangesModalOpen: boolean;
    isDeleteModalOpen: boolean;
    isLegacy?: boolean;
}

const InitialState: State = {
    editMode: false,
    internalNote: null,
    unmodifiedNote: null,
    isErrorModalOpen: false,
    isDiscardChangesModalOpen: false,
    isDeleteModalOpen: false,
};

export class NoteContentModal extends Component<Props, State> {
    public state = InitialState;

    public componentDidMount() {
        const { note, openMode } = this.props;

        this.setState({
            editMode: openMode === 'edit',
            unmodifiedNote: note,
            internalNote: note,
        });
    }

    public componentDidUpdate(prevProps: Props, prevState: State) {
        const { isOpen, openMode, note, noteUpdateStatus } = this.props;
        const { internalNote } = this.state;

        // Reset state on modal open
        if (isOpen !== prevProps.isOpen && openMode !== prevProps.openMode) {
            this.setState({
                editMode: openMode === 'edit',
                unmodifiedNote: note,
                internalNote: note,
            });
        }

        // Set note update success
        if (
            noteUpdateStatus !== prevProps.noteUpdateStatus &&
            noteUpdateStatus === AsyncStatus.Success
        ) {
            this.setState({
                editMode: false,
                unmodifiedNote: internalNote,
            });
        }

        if (
            noteUpdateStatus !== prevProps.noteUpdateStatus &&
            noteUpdateStatus === AsyncStatus.Error
        ) {
            this.toggleErrorModal(true);
        }
    }

    public render() {
        const { isOpen, noteUpdateStatus, t } = this.props;
        const {
            internalNote,
            editMode,
            isErrorModalOpen,
            isDiscardChangesModalOpen,
            isDeleteModalOpen,
        } = this.state;

        return internalNote === null ? null : (
            <Modal
                isOpen={isOpen}
                onClose={this.requestModalClose}
                className="note-modal"
                headerImage={<div className="note-modal__header-image" />}
                loading={noteUpdateStatus === AsyncStatus.Pending}
            >
                {this.renderNoteContent(internalNote, editMode)}

                {noteUpdateStatus === AsyncStatus.Error && (
                    <ErrorModal
                        isOpen={isErrorModalOpen}
                        onClose={() => this.toggleErrorModal(false)}
                        description={t('modal.note-update.failure', {
                            defaultValue:
                                'Your note was not updated, try again.',
                        })}
                    />
                )}

                <DiscardChangesModal
                    isOpen={isDiscardChangesModalOpen}
                    onCancel={() => this.toggleDiscardChangesModal(false)}
                    onSuccess={this.closeModal}
                />
                <NoteDeleteModal
                    note={internalNote}
                    deck={this.props.deck}
                    isLegacy={this.props.isLegacy}
                    isOpen={isDeleteModalOpen}
                    onClose={this.handleNoteDeleteModalClose}
                />
            </Modal>
        );
    }

    private renderNoteContent = (note: Note, editMode: boolean) => (
        <div
            className={classNames('note-modal__note-content', {
                'note-modal__note-content--edit-mode': editMode,
            })}
        >
            {!editMode && (
                <button
                    className="note-modal__close-btn"
                    onClick={this.requestModalClose}
                >
                    {this.props.t('general.hint.close', {
                        defaultValue: 'Close',
                    })}
                </button>
            )}
            <div className="note-modal__note-header">
                <div
                    className="note-modal__note-date"
                    title={this.props.t('note.date.hint.created-on', {
                        defaultValue: 'Created on {{date, fullDate}}',
                        date: new Date(note.creationTimestampMs),
                    })}
                >
                    {this.props.t('note.date.created-on', {
                        defaultValue: '{{date, byLanguage}}',
                        date: new Date(note.creationTimestampMs),
                    })}
                </div>
                {!editMode && (
                    <button
                        className="note-modal__note-action-btn note-modal__note-edit"
                        onClick={() => this.toggleEditMode(true)}
                    >
                        {this.props.t('note.btn.edit', {
                            defaultValue: 'Edit',
                        })}
                    </button>
                )}
                <button
                    className="note-modal__note-action-btn note-modal__note-delete"
                    onClick={() => this.toggleNoteDeleteModal(true)}
                >
                    {this.props.t('note.btn.delete', {
                        defaultValue: 'Delete',
                    })}
                </button>
            </div>
            <div className="note-modal__note-text">
                {editMode ? (
                    <TextInput
                        asTextArea={true}
                        onChange={this.handleNoteTextChange}
                        value={note.text}
                        className="note-modal__note-text-editor"
                    />
                ) : (
                    note.text
                )}
            </div>
            {editMode && (
                <div className="note-modal__note-edit-buttons">
                    <button
                        className="note-modal__note-cancel-btn btn"
                        onClick={this.cancelEditMode}
                    >
                        <span className="btn-content">
                            {this.props.t('note.btn.cancel', {
                                defaultValue: 'Cancel',
                            })}
                        </span>
                    </button>
                    <button
                        className="note-modal__note-save-btn btn-secondary"
                        onClick={this.requestNoteSave}
                        disabled={note.text.trim().length === 0}
                    >
                        <span className="btn-content">
                            {this.props.t('note.btn.save', {
                                defaultValue: 'Save',
                            })}
                        </span>
                    </button>
                </div>
            )}
        </div>
    );

    private toggleEditMode = (editMode: boolean) => this.setState({ editMode });

    private cancelEditMode = () =>
        this.setState({
            internalNote: this.state.unmodifiedNote,
            editMode: false,
        });

    private requestNoteSave = () => {
        const { internalNote: note } = this.state;
        this.props.requestNoteUpdate(this.props.deck.id, note.id, note.text);
    };

    private requestModalClose = () => {
        if (equals(this.state.unmodifiedNote, this.state.internalNote)) {
            this.closeModal();
        } else {
            this.toggleDiscardChangesModal(true);
        }
    };

    private closeModal = () => this.setState(InitialState, this.props.onClose);

    private toggleErrorModal = (isErrorModalOpen: boolean) =>
        this.setState({ isErrorModalOpen });

    private toggleDiscardChangesModal = (isDiscardChangesModalOpen: boolean) =>
        this.setState({ isDiscardChangesModalOpen });

    private toggleNoteDeleteModal = (isDeleteModalOpen: boolean) =>
        this.setState({ isDeleteModalOpen });

    private handleNoteDeleteModalClose = (wasNoteDeleted = false) => {
        this.toggleNoteDeleteModal(false);
        if (wasNoteDeleted) {
            this.closeModal();
        }
    };

    private handleNoteTextChange = (e: SyntheticEvent<HTMLTextAreaElement>) =>
        this.setState(
            assocPath(['internalNote', 'text'], e.currentTarget.value)
        );
}

const mapStateToProps = (state: RootState, ownProps) => {
    const sliceKey = !ownProps.isLegacy ? 'decks' : 'decksLegacy';

    return { noteUpdateStatus: state[sliceKey].updateNote.__status };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        requestNoteUpdate: (deckId, noteId, noteText) => {
            const actions = ownProps.isLegacy
                ? LegacyDecksActions
                : DecksActions;
            dispatch(actions.updateNoteRequest(deckId, noteId, noteText));
        },
    };
};

export default withNamespaces()(
    connect(mapStateToProps, mapDispatchToProps)(NoteContentModal)
);
