import { put, call } from 'redux-saga/effects';

import {
    DecksActions,
    AddNoteRequestAction,
    UpdateNoteRequestAction,
    DeleteNoteRequestAction,
    LegacyDecksActions,
} from '../../redux/decks';
import API from '../../api/Api';
import {
    NoteResponse,
    EmptyResponse,
    GeneralErrorResponse,
} from '../../api/ApiTypings';
import { normalizeNote } from '../Normalizers';
import { ensureUserId } from '@/shared/sagas/user/UserSaga';
import { isLegacyOwnershipAction } from '@/shared/redux/user_decks';

export function* addNote(
    api: API,
    action: AddNoteRequestAction
): Iterator<any> {
    const legacy = isLegacyOwnershipAction(action);
    const actions = legacy ? LegacyDecksActions : DecksActions;
    const userId = yield ensureUserId();
    const response: NoteResponse = yield call(
        api.addNoteToDeck,
        userId,
        action.deckId,
        action.noteText,
        legacy
    );
    if (response.status === 201) {
        const note = normalizeNote(response.data.data);
        yield put(actions.addNoteSuccess(note));
    } else {
        yield put(
            actions.addNoteFailure({
                error: response as unknown as GeneralErrorResponse,
            })
        );
    }
}

export function* updateNote(
    api: API,
    action: UpdateNoteRequestAction
): Iterator<any> {
    const legacy = isLegacyOwnershipAction(action);
    const actions = legacy ? LegacyDecksActions : DecksActions;
    const userId = yield ensureUserId();
    const response: NoteResponse = yield call(
        api.updateDeckNote,
        userId,
        action.deckId,
        action.noteId,
        action.noteText,
        legacy
    );
    if (response.status === 200) {
        const note = normalizeNote(response.data.data);
        yield put(actions.updateNoteSuccess(note));
    } else {
        yield put(
            actions.updateNoteFailure({
                error: response as unknown as GeneralErrorResponse,
            })
        );
    }
}

export function* deleteNote(
    api: API,
    action: DeleteNoteRequestAction
): Iterator<any> {
    const legacy = isLegacyOwnershipAction(action);
    const actions = legacy ? LegacyDecksActions : DecksActions;
    const userId = yield ensureUserId();
    const response: EmptyResponse = yield call(
        api.removeNoteFromDeck,
        userId,
        action.deckId,
        action.noteId,
        legacy
    );
    if (response.status === 204) {
        yield put(actions.deleteNoteSuccess({ noteId: action.noteId }));
    } else {
        yield put(
            actions.deleteNoteFailure({
                error: response as unknown as GeneralErrorResponse,
            })
        );
    }
}
