import {
    useQuery,
    useMutation,
    useQueryClient,
    UseMutationOptions,
} from '@tanstack/react-query';
import {
    GetFriendsPayload,
    AxiosError,
    SearchUsersPayload,
    Pagination,
    FriendsDecksPayload,
} from '@/shared/api/ApiTypings';
import { API } from '@/shared/api/Api';

export const friendsQueryKeys = {
    friendsList: ['friends'],
    search: (searchText: string) => ['friends', 'search', searchText],
    friendDecks: (
        userId?: string,
        search?: string,
        expansion?: number | string,
        onlyWatchlist?: boolean,
        houses?: string[],
        ordering?: string,
        pagination?: Pagination
    ) => [
        'friendDecks',
        userId,
        search,
        expansion,
        onlyWatchlist,
        houses,
        ordering,
        pagination,
    ],
};

export const useFriends = () =>
    useQuery<GetFriendsPayload, AxiosError>({
        queryKey: friendsQueryKeys.friendsList,
        queryFn: API.getFriendsList,
    });

export const useFriendsSearch = (searchText: string) =>
    useQuery<SearchUsersPayload, AxiosError>({
        queryKey: friendsQueryKeys.search(searchText),
        queryFn: () => {
            return API.searchUsers(searchText, { page: 0, pageSize: 10 });
        },
        enabled: !!searchText,
    });

export const useAddFriend = () => {
    const queryClient = useQueryClient();
    return useMutation<void, unknown, string>({
        mutationFn: (userId) => {
            if (!userId) {
                return;
            }
            return API.addFriend(userId);
        },
        onSuccess() {
            queryClient.invalidateQueries(friendsQueryKeys.friendsList);
        },
    });
};

export const useRemoveFriend = (
    options: Omit<UseMutationOptions<void, unknown, string>, 'mutationFn'> = {}
) => {
    const queryClient = useQueryClient();
    return useMutation<void, unknown, string>({
        ...options,
        mutationFn: (userId: string) => {
            if (!userId) {
                return;
            }
            return API.removeFriend(userId);
        },
        onSuccess(...args) {
            queryClient.invalidateQueries(friendsQueryKeys.friendsList);
            // eslint-disable-next-line no-unused-expressions
            options?.onSuccess?.(...args);
        },
    });
};

export const useFriendDecks = (
    search?: string,
    expansion?: number | string,
    onlyWatchlist?: boolean,
    houses?: string[],
    ordering?: string,
    pageSize?: number,
    page?: number,
    friendId: string = undefined
) => {
    return useQuery<FriendsDecksPayload, AxiosError>({
        queryKey: ['friendDecks', {
        search,
        expansion,
        onlyWatchlist,
        houses,
        ordering,
        pageSize,
        page,
        friendId}],
        queryFn: async ({queryKey}) => {
            const [_key, {
                friendId,
                search,
                expansion,
                onlyWatchlist,
                houses,
                ordering,
                pageSize,
                page}] = queryKey
            const friendsDecks = await API.getFriendsDecks(
                search,
                expansion,
                onlyWatchlist,
                houses,
                ordering,
                pageSize,
                page,
                friendId
            )
        
            return friendsDecks
        }
    });
};
