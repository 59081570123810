import { assoc } from 'ramda';
import {
    errorReducerIn,
    pendingReducerIn,
    successReducerIn,
} from '@/shared/redux/Async';
import { InitialState } from './State';
import { createReducer } from '@/shared/redux/ReduxHelpers';
import { UserDecksSuccessAction } from '@/shared/redux/user_decks/Actions';
import {
    Types,
    Creators,
    UserAlliancesSuccessAction,
    GetAllianceSuccessAction,
} from './Actions';

export default Creators;
export const AlliancesTypes = Types;
export type AlliancesStateType = typeof InitialState;

export const reducer = createReducer(InitialState, {
    [Types.DECK_SUGGESTIONS_REQUEST]: pendingReducerIn('deckSuggestions'),
    [Types.DECK_SUGGESTIONS_SUCCESS]: successReducerIn(
        'deckSuggestions',
        mapDecksSuggestionsSuccess
    ),
    [Types.DECK_SUGGESTIONS_FAILURE]: errorReducerIn('deckSuggestions'),

    [Types.CREATE_ALLIANCE_REQUEST]: pendingReducerIn('creatingAlliance'),
    [Types.CREATE_ALLIANCE_SUCCESS]: successReducerIn('creatingAlliance'),
    [Types.CREATE_ALLIANCE_FAILURE]: errorReducerIn('creatingAlliance'),
    [Types.CREATE_ALLIANCE_RESET]: mapCreateAllianceReset,

    [Types.UPDATE_ALLIANCE_REQUEST]: pendingReducerIn('updatingAlliance'),
    [Types.UPDATE_ALLIANCE_SUCCESS]: successReducerIn('updatingAlliance'),
    [Types.UPDATE_ALLIANCE_FAILURE]: errorReducerIn('updatingAlliance'),
    [Types.UPDATE_ALLIANCE_RESET]: mapUpdateAllianceReset,

    [Types.USER_ALLIANCES_REQUEST]: pendingReducerIn('userAlliances'),
    [Types.USER_ALLIANCES_SUCCESS]: successReducerIn(
        'userAlliances',
        mapUserAlliancesSuccess
    ),
    [Types.USER_ALLIANCES_FAILURE]: errorReducerIn('userAlliances'),

    [Types.GET_ALLIANCE_REQUEST]: pendingReducerIn('getAllianceData'),
    [Types.GET_ALLIANCE_SUCCESS]: successReducerIn(
        'getAllianceData',
        mapGetAllianceSuccess
    ),
    [Types.GET_ALLIANCE_FAILURE]: errorReducerIn('getAllianceData'),
});

function mapCreateAllianceReset(state: AlliancesStateType) {
    return assoc('creatingAlliance', InitialState.creatingAlliance, state);
}

function mapUpdateAllianceReset(state: AlliancesStateType) {
    return assoc('updatingAlliance', InitialState.updatingAlliance, state);
}

function mapDecksSuggestionsSuccess(
    state: AlliancesStateType['deckSuggestions'],
    action: UserDecksSuccessAction
): Partial<AlliancesStateType['deckSuggestions']> {
    return {
        list: action.payload.decks,
        totalCount: action.payload.totalCount,
    };
}

function mapUserAlliancesSuccess(
    state: AlliancesStateType['userAlliances'],
    action: UserAlliancesSuccessAction
): Partial<AlliancesStateType['userAlliances']> {
    return {
        list: action.payload.list,
        totalCount: action.payload.totalCount,
    };
}

function mapGetAllianceSuccess(
    state: AlliancesStateType['getAllianceData'],
    action: GetAllianceSuccessAction
): Partial<AlliancesStateType['getAllianceData']> {
    return {
        data: action.payload,
    };
}
