import React from 'react';

const ActionInitiated = ({ action, type, onClose, onProceed }) => {
    const headerContent = () => {
        switch (type) {
            case 'Claim':
                switch (action) {
                    case 'Cancel':
                        return <p>Cancel Deck Claim Request</p>;
                    case 'Approve':
                        return <p>Confirm Deck Claim Request Approval</p>;
                    case 'Deny':
                        return <p>Deny Deck Claim Request</p>;
                    case 'Escalate':
                        return <p>Escalate Deck Claim Request</p>;
                }
            case 'Transfer':
                switch (action) {
                    case 'Cancel':
                        return <p>Cancel Deck Transfer</p>;
                    case 'Approve':
                        return <p>Approve Deck Transfer</p>;
                    case 'Deny':
                        return <p>Deny Deck Transfer</p>;
                }
        }
    };

    const bodyContent = () => {
        switch (type) {
            case 'Claim':
                switch (action) {
                    case 'Escalate':
                        return (
                            <p className="modal-transfer-claim__description">
                                You are being redirected to Playstile to
                                escalate your claim with Ghost Galaxy. <br />
                                Ghost Galaxy is using Playstile to manage claim
                                verification. <br />
                                Upon submission 10 Æmber will be deducted from
                                your account.
                            </p>
                        );
                    case 'Cancel':
                        return (
                            <p className="modal-transfer-claim__description">
                                Are you sure you want to proceed to cancel this
                                deck Claim Request?
                            </p>
                        );
                    case 'Approve':
                        return (
                            <p className="modal-transfer-claim__description">
                                Warning! By approving this deck claim request,
                                the Master Vault Deck Ownership of this deck
                                will be transferred to the claimant and will no
                                longer be listed on your My Decks page.
                            </p>
                        );
                    case 'Deny':
                        return (
                            <p className="modal-transfer-claim__description">
                                Please confirm that you want to deny this deck
                                claim request.
                            </p>
                        );
                }
            case 'Transfer':
                switch (action) {
                    case 'Cancel':
                        return (
                            <p className="modal-transfer-claim__description">
                                Are you sure you want to cancel this deck
                                transfer?
                            </p>
                        );
                    case 'Approve':
                        return (
                            <p className="modal-transfer-claim__description">
                                A user wants to transfer the Master Vault Deck
                                Ownership of this deck to you. Are you sure you
                                want to approve this deck Transfer?
                            </p>
                        );
                    case 'Deny':
                        return (
                            <p className="modal-transfer-claim__description">
                                A user wants to transfer the Master Vault Deck
                                Ownership of this deck to you. Are you sure you
                                want to deny this deck transfer?
                            </p>
                        );
                }
        }
    };

    const buttonsContent = () => {
        switch (type) {
            case 'Claim':
                switch (action) {
                    case 'Cancel':
                        return (
                            <>
                                <button
                                    className="modal-transfer-claim__btn modal-transfer-claim__cancel-btn btn"
                                    onClick={onClose}
                                >
                                    Dismiss
                                </button>
                                <button
                                    className="modal-transfer-claim__btn modal-transfer-claim__remove-btn btn-secondary"
                                    onClick={onProceed}
                                >
                                    Proceed
                                </button>
                            </>
                        );
                    case 'Escalate':
                        return (
                            <>
                                <button
                                    className="modal-transfer-claim__btn modal-transfer-claim__cancel-btn btn"
                                    onClick={onClose}
                                >
                                    Dismiss
                                </button>
                                <button
                                    className="modal-transfer-claim__btn modal-transfer-claim__remove-btn btn-secondary"
                                    onClick={onProceed}
                                >
                                    Escalate
                                </button>
                            </>
                        );
                    case 'Approve':
                        return (
                            <>
                                <button
                                    className="modal-transfer-claim__btn modal-transfer-claim__cancel-btn btn"
                                    onClick={onClose}
                                >
                                    Dismiss
                                </button>
                                <button
                                    className="modal-transfer-claim__btn modal-transfer-claim__remove-btn btn-secondary"
                                    onClick={onProceed}
                                >
                                    Confirm
                                </button>
                            </>
                        );
                    case 'Deny':
                        return (
                            <>
                                <button
                                    className="modal-transfer-claim__btn modal-transfer-claim__cancel-btn btn"
                                    onClick={onClose}
                                >
                                    Dismiss
                                </button>
                                <button
                                    className="modal-transfer-claim__btn modal-transfer-claim__remove-btn btn-secondary"
                                    onClick={onProceed}
                                >
                                    Deny
                                </button>
                            </>
                        );
                }
            case 'Transfer':
                switch (action) {
                    case 'Cancel':
                        return (
                            <>
                                <button
                                    className="modal-transfer-claim__btn modal-transfer-claim__cancel-btn btn"
                                    onClick={onClose}
                                >
                                    Dismiss
                                </button>
                                <button
                                    className="modal-transfer-claim__btn modal-transfer-claim__remove-btn btn-secondary"
                                    onClick={onProceed}
                                >
                                    Proceed
                                </button>
                            </>
                        );
                    case 'Approve':
                        return (
                            <>
                                <button
                                    className="modal-transfer-claim__btn modal-transfer-claim__cancel-btn btn"
                                    onClick={onClose}
                                >
                                    Dismiss
                                </button>
                                <button
                                    className="modal-transfer-claim__btn modal-transfer-claim__remove-btn btn-secondary"
                                    onClick={onProceed}
                                >
                                    Approve Transfer
                                </button>
                            </>
                        );
                    case 'Deny':
                        return (
                            <>
                                <button
                                    className="modal-transfer-claim__btn modal-transfer-claim__cancel-btn btn"
                                    onClick={onClose}
                                >
                                    Dismiss
                                </button>
                                <button
                                    className="modal-transfer-claim__btn modal-transfer-claim__remove-btn btn-secondary"
                                    onClick={onProceed}
                                >
                                    Deny Transfer
                                </button>
                            </>
                        );
                }
        }
    };

    return (
        <div className="modal-transfer-claim__deck-deleted">
            <h2 className="modal-transfer-claim__header">{headerContent()}</h2>
            <div>{bodyContent()}</div>
            <div className="modal-transfer-claim__buttons">
                {buttonsContent()}
            </div>
        </div>
    );
};

export default ActionInitiated;
