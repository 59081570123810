import './BuyAemberModal.scss';
import React from 'react';
import Modal from '../../../common/Modal';
import { useInitPayment } from '@/shared/queries/payments';

interface BuyAemberModalProps {
    opened: boolean;
    onClose: () => void;
}

const BuyAemberModal = ({
    opened,
    onClose,
}: BuyAemberModalProps): JSX.Element => {
    const { mutate: initPayment, isLoading } = useInitPayment();

    return (
        <Modal
            isOpen={opened}
            className={'buy-aember-modal'}
            headerImage={<div className="buy-aember-modal__header-image" />}
            loading={isLoading}
        >
            <div className="buy-aember-modal__content">
                <h2 className="buy-aember-modal__header">Buy Æmber</h2>
                <h3 className="buy-aember-modal__description">
                    Would you like to buy {process.env.AEMBER_AMOUNT_IN_PACK}{' '}
                    Æmber for ${process.env.AEMBER_PACK_COST}?
                </h3>
                <h4 className="buy-aember-modal__redirect-info">
                    You will be redirected to Stripe, our payment provider, to
                    complete the transaction through our technology partner
                    AWEbase, Inc.
                </h4>
                <div className="buy-aember-modal__buttons">
                    <button
                        className="buy-aember-modal__btn btn btn-secondary"
                        onClick={onClose}
                    >
                        Cancel
                    </button>
                    <button
                        className="buy-aember-modal__btn btn btn-okay"
                        onClick={() => initPayment()}
                    >
                        Buy Æmber
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default BuyAemberModal;
