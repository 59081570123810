import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from '../api/ApiTypings';
import { API } from '../api/Api';
import { useMemo } from 'react';
import { ErrorType } from './deck_transfers';

export const useClaimDeckMutation = () => {
    return useMutation<void, ErrorType, any>({
        mutationFn: (code: string) => {
            return API.claimDeck(code);
        },
    });
};

export const useDeckClaims = (
    status?: string,
    type?: string,
    ordering?: string,
    pageSize?: number,
    page?: number
) => {
    const deckClaimKey = useMemo(() => {
        const uniqueId = Date.now(); // Or use any other unique identifier
        return ['deckClaims', status, type, ordering, pageSize, page, uniqueId];
    }, [status, type, ordering, pageSize, page]);

    return useQuery<any, AxiosError>({
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey: deckClaimKey,
        queryFn: () => API.getClaims(status, type, ordering, pageSize, page),
    });
};

export const useApproveDeckClaimMutation = () => {
    const queryClient = useQueryClient();
    return useMutation<void, ErrorType, any>({
        mutationFn: (claimId: string) => {
            return API.acceptClaim(claimId);
        },
        onSuccess() {
            // @ts-ignore
            queryClient.invalidateQueries('deckClaims');
        },
    });
};

export const useDenyDeckClaimMutation = () => {
    const queryClient = useQueryClient();
    return useMutation<void, ErrorType, any>({
        mutationFn: (claimId: string) => {
            return API.denyClaim(claimId);
        },
        onSuccess() {
            // @ts-ignore
            queryClient.invalidateQueries('deckClaims');
        },
    });
};

export const useCancelDeckClaimMutation = () => {
    const queryClient = useQueryClient();
    return useMutation<void, ErrorType, any>({
        mutationFn: (claimId: string) => {
            return API.cancelClaim(claimId);
        },
        onSuccess() {
            // @ts-ignore
            queryClient.invalidateQueries('deckClaims');
        },
    });
};
