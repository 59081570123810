import { PureComponent } from 'react';
import { RouteComponentProps } from 'react-router-dom';

export type Props = RouteComponentProps;

export default class ScrollToTop extends PureComponent<Props> {
    public componentDidUpdate(prevProps: Props) {
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0);
        }
    }

    public render = (): JSX.Element => null;
}
