import { includes, startsWith, head, split, find, pluck, propOr } from 'ramda';

export const DefaultLang = 'en-us';

interface Language {
    label: string;
    value: string;
}

// NOTE: when adding a new language, make sure to use localized label and to
// sort it by using localized string compare, ie. String​.prototype​.locale​Compare()
export const LanguageLabels: Language[] = [
    {
        label: 'Deutsch',
        value: 'de-de',
    },
    {
        label: 'English',
        value: 'en-us',
    },
    {
        label: 'Español',
        value: 'es-es',
    },
    {
        label: 'Français',
        value: 'fr-fr',
    },
    {
        label: 'Italiano',
        value: 'it-it',
    },
    {
        label: 'Polski',
        value: 'pl-pl',
    },
    {
        label: 'Português',
        value: 'pt-pt',
    },
    {
        label: 'Tiếng Việt',
        value: 'vi-vi',
    },
    {
        label: 'ไทย',
        value: 'th-th',
    },
    {
        label: '简体中文',
        value: 'zh-hans',
    },
    {
        label: '繁體中文',
        value: 'zh-hant',
    },
    {
        label: '한국어',
        value: 'ko-ko',
    },
];

const LocaleMap: Record<string, string> = {
    'zh-cn': 'zh-hans',
    'zh-sg': 'zh-hans',
    'zh-hk': 'zh-hant',
    'zh-mo': 'zh-hant',
    'zh-tw': 'zh-hant',
};

export const SupportedLanguages = pluck('value', LanguageLabels);

export function extractLocaleCode(storedLocale?: string): string {
    const locale = (storedLocale && storedLocale.toLowerCase()) || DefaultLang;
    const allowedLocale = mapAllowedLocale(locale);

    // try full locale code
    if (includes(allowedLocale, SupportedLanguages)) {
        return allowedLocale;
    }

    // try another locale from same country
    const countryCode = head(split('-', allowedLocale));
    const sameCountryDefaultLocale = find(
        startsWith(countryCode),
        SupportedLanguages
    );
    return sameCountryDefaultLocale || DefaultLang;
}

function mapAllowedLocale(locale: string): string {
    return propOr(locale, locale, LocaleMap);
}
