import React, { memo } from 'react';
import classNames from 'classnames';
import { Combobox } from '@headlessui/react';

import './LanguageSelect.scss';

export interface SelectItem {
    label: string;
    value: string;
}

export interface Props {
    className?: string;
    languageList: SelectItem[];
    value: string;
    onChange: (lang: string) => Promise<void>;
}

export function LanguageSelect(props: Props) {
    const value = props.languageList.find((l) => l.value === props.value);

    return (
        <Combobox
            as="div"
            className={classNames('language-select', props.className)}
            by="value"
            value={value}
            onChange={(lang: SelectItem) => {
                props.onChange(lang.value);
            }}
        >
            <Combobox.Button className="language-select__button">
                <div className="language-select__value">
                    {value?.label || ''}
                </div>
                <span className="language-select__caret" />
            </Combobox.Button>
            <Combobox.Options className="language-select__dropdown">
                <div className="language-select__values-list">
                    {props.languageList.map((item) => (
                        <Combobox.Option
                            value={item}
                            key={item.value}
                            className="language-select__item"
                        >
                            {item.label}
                        </Combobox.Option>
                    ))}
                </div>
            </Combobox.Options>
        </Combobox>
    );
}

export default memo(LanguageSelect);
