import React, { SyntheticEvent } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { RootState } from '@/shared/redux/RootState';
import { currentAuthorizationUrl } from '@/shared/services/Auth';
import PersistedUserActions from '@/shared/redux/persisted_user';
import { getBaseUrl } from '@/utils/url';

export interface AuthLinkProps {
    to: string;
    children?: React.ReactElement<any>;
    className: string;
    oauthNonce: string;
    refreshAuthorizationUrl: (redirectUrl: string) => void;
}

export class AuthLink extends React.Component<AuthLinkProps> {
    private shouldRedirect: boolean = false;

    public componentDidUpdate(prevProps: AuthLinkProps) {
        // oauth nonce will change after "refreshAuthorizationUrl", so we
        // redirect after the change
        if (this.shouldRedirect) {
            this.shouldRedirect = false;
            window.location.assign(
                currentAuthorizationUrl(
                    this.props.oauthNonce,
                    this.props.to,
                    `${getBaseUrl()}/authorize`
                )
            );
        }
    }

    public render() {
        return (
            <a
                href="#"
                className={classNames(
                    'auth-link btn-link',
                    this.props.className
                )}
                onClick={this.storeStateAndRedirect}
            >
                {this.props.children}
            </a>
        );
    }

    private storeStateAndRedirect = (ev: SyntheticEvent) => {
        ev.preventDefault();
        this.props.refreshAuthorizationUrl(this.props.to);
        this.shouldRedirect = true;
    };
}

const mapStateToProps = (state: RootState) => ({
    oauthNonce: state.persistedUser.auth.oauthNonce,
});

const mapDispatchToProps = {
    refreshAuthorizationUrl: PersistedUserActions.refreshAuthorizationUrl,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthLink);
