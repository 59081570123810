import './Logo.scss';

import React, { FC } from 'react';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import classNames from 'classnames';

export type Props = WithNamespaces;

export const Logo: FC<Props> = ({ t }) => (
    <a
        href="https://keyforging.com"
        className={classNames('logo', `logo--common`)}
        aria-label={t('navbar.btn.logo.label', {
            defaultValue: 'Go to homepage',
        })}
    />
);

export default withNamespaces()(Logo);
