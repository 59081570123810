import React, { SyntheticEvent } from 'react';
import TextInput from '../../../components/common/inputs/TextInput';

interface IProps {
    className: string;
    searchPlaceholder: string;
    searchText: string;
    handleSearchTextChange: (
        // eslint-disable-next-line no-undef
        e: SyntheticEvent<HTMLElement | HTMLTextAreaElement>
    ) => void;
    applyNewFilters: () => void;
    disableSearch?: boolean;
}

export const SearchDeckInput = (props: IProps) => {
    const {
        className,
        searchPlaceholder,
        searchText,
        handleSearchTextChange,
        applyNewFilters,
        disableSearch,
    } = props;
    return (
        <div className={`${className}__input ${className}__search`}>
            <h2
                className={`${className}__input-label ${className}__input-label-heading keyforge-heading-2`}
            >
                Search
            </h2>
            <div
                className={`${className}__input-group ${className}__search-input`}
            >
                <TextInput
                    className={`${className}__input-group-field`}
                    placeholder={searchPlaceholder}
                    ariaLabel={'abcd'}
                    value={searchText}
                    onChange={handleSearchTextChange}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            applyNewFilters();
                        }
                    }}
                    disabled={disableSearch}
                />
                <button
                    className={`${className}__input-group-btn ${className}__btn-search btn-small`}
                    onClick={applyNewFilters}
                    disabled={disableSearch}
                >
                    <span className="btn-content">Search</span>
                </button>
            </div>
        </div>
    );
};
