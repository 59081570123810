import React, { PureComponent } from 'react';

import { Card, Deck } from '@/shared/typings';
import CardTable, { HouseCards } from './CardTable';
import { groupBy } from 'ramda';

interface Props {
    cards: Card[];
    legacyCardsIds: Deck['setEraCards']['Legacy'];
    anomalyCardsIds: Deck['setEraCards']['Anomaly'];
    noHeader?: boolean;
}

export class NonDeckCardTable extends PureComponent<Props> {
    public render() {
        const { cards, legacyCardsIds, anomalyCardsIds, noHeader } = this.props;
        const cardsByHouses = groupBy((card) => card.house, cards);
        const houseCards: HouseCards[] = Object.keys(cardsByHouses).map(
            (houseId) => ({
                houseId,
                house: {
                    id: houseId,
                    name: houseId,
                    image: null,
                    inFilters: false,
                },
                cards,
            })
        );

        return (
            <CardTable
                houseCards={houseCards}
                legacyCardsIds={legacyCardsIds}
                anomalyCardsIds={anomalyCardsIds}
                showHouseIcon={false}
                otherCards={true}
                noHeader={noHeader}
            />
        );
    }
}
