import './LoadingPage.scss';

import React from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';

export interface LoadingPageProps extends WithNamespaces {
    children?: React.ReactNode;
}

export function LoadingPage({ children, t }: LoadingPageProps) {
    return (
        <div className="loading-page">
            <div className="spinner">
                {children
                    ? children
                    : t('general.hint.loading', {
                          defaultValue: 'Loading...',
                      })}
            </div>
        </div>
    );
}

export default withNamespaces()(LoadingPage);
