import React from 'react';

const ActionSuccessful = ({ action, type, onClose }) => {
    const headerContent = () => {
        switch (type) {
            case 'Claim':
                switch (action) {
                    case 'Cancel':
                        return <p>Deck Claim Request has Been Canceled.</p>;
                    case 'Approve':
                        return <p>Deck Claim Request Approved</p>;
                    case 'Deny':
                        return <p>Deck Claim Request has Been Denied</p>;
                }
            case 'Transfer':
                switch (action) {
                    case 'Cancel':
                        return <p>Transfer Canceled</p>;
                    case 'Approve':
                        return <p>Transfer Completed</p>;
                    case 'Deny':
                        return <p>Transfer Denied</p>;
                }
        }
    };

    const bodyContent = () => {
        switch (type) {
            case 'Claim':
                switch (action) {
                    case 'Cancel':
                        return null;
                    case 'Approve':
                        return (
                            <p className="modal-transfer-claim__description">
                                Master Vault Deck Ownership of this deck was
                                transferred to the claimant and will no longer
                                be listed on the My Decks page.
                            </p>
                        );
                    case 'Deny':
                        return null;
                }
            case 'Transfer':
                switch (action) {
                    case 'Cancel':
                        return (
                            <p className="modal-transfer-claim__description">
                                The transfer was canceled successfully.
                            </p>
                        );
                    case 'Approve':
                        return (
                            <p className="modal-transfer-claim__description">
                                The transfer was completed successfully, you can
                                find this deck on the My Decks page (a few
                                minutes and a browser refresh may be necessary
                                for the transfer to be visible).
                            </p>
                        );
                    case 'Deny':
                        return (
                            <p className="modal-transfer-claim__description">
                                The transfer was successfully denied.
                            </p>
                        );
                }
        }
    };

    const buttonsContent = () => {
        return (
            <button
                className="modal-transfer-claim__btn btn btn-okay"
                onClick={() => onClose()}
            >
                Dismiss
            </button>
        );
    };

    return (
        <div className="modal-transfer-claim__deck-deleted">
            <h2 className="modal-transfer-claim__header">{headerContent()}</h2>
            <div>{bodyContent()}</div>
            <div className="modal-transfer-claim__buttons">
                {buttonsContent()}
            </div>
        </div>
    );
};

export default ActionSuccessful;
