import { InitialAsyncRequestState } from '../Async';

export const InitialState = {
    auth: {
        ...InitialAsyncRequestState,

        oauthNonce: '',
        oauthState: '/',
    },
    // current locale represent entire application translation lang
    // for current user. it won't be stored on backend as we can not change
    // it in Asmodee panel
    currentLocale: null as string,
};

export type StateType = typeof InitialState;
