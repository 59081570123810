import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import AllianceEditForm, {
    ALLIANCE_CLONABLE_KEYS,
} from '@/components/alliances/AllianceEditForm/AllianceEditForm';
import { AsyncStatus } from '@/shared/redux/Async';
import { RootState } from '@/shared/redux/RootState';
import AlliancesActions, { AlliancesStateType } from '@/shared/redux/alliances';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import './ForgeAlliancePage.scss';
import { pick } from 'ramda';
import Breadcrumbs from '../common/Breadcrumbs';

export type Props = WithNamespaces & {
    decks: RootState['userDecks']['userDecks'];
    createAlliance: typeof AlliancesActions['createAllianceRequest'];
    creatingAlliance: AlliancesStateType['creatingAlliance'];
};

export const ForgeAlliancePage = (props: Props) => {
    const { t, createAlliance, creatingAlliance } = props;
    const submitting = creatingAlliance.__status === AsyncStatus.Pending;
    const success = creatingAlliance.__status === AsyncStatus.Success;
    const { search } = useLocation();
    const urlParams = Array.from(new URLSearchParams(search).entries()).reduce(
        (acc, [k, v]) => ({
            ...acc,
            [k]: k === 'set_id' ? parseInt(v, 10) : v,
        }),
        {}
    );
    const defaultValues = pick(ALLIANCE_CLONABLE_KEYS, urlParams);

    return success ? (
        <Redirect to="/alliances" />
    ) : (
        <div className="forge-alliance">
            <Breadcrumbs />
            <h1 className="forge-alliance__header keyforge-heading-1">
                {t('forge-alliance.header', {
                    defaultValue: 'Forge an Alliance',
                })}
            </h1>
            <AllianceEditForm
                mode="create"
                onSubmit={createAlliance}
                submitting={submitting}
                defaultValues={defaultValues}
            />
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    creatingAlliance: state.alliances.creatingAlliance,
});

const mapDispatchToProps = {
    createAlliance: AlliancesActions.createAllianceRequest,
};

export default withNamespaces()(
    connect(mapStateToProps, mapDispatchToProps)(ForgeAlliancePage)
);
