import { createReducer } from '@/shared/redux/ReduxHelpers';
import { InitialState, StateType } from './State';
import {
    pendingReducerIn,
    successReducerIn,
    errorReducerIn,
} from '@/shared/redux/Async';
import {
    Types,
    Creators,
    GetNearestEventsSuccessAction,
    GetCurrentAddressSuccessAction,
} from './Actions';

export const EventLocatorTypes = Types;
export default Creators;

export const reducer = createReducer(InitialState, {
    [Types.GET_NEAREST_EVENTS_REQUEST]: pendingReducerIn('getNearestEvents'),
    [Types.GET_NEAREST_EVENTS_SUCCESS]: successReducerIn(
        'getNearestEvents',
        getNearestEventsSuccess
    ),
    [Types.GET_NEAREST_EVENTS_FAILURE]: errorReducerIn('getNearestEvents'),

    [Types.GET_CURRENT_ADDRESS_REQUEST]: pendingReducerIn('getCurrentAddress'),
    [Types.GET_CURRENT_ADDRESS_SUCCESS]: successReducerIn(
        'getCurrentAddress',
        getCurrentAddressSuccess
    ),
    [Types.GET_CURRENT_ADDRESS_FAILURE]: errorReducerIn('getCurrentAddress'),
});

/* ----------------- reducers --------------------- */

function getNearestEventsSuccess(
    state: StateType['getNearestEvents'],
    action: GetNearestEventsSuccessAction
): Partial<StateType['getNearestEvents']> {
    return {
        list: action.payload.events,
        total: action.payload.total,
    };
}

function getCurrentAddressSuccess(
    state: StateType['getCurrentAddress'],
    action: GetCurrentAddressSuccessAction
): Partial<StateType['getCurrentAddress']> {
    const { address, latitude, longitude } = action.payload;
    return {
        address,
        latitude: latitude !== undefined ? latitude : state.latitude,
        longitude: longitude !== undefined ? longitude : state.longitude,
    };
}
