import { useMutation } from '@tanstack/react-query';
import { API } from '@/shared/api/Api';

export type ErrorType = {
    response: {
        data: {
            message: string;
            detail: string;
        };
    };
};

export const useOrderDeckCopyMutation = () => {
    return useMutation<void, ErrorType, any>({
        mutationFn: (payload: {
            deckId: string;
            locale: string;
            selectedAccolades: string[];
        }) => {
            const { deckId, locale, selectedAccolades } = payload;
            return API.orderDeckCopy(deckId, locale, selectedAccolades);
        },
    });
};
