import './Pagination.scss';

import React, { ImgHTMLAttributes } from 'react';
import ReactPaginate from 'react-paginate';
import { withNamespaces, WithNamespaces } from 'react-i18next';

import ArrowNext from '@/images/common/arrow-right-dark.svg';

const arrow = (props: ImgHTMLAttributes<HTMLImageElement>) => (
    <img src={ArrowNext} {...props} />
);

export interface Props extends WithNamespaces {
    currentPage: number;
    pageCount: number;
    onPageChange: (page: number) => void;
}

export function Pagination({ currentPage, pageCount, onPageChange, t }: Props) {
    const previousLabel = t('general.hint.previous', {
        defaultValue: 'Previous',
    });
    const nextLabel = t('general.hint.next', {
        defaultValue: 'Next',
    });
    return pageCount > 1 ? (
        <div className="pagination">
            <ReactPaginate
                pageCount={pageCount}
                pageRangeDisplayed={5}
                marginPagesDisplayed={1}
                onPageChange={({ selected }) => onPageChange(selected)}
                containerClassName="pagination__list"
                pageClassName="pagination__page"
                activeClassName="pagination__page--active"
                previousClassName="pagination__previous-page"
                nextClassName="pagination__next-page"
                pageLinkClassName="pagination__page-link pagination__link"
                previousLinkClassName="pagination__link"
                nextLinkClassName="pagination__link"
                breakClassName="pagination__break"
                previousLabel={arrow({
                    alt: previousLabel,
                    className: 'pagination__arrow',
                })}
                nextLabel={arrow({
                    alt: nextLabel,
                    className: 'pagination__arrow',
                })}
                forcePage={currentPage}
            />
        </div>
    ) : null;
}

export default withNamespaces()(Pagination);
