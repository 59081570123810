import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduce, compose, assoc, split } from 'ramda';
import { RouteComponentProps } from 'react-router-dom';
import { withNamespaces, WithNamespaces } from 'react-i18next';

import LoadingPage from './LoadingPage';
import { RootState } from '@/redux/RootState';
import PersistedUserActions from '@/shared/redux/persisted_user';
import { isAuthorized } from '@/shared/redux/Selectors';

const queryToObject = (accObj: object, currStr: string) => {
    const [key, value] = currStr.split('=');
    return assoc(key, value, accObj);
};

const hashToObject: (query: string) => Record<string, string> = compose(
    reduce(queryToObject, {}),
    split('&'),
    (hash: string) => hash.slice(1)
);

type AuthBaseProps = RouteComponentProps & WithNamespaces;
export interface AuthProps extends AuthBaseProps {
    isAuthorized: boolean;
    oauthState: RootState['persistedUser']['auth']['oauthState'];
    oauthNonce: RootState['persistedUser']['auth']['oauthNonce'];
    authorizeUser: (idToken: string, accessToken: string, nonce: string) => any;
}

interface AuthState {
    redirectAfterUpdate: string;
}

/**
 * logic only for component that authorizes user and redirects him to target website
 */
export class AuthorizeContainer extends Component<AuthProps, AuthState> {
    public readonly state = { redirectAfterUpdate: '/' };

    public componentDidMount() {
        const queryObject = hashToObject(window.location.hash);
        const {
            access_token: accessToken,
            id_token: idToken,
            state: encodedUrlState,
        } = queryObject;
        const urlState = decodeURIComponent(encodedUrlState);
        const { oauthState: storedState, oauthNonce } = this.props;

        if (storedState !== urlState) {
            console.warn(
                `Invalid state parameter, cannot authorize.\nProvided: "${
                    urlState || ''
                }".\nExpected: "${storedState || ''}".`
            );
            return this.props.history.push('/');
        }
        this.setState({ redirectAfterUpdate: urlState });
        this.props.authorizeUser(idToken, accessToken, oauthNonce);
    }

    public componentDidUpdate() {
        if (this.props.isAuthorized) {
            this.props.history.replace(this.state.redirectAfterUpdate);
        }
    }

    public render() {
        return (
            <LoadingPage>
                {this.props.t('general.hint.auth-in-progress', {
                    defaultValue: 'Authorization in progress, please wait...',
                })}
            </LoadingPage>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    oauthState: state.persistedUser.auth.oauthState,
    oauthNonce: state.persistedUser.auth.oauthNonce,
    isAuthorized: isAuthorized(state),
});

const mapDispatchToProps = {
    authorizeUser: PersistedUserActions.authorizeUserRequest,
};

export default withNamespaces()(
    connect(mapStateToProps, mapDispatchToProps)(AuthorizeContainer)
);
