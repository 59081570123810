export function formatDateByLocale(date: Date, locale: string) {
    return date.toLocaleDateString(locale);
}

export function formatShortDateByLocale(date: Date, locale: string) {
    return date.toLocaleDateString(locale, {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    });
}

export function formatFullDateByLocale(date: Date, locale: string) {
    return date.toLocaleString(locale, {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZoneName: 'short',
    });
}

export function formatNumberByLocale(num: number, locale: string) {
    return num.toLocaleString(locale);
}

export const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const month = date.toLocaleString('en-US', { month: 'long' });
    const day = date.getDate();
    const year = date.getFullYear();

    return `${month} ${day}, ${year}`;
};
