import React from 'react';
import classNames from 'classnames';

import { Transfer } from '@/shared/api/ApiTypings';
import { defaultTransferColumns } from './TransferClaimList';
import './TransferClaimList.scss';
import { useSelector } from 'react-redux';
import { ModalData } from './DeckTransferPage';
import { formatDate } from '@/shared/services/lang/formatHelpers';
import { useResponsive } from 'ahooks';

interface IProps {
    data: Transfer;
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setModalData: React.Dispatch<React.SetStateAction<ModalData>>;
    exchangeType: 'transfer' | 'claim';
}

export const TransferClaimListItem = ({
    data,
    setIsModalOpen,
    setModalData,
    exchangeType,
}: IProps) => {
    const userProfile = useSelector(
        (state: any) => state.userProfile.fetchProfile.details
    );
    const { md } = useResponsive();

    const checkTransferDirection = () => {
        if (data.from_user.username === userProfile.username) return 'Outgoing';
        else return 'Incoming';
    };

    const parsedStatus = (status: string): string => {
        switch (status) {
            case 'accepted':
                return 'Accepted';
            case 'denied':
                return 'Denied';
            case 'canceled':
                return 'Canceled';
            case 'pending':
                return 'Pending';
            case 'escalated_accepted':
                return 'Verification Approved';
            case 'escalated_denied':
                return 'Verification Denied';
            case 'escalated':
                return 'Verification Pending';
            default:
                return status;
        }
    };

    const parsedData = {
        name: data.deck_name,
        date: formatDate(data.created_at),
        type: checkTransferDirection(),
        inidiatedBy: data.from_user.username,
        fromUser: data.from_user.username,
        toUser: data.to_user.username,
        status: parsedStatus(data.status),
    };

    const actionHandler = (action: string) => {
        setModalData({
            type: exchangeType,
            action: action,
            data: {
                id: data.id,
                deckName: 'testName',
            },
        });
        setIsModalOpen(true);
    };

    return (
        <tr className="kf-table__row transfer-claim-list__data-row__grid">
            {defaultTransferColumns?.map((column, i) => {
                return (
                    <td
                        key={i}
                        className={classNames(
                            `kf-table__cell transfer-claim-list__${column.id}-field`
                        )}
                    >
                        {column.id === 'name' ? (
                            <div>
                                <p>{parsedData.name}</p>
                                <p>{data.expansion.name}</p>
                            </div>
                        ) : column.id === 'date' ? (
                            <p>{parsedData.date}</p>
                        ) : column.id === 'type' ? (
                            <p>{parsedData.type}</p>
                        ) : column.id === 'fromUser' ? (
                            <p>
                                {md
                                    ? parsedData.fromUser
                                    : 'From user: ' + parsedData.fromUser}
                            </p>
                        ) : column.id === 'toUser' ? (
                            <p>
                                {md
                                    ? parsedData.toUser
                                    : 'To user: ' + parsedData.toUser}
                            </p>
                        ) : column.id === 'status' ? (
                            <p className={parsedData.status}>
                                {parsedData.status}
                            </p>
                        ) : column.id === 'buttons' &&
                          parsedData.type === 'Incoming' ? (
                            <div className="transfer-claim-list__transfer-buttons-container">
                                {parsedData.status === 'Pending' ? (
                                    <>
                                        <button
                                            type="button"
                                            className="btn-very-small-secondary"
                                            disabled={false}
                                            onClick={() =>
                                                actionHandler('approve')
                                            }
                                        >
                                            Approve {exchangeType}
                                        </button>
                                        <button
                                            type="button"
                                            className="btn-very-small"
                                            disabled={false}
                                            onClick={() =>
                                                actionHandler('deny')
                                            }
                                        >
                                            Deny {exchangeType}
                                        </button>
                                    </>
                                ) : null}
                            </div>
                        ) : column.id === 'buttons' &&
                          parsedData.type === 'Outgoing' ? (
                            <>
                                {parsedData.status === 'Pending' &&
                                data.can_escalate ? (
                                    <div className="transfer-claim-list__transfer-buttons-container">
                                        <button
                                            type="button"
                                            className="btn-very-small-secondary"
                                            disabled={false}
                                            onClick={() =>
                                                actionHandler('cancel')
                                            }
                                        >
                                            Cancel {exchangeType}
                                        </button>
                                        <button
                                            type="button"
                                            className="btn-very-small"
                                            disabled={false}
                                            onClick={() =>
                                                actionHandler('escalate')
                                            }
                                        >
                                            Escalate {exchangeType}
                                        </button>
                                    </div>
                                ) : parsedData.status === 'Denied' &&
                                  data.can_escalate ? (
                                    <div className="transfer-claim-list__cancel-button-container">
                                        <button
                                            type="button"
                                            className="btn-very-small"
                                            disabled={false}
                                            onClick={() =>
                                                actionHandler('escalate')
                                            }
                                        >
                                            Escalate {exchangeType}
                                        </button>
                                    </div>
                                ) : parsedData.status === 'Pending' &&
                                  !data.can_escalate ? (
                                    <div className="transfer-claim-list__cancel-button-container">
                                        <button
                                            type="button"
                                            className="btn-very-small-secondary"
                                            disabled={false}
                                            onClick={() =>
                                                actionHandler('cancel')
                                            }
                                        >
                                            Cancel {exchangeType}
                                        </button>
                                    </div>
                                ) : null}
                            </>
                        ) : null}
                    </td>
                );
            })}
        </tr>
    );
};
