import React from 'react';
import { Disclosure } from '@headlessui/react';
import FiltersHeader, {
    FiltersHeaderOwnProps as HeaderProps,
} from './FiltersHeader';

interface Props extends HeaderProps {
    children?: React.ReactNode;
    headerClassName?: string;
    contentClassName?: string;
}

export const CollapsibleFilters = (props: Props) => {
    const {
        children,
        className,
        headerClassName,
        contentClassName,
        ...rest
    } = props;
    return (
        <Disclosure as="div" className={className}>
            {({ open }) => (
                <>
                    <FiltersHeader
                        buttonComponent={Disclosure.Button}
                        expanded={open}
                        className={headerClassName}
                        {...rest}
                    />
                    <Disclosure.Panel className={contentClassName}>
                        {children}
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    );
};

export default CollapsibleFilters;
