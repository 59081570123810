import { path, mergeDeepRight } from 'ramda';

import { randomString, unsecureGetRandomValues } from '@/shared/services/Auth';
import { createReducer } from '@/shared/redux/ReduxHelpers';
import { InitialState, StateType } from './State';
import { Types, Creators, RefreshUrlAction } from './Actions';

export const SsrTypes = Types;
export default Creators;

export const reducer = createReducer(InitialState, {
    [Types.REFRESH_SSR_AUTHORIZATION_URL]: refreshSsrAuthorizationUrl,
});

/* ----------------- reducers --------------------- */

const getRandomValues =
    typeof window === 'object' && path(['crypto', 'getRandomValues'], window)
        ? window.crypto.getRandomValues.bind(window.crypto)
        : unsecureGetRandomValues;
const generateNonce = randomString(getRandomValues);

function refreshSsrAuthorizationUrl(
    state: StateType,
    { redirectUrl }: RefreshUrlAction
): StateType {
    return mergeDeepRight(state, {
        auth: {
            oauthState: redirectUrl || '/',
            oauthNonce: generateNonce(8),
        },
    });
}
