import React, { Fragment, useEffect, useState } from 'react';
import { Disclosure } from '@headlessui/react';
import './TransferDeckComponent.scss';
import FormSelectBox from '../common/inputs/FormSelectBox';
import { useFriends, useFriendsSearch } from '@/shared/queries/friends';
import TextAdaptedToContainer from '../common/TextAdaptedToContainer';
import Modal from '../common/Modal';
import classNames from 'classnames';
import { Deck } from '@/shared/typings';
import { useTransferDeckMutation } from '@/shared/queries/deck_transfers';

interface IProps {
    deckToTransfer: Deck;
    onSuccess: () => void;
}

export const TransferDeckComponent = ({ deckToTransfer, onSuccess }: IProps) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isDeckTransfered, setIsDeckTransfered] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>('');
    const [isUserSelected, setIsUserSelected] = useState<boolean>(false);
    const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
    // Get queries
    const friendsQuery = useFriends();
    const searchQuery = useFriendsSearch(searchText);
    // Mutations
    const {
        mutate: transferDeck,
        isLoading: isTransferring,
        error: transferDeckError,
    } = useTransferDeckMutation();

    const friendsSearchItems = searchQuery?.data
        ? searchQuery?.data?.data.map((user) => ({
              label: user.username,
              value: user.id,
          }))
        : [];

    const onUserSelect = (userId: string, username: string) => {
        setSelectedUserId(userId);
        setSearchText(username);
        setIsUserSelected(true);
    };

    const handleTransferButton = () => {
        setIsModalOpen(!isModalOpen);
    };

    const handleCancelModal = () => {
        setIsDeckTransfered(false);
        setIsModalOpen(!isModalOpen);
    };

    const handleTransfer = () => {
        if (selectedUserId) {
            transferDeck({ deckId: deckToTransfer.id, userId: selectedUserId });
            setIsDeckTransfered(true);
            onSuccess()
        }
    };

    const modalClassName = classNames('modal-transfer-claim');
    const headerImage = <div className="modal-transfer-claim__header-image" />;

    const DeckTransfered = () => (
        <div className="modal-transfer-claim__deck-deleted">
            {transferDeckError && (
                <h2 className="modal-transfer-claim__header">
                    {transferDeckError?.response?.data?.detail}
                </h2>
            )}
            {!transferDeckError && !isTransferring && (
                <>
                    <h2 className="modal-transfer-claim__header">
                        Transfer Request Submitted
                    </h2>
                    <p className="modal-transfer-claim__description">
                        This deck is in the process of being transferred to{' '}
                        {searchText}. Visit your Deck Transfers page to check on
                        the status of this transfer.
                    </p>
                </>
            )}
            <div className="modal-transfer-claim__buttons">
                <button
                    className="modal-transfer-claim__btn modal-transfer-claim__cancel-btn btn"
                    onClick={handleCancelModal}
                >
                    Dismiss
                </button>
            </div>
        </div>
    );

    const ConfirmTransfer = () => (
        <div className="modal-transfer-claim__confirm-delete">
            <h2 className="modal-transfer-claim__header">
                Initiate Deck Transfer
            </h2>
            <p className="modal-transfer-claim__description">
                You are about to initiate Master Vault Deck Ownership transfer.
                If you proceed, one Æmber will be deducted from your Master
                Vault account. If the receiving user does not accept the
                transfer or you cancel the transfer prior to acceptance, then
                one Æmber will be refunded to your Master Vault account.
            </p>
            <p className="modal-transfer-claim__description">
                Do you want to transfer {deckToTransfer.name} to the user with
                username: {searchText}?
            </p>
            <div className="modal-transfer-claim__buttons">
                <button
                    className="modal-transfer-claim__btn modal-transfer-claim__cancel-btn btn"
                    onClick={handleCancelModal}
                >
                    Cancel
                </button>
                <button
                    className="modal-transfer-claim__btn modal-transfer-claim__remove-btn btn-secondary"
                    onClick={handleTransfer}
                >
                    Confirm
                </button>
            </div>
        </div>
    );

    const TransferButton = () => (
        <div className="transfer-deck-component__btn-container">
            <button
                className="transfer-deck-component__transfer-btn btn-medium"
                onClick={handleTransferButton}
            >
                <TextAdaptedToContainer>Transfer</TextAdaptedToContainer>
            </button>
        </div>
    );

    return (
        <div className="transfer-deck-component">
            <Disclosure>
                {({ open }) => (
                    <Fragment>
                        <div
                            className={`transfer-deck-component__content ${
                                open
                                    ? 'transfer-deck-component__content-left'
                                    : ''
                            }`}
                        >
                            <Disclosure.Button className="filters-header__toggle-btn btn-title">
                                Transfer Deck
                            </Disclosure.Button>
                            {open && (
                                <>
                                    <h3 className="transfer-deck-component__transfer-title keyforge-heading-2">
                                        Select a user to transfer the Deck to:
                                    </h3>
                                    <FormSelectBox
                                        placeholder={'Search user...'}
                                        value={null}
                                        items={friendsSearchItems}
                                        onChange={(value, label) => {
                                            onUserSelect(value, label);
                                        }}
                                        query={searchText}
                                        filterable={true}
                                        loading={
                                            friendsQuery.isLoading ||
                                            isTransferring
                                        }
                                        onQueryChange={setSearchText}
                                        className="transfer-deck-component__gap-lg"
                                    />
                                </>
                            )}
                        </div>
                        {isUserSelected && open && searchText.length > 0 && (
                            <TransferButton />
                        )}
                    </Fragment>
                )}
            </Disclosure>

            <Modal
                isOpen={isModalOpen}
                onClose={handleCancelModal}
                className={modalClassName}
                headerImage={headerImage}
                loading={false}
            >
                {isDeckTransfered ? <DeckTransfered /> : <ConfirmTransfer />}
            </Modal>
        </div>
    );
};
