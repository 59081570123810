import { put, call, select } from 'redux-saga/effects';

import {
    UserDecksActions,
    LegacyUserDecksActions,
    isLegacyOwnershipAction,
} from '../../redux/user_decks';
import {
    UserDecksRequestAction,
    AddDeckRequestAction,
    RemoveDeckRequestAction,
    UpdateCasualScoresRequestAction,
} from '../../redux/user_decks/Actions';
import { AddDeckStatus } from '../../redux/user_decks/State';
import API from '../../api/Api';
import {
    SearchDecksResponse,
    AddDeckResponse,
    EmptyResponse,
    GeneralErrorResponse,
    UpdateDeckCasualScoresResponse,
} from '../../api/ApiTypings';
import {
    normalizeDeck,
    normalizeDecks,
    normalizeCasualScores,
} from '../Normalizers';
import { HousesDict } from '@/shared/typings';
import { getAllHousesDict } from '../../redux/Selectors';
import { ensureUserId } from '@/shared/sagas/user/UserSaga';

export function* getUserDecks(
    api: API,
    action: UserDecksRequestAction
): Iterator<any> {
    const legacy = isLegacyOwnershipAction(action);
    const actions = legacy ? LegacyUserDecksActions : UserDecksActions;

    const userId = yield ensureUserId();
    const response: SearchDecksResponse = yield call(
        api.getUserDecks,
        userId,
        action.searchFilters,
        action.pagination,
        legacy
    );
    if (response.status === 200) {
        const allHouses: HousesDict = yield select(getAllHousesDict);
        const normalizedDecks = normalizeDecks(response.data.data, {
            allHouses,
            cards: response.data._linked?.cards,
        });
        yield put(
            actions.userDecksSuccess({
                decks: normalizedDecks,
                totalCount: response.data.count,
            })
        );
    } else {
        yield put(
            actions.userDecksFailure({
                error: response as unknown as GeneralErrorResponse,
            })
        );
    }
}

const AddDeckErrorStatuses: { [key: number]: AddDeckStatus } = {
    403: AddDeckStatus.BannedDeck,
    409: AddDeckStatus.DeckAlreadyExists,
    429: AddDeckStatus.TooManyRequests,
};

export function* addDeck(
    api: API,
    action: AddDeckRequestAction
): Iterator<any> {
    const legacy = isLegacyOwnershipAction(action);
    const actions = legacy ? LegacyUserDecksActions : UserDecksActions;
    const response: AddDeckResponse = yield call(
        api.addDeck,
        action.code,
        legacy
    );
    const allHouses: HousesDict = yield select(getAllHousesDict);
    switch (response.status) {
        case 200:
            return yield put(
                actions.addDeckSuccess({
                    deck: normalizeDeck(response.data.data, { allHouses }),
                    addStatus: AddDeckStatus.Registered,
                })
            );
        case 201:
            return yield put(
                actions.addDeckSuccess({
                    deck: normalizeDeck(response.data.data, { allHouses }),
                    addStatus: AddDeckStatus.Discovered,
                })
            );
        case null:
            return yield put(
                actions.addDeckFailure({
                    error: response as unknown as GeneralErrorResponse,
                    addStatus: AddDeckStatus.NetworkError,
                })
            );
        default:
            return yield put(
                actions.addDeckFailure({
                    error: response as unknown as GeneralErrorResponse,
                    addStatus:
                        AddDeckErrorStatuses[response.status] ||
                        AddDeckStatus.Failure,
                })
            );
    }
}

export function* removeUserDeck(api: API, action: RemoveDeckRequestAction) {
    const legacy = isLegacyOwnershipAction(action);
    const actions = legacy ? LegacyUserDecksActions : UserDecksActions;
    const response: EmptyResponse = yield call(
        api.deleteUserDeck,
        action.deckId,
        legacy
    );

    if (response.status === 204) {
        yield put(actions.removeDeckSuccess());
    } else {
        yield put(
            actions.removeDeckFailure({
                error: response as unknown as GeneralErrorResponse,
            })
        );
    }
}

export function* updateDeckCasualScores(
    api: API,
    action: UpdateCasualScoresRequestAction
): Iterator<any> {
    const userId = yield ensureUserId();
    const legacy = isLegacyOwnershipAction(action);
    const response: UpdateDeckCasualScoresResponse = yield call(
        api.updateDeckCasualScores,
        userId,
        action.deckId,
        action.casualScores,
        legacy
    );

    if (response.status === 200) {
        const casualScores = {
            id: response.data.data.id,
            ...normalizeCasualScores(response.data.data),
        };
        yield put(UserDecksActions.updateCasualScoresSuccess(casualScores));
    } else {
        yield put(
            UserDecksActions.updateCasualScoresFailure({
                error: response as unknown as GeneralErrorResponse,
            })
        );
    }
}
