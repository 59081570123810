import ExternalApi, { externalApi } from '@/shared/api/ExternalApi';
import { all, takeLatest, takeEvery, debounce } from 'redux-saga/effects';
import { REHYDRATE } from 'redux-persist';
import Api, { API } from '../api/Api';

/* ------------- Types ------------- */
import { DecksTypes, LegacyDecksTypes } from '../redux/decks';
import { PersistedUserTypes } from '../redux/persisted_user';
import {
    UserDecksTypes,
    LegacyUserDecksActionTypes,
    LEGACY_OWNERSHIP_ACTION_PREFIX,
} from '../redux/user_decks';
import { UserProfileTypes } from '../redux/user_profile';
import { TournamentsTypes } from '../redux/tournaments';
import { GameStoresTypes } from '../redux/game_stores';
import { EventLocatorTypes } from '../redux/event_locator';
import { AlliancesTypes } from '../redux/alliances';

/* ------------- Sagas ------------- */
import {
    getDeck,
    searchDecks,
    getDeckCount,
    addDeckToFavorites,
    removeDeckFromFavorites,
    getDecksOnWatchlist,
    addDeckToWatchlist,
    removeDeckFromWatchlist,
    getRecentDecks,
    cacheDeck,
} from './decks/DecksSaga';
import { addNote, updateNote, deleteNote } from './decks/NotesSaga';
import { getHouses } from './decks/MetadataSaga';
import {
    authorizeUser,
    logout,
    setApiToken,
    fetchUserProfile,
    updateUserProfile,
    getUserTransactions,
} from './user/UserSaga';
import {
    getUserDecks,
    addDeck,
    removeUserDeck,
    updateDeckCasualScores,
} from './user_decks/UserDecksSaga';
import {
    fetchDeckLeaderboard,
    fetchPlayerLeaderboard,
} from './tournaments/LeaderboardsSaga';
import { withMetadata } from './decks/withMetadataSaga';
import {
    getGameStore,
    searchGameStores,
    fetchGameStoreLeaderboard,
} from './game_stores/GameStoresSaga';
import {
    getNearestEvents,
    getCurrentAddress,
} from './event_locator/EventLocatorSaga';
import {
    getDecksSuggestions,
    createAlliance,
    getUserAlliances,
    getAlliance,
    updateAlliance,
} from '@/shared/sagas/alliances/AlliancesSaga';
import { Saga } from '@/shared/typings';

const debounceTimeMs = 500;

/* ------------- Connect Types To Sagas ------------- */

type ActionHandlerType = [string, Saga<Api>];
type ExternalApiActionHandlerType = [string, Saga<ExternalApi>];

const ActionHandlers: ActionHandlerType[] = [
    [REHYDRATE, setApiToken],

    [DecksTypes.GET_DECK_REQUEST, withMetadata(getDeck)],
    [DecksTypes.GET_HOUSES_REQUEST, getHouses],
    [DecksTypes.DECK_COUNT_REQUEST, getDeckCount],
    [DecksTypes.GET_WATCHLIST_DECKS_REQUEST, withMetadata(getDecksOnWatchlist)],

    [DecksTypes.ADD_NOTE_REQUEST, addNote],
    [DecksTypes.UPDATE_NOTE_REQUEST, updateNote],
    [DecksTypes.DELETE_NOTE_REQUEST, deleteNote],

    [GameStoresTypes.GET_GAME_STORE_REQUEST, getGameStore],
    [GameStoresTypes.SEARCH_GAME_STORES_REQUEST, searchGameStores],
    [
        GameStoresTypes.GET_GAME_STORE_LEADERBOARD_REQUEST,
        fetchGameStoreLeaderboard,
    ],

    [PersistedUserTypes.AUTHORIZE_USER_REQUEST, authorizeUser],
    [PersistedUserTypes.LOGOUT, logout],

    [
        TournamentsTypes.GET_DECK_LEADERBOARD_REQUEST,
        withMetadata(fetchDeckLeaderboard),
    ],
    [TournamentsTypes.GET_PLAYER_LEADERBOARD_REQUEST, fetchPlayerLeaderboard],

    [UserProfileTypes.UPDATE_PROFILE_REQUEST, updateUserProfile],
    [UserProfileTypes.GET_TRANSACTIONS_REQUEST, getUserTransactions],

    [UserDecksTypes.ADD_DECK_REQUEST, addDeck],
    [UserDecksTypes.USER_DECKS_REQUEST, withMetadata(getUserDecks)],
    [UserDecksTypes.REMOVE_DECK_REQUEST, removeUserDeck],
    [UserDecksTypes.UPDATE_CASUAL_SCORES_REQUEST, updateDeckCasualScores],

    [AlliancesTypes.CREATE_ALLIANCE_REQUEST, createAlliance],
    [AlliancesTypes.UPDATE_ALLIANCE_REQUEST, updateAlliance],
    [AlliancesTypes.USER_ALLIANCES_REQUEST, getUserAlliances],
    [AlliancesTypes.GET_ALLIANCE_REQUEST, getAlliance],
];

const DebouncedActionHandlers: ActionHandlerType[] = [
    [UserProfileTypes.FETCH_PROFILE_REQUEST, fetchUserProfile],
    [DecksTypes.SEARCH_DECKS_REQUEST, withMetadata(searchDecks)],
    [DecksTypes.GET_RECENT_DECKS_REQUEST, withMetadata(getRecentDecks)],

    [
        AlliancesTypes.DECK_SUGGESTIONS_REQUEST,
        withMetadata(getDecksSuggestions),
    ],
];

const ExternalApiActionHandlers: ExternalApiActionHandlerType[] = [
    [EventLocatorTypes.GET_NEAREST_EVENTS_REQUEST, getNearestEvents],
    [EventLocatorTypes.GET_CURRENT_ADDRESS_REQUEST, getCurrentAddress],
];

const ParallelActionHandlers: ActionHandlerType[] = [
    [DecksTypes.ADD_DECK_TO_FAVORITES_REQUEST, addDeckToFavorites],
    [DecksTypes.REMOVE_DECK_FROM_FAVORITES_REQUEST, removeDeckFromFavorites],
    [DecksTypes.ADD_DECK_TO_WATCHLIST_REQUEST, addDeckToWatchlist],
    [DecksTypes.REMOVE_DECK_FROM_WATCHLIST_REQUEST, removeDeckFromWatchlist],
    [DecksTypes.CACHE_DECK_REQUEST, cacheDeck],
];

export default function* root() {
    // Add non-legacy saga handlers for the same legacy action types
    [
        ExternalApiActionHandlers,
        ActionHandlers,
        DebouncedActionHandlers,
        ParallelActionHandlers,
    ].forEach((handlers) => {
        handlers.forEach(([type, fn]) => {
            [
                ...Object.values(LegacyDecksTypes),
                ...Object.values(LegacyUserDecksActionTypes),
            ].forEach((legacyActionType) => {
                if (
                    legacyActionType ===
                    LEGACY_OWNERSHIP_ACTION_PREFIX + type
                ) {
                    handlers.push([legacyActionType, fn as any]);
                }
            });
        });
    });

    const externalEffects = ExternalApiActionHandlers.map(([type, handler]) =>
        takeLatest(type, handler, externalApi)
    );
    const actionEffects = ActionHandlers.map(([type, handler]) =>
        takeLatest(type, handler, API)
    );
    const debouncedEffects = DebouncedActionHandlers.map(([type, handler]) =>
        debounce(debounceTimeMs, type, handler, API)
    );
    const parallelEffects = ParallelActionHandlers.map(([type, handler]) =>
        takeEvery(type, handler, API)
    );
    const effects = [
        ...externalEffects,
        ...actionEffects,
        ...debouncedEffects,
        ...parallelEffects,
    ];
    yield all(effects);
}
