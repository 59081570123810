import './InfoTooltip.scss';

import React, { SFC } from 'react';
import Tippy, { TippyProps } from '@tippy.js/react';

interface InfoTooltipProps {
    children: TippyProps['content'];
    placement?: TippyProps['placement'];
}

const InfoTooltip: SFC<InfoTooltipProps> = ({ children, placement }) => (
    <Tippy
        animateFill={false}
        duration={0}
        distance={12}
        content={children}
        interactive={true}
        placement={placement}
        theme="keyforge"
        trigger="click"
    >
        <button className="info-tooltip__btn" />
    </Tippy>
);

InfoTooltip.defaultProps = {
    placement: 'bottom',
};

export default InfoTooltip;
