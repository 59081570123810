import React, { PureComponent } from 'react';

import DeckFilters from './DecksFilters';
import { DecksSearchFilters } from '@/shared/api/ApiTypings';
import withMetadata, { TWithMetadata } from '@/shared/components/WithMetadata';
import { SelectItem } from '../inputs/FormSelectBox';

export interface Props extends TWithMetadata {
    value: DecksSearchFilters;
    onChange: (filters: DecksSearchFilters) => void;
    disableToggleBtn?: boolean;
    legacy?: boolean;
    onSearch: () => void;
    onReset: () => void;
    searchFriends?: boolean;
    allFriends?: SelectItem[];
    onFriendPick?: (friendId: string) => void;
    searchFriendsText?: string;
    setSearchFriendsText?: (query: string) => void;
}

export class DecksFiltersContainer extends PureComponent<Props> {
    public render() {
        const {
            metadata,
            searchFriends,
            allFriends,
            onFriendPick,
            searchFriendsText,
            setSearchFriendsText,
            legacy,
        } = this.props;
        return (
            metadata.isMetadataSuccess() && (
                <DeckFilters
                    houses={metadata.houses.dict}
                    expansions={metadata.expansions}
                    onSearch={this.props.onSearch}
                    filters={this.props.value}
                    onReset={this.props.onReset}
                    onChange={this.props.onChange}
                    disableToggleBtn={this.props.disableToggleBtn}
                    legacy={legacy}
                    searchFriends={searchFriends}
                    allFriends={allFriends}
                    onFriendPick={onFriendPick}
                    searchFriendsText={searchFriendsText}
                    setSearchFriendsText={setSearchFriendsText}
                />
            )
        );
    }
}

export default withMetadata(DecksFiltersContainer);
