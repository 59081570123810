import { InitialAsyncRequestState } from '@/shared/redux/Async';
import { StoreEvent } from '@/shared/typings';

export const InitialState = {
    getNearestEvents: {
        ...InitialAsyncRequestState,
        list: [] as StoreEvent[],
        total: 0,
    },
    getCurrentAddress: {
        ...InitialAsyncRequestState,
        address: '',
        latitude: -1,
        longitude: -1,
    },
};

export type StateType = typeof InitialState;
