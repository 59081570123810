import './FormSelectBox.scss';

import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { Combobox } from '@headlessui/react';

export interface SelectItem {
    label: string;
    value: string;
}

export interface SelectBoxProps {
    id?: string;
    items: SelectItem[];
    value?: string;
    defaultValue?: string;
    placeholder?: string;
    onChange: (value: string, label: string) => void;
    onQueryChange?: (query: string) => void;
    query?: string;
    className?: string;
    selectClassName?: string;
    dropdownClassName?: string;
    disabled?: boolean;
    filterable?: boolean;
    nullable?: boolean;
    multiple?: boolean;
    loading?: boolean;
    displayValue?: (item: string) => string;
    renderItem?: (item: SelectItem) => React.ReactNode;
}

type TSelectBox = ((props: SelectBoxProps) => JSX.Element) & {
    Item: typeof SelectBoxItem;
};

export const FormSelectBox = function SelectBox(props: SelectBoxProps) {
    const {
        items,
        disabled,
        className,
        selectClassName,
        dropdownClassName,
        placeholder,
        value,
        defaultValue,
        onChange,
        nullable,
        multiple,
        filterable,
        loading,
        query,
        onQueryChange,
        displayValue = (v: string) => {
            const selectedOption = items.find((item) => item.value === v);
            return selectedOption?.label;
        },
        renderItem,
        id,
    } = props;

    const handleOnChange = (selectedValue: string | null) => {
        const selectedOption = items.find((item) => item.value === selectedValue);
        if (selectedOption) {
            const { value, label } = selectedOption;
            onChange(value, label);
        } else {
            onChange("", "");
        }
    };

    return (
        <Combobox
            as="div"
            className={classNames('form-select-box', className, {
                'form-select-box--disabled': disabled,
            })}
            disabled={disabled}
            multiple={multiple as any}
            nullable={nullable as any}
            value={value}
            defaultValue={defaultValue}
            onChange={handleOnChange}
        >
            <div className="form-select-box__control">
                {/* hack to open dropdown when input is clicked */}
                <Combobox.Button
                    as="div"
                    className="form-select-box__input-wrap"
                >
                    <Combobox.Input
                        onChange={(event) => {
                            onQueryChange?.(event.target.value);
                        }}
                        className={classNames(
                            'form-select-box__input',
                            selectClassName
                        )}
                        readOnly={!filterable}
                        value={query}
                        displayValue={displayValue}
                        placeholder={placeholder}
                        id={id}
                    />
                </Combobox.Button>
                <Combobox.Button
                    className={classNames(
                        'form-select-box__button',
                        loading && 'form-select-box__button--loading'
                    )}
                >
                    {loading && (
                        <span className="form-select-box__button-spinner spinner" />
                    )}
                </Combobox.Button>
                <Combobox.Options
                    className={classNames(
                        'form-select-box__dropdown',
                        dropdownClassName
                    )}
                >
                    {items.length ? (
                        items.map((item) =>
                            renderItem ? (
                                renderItem(item)
                            ) : (
                                <SelectBoxItem
                                    key={item.value}
                                    value={item.value}
                                >
                                    {item.label}
                                </SelectBoxItem>
                            )
                        )
                    ) : (
                        <div
                            className={classNames(
                                'form-select-box__item',
                                'form-select-box__no-results'
                            )}
                        >
                            No results found
                        </div>
                    )}
                </Combobox.Options>
            </div>
        </Combobox>
    );
} as TSelectBox;

export const SelectBoxItem = forwardRef(function SelectBoxItem(props, ref) {
    return (
        <Combobox.Option
            {...props}
            className={classNames('form-select-box__item', props.className)}
            ref={ref}
        />
    );
}) as typeof Combobox.Option;

FormSelectBox.Item = SelectBoxItem;

export default FormSelectBox;
