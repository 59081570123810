import './ShareTooltip.scss';

import React from 'react';
import Tippy, { TippyProps } from '@tippy.js/react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import ExternalLink from '@/components/common/ExternalLink';
import FacebookIcon from '@/images/common/facebook-icon-red.svg';
import TwitterIcon from '@/images/common/twitter-icon-red.svg';
import { getBaseUrl } from '@/utils/url';

type BaseProps = WithNamespaces & RouteComponentProps;
export interface Props extends BaseProps {
    children: TippyProps['children'];
}

export function ShareTooltip({ children, location, t }: Props) {
    const facebook = t('footer.social.facebook', {
        defaultValue: 'Facebook',
    });
    const twitter = t('footer.social.twitter', {
        defaultValue: 'Twitter',
    });

    const url = getBaseUrl() + location.pathname;

    const socialIcons = (
        <div className="share-tooltip__social-icons">
            <ExternalLink
                to={`https://facebook.com/sharer.php?u=${url}`}
                className="share-tooltip__social-link share-tooltip__social-link--facebook"
            >
                <img
                    className="share-tooltip__social-link-icon"
                    src={FacebookIcon}
                    alt={facebook}
                />
                <div className="share-tooltip__social-link-label">
                    {facebook}
                </div>
            </ExternalLink>
            <ExternalLink
                to={`https://twitter.com/intent/tweet?url=${url}`}
                className="share-tooltip__social-link share-tooltip__social-link--twitter"
            >
                <img
                    className="share-tooltip__social-link-icon"
                    src={TwitterIcon}
                    alt={twitter}
                />
                <div className="share-tooltip__social-link-label">
                    {twitter}
                </div>
            </ExternalLink>
        </div>
    );

    return (
        <Tippy
            animateFill={false}
            duration={0}
            distance={0}
            content={socialIcons}
            interactive={true}
            placement="bottom"
            theme="keyforge"
            trigger="click"
        >
            {children}
        </Tippy>
    );
}

export default withRouter(withNamespaces()(ShareTooltip));
