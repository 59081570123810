import './NoteList.scss';

import React, { Component } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';

import NoteContentModal from './NoteContentModal';
import NoteDeleteModal from './NoteDeleteModal';
import ClientSideOnly from '@/components/common/ClientSideOnly';
import { Deck, Note } from '@/shared/typings';

export interface Props extends WithNamespaces {
    notes: Note[];
    deck: Deck;
    isLegacy: boolean;
}

interface State {
    visibleNoteCount: number;
    noteModalOpenMode: 'content' | 'edit';
    dialogNote: Note;
    isContentModalOpen: boolean;
    isDeleteModalOpen: boolean;
}

export class NoteList extends Component<Props> {
    public state: State = {
        visibleNoteCount: 2,
        noteModalOpenMode: null,
        dialogNote: null,
        isContentModalOpen: false,
        isDeleteModalOpen: false,
    };

    public render() {
        const notes = this.props.notes
            .slice(0, this.state.visibleNoteCount)
            .map(this.renderNote);

        return (
            <div className="note-list">
                <ul className="note-list__list">{notes}</ul>
                {this.props.notes.length > this.state.visibleNoteCount && (
                    <button
                        className="note-list__view-more-btn"
                        onClick={this.loadMoreNotes}
                    >
                        {this.props.t('note.btn.view-more-notes', {
                            defaultValue: 'View more notes',
                        })}
                    </button>
                )}
                <NoteContentModal
                    isOpen={this.state.isContentModalOpen}
                    openMode={this.state.noteModalOpenMode}
                    note={this.state.dialogNote}
                    deck={this.props.deck}
                    onClose={() => this.toggleNoteContentModal(false)}
                    isLegacy={this.props.isLegacy}
                />
                <NoteDeleteModal
                    isLegacy={this.props.isLegacy}
                    deck={this.props.deck}
                    isOpen={this.state.isDeleteModalOpen}
                    note={this.state.dialogNote}
                    onClose={() => this.toggleNoteDeleteModal(false)}
                />
            </div>
        );
    }

    private renderNote = (note: Note) => {
        const { t } = this.props;
        return (
            <li className="note-list__note" key={note.id}>
                <div className="note-list__note-header">
                    <ClientSideOnly
                        fallback={<div className="note-list__note-date" />}
                    >
                        <div
                            className="note-list__note-date"
                            title={t('note.date.hint.created-on', {
                                defaultValue: 'Created on {{date, fullDate}}',
                                date: new Date(note.creationTimestampMs),
                            })}
                        >
                            {t('note.date.created-on', {
                                defaultValue: '{{date, byLanguage}}',
                                date: new Date(note.creationTimestampMs),
                            })}
                        </div>
                    </ClientSideOnly>
                    <button
                        className="note-list__note-action-btn note-list__note-edit"
                        onClick={() =>
                            this.toggleNoteContentModal(true, note, 'edit')
                        }
                    >
                        {t('note.btn.edit', {
                            defaultValue: 'Edit',
                        })}
                    </button>
                    <button
                        className="note-list__note-action-btn note-list__note-delete"
                        onClick={() => this.toggleNoteDeleteModal(true, note)}
                    >
                        {t('note.btn.delete', {
                            defaultValue: 'Delete',
                        })}
                    </button>
                </div>
                <div className="note-list__note-text" title={note.text}>
                    {note.text}
                </div>
                <button
                    className="note-list__note-read-more-btn"
                    onClick={() =>
                        this.toggleNoteContentModal(true, note, 'content')
                    }
                >
                    {t('note.btn.read-more', {
                        defaultValue: 'Read more',
                    })}
                </button>
            </li>
        );
    };

    private loadMoreNotes = () =>
        this.setState({ visibleNoteCount: this.state.visibleNoteCount + 2 });

    private toggleNoteContentModal = (
        show: boolean,
        note: Note = null,
        openMode: State['noteModalOpenMode'] = null
    ) =>
        this.setState({
            isContentModalOpen: show,
            dialogNote: note,
            noteModalOpenMode: openMode,
        });

    private toggleNoteDeleteModal = (show: boolean, note: Note = null) =>
        this.setState({ isDeleteModalOpen: show, dialogNote: note });
}

export default withNamespaces()(NoteList);
