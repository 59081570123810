import React, { ComponentType, PureComponent } from 'react';
import { connect } from 'react-redux';

import { RootState } from '@/shared/redux/RootState';
import { Expansion } from '@/shared/typings';
import { AsyncStatus } from '@/shared/redux/Async';
import { DecksActions } from '@/shared/redux/decks';
import { isSSRBuild } from '@/shared/services/Utils';

export const Expansions = [
    { id: 341, name: 'Call of the Archons' },
    { id: 435, name: 'Age of Ascension' },
    { id: 452, name: 'Worlds Collide' },
    { id: 479, name: 'Mass Mutation' },
    { id: 496, name: 'Dark Tidings' },
    { id: 600, name: 'Winds of Exchange' },
    { id: 601, name: 'Unchained 2023' },
    { id: 609, name: 'Vault Masters 2023' },
    { id: 700, name: 'Grim Reminders' },
    { id: 722, name: 'Menagerie 2024' },
    { id: 737, name: 'Vault Masters 2024' },
    { id: 800, name: 'Æmber Skies' },
    { id: 855, name: 'Tokens of Change' },
    { id: 874, name: 'More Mutation' },
    { id: 886, name: 'Prophetic Visions' },
    { id: 892, name: 'Martian Civil War' },
    { id: 907, name: 'Discovery' },
];

export interface TWithMetadata {
    metadata: {
        houses: RootState['decks']['houses'];
        expansions: Expansion[];
        isMetadataReady: () => boolean;
        isMetadataSuccess: () => boolean;
    };
}

export interface WithMetadataBaseProps {
    component: ComponentType<any>;
    componentProps: object;
    fetchAllHouses: typeof DecksActions['getHousesRequest'];
    housesReq: RootState['decks']['houses'];
}

export class WithMetadataBase extends PureComponent<WithMetadataBaseProps> {
    // we use WillMount hook, as it is the only hook that runs during SSR
    public UNSAFE_componentWillMount() {
        const { __status: status } = this.props.housesReq;
        if (isSSRBuild() && status === AsyncStatus.Init) {
            this.props.fetchAllHouses();
        }
    }

    // Monitor metadata requests. Fetching metadata generally should be
    // handled by sagas, so this should be only when request failed or
    // wasn't fired at all for some reason.
    // Note: request should not be called on mount, because cached status
    // is already outdated due to sagas
    public componentDidUpdate() {
        const { __status: status } = this.props.housesReq;
        if (status === AsyncStatus.Init || status === AsyncStatus.Error) {
            this.props.fetchAllHouses();
        }
    }

    public render() {
        const { component, componentProps } = this.props;
        const props = { metadata: this.getMetadata(), ...componentProps };
        return React.createElement(component, props);
    }

    private getMetadata = (): TWithMetadata['metadata'] => ({
        houses: this.props.housesReq,
        expansions: Expansions,
        isMetadataReady: this.isMetadataReady,
        isMetadataSuccess: this.isMetadataSuccess,
    });

    private isMetadataReady = () => {
        return (
            this.isMetadataSuccess() ||
            this.props.housesReq.__status === AsyncStatus.Error
        );
    };

    private isMetadataSuccess = () => {
        return this.props.housesReq.__status === AsyncStatus.Success;
    };
}

const mapDispatchToProps = {
    fetchAllHouses: DecksActions.getHousesRequest,
};

const mapStateToProps = (state: RootState) => ({
    housesReq: state.decks.houses,
});

export const WithMetadata = connect(
    mapStateToProps,
    mapDispatchToProps
)(WithMetadataBase);

export default function withMetadata<TProps>(CmpClass: ComponentType<TProps>) {
    type Props = Subtract<TProps, TWithMetadata>;
    // tslint:disable-next-line max-classes-per-file
    return class extends PureComponent<Props> {
        public static displayName = `WithMetadata(${
            CmpClass.displayName || CmpClass.name
        })`;

        public render() {
            return (
                <WithMetadata
                    component={CmpClass}
                    componentProps={this.props}
                />
            );
        }
    };
}
