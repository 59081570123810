import React from 'react';
import withMetadata, { TWithMetadata } from '@/shared/components/WithMetadata';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import classNames from 'classnames';
import { Alliance } from '@/shared/typings/Alliance';
import { Link } from 'react-router-dom';
import {
    getTranslatedExpansionName,
    getTranslatedHouseName,
} from '@/shared/services/lang/translationHelpers';
import { Expansion, HousesDict } from '@/shared/typings';
import './AlliancesTable.scss';
import SortInterface from '@/components/common/tables/SortInterface';

export interface OwnProps {
    alliances: Alliance[];
    sorting: string;
    onSortChange: () => void;
}

export interface Props extends OwnProps, TWithMetadata, WithNamespaces {}

interface Column<K extends keyof Alliance = keyof Alliance> {
    label: string;
    sorting: string | null;
    key: K;
    valueForKey: (params: ColRenderParams<K>) => React.ReactNode;
}

interface ColRenderParams<K extends keyof Alliance = keyof Alliance> {
    alliance: Alliance;
    key: K;
    housesDict: HousesDict;
    expansions: Expansion[];
}

function getColumns(t: WithNamespaces['t']): Column[] {
    function renderHouse<K extends 'house_1_id' | 'house_2_id' | 'house_3_id'>({
        alliance,
        key,
        housesDict,
    }: ColRenderParams<K>) {
        const house = housesDict[alliance[key]];
        const houseName = getTranslatedHouseName(house, t);
        return (
            <>
                <img
                    src={house.image}
                    alt={houseName}
                    className="alliances-table__house-icon"
                />
            </>
        );
    }

    return [
        {
            key: 'name',
            label: 'Name',
            sorting: 'name',
            valueForKey: ({ alliance }) => (
                <Link to={`/alliances/${alliance.id}`}>{alliance.name}</Link>
            ),
        },
        {
            key: 'created_at',
            label: 'Date',
            sorting: 'created_at',
            valueForKey: ({ alliance }) => {
                const date = new Date(alliance.created_at);
                const options = { year: 'numeric', month: 'long', day: 'numeric' };
                const renderDate = date.toLocaleDateString('en-US', options);
                return (<p>{renderDate}</p>)
            },
        },
        {
            key: 'set_id',
            label: 'Set',
            sorting: null,
            valueForKey: ({ alliance, expansions }) =>
                getTranslatedExpansionName(alliance.set_id, expansions, t),
        },
        {
            key: 'house_1_id',
            label: 'Pod 1',
            sorting: null,
            valueForKey: renderHouse,
        },
        {
            key: 'house_2_id',
            label: 'Pod 2',
            sorting: null,
            valueForKey: renderHouse,
        },
        {
            key: 'house_3_id',
            label: 'Pod 3',
            sorting: null,
            valueForKey: renderHouse,
        },
    ];
}

export const AlliancesTable = (props: Props) => {
    const { metadata, alliances, t, sorting, onSortChange } = props;
    const columns = getColumns(t);
    const getCellClass = (...classes: Parameters<typeof classNames>) =>
        classNames('kf-table__cell', 'alliances-table__cell', ...classes);

    return (
        <table className={classNames('kf-table alliances-table', '')}>
            <thead className="kf-table__head">
                <tr className="kf-table__row">
                    {columns.map((col, i) => (
                        <th
                            key={i}
                            className={getCellClass(
                                'alliances-table__th',
                                `alliances-table__cell--${col.key}`
                            )}
                        >
                            <p>{col.label}</p> 
                            {col.label === 'Date' ? (
                                <SortInterface 
                                    column={col.key}
                                    sorting={sorting}
                                    onSortChange={onSortChange}
                                    className={`transaction-list__sort transaction-list__sort--${col.key}`}
                                />
                            ): null}
                        </th>
                    ))}
                </tr>
            </thead>
            {metadata.isMetadataSuccess() && (
                <tbody className="kf-table__body">
                    {alliances.map((alliance, i) => (
                        <tr
                            key={i}
                            className="kf-table__row alliances-table__body-row"
                        >
                            {columns.map((col, j) => (
                                <td
                                    key={j}
                                    className={getCellClass(
                                        `alliances-table__cell--${col.key}`
                                    )}
                                >
                                    {col.valueForKey({
                                        alliance,
                                        key: col.key,
                                        housesDict: metadata.houses.dict,
                                        expansions: metadata.expansions,
                                    })}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            )}
        </table>
    );
};

export default withMetadata(withNamespaces()(AlliancesTable));
