import './UserProfileHeader.scss';
import React from 'react';
import InfoTooltip from '@/components/common/InfoTooltip';
import ExternalLink from '@/components/common/ExternalLink';
import { KEYBRINGER_URL } from '@/shared/services/Auth';
import avatarStub from '@/images/header/avatar.jpg';
import { Link } from 'react-router-dom';
import TextAdaptedToContainer from '@/components/common/TextAdaptedToContainer';
import EventKeyImage from '@/images/profile/tablet/key-6of6.png';
import AemberIcon from '@/images/profile/tablet/aember-shard-3of3.png';
import { WithNamespaces, withNamespaces } from 'react-i18next';

interface UserProfileHeaderProps extends WithNamespaces {
    profile: any;
    onBuyAember: () => void;
    onUnlockEventKey: () => void;
}

const UserProfileHeader = ({
    t,
    profile,
    onBuyAember,
    onUnlockEventKey,
}: UserProfileHeaderProps) => {
    return (
        <section className="user-profile-header">
            <div className="user-profile-header__account-wrapper">
                <div className="user-profile-header__account">
                    <div className="user-profile-header__account-container">
                        <img
                            className="user-profile-header__account-avatar"
                            src={avatarStub}
                        />
                        <div className="user-profile-header__account-details">
                            <div className="user-profile-header__account-details-header">
                                <h1
                                    className="user-profile-header__details-username"
                                    title={profile.username}
                                >
                                    {profile.username}
                                </h1>
                            </div>
                            <div className="user-profile-header__account-qr-code-label">
                                {t('profile.hint.profile-id', {
                                    defaultValue: 'Profile ID',
                                })}
                                <InfoTooltip>
                                    <span className="user-profile-header__account-qr-code-tooltip">
                                        {t('profile.tooltip.qr-code', {
                                            defaultValue:
                                                'This unique QR code identifies your Master Vault profile at events',
                                        })}
                                    </span>
                                </InfoTooltip>
                            </div>
                        </div>
                        <img
                            className="user-profile-header__account-qr-code-img"
                            src={`data:image/png;base64,${profile.qrCode}`}
                            alt={t('profile.hint.qr-code', {
                                defaultValue: 'Profile identification QR code',
                            })}
                        />
                    </div>
                    <ExternalLink
                        to={KEYBRINGER_URL + '/account/'}
                        className="external-link user-profile-header__account-keybringer-link"
                    >
                        {t('profile.link.go-to-keybringer', {
                            defaultValue: 'Go to my Keybringer Account',
                        })}
                    </ExternalLink>
                </div>
            </div>
            <div className="user-profile-header__aember">
                <div className="user-profile-header__aember-label">Æmber</div>
                <div className="user-profile-header__aember-icon-container">
                    <img
                        className="user-profile-header__aember-icon-img"
                        src={AemberIcon}
                    />
                    <div className="user-profile-header__aember-icon-value">
                        {profile.shards}
                    </div>
                </div>
                <Link
                    onClick={() => onBuyAember()}
                    to="/profile"
                    className="btn btn-okay user-profile-header__button"
                >
                    <TextAdaptedToContainer>
                        {t('profile.btn.buy-aember', {
                            defaultValue: 'Buy Æmber',
                        })}
                    </TextAdaptedToContainer>
                </Link>
                <ExternalLink
                    to={'https://keyforging.com/digital-aember/'}
                    className="external-link user-profile-header__aember-info-link"
                >
                    {t('profile.link.why-buy-aember', {
                        defaultValue: 'Why buy Æmber?',
                    })}
                </ExternalLink>
            </div>
            <div className="user-profile-header__event-key">
                <div className="user-profile-header__event-key-label">
                    Event Keys
                </div>
                <div className="user-profile-header__event-key-icon-container">
                    <img
                        className="user-profile-header__event-key-icon-img"
                        src={EventKeyImage}
                    />
                    <div className="user-profile-header__event-key-icon-value">
                        {profile.keyTokens}
                    </div>
                </div>
                <Link
                    onClick={() => onUnlockEventKey()}
                    to="/profile"
                    className="btn btn-okay user-profile-header__button"
                >
                    <TextAdaptedToContainer>
                        {t('profile.btn.event-key', {
                            defaultValue: 'Unlock Event Key',
                        })}
                    </TextAdaptedToContainer>
                </Link>
                <div className="user-profile-header__event-key-cost-container">
                    <div className="user-profile-header__event-key-cost-value">
                        Cost: {profile.keyTokenCost}
                    </div>
                    <img
                        className="user-profile-header__event-key-cost-img"
                        src={AemberIcon}
                    />
                </div>
            </div>
        </section>
    );
};

export default withNamespaces()(UserProfileHeader);
