import React, { Component, SyntheticEvent } from 'react';
import { evolve, assoc, assocPath } from 'ramda';
import { WithNamespaces } from 'react-i18next';

import { RootState } from '@/shared/redux/RootState';
import { UserDecksActions } from '@/shared/redux/user_decks';
import { DecksSearchFilters } from '@/shared/api/ApiTypings';
import TextInput from '@/components/common/inputs/TextInput';
import { Props as DeckListProps } from '@/components/common/deck_lists/DeckList';

const DecksPerPage = 10;

export const DefaultFiltersState: DecksSearchFilters = {
    searchText: '',
    sorting: '-date',
    houses: [],
    expansionId: '',
    favoriteOnly: false,
};

export interface DecksPageBaseProps extends WithNamespaces {
    decks: RootState['userDecks']['userDecks'];
    displayAuthorizedInterface: boolean;
    fetchDecks: typeof UserDecksActions.userDecksRequest;
}

export interface DecksPageBaseState {
    decksCurrentPage: number;
    filters: DecksSearchFilters;
    appliedFilters: DecksSearchFilters;
}

export class DecksPageBase<
    TProps extends DecksPageBaseProps = DecksPageBaseProps,
    TState extends DecksPageBaseState = DecksPageBaseState
> extends Component<TProps, TState> {
    // Fetch decks only on client side, web crawlers don't need this data
    public componentDidMount() {
        this.fetchDecksForFilters();
    }

    protected renderSearchInput(
        searchPlaceholder: string,
        disableSearch?: boolean
    ) {
        const className = this.className();
        return (
            <div className={`${className}__input ${className}__search`}>
                <h2
                    className={`${className}__input-label ${className}__input-label-heading keyforge-heading-2`}
                >
                    Search
                </h2>
                <div
                    className={`${className}__input-group ${className}__search-input`}
                >
                    <TextInput
                        className={`${className}__input-group-field`}
                        placeholder={searchPlaceholder}
                        ariaLabel="Search text input"
                        value={this.state.filters.searchText}
                        onChange={this.handleSearchTextChange}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                this.applyNewFilters();
                            }
                        }}
                        disabled={disableSearch}
                    />
                    <button
                        className={`${className}__input-group-btn ${className}__btn-search btn-small`}
                        onClick={this.applyNewFilters}
                        disabled={disableSearch}
                    >
                        <span className="btn-content">Search</span>
                    </button>
                </div>
            </div>
        );
    }

    protected decksPageCount = () =>
        Math.ceil(this.props.decks.totalCount / DecksPerPage);

    protected paginatedListHeader = () => {
        const className = this.className();
        return (
            <div className={`${className}__decks-list-header`}>
                <div className={`${className}__decks-list-total`}>
                    {`Search Results (${this.props.decks.totalCount})`}
                </div>
                {this.headerExtension()}
            </div>
        );
    };

    protected headerExtension(): React.ReactNode {
        return null;
    }

    protected applyNewFilters = () =>
        this.setState(
            { decksCurrentPage: 0, appliedFilters: this.state.filters },
            this.fetchDecksForFilters
        );

    protected fetchDecksForFilters = () => {
        this.props.fetchDecks(this.state.appliedFilters, {
            page: this.state.decksCurrentPage,
            pageSize: DecksPerPage,
        });
    };

    protected onSortChange: DeckListProps['onSortChange'] = (sorting) => {
        const newSorting =
            sorting === this.state.filters.sorting
                ? DefaultFiltersState.sorting
                : sorting;
        this.setState(
            evolve({
                filters: assoc('sorting', newSorting),
                appliedFilters: assoc('sorting', newSorting),
            }),
            this.fetchDecksForFilters
        );
    };

    protected onPageChange = (page: number) =>
        this.setState({ decksCurrentPage: page }, this.fetchDecksForFilters);

    protected handleFiltersChange = (filters: DecksSearchFilters) =>
        this.setState({
            filters: { ...(this.state.filters as {}), ...filters },
        });

    protected handleFiltersReset = () =>
        this.setState(
            {
                filters: DefaultFiltersState,
                appliedFilters: DefaultFiltersState,
                decksCurrentPage: 0,
            },
            this.fetchDecksForFilters
        );

    protected className() {
        return 'decks-page-base';
    }

    private handleSearchTextChange = (e: SyntheticEvent<HTMLInputElement>) => {
        this.setState(
            assocPath(['filters', 'searchText'], e.currentTarget.value)
        );
    };
}

export default DecksPageBase;
