import { Deck } from '@/shared/typings';
import React, { PureComponent } from 'react';
import CardTable from './CardTable';

interface Props {
    houses: Deck['houses'];
    cards: Deck['cards'];
    legacyCardsIds: Deck['setEraCards']['Legacy'];
    anomalyCardsIds: Deck['setEraCards']['Anomaly'];
}

export class DeckCardTable extends PureComponent<Props> {
    public render() {
        const { cards, houses, legacyCardsIds, anomalyCardsIds } = this.props;
        const houseCards = Object.keys(cards).map((houseId, index) => {
            const house = houses.find((house) => house.id === houseId);
            if (!house) {
                console.warn(
                    `CardTable: House "${houseId}" does not exist in provided houses list, but it's required by cards list.`
                );
            }

            return {
                houseId,
                cards: cards[houseId],
                house,
            };
        });

        return (
            <CardTable
                houseCards={houseCards}
                legacyCardsIds={legacyCardsIds}
                anomalyCardsIds={anomalyCardsIds}
                showHouseIcon={true}
            />
        );
    }
}
