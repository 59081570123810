import { RootState } from './RootState';
import { GeneralErrorResponse } from '../api/ApiTypings';
import ApiToken from '../services/ApiToken';

export const getUserId = (state: RootState) =>
    state.userProfile?.fetchProfile?.details?.id;

export const getAllHousesDict = (state: RootState) => state.decks.houses.dict;

export const getCachedDecks = (state: RootState) => state.decks.decksCache;

export const getRequestErrorCode = (error: GeneralErrorResponse) =>
    error && error.status;

export const getApiToken = <TState extends RootState>(state?: TState) =>
    ApiToken.get(state);

export const isAuthorized = <TState extends RootState>(state?: TState) =>
    Boolean(getApiToken(state));

export const getAddress = (state: RootState) =>
    state.eventLocator.getCurrentAddress.address;
