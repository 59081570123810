import { useQuery } from '@tanstack/react-query';
import { AxiosError } from '../api/ApiTypings';
import { API } from '../api/Api';

export const useHouses = () =>
    useQuery<any, AxiosError>({
        queryKey: ['houses'],
        queryFn: async () => {
          const housesResponse = await API.getHouses()

          return housesResponse.data;
        },
    });