import './NoteModal.scss';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces, WithNamespaces } from 'react-i18next';

import Modal from '@/components/common/Modal';
import ErrorModal from '@/components/common/ErrorModal';
import { DecksActions, LegacyDecksActions } from '@/shared/redux/decks';
import { RootState } from '@/shared/redux/RootState';
import { AsyncStatus } from '@/shared/redux/Async';
import { Deck, Note } from '@/shared/typings';

enum DeleteStatus {
    WaitingForConfirmation,
    Deleted,
    Failure,
}

export interface Props extends WithNamespaces {
    note: Note;
    isLegacy: boolean;
    isOpen: boolean;
    onClose: (wasNoteDeleted?: boolean) => void;
    deck: Deck;
    requestNoteDelete: (typeof DecksActions)['deleteNoteRequest'];
    noteDeleteStatus: AsyncStatus;
}

interface State {
    deleted: DeleteStatus;
}

const InitialState: State = {
    deleted: DeleteStatus.WaitingForConfirmation,
};

export class NoteDeleteModal extends Component<Props, State> {
    public state = InitialState;

    public componentDidUpdate(prevProps: Props, prevState: State) {
        const { noteDeleteStatus } = this.props;

        if (
            noteDeleteStatus !== prevProps.noteDeleteStatus &&
            noteDeleteStatus === AsyncStatus.Success
        ) {
            this.setState({ deleted: DeleteStatus.Deleted });
        }

        if (
            noteDeleteStatus !== prevProps.noteDeleteStatus &&
            noteDeleteStatus === AsyncStatus.Error
        ) {
            this.setState({ deleted: DeleteStatus.Failure });
        }
    }

    public render() {
        const { isOpen, noteDeleteStatus, t } = this.props;
        const { deleted } = this.state;
        return (
            <Modal
                isOpen={isOpen}
                onClose={this.onModalClose}
                className="note-modal note-modal--small"
                loading={noteDeleteStatus === AsyncStatus.Pending}
            >
                {deleted === DeleteStatus.WaitingForConfirmation &&
                    this.renderConfirmDelete()}
                {deleted === DeleteStatus.Deleted && this.renderNoteDeleted()}
                {deleted === DeleteStatus.Failure && (
                    <ErrorModal
                        isOpen={true}
                        onClose={this.onModalClose}
                        description={t('modal.note-delete.failure', {
                            defaultValue: 'Your note was not deleted.',
                        })}
                    />
                )}
            </Modal>
        );
    }

    private renderConfirmDelete = () => (
        <div className="note-modal__confirm-delete">
            <h3 className="note-modal__prompt-heading">
                {this.props.t('modal.note-delete.are-you-sure', {
                    defaultValue: 'Are you sure you want to delete your note?',
                })}
            </h3>
            <div className="note-modal__prompt-buttons">
                <button
                    className="note-modal__prompt-btn note-modal__cancel-btn btn"
                    onClick={this.onModalClose}
                >
                    <span className="btn-content">
                        {this.props.t('modal.note-delete.btn.cancel', {
                            defaultValue: 'No, Go Back',
                        })}
                    </span>
                </button>
                <button
                    className="note-modal__prompt-btn note-modal__delete-btn btn-secondary"
                    onClick={this.requestNoteDelete}
                >
                    <span className="btn-content">
                        {this.props.t('modal.note-delete.btn.ok', {
                            defaultValue: 'Yes, Delete',
                        })}
                    </span>
                </button>
            </div>
        </div>
    );

    private renderNoteDeleted = () => (
        <div className="note-modal__note-deleted">
            <h3 className="note-modal__prompt-heading">
                {this.props.t('modal.note-delete.deleted', {
                    defaultValue: 'Note has been successfully deleted',
                })}
            </h3>
            <div className="note-modal__prompt-buttons">
                <button
                    className="note-modal__prompt-btn btn btn-okay"
                    onClick={this.onModalClose}
                >
                    {this.props.t('general.btn.okay', {
                        defaultValue: 'Okay',
                    })}
                </button>
            </div>
        </div>
    );

    private requestNoteDelete = () => {
        this.props.requestNoteDelete(this.props.deck.id, this.props.note.id);
    };

    private onModalClose = () => {
        const { deleted } = this.state;
        this.setState(InitialState, () =>
            this.props.onClose(deleted === DeleteStatus.Deleted)
        );
    };
}

const mapStateToProps = (state: RootState, ownProps) => {
    const sliceKey = !ownProps.isLegacy ? 'decks' : 'decksLegacy';

    return { noteDeleteStatus: state[sliceKey].deleteNote.__status };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        requestNoteDelete: (deckId, noteId) => {
            const actions = ownProps.isLegacy
                ? LegacyDecksActions
                : DecksActions;
            dispatch(actions.deleteNoteRequest(deckId, noteId));
        },
    };
};

export default withNamespaces()(
    connect(mapStateToProps, mapDispatchToProps)(NoteDeleteModal)
);
