import {
    indexBy,
    prop,
    propEq,
    assoc,
    assocPath,
    mergeRight,
    mergeDeepRight,
} from 'ramda';

import { createReducer } from '../ReduxHelpers';
import { InitialState, StateType } from './State';
import {
    successReducerIn,
    pendingReducerIn,
    errorReducerIn,
    AsyncStatus,
} from '../Async';
import {
    DecksTypes as Types,
    SetRequestStatusAction,
    GetDeckSuccessAction,
    GetDeckFailureAction,
    CacheDeckSuccessAction,
    SearchDecksSuccessAction,
    DeckCountSuccessAction,
    GetHousesSuccessAction,
    AddNoteSuccessAction,
    UpdateNoteSuccessAction,
    DeleteNoteSuccessAction,
    AddDeckToFavoritesSuccessAction,
    RemoveDeckFromFavoritesSuccessAction,
    GetWatchlistDecksSuccessAction,
    AddDeckToWatchlistSuccessAction,
    RemoveDeckFromWatchlistSuccessAction,
} from './Actions';
import {
    UserDecksTypes,
    UpdateCasualScoresSuccessAction,
    LEGACY_OWNERSHIP_ACTION_PREFIX,
} from '../user_decks';
import { adjustWhere } from '@/shared/services/Utils';
import { House } from '@/shared/typings';

export * from './Actions';

const reducersMap = {
    [Types.SET_REQUEST_STATUS]: setRequestStatus,

    [Types.GET_DECK_REQUEST]: pendingReducerIn('getDeck'),
    [Types.GET_DECK_SUCCESS]: successReducerIn('getDeck', mapGetDeckSuccess),
    [Types.GET_DECK_FAILURE]: errorReducerIn('getDeck', mapGetDeckFailure),

    [Types.CACHE_DECK_SUCCESS]: mapCacheDeckSuccess,

    [Types.SEARCH_DECKS_REQUEST]: pendingReducerIn('searchDecks'),
    [Types.SEARCH_DECKS_SUCCESS]: successReducerIn(
        'searchDecks',
        mapSearchDecksSuccess
    ),
    [Types.SEARCH_DECKS_FAILURE]: errorReducerIn('searchDecks'),

    [Types.GET_RECENT_DECKS_REQUEST]: pendingReducerIn('recentDecks'),
    [Types.GET_RECENT_DECKS_SUCCESS]: successReducerIn(
        'recentDecks',
        mapGetRecentDecksSuccess
    ),
    [Types.GET_RECENT_DECKS_FAILURE]: errorReducerIn('recentDecks'),

    [Types.DECK_COUNT_REQUEST]: pendingReducerIn('deckCount'),
    [Types.DECK_COUNT_SUCCESS]: mapDeckCountSuccess,
    [Types.DECK_COUNT_FAILURE]: errorReducerIn('deckCount'),

    [Types.GET_HOUSES_REQUEST]: pendingReducerIn('houses'),
    [Types.GET_HOUSES_SUCCESS]: successReducerIn('houses', mapGetHousesSuccess),
    [Types.GET_HOUSES_FAILURE]: errorReducerIn('houses'),

    [Types.ADD_NOTE_REQUEST]: pendingReducerIn('addNote'),
    [Types.ADD_NOTE_SUCCESS]: mapAddNoteSuccess,
    [Types.ADD_NOTE_FAILURE]: errorReducerIn('addNote'),

    [Types.UPDATE_NOTE_REQUEST]: pendingReducerIn('updateNote'),
    [Types.UPDATE_NOTE_SUCCESS]: mapUpdateNoteSuccess,
    [Types.UPDATE_NOTE_FAILURE]: errorReducerIn('updateNote'),

    [Types.DELETE_NOTE_REQUEST]: pendingReducerIn('deleteNote'),
    [Types.DELETE_NOTE_SUCCESS]: mapDeleteNoteSuccess,
    [Types.DELETE_NOTE_FAILURE]: errorReducerIn('deleteNote'),

    [Types.ADD_DECK_TO_FAVORITES_REQUEST]: pendingReducerIn(
        'addDeckToFavorites'
    ),
    [Types.ADD_DECK_TO_FAVORITES_SUCCESS]: mapAddDeckToFavoritesSuccess,
    [Types.ADD_DECK_TO_FAVORITES_FAILURE]: errorReducerIn('addDeckToFavorites'),

    [Types.REMOVE_DECK_FROM_FAVORITES_REQUEST]: pendingReducerIn(
        'removeDeckFromFavorites'
    ),
    [Types.REMOVE_DECK_FROM_FAVORITES_SUCCESS]: mapRemoveDeckFromFavoritesSuccess,
    [Types.REMOVE_DECK_FROM_FAVORITES_FAILURE]: errorReducerIn(
        'removeDeckFromFavorites'
    ),

    [Types.ADD_DECK_TO_WATCHLIST_REQUEST]: pendingReducerIn(
        'addDeckToWatchlist'
    ),
    [Types.ADD_DECK_TO_WATCHLIST_SUCCESS]: mapAddDeckToWatchlistSuccess,
    [Types.ADD_DECK_TO_WATCHLIST_FAILURE]: errorReducerIn('addDeckToWatchlist'),

    [Types.REMOVE_DECK_FROM_WATCHLIST_REQUEST]: pendingReducerIn(
        'removeDeckFromWatchlist'
    ),
    [Types.REMOVE_DECK_FROM_WATCHLIST_SUCCESS]: mapRemoveDeckFromWatchlistSuccess,
    [Types.REMOVE_DECK_FROM_WATCHLIST_FAILURE]: errorReducerIn(
        'removeDeckFromWatchlist'
    ),

    [Types.GET_WATCHLIST_DECKS_REQUEST]: pendingReducerIn('getWatchlistDecks'),
    [Types.GET_WATCHLIST_DECKS_SUCCESS]: successReducerIn(
        'getWatchlistDecks',
        mapGetWatchlistFailure
    ),
    [Types.GET_WATCHLIST_DECKS_FAILURE]: errorReducerIn('getWatchlistDecks'),

    [UserDecksTypes.UPDATE_CASUAL_SCORES_SUCCESS]: successCurrentUserDeckCasualScores,
};

export const DecksReducer = createReducer(InitialState, reducersMap);

export const LegacyDecksReducer = createReducer(
    InitialState,
    // add the same reducer functions, but prefix action names with LEGACY_OWNERSHIP_ACTION_PREFIX
    Object.entries(reducersMap).reduce(
        (acc, [type, reducer]) =>
            assoc(LEGACY_OWNERSHIP_ACTION_PREFIX + type, reducer, acc),
        {}
    )
);

function setRequestStatus(
    state: StateType,
    action: SetRequestStatusAction
): StateType {
    return assocPath([action.requestName, '__status'], action.status, state);
}

function successCurrentUserDeckCasualScores(
    state: StateType,
    action: UpdateCasualScoresSuccessAction
) {
    if (state.getDeck.deck.id !== action.payload.id) {
        return state;
    }

    return mergeDeepRight(state, {
        getDeck: {
            deck: action.payload,
        },
    });
}

function mapGetDeckSuccess(
    state: StateType['getDeck'],
    action: GetDeckSuccessAction
): Partial<StateType['getDeck']> {
    return { deck: action.payload };
}

function mapGetDeckFailure(
    state: StateType['getDeck'],
    action: GetDeckFailureAction
): Partial<StateType['getDeck']> {
    return {
        deck: mergeDeepRight(InitialState.getDeck.deck, {
            id: action.payload.deckId,
        }),
    };
}

function mapCacheDeckSuccess(
    state: StateType,
    action: CacheDeckSuccessAction
): Partial<StateType> {
    return assocPath(['decksCache', action.payload.id], action.payload, state);
}

function mapSearchDecksSuccess(
    state: StateType['searchDecks'],
    action: SearchDecksSuccessAction
): Partial<StateType['searchDecks']> {
    return {
        list: action.payload.decks,
        totalCount: action.payload.totalCount,
    };
}

function mapGetRecentDecksSuccess(
    state: StateType['recentDecks'],
    action: SearchDecksSuccessAction
): Partial<StateType['recentDecks']> {
    return {
        list: action.payload.decks,
        totalCount: action.payload.totalCount,
    };
}

function mapDeckCountSuccess(
    state: StateType,
    action: DeckCountSuccessAction
): StateType {
    return mergeDeepRight(state, {
        deckCount: {
            count: action.payload.deckCount,

            __status: AsyncStatus.Success,
            __error: null,
        },
    });
}

function mapGetHousesSuccess(
    state: StateType['houses'],
    action: GetHousesSuccessAction
): Partial<StateType['houses']> {
    const newHouses = indexBy<House>(prop('id'), action.payload);
    return { dict: mergeRight(state.dict, newHouses) };
}

function mapAddNoteSuccess(
    state: StateType,
    action: AddNoteSuccessAction
): StateType {
    const notes = [action.payload].concat(state.getDeck.deck.notes);
    return mergeDeepRight(state, {
        getDeck: {
            deck: {
                notes,
            },
        },
        addNote: {
            __status: AsyncStatus.Success,
            __error: null,
        },
    }) as StateType; // mergeDeepRight messes up types for notes array
}

function mapUpdateNoteSuccess(
    state: StateType,
    action: UpdateNoteSuccessAction
): StateType {
    const notes = adjustWhere(
        propEq('id', action.payload.id),
        () => action.payload,
        state.getDeck.deck.notes
    );
    return mergeDeepRight(state, {
        getDeck: {
            deck: {
                notes,
            },
        },
        updateNote: {
            __status: AsyncStatus.Success,
            __error: null,
        },
    }) as StateType; // mergeDeepRight messes up types for notes array
}

function mapDeleteNoteSuccess(
    state: StateType,
    action: DeleteNoteSuccessAction
): StateType {
    const notes = state.getDeck.deck.notes.filter(
        (note) => action.payload.noteId !== note.id
    );
    return mergeDeepRight(
        mergeDeepRight(state, { getDeck: { deck: { notes: null } } }),
        {
            getDeck: {
                deck: {
                    notes,
                },
            },
            deleteNote: {
                __status: AsyncStatus.Success,
                __error: null,
            },
        }
    ) as StateType; // mergeDeepRight messes up types for notes array
}

function mapAddDeckToFavoritesSuccess(
    state: StateType,
    action: AddDeckToFavoritesSuccessAction
): StateType {
    const getDeck =
        action.payload.deckId === state.getDeck.deck.id
            ? { getDeck: { deck: { favorite: true } } }
            : null;
    return mergeDeepRight(state, {
        ...getDeck,
        addDeckToFavorites: {
            __status: AsyncStatus.Success,
            __error: null,
        },
    }) as StateType;
}

function mapRemoveDeckFromFavoritesSuccess(
    state: StateType,
    action: RemoveDeckFromFavoritesSuccessAction
): StateType {
    const getDeck =
        action.payload.deckId === state.getDeck.deck.id
            ? { getDeck: { deck: { favorite: false } } }
            : null;
    return mergeDeepRight(state, {
        ...getDeck,
        removeDeckFromFavorites: {
            __status: AsyncStatus.Success,
            __error: null,
        },
    }) as StateType;
}

function mapAddDeckToWatchlistSuccess(
    state: StateType,
    action: AddDeckToWatchlistSuccessAction
): StateType {
    const addDeckToWatchlist = adjustWhere(
        propEq('id', action.payload.deckId),
        assoc('isOnWatchlist', true)
    );
    const getDeck =
        action.payload.deckId === state.getDeck.deck.id
            ? { getDeck: { deck: { isOnWatchlist: true } } }
            : null;
    const searchDecksList = addDeckToWatchlist(state.searchDecks.list);
    const getWatchlistDecksList = addDeckToWatchlist(
        state.getWatchlistDecks.list
    );

    return mergeDeepRight(state, {
        ...getDeck,
        searchDecks: { list: searchDecksList },
        getWatchlistDecks: { list: getWatchlistDecksList },
        addDeckToWatchlist: {
            __status: AsyncStatus.Success,
            __error: null,
        },
    }) as StateType;
}

function mapRemoveDeckFromWatchlistSuccess(
    state: StateType,
    action: RemoveDeckFromWatchlistSuccessAction
): StateType {
    const removeDeckFromWatchlist = adjustWhere(
        propEq('id', action.payload.deckId),
        assoc('isOnWatchlist', false)
    );
    const getDeck =
        action.payload.deckId === state.getDeck.deck.id
            ? { getDeck: { deck: { isOnWatchlist: false } } }
            : null;
    const searchDecksList = removeDeckFromWatchlist(state.searchDecks.list);
    const getWatchlistDecksList = removeDeckFromWatchlist(
        state.getWatchlistDecks.list
    );

    return mergeDeepRight(state, {
        ...getDeck,
        searchDecks: { list: searchDecksList },
        getWatchlistDecks: { list: getWatchlistDecksList },
        removeDeckFromWatchlist: {
            __status: AsyncStatus.Success,
            __error: null,
        },
    }) as StateType;
}

function mapGetWatchlistFailure(
    state: StateType['getWatchlistDecks'],
    action: GetWatchlistDecksSuccessAction
): Partial<StateType['getWatchlistDecks']> {
    return {
        list: action.payload.decks,
        totalCount: action.payload.totalCount,
    };
}
