import './DecksFilters.scss';
import { getTranslatedExpansionName } from '@/shared/services/lang/translationHelpers';

import React, { Fragment, PureComponent, SyntheticEvent } from 'react';
import {
    assocPath,
    includes,
    without,
    assoc,
    any,
    equals,
    values,
} from 'ramda';
import { withNamespaces, WithNamespaces } from 'react-i18next';

import HouseCheckbox from '@/components/common/inputs/HouseCheckbox';
import FormSelectBox, {
    SelectItem,
} from '@/components/common/inputs/FormSelectBox';
import { Expansion, HousesDict } from '@/shared/typings';
import { DecksSearchFilters } from '@/shared/api/ApiTypings';
import { toggleValue } from '@/shared/services/Utils';
import { CollapsibleFilters } from '@/components/common/filters/CollapsibleFilters';

const MaxSelectedHouses = 3;

export interface DecksFiltersProps extends WithNamespaces {
    disableToggleBtn?: boolean;
    expansions: Expansion[];
    houses: HousesDict;
    legacy?: boolean;
    filters: DecksSearchFilters;
    onSearch: () => void;
    onReset: () => void;
    onChange: (filters: DecksSearchFilters) => void;
    searchFriends?: boolean;
    allFriends?: SelectItem[];
    onFriendPick?: (friendId: string) => void;
    searchFriendsText?: string;
    setSearchFriendsText?: (query: string) => void;
}

interface DecksFiltersState {
    defaultFilters?: DecksSearchFilters;
}

export class DecksFilters extends PureComponent<
    DecksFiltersProps,
    DecksFiltersState
> {
    public state: DecksFiltersState = {};

    public componentDidMount() {
        this.setState({ defaultFilters: this.props.filters });
    }

    public render() {
        const {
            disableToggleBtn,
            expansions,
            houses,
            searchFriends,
            allFriends,
            onFriendPick,
            searchFriendsText,
            setSearchFriendsText,
            legacy,
            t,
        } = this.props;
        const isResetBtnDisabled = equals(
            this.state.defaultFilters,
            this.props.filters
        );

        return (
            <CollapsibleFilters
                className={'decks-filters'}
                headerClassName="decks-filters__header"
                contentClassName="decks-filters__content"
                collapseDisabled={disableToggleBtn}
                title={t('decks-filters.header.filter-decks', {
                    defaultValue: 'Filter Decks',
                })}
                onReset={this.props.onReset}
                resetDisabled={isResetBtnDisabled}
            >
                <div className="decks-filters__filters">
                    <div className="decks-filters__filter decks-filters__expansions-filter">
                        <div>
                            <h2 className="decks-filters__filter-name keyforge-heading-2">
                                {t('decks-filters.header.set-expansion', {
                                    defaultValue: 'Set Expansion',
                                })}
                            </h2>
                            <FormSelectBox
                                className="decks-filters__expansion-select"
                                items={this.getExpansionsItems(expansions)}
                                value={this.props.filters.expansionId}
                                onChange={this.applyFilterValue([
                                    'expansionId',
                                ])}
                                placeholder={t(
                                    'decks-filters.input.set-expansion.placeholder',
                                    {
                                        defaultValue: 'No expansion selected',
                                    }
                                )}
                            />
                        </div>
                        {legacy && (
                            <div>
                                <h2 className="decks-filters__filter-name keyforge-heading-2">
                                    {t('decks-filters.header.set-is-owned', {
                                        defaultValue: 'Filter by ownership',
                                    })}
                                </h2>
                                <FormSelectBox
                                    className="decks-filters__expansion-select"
                                    items={[
                                        {
                                            label: 'All',
                                            value: undefined,
                                        },
                                        {
                                            label: 'Owned',
                                            value: 'owned',
                                        },
                                        {
                                            label: 'Not owned',
                                            value: 'not-owned',
                                        },
                                    ]}
                                    value={this.props.filters.legacyOwnership}
                                    onChange={this.applyFilterValue([
                                        'legacyOwnership',
                                    ])}
                                    placeholder={t(
                                        'decks-filters.input.set-ownership-filter.placeholder',
                                        {
                                            defaultValue:
                                                'Displaying all decks',
                                        }
                                    )}
                                />
                            </div>
                        )}
                        <div>
                            {searchFriends && (
                                <Fragment>
                                    <h2 className="decks-filters__filter-name keyforge-heading-2">
                                        {t(
                                            'decks-filters.header.set-expansion',
                                            {
                                                defaultValue: 'Friends',
                                            }
                                        )}
                                    </h2>
                                    <FormSelectBox
                                        placeholder={'Search friends...'}
                                        value={searchFriendsText}
                                        items={allFriends}
                                        onChange={(userId) => {
                                            onFriendPick(userId);
                                        }}
                                        filterable={true}
                                        query={searchFriendsText}
                                        onQueryChange={setSearchFriendsText}
                                        className="decks-filters__expansion-select"
                                    />
                                </Fragment>
                            )}
                        </div>
                    </div>
                    <div className="decks-filters__filter decks-filters__houses-filter">
                        <div className="decks-filters__filter-name">
                            <h2 className="decks-filters__filter-name-heading keyforge-heading-2">
                                {t('decks-filters.header.houses', {
                                    defaultValue: 'Houses',
                                })}
                            </h2>
                            <div className="decks-filters__filter-heading-note">
                                {t('decks-filters.header.houses.hint', {
                                    defaultValue:
                                        '(Please select up to 3 Houses)',
                                })}
                            </div>
                        </div>
                        {this.renderHouseList(houses)}
                    </div>
                </div>
                <button
                    className="decks-filters__search-btn btn"
                    onClick={this.props.onSearch}
                >
                    <span className="btn-content">
                        {t('search-decks.btn.search', {
                            defaultValue: 'Search',
                        })}
                    </span>
                </button>
            </CollapsibleFilters>
        );
    }

    private applyFilterValue = (fieldPath: string[]) => (value: string) =>
        this.props.onChange(assocPath(fieldPath, value, this.props.filters));

    private handleHouseChange = (ev: SyntheticEvent<HTMLInputElement>) => {
        const selectedHousesIds = this.props.filters.houses;
        const clickedHouseId = ev.currentTarget.value;
        const resultHouses =
            selectedHousesIds.length === MaxSelectedHouses
                ? includes(clickedHouseId, selectedHousesIds)
                    ? without([clickedHouseId], selectedHousesIds)
                    : selectedHousesIds
                : toggleValue(clickedHouseId, selectedHousesIds);

        this.props.onChange(assoc('houses', resultHouses, this.props.filters));
    };

    private renderHouseList(houses: HousesDict) {
        return (
            <ul className="decks-filters__houses-filter-list">
                {values(houses)
                    .filter((house) => house.inFilters)
                    .map((house, index) => (
                        <li key={index} className="decks-filters__house">
                            <HouseCheckbox
                                onChange={this.handleHouseChange}
                                checked={any(
                                    equals(house.id),
                                    this.props.filters.houses
                                )}
                                house={house}
                            />
                        </li>
                    ))}
            </ul>
        );
    }

    private getExpansionsItems(expansions: Expansion[]): SelectItem[] {
        return expansions.map(({ id, name }) => ({
            value: String(id),
            label: getTranslatedExpansionName(id, expansions, this.props.t),
        }));
    }
}

export default withNamespaces()(DecksFilters);
