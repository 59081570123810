import './HouseCheckbox.scss';

import React, { SyntheticEvent, forwardRef } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import classNames from 'classnames';

import { House } from '@/shared/typings';
import { getTranslatedHouseName } from '@/shared/services/lang/translationHelpers';

interface HouseCheckboxProps extends WithNamespaces {
    onChange: (e: SyntheticEvent<HTMLInputElement>) => void;
    checked: boolean;
    house: House;
}

export const HouseCheckbox = forwardRef(function HouseCheckbox(
    { onChange, checked, house, t }: HouseCheckboxProps,
    ref: React.Ref<HTMLInputElement>
) {
    const fullClassName = classNames('house-checkbox', {
        'house-checkbox--checked': checked,
    });
    const houseName = getTranslatedHouseName(house, t);

    return (
        <label className={fullClassName}>
            <input
                checked={checked}
                onChange={onChange}
                value={house.id}
                className="house-checkbox__checkbox"
                type="checkbox"
                ref={ref}
            />
            <img
                className="house-checkbox__image"
                src={house.image}
                alt={houseName}
            />
            <span className="house-checkbox__name">{houseName}</span>
        </label>
    );
});

export default withNamespaces()(HouseCheckbox);
