import './MissingPage.scss';

import React from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';

export function MissingPage({ t }: WithNamespaces) {
    return (
        <div className="missing-page">
            <p>
                {t('general.hint.missing-page', {
                    defaultValue: 'Missing page',
                })}
            </p>
        </div>
    );
}

export default withNamespaces()(MissingPage);
