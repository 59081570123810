import { uniq } from 'ramda';
import React, { useRef } from 'react';
import { useConditionalEffect, useDeltaObject } from 'react-delta';
import { useForm, useController } from 'react-hook-form';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import AllianceColumn from '@/components/alliances/AllianceEditForm/AllianceColumn';
import { FormSelectBox } from '@/components/common/inputs/FormSelectBox';
import TextInput from '@/components/common/inputs/TextInput';
import withMetadata, {
    Expansions,
    TWithMetadata,
} from '@/shared/components/WithMetadata';
import { RootState } from '@/shared/redux/RootState';
import AlliancesActions from '@/shared/redux/alliances';
import { DecksActions } from '@/shared/redux/decks';
import { getTranslatedExpansionName } from '@/shared/services/lang/translationHelpers';
import { Card, Deck, House } from '@/shared/typings';
import { Alliance } from '@/shared/typings/Alliance';
import { AsyncStatus } from '@/shared/redux/Async';
import './AllianceEditForm.scss';
import {
    CreateAlliancePayload,
    UpdateAlliancePayload,
} from '@/shared/api/ApiTypings';
import { SelectItem } from '@/components/common/inputs/FormSelectBox';
import { useCreateAlliance } from '@/shared/queries/alliance';
import ErrorModal from '@/components/common/ErrorModal';
import { NonDeckCardTable } from '@/components/single_deck/NonDeckCardTable';
import TextAdaptedToContainer from '@/components/common/TextAdaptedToContainer';
import { useFriendDecks } from '@/shared/queries/friends';
import QRCodeGenerator from '@/components/common/QRCodeGenerator';
import { useDecks } from '@/shared/queries/decks';
import { useDebounceFn } from 'ahooks';
import { useHouses } from '@/shared/queries/houses';

export type FormModes = 'create' | 'update';

export type OwnProps<MODE extends FormModes> = {
    mode: MODE;
    submitting?: boolean;
    fetchSuggestions: typeof AlliancesActions.deckSuggestionsRequest;
    suggestions: RootState['alliances']['deckSuggestions'];
    decksCache: RootState['decks']['decksCache'];
    cacheDeck: typeof DecksActions.cacheDeckRequest;
    onCancel?: () => void;
    onDuplicate?: (values: FormValues) => void;
} & (
    | {
          mode: 'create';
          defaultValues?: Partial<Alliance>;
          onSubmit: (values: CreateAlliancePayload) => void;
      }
    | {
          mode: 'update';
          defaultValues: Alliance;
          onSubmit: (values: UpdateAlliancePayload) => void;
      }
);
export type Props<MODE extends FormModes> = WithNamespaces &
    TWithMetadata &
    OwnProps<MODE>;

export interface FormValues {
    name: string;
    notes: string;
    deck_1_id: string | null;
    deck_2_id: string | null;
    deck_3_id: string | null;
    house_1_id: string | null;
    house_2_id: string | null;
    house_3_id: string | null;
    hidden?: boolean;
    is_my_alliance?: boolean | null;
    token_creature_card_id?: string | null;
}

const DEFAULT_VALUES: FormValues = {
    name: '',
    notes: '',
    deck_1_id: null,
    deck_2_id: null,
    deck_3_id: null,
    house_1_id: null,
    house_2_id: null,
    house_3_id: null,
    is_my_alliance: null,
    token_creature_card_id: null,
};

type Decks = (typeof DECKS)[number];
type Houses = (typeof DECKS)[number];

const DECKS = ['deck_1_id', 'deck_2_id', 'deck_3_id'] as const;
const HOUSES = ['house_1_id', 'house_2_id', 'house_3_id'] as const;
let updatedCards: SelectItem[] = [];
let allTokenCardsArray: Card[] = [];
let friendsDecksArray = [];

export const ALLIANCE_CLONABLE_KEYS = [...DECKS, ...HOUSES] as const;

export function AllianceEditForm(props: Props<'create'> | Props<'update'>) {
    const {
        t,
        mode,
        suggestions,
        fetchSuggestions,
        metadata,
        submitting,
        decksCache,
        cacheDeck,
        onCancel,
        onDuplicate,
    } = props;
    const [searchText, setSearchText] = React.useState('');
    const debouncedSearchText = useDebounceFn((query) => setSearchText(query), {wait: 900, trailing: true})

    const defaultValues = {
        ...DEFAULT_VALUES,
        ...(props.defaultValues || {}),
    };
    const { register, handleSubmit, setValue, watch, control, getValues } =
        useForm<FormValues>({ defaultValues });
    const commonProps = { control, rules: { required: true } };
    const houseFields = [
        useController({ name: 'house_1_id', ...commonProps }),
        useController({ name: 'house_2_id', ...commonProps }),
        useController({ name: 'house_3_id', ...commonProps }),
    ] as const;
    const selectedDecks: ReadonlyArray<string | null> = watch(DECKS);
    const selectedHouses: Array<string | null> = watch(HOUSES);
    const firstSelectedDeckId = selectedDecks.find((deck) => !!deck);
    const firstSelectedDeck =
        decksCache[firstSelectedDeckId] ||
        suggestions.list.find((deck) => deck.id === firstSelectedDeckId);
    const allowedSet = firstSelectedDeck?.expansion || null;
    const firstEmptySlot = DECKS.find((deck, i) => !selectedDecks[i]) || null;
    const deltas = useDeltaObject({
        searchText,
        selectedDecks: selectedDecks.filter(Boolean).length,
    });
    const userSelectedOrUnselectedFirstDeck =
        deltas.selectedDecks?.curr < 2 &&
        (!deltas.selectedDecks.prev || deltas.selectedDecks?.prev < 2);
    const refetchBecauseOfUserAction =
        !!deltas.searchText || userSelectedOrUnselectedFirstDeck;
    const isCreateMode = mode === 'create';
    const isUpdateMode = mode === 'update';
    const isCloning = defaultValues && isCreateMode;
    const isInitialSuggestionsFetchRef = useRef(true);
    const shouldFetchOnceIfCloning =
        isCloning &&
        isInitialSuggestionsFetchRef.current === true &&
        !!firstSelectedDeck; // wait while this var can be derived from fetched suggestions
    const [tokenCreatureCards, setTokenCreatureCards] = React.useState<
        SelectItem[]
    >([]);
    const [tokenCreatureName, setTokenCreatureName] =
        React.useState<string>('');
    const { mutate, isError, error } = useCreateAlliance();

    const [isErrorModalOpen, setIsErrorModalOpen] =
        React.useState<boolean>(true);
    const [tokenCard, setTokenCard] = React.useState<Card>();
    const [filterDeck, setFilterDeck] = React.useState<boolean>();
    const [choosenExpansion, setChoosenExpansion] = React.useState<string>('');

    const userProfile = useSelector(
        (state) => state.userProfile.fetchProfile.details
    );
    const [renderedSuggestions, setRenderedSuggestions] = React.useState<any>(
        []
    );

    const {data: userDecksData} = useDecks(userProfile?.id, {
        searchText,
        expansionId: allowedSet ? String(allowedSet) : null,
    },
    {
        page: 0,
        pageSize: allowedSet ? 99 : 10,
    }, {enabled: !!userProfile?.id})

    const { data: friendsDecks } = useFriendDecks(searchText, allowedSet ? String(allowedSet) : null);
    const { data: housesData } = useHouses()


    React.useEffect(() => {
        if (housesData?.data && friendsDecks?.data && userDecksData?.data) {
            const decks = [...friendsDecks?.data, ...userDecksData?.data]
            const mappedDecks = decks?.map(deck => {
                return {
                    label: deck.name,
                    value: deck.id,
                    expansion: getTranslatedExpansionName(
                        deck.expansion?.set_id || deck.expansion,
                        metadata.expansions,
                        t
                    ),
                    username: deck?.owner?.username || userProfile?.username,
                    houses: deck._links.houses?.map(houseId => housesData?.data?.find(h => h.id === houseId)),
                }
            })

            setRenderedSuggestions(mappedDecks);
        }
    }, [friendsDecks, userDecksData]);

    const handleCloseErrorModal = () => {
        setIsErrorModalOpen(false);
    };

    const findTokenCard = (deck: Deck) => {
        if (deck && deck.cards) {
            Object.values(deck.cards).forEach((cards: Card[]) => {
                cards.forEach((card: Card) => {
                    card.id === defaultValues.token_creature_card_id &&
                        setTokenCard(card);
                });
            });
        }
    };

    React.useEffect(() => {
        updatedCards = [];
        allTokenCardsArray = [];
        isCreateMode &&
            selectedDecks.forEach((deckId) => {
                const deck = deckId ? decksCache[deckId] : null;
                if (deck && deck.cards) {
                    Object.values(deck.cards).forEach((cards: Card[]) => {
                        cards.forEach((card: Card) => {
                            if (card.cardType === 5) {
                                const existingCard = updatedCards.find(
                                    (c) => c.value === card.id
                                );
                                if (!existingCard) {
                                    allTokenCardsArray.push(card);
                                    updatedCards.push({
                                        value: card.id,
                                        label: card.name,
                                    });
                                }
                            }
                        });
                    });
                }
            });
        if (updatedCards.length === 1) {
            setValue('token_creature_card_id', updatedCards[0].value);
            setTokenCreatureName(updatedCards[0].label);
        } else {
            setValue('token_creature_card_id', null);
            setTokenCreatureName('');
        }
        setTokenCard(
            allTokenCardsArray.find(
                (card) => card.id === getValues('token_creature_card_id')
            )
        );
        setTokenCreatureCards(updatedCards);
    }, [decksCache, filterDeck]);

    // Fetch suggestions when user types in the search box
    useConditionalEffect(() => {
        if (isCreateMode) {
            fetchSuggestions(
                {
                    searchText,
                    expansionId: allowedSet ? String(allowedSet) : null,
                },
                {
                    page: 0,
                    // if user selected first deck,
                    // fetch (hopefully) all decks from the same expansion
                    pageSize: allowedSet ? 99 : 10,
                }
            );
            if (shouldFetchOnceIfCloning) {
                isInitialSuggestionsFetchRef.current = false;
            }
        }
    }, refetchBecauseOfUserAction || shouldFetchOnceIfCloning);

    // Fetch deck data for selected decks
    useConditionalEffect(() => {
        uniq(selectedDecks.filter(Boolean)).forEach((deckId) => {
            if (!decksCache[deckId]) {
                cacheDeck(deckId);
            }
        });
    }, deltas.selectedDecks);

    const doSubmit = (data: FormValues) => {
        isCreateMode
            ? mutate(data)
            : props.onSubmit({
                  hidden: props.defaultValues.hidden,
                  id: props.defaultValues.id,
                  ...data,
                  is_my_alliance: props.defaultValues.is_my_alliance,
              });
        isError && setIsErrorModalOpen(true);
    };

    const handleHide = handleSubmit((values: FormValues) => {
        if (isUpdateMode) {
            doSubmit({
                ...values,
                hidden: !props.defaultValues.hidden,
                is_my_alliance: true,
            });
        }
    });

    return (
        <form
            onSubmit={handleSubmit((data, event) => {
                event.preventDefault();
                doSubmit(data);
            })}
            className="alliance-form"
            role="form"
        >
            {isCreateMode && (
                <>
                    {choosenExpansion && (
                        <p className="alliance-form__expansion">
                            Expansion: {choosenExpansion}
                        </p>
                    )}
                    <div className="alliance-form__search-wrap">
                        <FormSelectBox
                            className="alliance-form__search"
                            items={renderedSuggestions}
                            placeholder={
                                !firstEmptySlot
                                    ? t('alliances.search.all-decks-selected', {
                                          defaultValue: 'All decks selected',
                                      })
                                    : t('alliances.search.search-your-decks', {
                                          defaultValue: 'Search your decks...',
                                      })
                            }
                            nullable={true}
                            filterable={true}
                            disabled={!firstEmptySlot}
                            value=""
                            onQueryChange={q => debouncedSearchText.run(q)}
                            onChange={(deckId) => {
                                setSearchText('');
                                if (firstEmptySlot) {
                                    let deck = renderedSuggestions.find(
                                        (d) => d.value === deckId
                                    );
                                    
                                    setChoosenExpansion(deck.expansion);
                                    setValue(firstEmptySlot, deck.value);
                                }
                                setFilterDeck(!filterDeck);
                            }}
                            loading={
                                suggestions.__status === AsyncStatus.Pending
                            }
                            renderItem={(item) => {
                                return (
                                        <FormSelectBox.Item
                                            value={item.value}
                                            key={item.value}
                                            className="alliance-form__dropdown-item"
                                        >
                                            <div className="alliance-form__dropdown-item-name">
                                                {item.label}
                                            </div>
                                            <div className="alliance-form__dropdown-item-name">
                                                {item.username}
                                            </div>
                                            <div className="alliance-form__dropdown-item-set">
                                                {item.expansion}
                                            </div>
                                            <div className="alliance-form__dropdown-item-houses">
                                                {item.houses.map((house) => (
                                                    <img
                                                        className="alliance-form__dropdown-item-house"
                                                        key={house.id}
                                                        src={house.image}
                                                        alt={house.name}
                                                        title={house.name}
                                                    />
                                                ))}
                                            </div>
                                        </FormSelectBox.Item>
                                );
                            }}
                        />
                    </div>
                </>
            )}

            {/* todo extract table and memoize? */}
            <div className="alliance-form__table">
                {selectedDecks.map((deckId, i) => {
                    const selectedHouse = selectedHouses[i];
                    const deck = deckId ? decksCache[deckId] : null;
                    if (!isCreateMode && !tokenCard) findTokenCard(deck);

                    return (
                        <AllianceColumn
                            editable={isCreateMode}
                            key={i}
                            slotNumber={i + 1}
                            deckId={deckId}
                            deckData={deck}
                            selectedHouse={selectedHouse}
                            selectedHouses={selectedHouses}
                            onSelectHouse={(h: House) => {
                                const isSelectedHouseClickedAgain =
                                    selectedHouse === h.id;

                                if (
                                    selectedHouses.includes(h.id) &&
                                    !isSelectedHouseClickedAgain
                                ) {
                                    // same house cannot be selected twice
                                    return;
                                }
                                houseFields[i].field.onChange(
                                    isSelectedHouseClickedAgain ? null : h.id
                                );
                            }}
                            onClear={() => {
                                setFilterDeck(!filterDeck);
                                setValue(`house_${i + 1}_id` as Houses, null);
                                setValue(`deck_${i + 1}_id` as Decks, null);
                                !getValues().deck_1_id &&
                                    !getValues().deck_2_id &&
                                    !getValues().deck_3_id &&
                                    setChoosenExpansion('');
                            }}
                        />
                    );
                })}
            </div>
            {isCreateMode && tokenCreatureCards.length > 0 && (
                <div className="alliance-form__token-container">
                    <FormSelectBox
                        className="alliance-form__token-card-select"
                        items={tokenCreatureCards}
                        value=""
                        query={tokenCreatureName}
                        onChange={(creatureId) => {
                            const selectedCard = tokenCreatureCards.find(
                                (card) => card.value === creatureId
                            );
                            if (selectedCard) {
                                setValue('token_creature_card_id', creatureId);
                                setTokenCard(
                                    allTokenCardsArray.find(
                                        (card) =>
                                            card.id ===
                                            getValues('token_creature_card_id')
                                    )
                                );
                                setTokenCreatureName(selectedCard.label);
                            }
                        }}
                        placeholder={'Pick a card'}
                    />
                    {tokenCard && (
                        <div className="alliance-form__non-deck-card">
                            <NonDeckCardTable
                                cards={[tokenCard]}
                                legacyCardsIds={[]}
                                anomalyCardsIds={[]}
                                noHeader={true}
                            />
                        </div>
                    )}
                </div>
            )}

            {!isCreateMode && tokenCard && (
                <div className="deck-page__cards">
                    <div className="deck-page__cards-header">
                        <div className="deck-page__cards-title btn-title">
                            <TextAdaptedToContainer>
                                {t('single-deck.header.non-deck-cards', {
                                    defaultValue: 'Token Cards',
                                })}
                            </TextAdaptedToContainer>
                        </div>
                        <div
                            className="deck-page__cards-header-spacer"
                            role="presentation"
                        />
                    </div>
                    <div className="deck-page__cards-table">
                        <NonDeckCardTable
                            cards={[tokenCard]}
                            legacyCardsIds={[]}
                            anomalyCardsIds={[]}
                        />
                    </div>
                </div>
            )}

            {(defaultValues.is_my_alliance || isCreateMode) && (
                <div className="alliance-form__inputs">
                    <TextInput
                        {...register('name', {
                            required: true,
                        })}
                        placeholder={t('alliances.input-name.placeholder', {
                            defaultValue: 'Enter an Alliance name...',
                        })}
                        className="alliance-form__input-name"
                    />

                    <TextInput
                        {...register('notes')}
                        value={undefined}
                        asTextArea={true}
                        rows={1}
                        placeholder={t('alliances.input-notes.placeholder', {
                            defaultValue: 'Enter any notes...',
                        })}
                        className="alliance-form__input-notes"
                    />
                </div>
            )}

            {isCreateMode && (
                <div className="alliance-form__actions">
                    <button type="submit" className="btn" disabled={submitting}>
                        {t('alliances.forge.btn-forge', {
                            defaultValue: 'Forge Alliance',
                        })}
                    </button>
                </div>
            )}

            {defaultValues.is_my_alliance && (
                <div className="alliance-form__actions">
                    <button type="button" className="btn" onClick={handleHide}>
                        {props.defaultValues.hidden
                            ? t('alliances.unhide', { defaultValue: 'Unhide' })
                            : t('alliances.hide', { defaultValue: 'Hide' })}
                    </button>
                    <button
                        type="button"
                        className="btn"
                        onClick={() => {
                            onDuplicate?.(getValues());
                        }}
                    >
                        {t('alliances.duplicate', {
                            defaultValue: 'Duplicate',
                        })}
                    </button>
                    <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={onCancel}
                    >
                        {t('alliances.cancel', { defaultValue: 'Cancel' })}
                    </button>
                    <button type="submit" className="btn" disabled={submitting}>
                        {t('alliances.save', { defaultValue: 'Save' })}
                    </button>
                </div>
            )}
            {/* {defaultValues.notes && defaultValues.is_my_alliance && (
                <>
                    <h1 className="alliance-page__header">Notes</h1>
                    <p className="my-decks-page__no-decks-description">
                        {defaultValues.notes}
                    </p>
                </>
            )} */}
            {defaultValues.is_my_alliance && !isCreateMode && (
                <div className="alliance-page__qr-code-container">
                    <QRCodeGenerator id={defaultValues.id} />
                </div>
            )}
            {isError && isErrorModalOpen && (
                <ErrorModal
                    isOpen={isErrorModalOpen && isErrorModalOpen}
                    onClose={handleCloseErrorModal}
                    description={error?.response?.data?.detail}
                />
            )}
        </form>
    );
}

const mapStateToProps = (state: RootState) => ({
    suggestions: state.alliances.deckSuggestions,
    decksCache: state.decks.decksCache,
});

const mapDispatchToProps = {
    fetchSuggestions: AlliancesActions.deckSuggestionsRequest,
    cacheDeck: DecksActions.cacheDeckRequest,
};

export default withMetadata(
    withNamespaces()(
        connect(mapStateToProps, mapDispatchToProps)(AllianceEditForm)
    )
);
