import './SortInterface.scss';

import React from 'react';
import classNames from 'classnames';

export interface SortInterfaceProps {
    column: string;
    sorting?: string;
    onSortChange: (sorting: string) => void;
    className?: string;
}

export function SortInterface({
    column,
    sorting,
    onSortChange,
    className,
}: SortInterfaceProps) {
    const desc = sorting && sorting.charAt(0) === '-';
    const selected = sorting && sorting.slice(-column.length) === column;
    return (
        <div className={classNames('sort-interface', className)}>
            <SortButton
                className={classNames(
                    'sort-interface__sort sort-interface__sort-asc btn-link',
                    { 'sort-interface__sort--selected': selected && !desc }
                )}
                onClick={() => onSortChange(column)}
            />
            <SortButton
                className={classNames(
                    'sort-interface__sort sort-interface__sort-desc btn-link',
                    { 'sort-interface__sort--selected': selected && desc }
                )}
                onClick={() => onSortChange(`-${column}`)}
            />
        </div>
    );
}

interface SortButtonProps {
    className: string;
    onClick: () => void;
}

export function SortButton(props: SortButtonProps) {
    return (
        <button {...props}>
            <span className="sort-interface__sort-icon" />
        </button>
    );
}

export default SortInterface;
