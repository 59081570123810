import { call, put, select } from 'redux-saga/effects';

import {
    DecksActions,
    LegacyDecksActions,
    GetDeckRequestAction,
    SearchDecksRequestAction,
    AddDeckToFavoritesRequestAction,
    RemoveDeckFromFavoritesRequestAction,
    GetWatchlistDecksRequestAction,
    AddDeckToWatchlistRequestAction,
    RemoveDeckFromWatchlistRequestAction,
    CacheDeckRequestAction,
} from '../../redux/decks';
import API from '../../api/Api';
import {
    GetDeckResponse,
    SearchDecksResponse,
    DeckCountResponse,
    AddDeckToFavoritesResponse,
    AddDeckToWatchlistResponse,
    GeneralErrorResponse,
    PublicSearchDecksResponse,
} from '../../api/ApiTypings';
import { HousesDict } from '@/shared/typings';
import { normalizeDeck, normalizeDecks } from '../Normalizers';
import { getAllHousesDict, getCachedDecks } from '../../redux/Selectors';
import { ensureUserId } from '@/shared/sagas/user/UserSaga';
import { isLegacyOwnershipAction } from '@/shared/redux/user_decks';
import { getHouses } from './MetadataSaga';

function* normalizeDeckFromResponse(
    deckResponseData: GetDeckResponse['data'],
    api?: API
) {
    const { cards, notes, accolades } = deckResponseData._linked;
    let allHouses = yield select(getAllHousesDict);

    if (Object.keys(allHouses).length === 0) {
        yield call(getHouses, api, null, true);
        allHouses = yield select(getAllHousesDict);
    }

    return normalizeDeck(deckResponseData.data, {
        cards,
        notes,
        accolades,
        allHouses,
    });
}

export function* getDeck(
    api: API,
    action: GetDeckRequestAction
): Iterator<any> {
    const legacy = isLegacyOwnershipAction(action);
    const actions = legacy ? LegacyDecksActions : DecksActions;
    const response: GetDeckResponse = yield call(
        api.getDeck,
        action.deckId,
        legacy
    );
    if (response.status === 200) {
        const deck = yield normalizeDeckFromResponse(response.data);
        yield put(actions.getDeckSuccess(deck));
    } else {
        yield put(
            actions.getDeckFailure({
                error: (response as unknown) as GeneralErrorResponse,
                deckId: action.deckId,
            })
        );
    }
}

export function* cacheDeck(
    api: API,
    action: CacheDeckRequestAction
): Iterator<any> {
    const legacy = isLegacyOwnershipAction(action);
    const actions = legacy ? LegacyDecksActions : DecksActions;
    const cachedDecks = yield select(getCachedDecks);
    const deck = cachedDecks && cachedDecks[action.deckId];
    if (deck) {
        yield put(actions.cacheDeckSuccess(deck));
    } else {
        const response: GetDeckResponse = yield call(
            api.getDeck,
            action.deckId
        );
        if (response.status === 200) {
            const deck = yield normalizeDeckFromResponse(response.data, api);
            yield put(actions.cacheDeckSuccess(deck));
        }
    }
}

export function* searchDecks(
    api: API,
    action: SearchDecksRequestAction
): Iterator<any> {
    const response: PublicSearchDecksResponse = yield call(
        api.searchDecks,
        action.searchFilters,
        action.pagination
    );
    if (response.status === 200) {
        const allHouses: HousesDict = yield select(getAllHousesDict);
        const normalizedDecks = normalizeDecks(response.data.data, {
            allHouses,
        });
        yield put(
            DecksActions.searchDecksSuccess({
                decks: normalizedDecks,
                totalCount: response.data.count,
            })
        );
    } else {
        yield put(
            DecksActions.searchDecksFailure({
                error: (response as unknown) as GeneralErrorResponse,
            })
        );
    }
}

export function* getRecentDecks(api: API, action: SearchDecksRequestAction) {
    const response: PublicSearchDecksResponse = yield call(
        api.searchDecks,
        {},
        action.pagination
    );
    if (response.status === 200) {
        const allHouses: HousesDict = yield select(getAllHousesDict);
        const normalizedDecks = normalizeDecks(response.data.data, {
            allHouses,
        });
        yield put(
            DecksActions.getRecentDecksSuccess({
                decks: normalizedDecks,
                totalCount: response.data.count,
            })
        );
    } else {
        yield put(
            DecksActions.getRecentDecksFailure({
                error: (response as unknown) as GeneralErrorResponse,
            })
        );
    }
}

export function* getDeckCount(api: API): Iterator<any> {
    const response: DeckCountResponse = yield call(api.getTotalDeckCount);
    if (response.status === 200) {
        yield put(
            DecksActions.deckCountSuccess({
                deckCount: response.data.count,
            })
        );
    } else {
        yield put(
            DecksActions.deckCountFailure({
                error: (response as unknown) as GeneralErrorResponse,
            })
        );
    }
}

export function* addDeckToFavorites(
    api: API,
    action: AddDeckToFavoritesRequestAction
): Iterator<any> {
    const userId = yield ensureUserId();
    const legacy = isLegacyOwnershipAction(action);
    const actions = legacy ? LegacyDecksActions : DecksActions;
    const response: AddDeckToFavoritesResponse = yield call(
        api.addDeckToFavorites,
        userId,
        action.deckId,
        legacy
    );
    if (response.status === 201) {
        const allHouses: HousesDict = yield select(getAllHousesDict);
        const deckId = normalizeDeck(response.data.data, { allHouses }).id;
        yield put(actions.addDeckToFavoritesSuccess({ deckId }));
    } else {
        yield put(
            actions.addDeckToFavoritesFailure({
                error: (response as unknown) as GeneralErrorResponse,
            })
        );
    }
}

export function* removeDeckFromFavorites(
    api: API,
    action: RemoveDeckFromFavoritesRequestAction
): Iterator<any> {
    const userId = yield ensureUserId();
    const legacy = isLegacyOwnershipAction(action);
    const actions = legacy ? LegacyDecksActions : DecksActions;
    const response: AddDeckToFavoritesResponse = yield call(
        api.removeDeckFromFavorites,
        userId,
        action.deckId,
        legacy
    );
    if (response.status === 204) {
        yield put(
            actions.removeDeckFromFavoritesSuccess({
                deckId: action.deckId,
            })
        );
    } else {
        yield put(
            actions.removeDeckFromFavoritesFailure({
                error: (response as unknown) as GeneralErrorResponse,
            })
        );
    }
}

export function* getDecksOnWatchlist(
    api: API,
    action: GetWatchlistDecksRequestAction
): Iterator<any> {
    const legacy = isLegacyOwnershipAction(action);
    const actions = legacy ? LegacyDecksActions : DecksActions;
    const response: SearchDecksResponse = yield call(
        api.getDecksOnWatchlist,
        action.watchlistFilters,
        action.pagination,
        legacy
    );
    if (response.status === 200) {
        const allHouses: HousesDict = yield select(getAllHousesDict);
        const normalizedDecks = normalizeDecks(response.data.data, {
            allHouses,
        });
        yield put(
            actions.getWatchlistDecksSuccess({
                decks: normalizedDecks,
                totalCount: response.data.count,
            })
        );
    } else {
        yield put(
            actions.getWatchlistDecksFailure({
                error: (response as unknown) as GeneralErrorResponse,
            })
        );
    }
}

export function* addDeckToWatchlist(
    api: API,
    action: AddDeckToWatchlistRequestAction
): Iterator<any> {
    const userId = yield ensureUserId();
    const legacy = isLegacyOwnershipAction(action);
    const actions = legacy ? LegacyDecksActions : DecksActions;
    const response: AddDeckToWatchlistResponse = yield call(
        api.addDeckToWatchlist,
        userId,
        action.deckId,
        legacy
    );
    if (response.status === 201) {
        const allHouses: HousesDict = yield select(getAllHousesDict);
        const deckId = normalizeDeck(response.data.data, { allHouses }).id;
        yield put(actions.addDeckToWatchlistSuccess({ deckId }));
    } else {
        yield put(
            actions.addDeckToWatchlistFailure({
                error: (response as unknown) as GeneralErrorResponse,
            })
        );
    }
}

export function* removeDeckFromWatchlist(
    api: API,
    action: RemoveDeckFromWatchlistRequestAction
): Iterator<any> {
    const userId = yield ensureUserId();
    const legacy = isLegacyOwnershipAction(action);
    const actions = legacy ? LegacyDecksActions : DecksActions;
    const response: AddDeckToWatchlistResponse = yield call(
        api.removeDeckFromWatchlist,
        userId,
        action.deckId,
        legacy
    );
    if (response.status === 204) {
        yield put(
            actions.removeDeckFromWatchlistSuccess({
                deckId: action.deckId,
            })
        );
    } else {
        yield put(
            actions.removeDeckFromWatchlistFailure({
                error: (response as unknown) as GeneralErrorResponse,
            })
        );
    }
}
