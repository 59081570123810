import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MyDecksPageComponent from '@/components/decks/my_decks/MyDecksPageComponent';
import { RootState } from '@/shared/redux/RootState';
import { isAuthorized } from '@/shared/redux/Selectors';
import {
    UserDecksActions,
    LegacyUserDecksActions,
} from '@/shared/redux/user_decks';
import { DecksActions, LegacyDecksActions } from '@/shared/redux/decks';

import { useFlag } from '@/shared/services/Flags';
import { Link } from 'react-router-dom';

const ownershipLaunchDate = process.env.OWNERSHIP_LAUNCH_DATE || 'May 31, 2023';

export const MyDecksPage = () => {
    const dispatch = useDispatch();
    const useNewOwnership = useFlag('newDeckOwnership');
    const sliceKey = useNewOwnership ? 'userDecks' : 'userDecksLegacy';
    const userDecksActions = useNewOwnership
        ? UserDecksActions
        : LegacyUserDecksActions;
    const decksActions = useNewOwnership ? DecksActions : LegacyDecksActions;

    const decks = useSelector<RootState, RootState['userDecks']['userDecks']>(
        (state) => state[sliceKey].userDecks
    );
    const addStatus = useSelector<
        RootState,
        RootState['userDecks']['addingDeck']['addStatus']
    >((state) => state[sliceKey].addingDeck.addStatus);
    const displayAuthorizedInterface = useSelector(isAuthorized);

    const addDeck = (code: string) =>
        dispatch(userDecksActions.addDeckRequest(code));

    const fetchDecks = (
        ...args: Parameters<typeof userDecksActions.userDecksRequest>
    ) => dispatch(userDecksActions.userDecksRequest(...args));

    const requestAddDeckToFavorites = (
        ...args: Parameters<typeof decksActions.addDeckToFavoritesRequest>
    ) => dispatch(decksActions.addDeckToFavoritesRequest(...args));

    const requestRemoveDeckFromFavorites = (
        ...args: Parameters<typeof decksActions.removeDeckFromFavoritesRequest>
    ) => dispatch(decksActions.removeDeckFromFavoritesRequest(...args));

    const noDecksMessage = useNewOwnership ? (
        <span>
            Please register a deck below to claim {' '}
            <a href="https://keyforging.com/keyforge-deck-ownership/">
                <strong>ownership</strong>
            </a>
            {' '} of the deck. 
            Ownership of a deck comes with certain privileges. 
            For more information about deck ownership please read {' '} 
            <a href="https://keyforging.com/important-master-vault-updates/">
                <strong>this article</strong>
            </a>
            {'.'}
            <br /> If you have registered deck before {ownershipLaunchDate}, {' '}
            you can find your linked decks in the Legacy Deck Page {' '} 
            <Link to="/my-decks-legacy">
                <strong>here</strong>
            </Link>
            {'.'}
            <br />
            You will need to re-register your decks to claim deck ownership.
        </span>
    ) : null;

    const noDecksHeader = useNewOwnership ? 'No Owned Decks' : null;

    return (
        <MyDecksPageComponent
            {...{
                decks,
                addStatus,
                displayAuthorizedInterface,
                addDeck,
                fetchDecks,
                requestAddDeckToFavorites,
                requestRemoveDeckFromFavorites,
                noDecksMessage,
                noDecksHeader,
            }}
        />
    );
};
export default MyDecksPage;
