import API from '@/shared/api/Api';
import {
    CreateAllianceResponse,
    GeneralErrorResponse,
    SearchDecksResponse,
    CreateAlliancePayload,
    GetUserAlliancesResponse,
    GetAllianceResponse,
    UpdateAllianceResponse,
} from '@/shared/api/ApiTypings';
import {
    DeckSuggestionsRequestAction,
    GetAllianceRequestAction,
    UpdateAllianceRequestAction,
    UserAlliancesRequestAction,
} from '@/shared/redux/alliances/Actions';
import { PayloadAction } from '@/shared/redux/ReduxHelpers';
import { getAllHousesDict, getUserId } from '@/shared/redux/Selectors';
import AlliancesActions from '@/shared/redux/alliances';
import { normalizeDecks } from '@/shared/sagas/Normalizers';
import { HousesDict } from '@/shared/typings';
import { call, put, select } from 'redux-saga/effects';

export function* getDecksSuggestions(
    api: API,
    action: DeckSuggestionsRequestAction
): Iterator<any> {
    const userId = yield select(getUserId);

    const response: SearchDecksResponse = yield call(
        api.getUserDecks,
        userId,
        action.searchFilters,
        action.pagination
    );
    if (response.status === 200) {
        const allHouses: HousesDict = yield select(getAllHousesDict);
        const normalizedDecks = normalizeDecks(response.data.data, {
            allHouses,
            cards: response.data._linked?.cards,
        });
        yield put(
            AlliancesActions.deckSuggestionsSuccess({
                decks: normalizedDecks,
                totalCount: response.data.count,
            })
        );
    } else {
        yield put(
            AlliancesActions.deckSuggestionsFailure({
                error: (response as unknown) as GeneralErrorResponse,
            })
        );
    }
}

export function* createAlliance(
    api: API,
    action: PayloadAction<CreateAlliancePayload>
): Iterator<any> {
    const response: CreateAllianceResponse = yield call(
        api.createAlliance,
        action.payload
    );

    if (response.status === 201) {
        yield put(AlliancesActions.createAllianceSuccess());
    } else {
        yield put(
            AlliancesActions.createAllianceFailure({
                error: (response as unknown) as GeneralErrorResponse,
            })
        );
    }
}

export function* updateAlliance(
    api: API,
    action: UpdateAllianceRequestAction
): Iterator<any> {
    const response: UpdateAllianceResponse = yield call(
        api.updateAlliance,
        action.payload
    );

    if (response.status === 200) {
        yield put(AlliancesActions.updateAllianceSuccess());
    } else {
        yield put(
            AlliancesActions.updateAllianceFailure({
                error: (response as unknown) as GeneralErrorResponse,
            })
        );
    }
}

export function* getUserAlliances(
    api: API,
    action: UserAlliancesRequestAction
): Iterator<any> {
    const response: GetUserAlliancesResponse = yield call(
        api.getUserAlliances,
        action.searchFilters,
        action.pagination
    );

    if (response.status === 200) {
        yield put(
            AlliancesActions.userAlliancesSuccess({
                list: response.data.data,
                totalCount: response.data.count,
            })
        );
    } else {
        yield put(
            AlliancesActions.userAlliancesFailure({
                error: (response as unknown) as GeneralErrorResponse,
            })
        );
    }
}

export function* getAlliance(
    api: API,
    action: GetAllianceRequestAction
): Iterator<any> {
    const parts = window.location.pathname.split('/');
    const allianceDetails = parts[1]; // Assuming 'alliance-details' is always the second part
    let response: GetAllianceResponse;
    if(allianceDetails === 'alliance-details') {
        response = yield call(
            api.getAlliance,
            action.payload,
            true,
        );
    } else {
        response = yield call(
            api.getAlliance,
            action.payload,
        );
    }


    if (response.status === 200) {
        yield put(AlliancesActions.getAllianceSuccess(response.data));
    } else {
        yield put(
            AlliancesActions.getAllianceFailure({
                error: (response as unknown) as GeneralErrorResponse,
            })
        );
    }
}
