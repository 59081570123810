import './Modal.scss';

import React, { Fragment, ReactNode } from 'react';
import ReactModal from 'react-modal';
import classNames from 'classnames';

if (process.env.NODE_ENV !== 'test') {
    ReactModal.setAppElement('#root');
}

export interface ModalProps {
    isOpen: boolean;
    onClose?: () => void;
    children: ReactNode;
    loading?: boolean;
    hideModalOnLoading?: boolean;
    className?: string;
    headerImage?: ReactNode;
}

export default function Modal({
    isOpen,
    onClose,
    children,
    className,
    loading,
    hideModalOnLoading,
    headerImage,
}: ModalProps) {
    const modalClassName = classNames('modal', className, {
        'modal--loading': loading,
        'modal--hide-on-loading': hideModalOnLoading,
        'modal--with-header-image': headerImage,
    });

    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={onClose}
            className={modalClassName}
            overlayClassName="modal__overlay"
            bodyOpenClassName="modal__body--open"
        >
            {loading && hideModalOnLoading ? (
                <span className="spinner" />
            ) : (
                <Fragment>
                    {loading && (
                        <div className="modal__spinner-overlay">
                            <span className="spinner" />
                        </div>
                    )}
                    {headerImage && (
                        <div
                            className="modal__header-image"
                            role="presentation"
                        >
                            {headerImage}
                        </div>
                    )}
                    <div className="modal__content">{children}</div>
                </Fragment>
            )}
        </ReactModal>
    );
}
