import { Action } from 'redux';

import { RequestFailureAction, createActions } from '../ReduxHelpers';
import { StateType } from './State';
import {
    UserProfile,
    Transaction,
    ParticipatedTournament,
} from '@/shared/typings';
import { Pagination, TransactionFilters } from '@/shared/api/ApiTypings';

/* ------------- Types and Action Creators ------------- */

export const { Types, Creators } = createActions<CreatorsType>({
    fetchProfileRequest: null,
    fetchProfileSuccess: ['payload'],
    fetchProfileFailure: ['payload'],

    updateProfileRequest: ['profileOptions'],
    updateProfileSuccess: ['payload'],
    updateProfileFailure: ['payload'],

    getTransactionsRequest: ['filters', 'pagination'],
    getTransactionsSuccess: ['payload'],
    getTransactionsFailure: ['payload'],
});

/* --------------- Actions definitions ------------------- */

interface CreatorsType {
    fetchProfileRequest: () => Action;
    fetchProfileSuccess: (payload: {
        profile: UserProfile;
        tournaments: ParticipatedTournament[];
    }) => FetchProfileSuccessAction;
    fetchProfileFailure: (payload: {
        error: StateType['fetchProfile']['__error'];
    }) => RequestFailureAction;

    updateProfileRequest: (profileOptions: {
        isAnonymous: boolean;
    }) => UpdateProfileRequestAction;
    updateProfileSuccess: (payload: UserProfile) => UpdateProfileSuccessAction;
    updateProfileFailure: (payload: {
        error: StateType['updateProfile']['__error'];
    }) => RequestFailureAction;

    getTransactionsRequest: (
        filters: TransactionFilters,
        pagination: Pagination
    ) => GetTransactionsRequestAction;
    getTransactionsSuccess: (payload: {
        transactions: Transaction[];
        totalCount: number;
    }) => GetTransactionsSuccessAction;
    getTransactionsFailure: (payload: {
        error: StateType['getTransactions']['__error'];
    }) => RequestFailureAction;
}

export interface FetchProfileSuccessAction extends Action {
    payload: {
        profile: UserProfile;
        tournaments: ParticipatedTournament[];
    };
}

export interface UpdateProfileRequestAction extends Action {
    profileOptions: {
        isAnonymous: boolean;
    };
}

export interface UpdateProfileSuccessAction extends Action {
    payload: UserProfile;
}

export interface GetTransactionsRequestAction extends Action {
    filters: TransactionFilters;
    pagination: Pagination;
}

export interface GetTransactionsSuccessAction extends Action {
    payload: {
        transactions: Transaction[];
        totalCount: number;
    };
}
