import { Action } from 'redux';

import {
    RequestFailureAction,
    createActions,
} from '@/shared/redux/ReduxHelpers';
import { StateType } from './State';
import {
    Pagination,
    AvailableTournaments,
    TournamentDeckFilters,
} from '@/shared/api/ApiTypings';
import { TournamentPlayer, TournamentDeck } from '@/shared/typings';

/* ------------- Types and Action Creators ------------- */

export const { Types, Creators } = createActions<CreatorsType>({
    getPlayerLeaderboardRequest: ['tournament', 'pagination'],
    getPlayerLeaderboardSuccess: ['payload'],
    getPlayerLeaderboardFailure: ['payload'],

    getDeckLeaderboardRequest: ['tournament', 'filters', 'pagination'],
    getDeckLeaderboardSuccess: ['payload'],
    getDeckLeaderboardFailure: ['payload'],
});

/* --------------- Actions definitions ------------------- */

interface CreatorsType {
    getPlayerLeaderboardRequest: (
        tournament: AvailableTournaments,
        pagination: Pagination
    ) => GetPlayerLeaderboardRequestAction;
    getPlayerLeaderboardSuccess: (payload: {
        players: TournamentPlayer[];
        totalCount: number;
        participantCount: number;
        userRank: number;
    }) => GetPlayerLeaderboardSuccessAction;
    getPlayerLeaderboardFailure: (payload: {
        error: StateType['getPlayerLeaderboard']['__error'];
    }) => RequestFailureAction;

    getDeckLeaderboardRequest: (
        tournament: AvailableTournaments,
        filters: TournamentDeckFilters,
        pagination: Pagination
    ) => GetDeckLeaderboardRequestAction;
    getDeckLeaderboardSuccess: (payload: {
        decks: TournamentDeck[];
        totalCount: number;
    }) => GetDeckLeaderboardSuccessAction;
    getDeckLeaderboardFailure: (payload: {
        error: StateType['getPlayerLeaderboard']['__error'];
    }) => RequestFailureAction;
}

export interface GetPlayerLeaderboardRequestAction extends Action {
    tournament: AvailableTournaments;
    pagination: Pagination;
}

export interface GetPlayerLeaderboardSuccessAction extends Action {
    payload: {
        players: TournamentPlayer[];
        totalCount: number;
        participantCount: number;
        userRank: number;
    };
}

export interface GetDeckLeaderboardRequestAction extends Action {
    tournament: AvailableTournaments;
    filters: TournamentDeckFilters;
    pagination: Pagination;
}

export interface GetDeckLeaderboardSuccessAction extends Action {
    payload: {
        decks: TournamentDeck[];
        totalCount: number;
    };
}
