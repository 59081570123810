import './GoToMyDecks.scss';

import React from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';

import AuthLink from '@/components/common/AuthLink';

import GoToMyDecksPicture from '@/images/search_decks/go-to-my-decks.png';

interface GoToMyDecksProps extends WithNamespaces {
    isUserAuthorized: boolean;
}

export function GoToMyDecks({ t, isUserAuthorized }: GoToMyDecksProps) {
    const goToDecksLabel = t('general.btn.go-to-my-decks', {
        defaultValue: 'Go to My Decks',
    });
    const MyDecksLink = isUserAuthorized ? Link : AuthLink;
    return (
        <div className="go-to-my-decks">
            <img
                className="go-to-my-decks__img"
                src={GoToMyDecksPicture}
                alt={goToDecksLabel}
            />

            {/* @ts-ignore */}
            <MyDecksLink className="btn go-to-my-decks__link" to="/my-decks">
                {goToDecksLabel}
            </MyDecksLink>
        </div>
    );
}

export default withNamespaces()(GoToMyDecks);
