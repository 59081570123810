import SortInterface from '@/components/common/tables/SortInterface';
import { DeckSearchOrder, Transfer } from '@/shared/api/ApiTypings';

import classNames from 'classnames';

import React from 'react';
import './TransferClaimList.scss';
import { TransferColumn } from '../common/deck_lists/DeckListTypes';
import { ModalData } from './DeckTransferPage';
import { ClaimListItem } from './ClaimListItem';

export type SortableColumns = DeckSearchOrder;

interface IProps {
    data: Transfer[];
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setModalData: React.Dispatch<React.SetStateAction<ModalData>>;
    showInactiveOperations: boolean;
    exchangeType: 'transfer' | 'claim';
    onSortChange?: (sorting: SortableColumns) => void;
    sorting?: SortableColumns | null /* if null, hide all sorting */;
}

export const defaultTransferColumns: TransferColumn[] = [
    {
        id: 'name',
        label: 'Name',
    },
    {
        id: 'date',
        sorting: 'date',
        label: 'Date',
    },
    {
        id: 'type',
        label: 'Type',
    },
    {
        id: 'fromUser',
        label: 'Initiated By',
    },
    {
        id: 'status',
        label: 'Status',
    },
    {
        id: 'buttons',
        label: '',
    },
];

export const ClaimList = ({
    data,
    sorting,
    onSortChange,
    setIsModalOpen,
    showInactiveOperations,
    setModalData,
    exchangeType,
}: IProps) => {
    const renderSortInterface = (column: any) =>
        onSortChange && (
            <SortInterface
                column={column}
                sorting={sorting}
                onSortChange={onSortChange}
                className={`claim-list__head-sort claim-list__head-sort--${column}`}
            />
        );

    const renderColumn = (column: TransferColumn, i: number) => {
        return (
            <th
                key={i}
                className={classNames(
                    'kf-table__cell',
                    `claim-list__${column.id}-field`,
                    column.className
                )}
            >
                <span className="kf-table__label-text">{column.label}</span>
                {sorting !== null &&
                    column.sorting &&
                    renderSortInterface(column.sorting)}
            </th>
        );
    };

    const filteredData = showInactiveOperations
        ? data
        : data.filter((item) => item.status === 'pending');

    return (
        <table className={classNames('claim-list kf-table')}>
            <thead className="kf-table__head">
                <tr className="kf-table__row">
                    {defaultTransferColumns.map((column, i) => {
                        return renderColumn(column, i);
                    })}
                </tr>
            </thead>
            <tbody className="kf-table__body">
                {filteredData.map((data, i) => (
                    <ClaimListItem
                        key={i}
                        data={data}
                        setIsModalOpen={setIsModalOpen}
                        setModalData={setModalData}
                        exchangeType={exchangeType}
                    />
                ))}
            </tbody>
        </table>
    );
};
