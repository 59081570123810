import { InitialAsyncRequestState } from '../Async';
import { UserProfile, Transaction } from '../../typings';

export enum TransactionType {
    DeckDiscovered = 1,
    AddedByAdmin,
    TournamentWin,
    PrizeExchange,
    PrizeReturn,
}

const InitialUserProfile: UserProfile = {
    id: '',
    email: '',
    language: '',
    profilePhoto: '',
    username: '',
    qrCode: '',
    shards: 0,
    lifetimeShards: 0,
    keys: 0,
    keyTokenCost: +process.env.EVENT_KEY_AEMBER_COST || 0,
    keyTokens: 0,
    isAnonymous: true,
};

export const InitialState = {
    fetchProfile: {
        ...InitialAsyncRequestState,

        details: InitialUserProfile,
    },
    updateProfile: InitialAsyncRequestState,
    getTransactions: {
        ...InitialAsyncRequestState,

        totalCount: 0,
        list: [] as Transaction[],
    },
};

export type StateType = typeof InitialState;
