import './UserTransactionsList.scss';

import React from 'react';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import UserTransactionsListItem from './UserTransactionsListItem';

interface UserTransactionsListProps extends WithNamespaces {
    transactions: Record<string, any>[];
}

export const UserTransactionsList = ({
    transactions,
    t,
}: UserTransactionsListProps) => {
    return (
        <table className="transaction-list kf-table">
            <thead className="kf-table__head">
                <tr className="kf-table__row">
                    <th className="kf-table__cell transaction-list__date">
                        <span className="kf-table__label-text">
                            {t('transactions.table.header.date', {
                                defaultValue: 'Date',
                            })}
                        </span>
                    </th>
                    <th className="kf-table__cell transaction-list__location">
                        <span className="kf-table__label-text">
                            {t('transactions.table.header.type', {
                                defaultValue: 'Transaction Type',
                            })}
                        </span>
                    </th>
                    <th className="kf-table__cell transaction-list__description"></th>
                    <th className="kf-table__cell transaction-list__shards transaction-list__shards-changed">
                        <span className="kf-table__label-text">
                            {t('transactions.table.header.cost', {
                                defaultValue: 'Cost',
                            })}
                        </span>
                    </th>
                    <th className="kf-table__cell transaction-list__shards transaction-list__shards-total">
                        <span className="kf-table__label-text">
                            {t('transactions.table.header.amount', {
                                defaultValue: 'Amount',
                            })}
                        </span>
                    </th>
                    <th className="kf-table__cell transaction-list__shards transaction-list__shards-total">
                        <span className="kf-table__label-text">
                            {t('transactions.table.header.payment_status', {
                                defaultValue: 'Status',
                            })}
                        </span>
                    </th>
                </tr>
            </thead>
            <tbody className="kf-table__body">
                {transactions.map((transaction) => (
                    <UserTransactionsListItem
                        key={transaction.id}
                        transaction={transaction}
                    />
                ))}
            </tbody>
        </table>
    );
};

export default withNamespaces()(UserTransactionsList);
