import { GeneralErrorResponse } from '@/shared/api/ApiTypings';
import { normalizeEvents } from './../Normalizers';
import {
    StoreEventsResponse,
    CoordinatesResponse,
    Location,
} from '@/shared/api/ExternalApiTypings';
import { put, call, select } from 'redux-saga/effects';
import ExternalApi from '@/shared/api/ExternalApi';
import EventLocatorActions from '../../redux/event_locator';
import {
    GetNearestEventsRequestAction,
    GetCurrentAddressRequestAction,
} from '@/shared/redux/event_locator/Actions';
import { getAddress } from '@/shared/redux/Selectors';

export function* getCurrentAddress(
    api: ExternalApi,
    action: GetCurrentAddressRequestAction
) {
    const coordinatesResponse: CoordinatesResponse = yield call(
        api.getAddressFromLocation,
        action.lat,
        action.lng,
        action.platform
    );
    if (coordinatesResponse.data.status === 'OK') {
        const firstResult = coordinatesResponse.data.results[0];
        const address: string = firstResult.formatted_address;
        const location: Location = firstResult.geometry.location;
        yield put(
            EventLocatorActions.getCurrentAddressSuccess({
                address,
                latitude: location.lat,
                longitude: location.lng,
            })
        );
    } else {
        yield put(
            EventLocatorActions.getCurrentAddressFailure({
                error: (coordinatesResponse.data
                    .status as unknown) as GeneralErrorResponse,
            })
        );
    }
}

export function* getNearestEvents(
    api: ExternalApi,
    action: GetNearestEventsRequestAction
): Iterator<any> {
    const address = yield select(getAddress);
    yield put(
        EventLocatorActions.getCurrentAddressSuccess({
            address,
            latitude: action.lat,
            longitude: action.lng,
        })
    );
    const response: StoreEventsResponse = yield call(
        api.getNearestEvents,
        action.radius,
        action.lat,
        action.lng,
        action.startDate,
        action.endDate
    );
    if (response.status === 200) {
        const normalizedEvents = normalizeEvents(response.data);
        yield put(
            EventLocatorActions.getNearestEventsSuccess({
                events: normalizedEvents,
                total: normalizedEvents.length,
            })
        );
    } else {
        yield put(
            EventLocatorActions.getNearestEventsFailure({
                error: (response as unknown) as GeneralErrorResponse,
            })
        );
    }
}
