import { Action } from 'redux';
import { createActions } from '@/shared/redux/ReduxHelpers';

/* ------------- Types and Action Creators ------------- */

export const { Types, Creators } = createActions<CreatorsType>({
    refreshSsrAuthorizationUrl: ['redirectUrl'],
});

/* --------------- Actions definitions ------------------- */

interface CreatorsType {
    refreshSsrAuthorizationUrl: (redirectUrl: string) => RefreshUrlAction;
}

export interface RefreshUrlAction extends Action {
    redirectUrl: string;
}
