import './ErrorModal.scss';

import React, { ReactNode } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';

import Modal from '@/components/common/Modal';

export interface Props extends WithNamespaces {
    isOpen: boolean;
    onClose: () => void;
    description?: ReactNode;
}

export function ErrorModal({ isOpen, onClose, description, t }: Props) {
    return (
        <Modal isOpen={isOpen} onClose={onClose} className="error-modal">
            <h3 className="error-modal__heading">
                {t('general.hint.something-wrong', {
                    defaultValue: 'Oops, something went wrong!',
                })}
            </h3>
            {description && (
                <p className="error-modal__description">{description}</p>
            )}
            <button
                className="error-modal__close-btn btn btn-okay"
                onClick={onClose}
            >
                {t('general.btn.okay', {
                    defaultValue: 'Okay',
                })}
            </button>
        </Modal>
    );
}

export default withNamespaces()(ErrorModal);
