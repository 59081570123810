import React, { ReactNode, SyntheticEvent, forwardRef } from 'react';
import './Checkbox.scss';

interface Props {
    children?: ReactNode;
    checked?: boolean;
    onChange: (e: SyntheticEvent<HTMLInputElement>) => void;
    onBlur?: (e: SyntheticEvent<HTMLInputElement>) => void;
    name?: string;
    id?: string;
}

export const Checkbox = forwardRef(function Checkbox(
    { checked, onChange, onBlur, children, name, id }: Props,
    ref: React.ForwardedRef<HTMLInputElement>
) {
    return (
        <label className="checkbox">
            <input
                className="checkbox__input"
                type="checkbox"
                checked={checked}
                onChange={onChange}
                onBlur={onBlur}
                ref={ref}
                name={name}
                id={id}
            />
            <span className="checkbox__box">
                <span className="checkbox__inner-box" />
            </span>
            <span className="checkbox__label">{children}</span>
        </label>
    );
});

export default Checkbox;
