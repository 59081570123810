import { useClaimDeckMutation } from '@/shared/queries/deck_claims';
import React, { useEffect } from 'react';

interface IClaimButton {
    deckCode: string;
    setIsClaimError: (isError: boolean) => void;
    setClaimError: (error: string) => void;
    setIsClaimSuccess: (isSuccess: boolean) => void;
}

export const ClaimButton = ({
    deckCode,
    setIsClaimError,
    setClaimError,
    setIsClaimSuccess,
}: IClaimButton) => {
    const { mutate, isError, error, isSuccess } = useClaimDeckMutation();

    useEffect(() => {
        setClaimError(error?.response?.data?.detail);
        setIsClaimError(isError);
        setIsClaimSuccess(isSuccess);
    }, [isError, isSuccess]);

    const handleClaimDeck = () => {
        mutate(deckCode);
    };

    return (
        <div style={{ marginLeft: '16px', paddingBottom: '16px' }}>
            <button
                className="add-deck-modal__btn btn btn-okay"
                onClick={handleClaimDeck}
            >
                Claim Deck
            </button>
        </div>
    );
};
