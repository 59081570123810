import { curry, includes, without, append } from 'ramda';

/**
 * toggle value from given array. array is interpreted as set of unique
 * values. if value is on the list, it will be removed. if it is not on the list,
 * it will be added.
 * as result new list will be provided.
 * @type {TValue} value type
 */
function rawToggleValue<TValue>(val: TValue, items: TValue[]) {
    return includes(val, items) ? without([val], items) : append(val, items);
}

interface ToggleValueTypings {
    <T>(val: T): (items: T[]) => T[];
    <T>(val: T, items: T[]): T[];
}

export const toggleValue: ToggleValueTypings = curry(rawToggleValue);

type AdjustWhereMatcher<TValue> = (item: TValue, index: number) => boolean;
type AdjustWhereMapper<TValue> = (item: TValue, index: number) => TValue;

/**
 * maps over each item of the `list`. uses `matcher` to determine if it should
 * apply the modifications specified in the `mapper` fn.
 * both `matcher` and `mapper` are called with current item and its index
 * @type {TValue} value type
 */
function rawAdjustWhere<TValue>(
    matcher: AdjustWhereMatcher<TValue>,
    mapper: AdjustWhereMapper<TValue>,
    list: TValue[]
): TValue[] {
    return list.map((item, index) => {
        if (matcher(item, index)) {
            return mapper(item, index);
        }
        return item;
    });
}

interface AdjustWhereTypings {
    <T>(matcher: AdjustWhereMatcher<T>): (
        mapper: AdjustWhereMapper<T>,
        list: T[]
    ) => T[];
    <T>(matcher: AdjustWhereMatcher<T>, mapper: AdjustWhereMapper<T>): (
        list: T[]
    ) => T[];
    <T>(
        matcher: AdjustWhereMatcher<T>,
        mapper: AdjustWhereMapper<T>,
        list: T[]
    ): T[];
}

export const adjustWhere: AdjustWhereTypings = curry(rawAdjustWhere);

/**
 * detect if code is run in browser
 * @type {Boolean}
 */
export const isBrowser =
    typeof window !== 'undefined' && typeof document !== 'undefined';

/**
 * detect if code is build for SSR run, on node side
 * @type {Boolean}
 */
export const isSSRBuild = () => process.env.SSR === '1';

/**
 * returns a promise that resolves itself after given `time` with given `value`
 * @param {number} time  Time to resolve a promise (in miliseconds)
 * @param {T}      value Data to be resolved
 */
export const createTimeout = <T>(time: number, value: T): Promise<T> =>
    new Promise((resolve) => setTimeout(() => resolve(value), time));
