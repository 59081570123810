import classNames from 'classnames';
import React from 'react';
import { House } from '@/shared/typings';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { getTranslatedHouseName } from '@/shared/services/lang/translationHelpers';

type Props = WithNamespaces & {
    name: string;
    value: string;
    house: House;
    onChange?: (house: House) => void;
    active?: boolean;
    disabled?: boolean;
};

export function HouseSelectButton(props: Props) {
    const { house, name, value, onChange, active, disabled, t } = props;
    const houseName = getTranslatedHouseName(house, t);

    return (
        <label
            className={classNames('alliance-form__house-btn', {
                'alliance-form__house-btn--active': active,
                'alliance-form__house-btn--clickable': onChange,
                'alliance-form__house-btn--disabled': disabled,
            })}
            title={houseName}
        >
            <input
                type="radio"
                className="alliance-form__house-input"
                name={name}
                value={value}
                onChange={() => onChange?.(house)}
                disabled={disabled}
            />
            <img
                src={house.image}
                alt={houseName}
                className={classNames('alliance-form__house-icon', {
                    'alliance-form__house-icon--active': active,
                })}
            />
            {houseName}
        </label>
    );
}

export default withNamespaces()(HouseSelectButton);
