import { compose, curry, values, join, map } from 'ramda';

const charset =
    '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._~';
const uintArrayToString = compose(
    join(''),
    map((c: number) => charset[c % charset.length]),
    /**
     * We are doing this in that way because on React-Native new Uint8Array(length)
     * creates object instead of array. So we have to iterate through values
     * and can not just map the "random" array.
     */
    values
);
type cryptoMethodType = (arr: Uint8Array) => Uint8Array;

// code from: https://auth0.com/docs/api-auth/tutorials/nonce
export const randomString = curry(
    (cryptoMethod: cryptoMethodType, length: number) => {
        const bytes = new Uint8Array(length);
        const random = cryptoMethod(bytes);
        return uintArrayToString(random);
    }
);

// polyfill crypto.getRandomValues
export const unsecureGetRandomValues: cryptoMethodType = (bytes) => {
    for (let i = 0; i < bytes.length; i++) {
        bytes[i] = Math.floor(Math.random() * 256);
    }
    return bytes;
};

export const KEYBRINGER_URL =
    'https://account.keybringer.com/realms/keybringer';

export const OPEN_ID_URL = KEYBRINGER_URL + '/protocol/openid-connect';

export const currentAuthorizationUrl = (
    oauthNonce: string,
    oauthState: string,
    redirectURI: string
) =>
    OPEN_ID_URL +
    '/auth' +
    '?display=popup' +
    `&scope=${encodeURIComponent('openid profile email')}` +
    `&response_type=${encodeURIComponent('id_token token')}` +
    `&client_id=${process.env.SOCIAL_AUTH_ASMODEE_KEY}` +
    `&state=${encodeURIComponent(oauthState)}` +
    `&redirect_uri=${encodeURIComponent(redirectURI)}` +
    `&nonce=${oauthNonce}`;

export const currentRegistrationUrl = (
    oauthNonce: string,
    oauthState: string,
    redirectURI: string
) =>
    OPEN_ID_URL +
    '/registrations' +
    '?display=popup' +
    `&scope=${encodeURIComponent('openid profile email')}` +
    `&response_type=${encodeURIComponent('id_token token')}` +
    `&client_id=${process.env.SOCIAL_AUTH_ASMODEE_KEY}` +
    `&state=${encodeURIComponent(oauthState)}` +
    `&redirect_uri=${encodeURIComponent(redirectURI)}` +
    `&nonce=${oauthNonce}`;
