import { User } from '@/shared/api/ApiTypings';
import React from 'react';
import avatarStub from '@/images/header/avatar.jpg';
import './Friend.scss';

interface Props {
    user: User;
    suffix?: React.ReactNode;
}

export const Friend = ({ user, suffix }: Props) => {
    return (
        <div className="friend">
            <img className="friend__avatar" src={avatarStub} alt="" />
            <div className="friend__name">{user.username}</div>
            {suffix && <div className="friend__suffix">{suffix}</div>}
        </div>
    );
};
