import './SectionLead.scss';

import React from 'react';

interface SectionLeadProps {
    children: React.ReactNode;
    className?: string;
    headerLevel?: 1 | 2 | 3 | 4 | 5 | 6;
}

export function SectionLead({
    className,
    children,
    headerLevel = 1,
}: SectionLeadProps) {
    return (
        <div className={`section-lead ${className || ''}`}>
            {React.createElement(
                `h${headerLevel}`,
                { className: 'section-lead__content' },
                children
            )}
        </div>
    );
}

export default SectionLead;
