import React, { memo } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';

import DeckList, { Props as ListProps } from './DeckList';
import { Deck } from '@/shared/typings';
import { AdditionalColumn } from './DeckListTypes';

export interface OwnProps
    extends Pick<
        ListProps,
        | 'decks'
        | 'onSortChange'
        | 'sorting'
        | 'renderDeckRowOverlay'
        | 'renderMobileChains'
        | 'additionalColumns'
    > {
    renderWatchlistIcon: AdditionalColumn<Deck>['valueForDeck'];
    displayAuthorizedInterface: boolean;
}

export type Props = OwnProps & WithNamespaces;

export function WatchableDeckList({
    decks,
    onSortChange,
    sorting,
    renderDeckRowOverlay,
    displayAuthorizedInterface,
    additionalColumns = [],
    renderWatchlistIcon,
    renderMobileChains,
    t,
}: Props) {
    if (displayAuthorizedInterface) {
        additionalColumns.push({
            id: 'isOnWatchlist',
            label: t('decks-list.table.header.watchlist', {
                defaultValue: 'Watchlist',
            }),
            valueForDeck: renderWatchlistIcon,
            className: 'deck-list__toggle-field',
            listClassName: 'deck-list--with-toggle',
        });
    }

    return (
        <DeckList
            {...{
                decks,
                onSortChange,
                sorting,
                additionalColumns,
                renderDeckRowOverlay,
                renderMobileChains,
            }}
        />
    );
}

export default withNamespaces()(memo(WatchableDeckList));
