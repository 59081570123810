import SortInterface from '@/components/common/tables/SortInterface';
import { DeckSearchOrder } from '@/shared/api/ApiTypings';
import withMetadata, { TWithMetadata } from '@/shared/components/WithMetadata';

import { Deck, DeckBase } from '@/shared/typings';
import classNames from 'classnames';
import { TranslationFunction } from 'i18next';

import React, { memo } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import './DeckList.scss';

import DeckListItem, { Props as ListItemProps } from './DeckListItem';
import { AdditionalColumn, Column } from './DeckListTypes';

export type SortableColumns = DeckSearchOrder;

export interface OwnProps<TDeck extends DeckBase> {
    legacy?: boolean;
    decks: TDeck[];
    showExpansionColumn?: boolean;
    onSortChange?: (sorting: SortableColumns) => void;
    sorting?: SortableColumns | null /* if null, hide all sorting */;

    renderDeckRowOverlay?: ListItemProps<TDeck>['renderDeckRowOverlay'];
    showUsernameColumn?: boolean;
    additionalColumns?: Array<AdditionalColumn<TDeck>>;

    renderMobileRank?: ListItemProps<TDeck>['renderMobileRank'];
    renderMobileChains?: ListItemProps<TDeck>['renderMobileChains'];
}

export type Props<TDeck extends DeckBase = Deck> = OwnProps<TDeck> &
    TWithMetadata &
    WithNamespaces;

export const getDefaultColumns = (
    t: TranslationFunction,
    showUsernameColumn: boolean
): Column[] => {
    const defaultColumns: Column[] = [
        {
            id: 'name',
            sorting: 'name',
            label: t('decks-list.table.header.name', {
                defaultValue: 'Name',
            }),
        },
        {
            id: 'expansion',
            label: t('general.expansion', { defaultValue: 'Expansion' }),
        },
        {
            id: 'houses',
            label: t('decks-list.table.header.houses', {
                defaultValue: 'Houses',
            }),
        },
    ];

    if (showUsernameColumn) {
        defaultColumns.splice(2, 0, {
            id: 'username',
            label: t('decks-list.table.header.username', {
                defaultValue: 'Username',
            }),
        });
    }

    return defaultColumns;
};

export function DeckList<TDeck extends DeckBase>({
    legacy,
    decks,
    sorting,
    onSortChange,
    renderDeckRowOverlay,
    showUsernameColumn,
    additionalColumns = [],
    renderMobileRank,
    renderMobileChains,
    showExpansionColumn = true,
    metadata,
    t,
}: Props<TDeck>) {
    const renderSortInterface = (column: SortableColumns) =>
        onSortChange && (
            <SortInterface
                column={column}
                sorting={sorting}
                onSortChange={onSortChange}
                className={`deck-list__head-sort deck-list__head-sort--${column}`}
            />
        );

    const modifierClassNames = additionalColumns.reduce(
        (classStr, column) => classNames(classStr, column.listClassName),
        ''
    );

    const columns = getDefaultColumns(t, showUsernameColumn);

    const renderColumn = (column: Column, i: number) => {
        return (
            <th
                key={i}
                className={classNames(
                    'kf-table__cell',
                    `deck-list__${column.id}-field`,
                    column.className
                )}
            >
                <span className="kf-table__label-text">{column.label}</span>
                {sorting !== null &&
                    column.sorting &&
                    renderSortInterface(column.sorting)}
            </th>
        );
    };

    return (
        <table className={classNames('deck-list kf-table', modifierClassNames)}>
            <thead className="kf-table__head">
                <tr className="kf-table__row">
                    {columns.map((column, i) => {
                        const skipExpansion =
                            column.id === 'expansion' && !showExpansionColumn;
                        return skipExpansion ? null : renderColumn(column, i);
                    })}
                    {additionalColumns.map(renderColumn)}
                </tr>
            </thead>
            {metadata.isMetadataSuccess() && (
                <tbody className="kf-table__body">
                    {decks.map((deck, i) => (
                        <DeckListItem
                            key={i}
                            legacy={legacy}
                            deck={deck}
                            expansions={metadata.expansions}
                            showExpansionColumn={showExpansionColumn}
                            showUsernameColumn={showUsernameColumn}
                            renderDeckRowOverlay={renderDeckRowOverlay}
                            additionalColumns={additionalColumns}
                            renderMobileRank={renderMobileRank}
                            renderMobileChains={renderMobileChains}
                        />
                    ))}
                </tbody>
            )}
        </table>
    );
}

// interface instead of type, because type has bugged syntax highlighting
// tslint:disable callable-types
interface DeckListType {
    <TDeck extends DeckBase>(props: OwnProps<TDeck>): JSX.Element;
}

// override default export, because HOCs remove generic type from Props
export default withMetadata(
    withNamespaces()(memo(DeckList))
) as unknown as DeckListType;
