import { put, call } from 'redux-saga/effects';

import API from '@/shared/api/Api';
import {
    TournamentPlayerLeaderboardResponse,
    GeneralErrorResponse,
    GetGameStoreResponse,
    SearchGameStoresResponse,
} from '@/shared/api/ApiTypings';
import {
    normalizeTournamentPlayers,
    normalizeGameStore,
    normalizeGameStores,
} from '@/shared/sagas/Normalizers';
import {
    GetGameStoreRequestAction,
    SearchGameStoresRequestAction,
    GetGameStoreLeaderboardRequestAction,
} from '@/shared/redux/game_stores/Actions';
import GameStoresActions from '@/shared/redux/game_stores';

export function* getGameStore(api: API, action: GetGameStoreRequestAction) {
    const response: GetGameStoreResponse = yield call(
        api.getGameStore,
        action.gameStoreId
    );
    if (response.status === 200) {
        const gameStore = normalizeGameStore(response.data.data);
        yield put(GameStoresActions.getGameStoreSuccess(gameStore));
    } else {
        yield put(
            GameStoresActions.getGameStoreFailure({
                error: (response as unknown) as GeneralErrorResponse,
                gameStoreId: action.gameStoreId,
            })
        );
    }
}

export function* searchGameStores(
    api: API,
    action: SearchGameStoresRequestAction
) {
    const response: SearchGameStoresResponse = yield call(
        api.searchGameStores,
        action.searchFilters,
        action.pagination
    );
    if (response.status === 200) {
        const normalizedGameStores = normalizeGameStores(response.data.data);
        yield put(
            GameStoresActions.searchGameStoresSuccess({
                gameStores: normalizedGameStores,
                totalCount: response.data.count,
            })
        );
    } else {
        yield put(
            GameStoresActions.searchGameStoresFailure({
                error: (response as unknown) as GeneralErrorResponse,
            })
        );
    }
}

export function* fetchGameStoreLeaderboard(
    api: API,
    action: GetGameStoreLeaderboardRequestAction
) {
    const response: TournamentPlayerLeaderboardResponse = yield call(
        api.getGameStoreLeaderboard,
        action.gameStoreId,
        action.pagination,
        action.month
    );
    if (response.status === 200) {
        const players = normalizeTournamentPlayers(response.data.data);
        yield put(
            GameStoresActions.getGameStoreLeaderboardSuccess({
                players,
                totalCount: response.data.count,
                participantCount: response.data.total_participants_number,
                userRank: response.data.user_rank,
            })
        );
    } else {
        yield put(
            GameStoresActions.getGameStoreLeaderboardFailure({
                error: (response as unknown) as GeneralErrorResponse,
            })
        );
    }
}
