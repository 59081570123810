import React from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import classNames from 'classnames';
import './FiltersHeader.scss';

export interface FiltersHeaderOwnProps {
    onToggleCollapse?: () => void;
    collapseDisabled?: boolean;
    title: any;
    onReset?: () => void;
    resetDisabled?: boolean;
    expanded?: boolean;
    className?: string;
    buttonComponent?: React.ElementType;
}

export interface Props extends WithNamespaces, FiltersHeaderOwnProps {}

export function FiltersHeader(props: Props) {
    const Button = props.buttonComponent || 'button';
    return (
        <div
            className={classNames(
                'filters-header',
                props.expanded && 'filters-header--expanded',
                props.className
            )}
        >
            <Button
                onClick={props.onToggleCollapse}
                className="filters-header__toggle-btn btn-title"
                disabled={props.collapseDisabled}
            >
                {props.title}
            </Button>
            <div className="filters-header__spacer" role="presentation" />
            <button
                onClick={props.onReset}
                className="btn-clear filters-header__clear-selection"
                disabled={props.resetDisabled}
            >
                {props.t('decks-filters.btn.clear-selection', {
                    defaultValue: 'Clear All',
                })}
            </button>
        </div>
    );
}

export default withNamespaces()(FiltersHeader);
