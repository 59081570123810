import React from 'react';
import './ModalTransferClaim.scss';
import Modal from '../common/Modal';
import classNames from 'classnames';
import { ModalData } from './DeckTransferPage';
import {
    useApproveDeckTransferMutation,
    useCancelDeckTransferMutation,
    useDenyDeckTransferMutation,
} from '@/shared/queries/deck_transfers';
import {
    useApproveDeckClaimMutation,
    useCancelDeckClaimMutation,
    useDenyDeckClaimMutation,
} from '@/shared/queries/deck_claims';
import ActionSuccessful from './modals/ActionSuccessful';
import ActionFailed from './modals/ActionFailed';
import ActionInitiated from './modals/ActionInitiated';

interface IProps {
    isModalOpen: boolean;
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setModalData: React.Dispatch<React.SetStateAction<ModalData>>;
    modalData: ModalData;
}

const GHOST_GALAXY_ORG_ID = '80078ce1-a2c6-4d35-bf5d-77474a3913fc';
const KEYFORGE_GAME_ID = 'aae8065e-5414-4b33-8d2b-efa99136f29e';

export const ModalTransferClaim = ({
    isModalOpen,
    setIsModalOpen,
    modalData,
}: IProps) => {
    const modalClassName = classNames('modal-transfer-claim');
    const capitalAction =
        modalData?.action.charAt(0).toUpperCase() + modalData?.action.slice(1);
    const capitalType =
        modalData?.type.charAt(0).toUpperCase() + modalData?.type.slice(1);

    const {
        mutate: approveDeckTransfer,
        isError: isApproveDeckTransferError,
        error: approveDeckTransferError,
        isLoading: isApproveDeckTransferLoading,
        isSuccess: isApproveDeckTransferSuccess,
        reset: resetApproveDeckTransfer,
    } = useApproveDeckTransferMutation();
    const {
        mutate: denyDeckTransfer,
        isError: isDenyDeckTransferError,
        error: denyDeckTransferError,
        isLoading: isDenyDeckTransferLoading,
        isSuccess: isDenyDeckTransferSuccess,
        reset: resetDenyDeckTransfer,
    } = useDenyDeckTransferMutation();
    const {
        mutate: cancelDeckTransfer,
        isError: isCancelDeckTransferError,
        error: cancelDeckTransferError,
        isLoading: isCancelDeckTransferLoading,
        isSuccess: isCancelDeckTransferSuccess,
        reset: resetCancelDeckTransfer,
    } = useCancelDeckTransferMutation();
    const {
        mutate: approveDeckClaim,
        isError: isApproveDeckClaimError,
        error: approveDeckClaimError,
        isLoading: isApproveDeckClaimLoading,
        isSuccess: isApproveDeckClaimSuccess,
        reset: resetApproveDeckClaim,
    } = useApproveDeckClaimMutation();
    const {
        mutate: denyDeckClaim,
        isError: isDenyDeckClaimError,
        error: denyDeckClaimError,
        isLoading: isDenyDeckClaimLoading,
        isSuccess: isDenyDeckClaimSuccess,
        reset: resetDenyDeckClaim,
    } = useDenyDeckClaimMutation();
    const {
        mutate: cancelDeckClaimMutation,
        isError: isCancelDeckDenyError,
        error: cancelDeckClaimError,
        isLoading: isCancelDeckDenyLoading,
        isSuccess: isCancelDeckDenySuccess,
        reset: resetCancelDeckClaim,
    } = useCancelDeckClaimMutation();

    const resetAllMutations = () => {
        resetApproveDeckClaim();
        resetApproveDeckTransfer();
        resetCancelDeckClaim();
        resetCancelDeckTransfer();
        resetDenyDeckClaim();
        resetDenyDeckTransfer();
    };

    const handleCloseModal = () => {
        resetAllMutations();
        setIsModalOpen(false);
    };

    const directToPlaystyle = () => {
        window.location.href = `https://www.playstile.com/disputes/${GHOST_GALAXY_ORG_ID}/escalate/${KEYFORGE_GAME_ID}?external_id=${modalData.data.id}&white_label=${GHOST_GALAXY_ORG_ID}`;
    };

    const handleActionButton = () => {
        if (modalData.type === 'transfer') {
            if (modalData.action === 'approve') {
                approveDeckTransfer(modalData.data.id);
            } else if (modalData.action === 'deny') {
                denyDeckTransfer(modalData.data.id);
            } else {
                cancelDeckTransfer(modalData.data.id);
            }
        } else {
            if (modalData.action === 'approve') {
                approveDeckClaim(modalData.data.id);
            } else if (modalData.action === 'deny') {
                denyDeckClaim(modalData.data.id);
            } else if (modalData.action === 'cancel') {
                cancelDeckClaimMutation(modalData.data.id);
            } else {
                directToPlaystyle();
            }
        }
    };

    const isLoading =
        isApproveDeckClaimLoading ||
        isApproveDeckTransferLoading ||
        isCancelDeckDenyLoading ||
        isCancelDeckTransferLoading ||
        isDenyDeckClaimLoading ||
        isDenyDeckTransferLoading;

    const isSuccess =
        isApproveDeckClaimSuccess ||
        isApproveDeckTransferSuccess ||
        isCancelDeckDenySuccess ||
        isCancelDeckTransferSuccess ||
        isDenyDeckClaimSuccess ||
        isDenyDeckTransferSuccess;

    const isError =
        isApproveDeckClaimError ||
        isApproveDeckTransferError ||
        isCancelDeckDenyError ||
        isCancelDeckTransferError ||
        isDenyDeckClaimError ||
        isDenyDeckTransferError;

    const errorDetails =
        cancelDeckClaimError?.response?.data.detail ||
        approveDeckClaimError?.response?.data.detail ||
        denyDeckClaimError?.response?.data.detail ||
        cancelDeckTransferError?.response?.data.detail ||
        approveDeckTransferError?.response?.data.detail ||
        denyDeckTransferError?.response?.data.detail;

    const headerImage = <div className="modal-transfer-claim__header-image" />;

    return (
        <div className="transfer-deck-component">
            <Modal
                isOpen={isModalOpen}
                className={modalClassName}
                headerImage={headerImage}
                loading={isLoading}
            >
                {isSuccess ? (
                    <ActionSuccessful
                        action={capitalAction}
                        type={capitalType}
                        onClose={handleCloseModal}
                    />
                ) : isError ? (
                    <ActionFailed
                        errorDetails={errorDetails}
                        onClose={handleCloseModal}
                    />
                ) : (
                    <ActionInitiated
                        action={capitalAction}
                        type={capitalType}
                        onClose={handleCloseModal}
                        onProceed={handleActionButton}
                    />
                )}
            </Modal>
        </div>
    );
};
