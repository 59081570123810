import { includes } from 'ramda';
import { Store } from 'redux';

import API from '../api/Api';
import PersistedUserActions from '../redux/persisted_user';

export const flaggedPatterns = ['/users/login', '/users/logout'];
const containsFlagged = (str: string) =>
    flaggedPatterns.some((item) => includes(item, str));

export function createAuthListener(api: API, store: Store) {
    api.addMonitor((response) => {
        if (response.status === 401 && !containsFlagged(response.config.url)) {
            store.dispatch(PersistedUserActions.logout());
        }
    });
}
