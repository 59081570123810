import {
    createReducer as sauceCreateReducer,
    createActions as sauceCreateActions,
    Options,
    CreatedActions,
    Actions,
} from 'reduxsauce';
import { Action, AnyAction, Reducer } from 'redux';
import { mapObjIndexed } from 'ramda';
import { ApiErrorResponse } from 'apisauce';

// reset store, but do not reset user authorization data
export const ACTION_PURGE_STORE = 'keyforge/purge-store';

export const HelperActions = {
    purgeStore: () => ({ type: ACTION_PURGE_STORE }),
};

export type PayloadAction<Payload = {}> = Action & { payload: Payload };

export type RequestFailureAction<Data = {}> = PayloadAction<{
    error: ApiErrorResponse<Data>;
}>;

export type PayloadOnlyActionHandler<Payload> = (
    payload: Payload
) => PayloadAction<Payload>;

export type RequestFailureActionHandler<Payload> = (
    payload: Payload
) => RequestFailureAction<Payload>;

// from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/freeze
export function deepFreeze<T extends Record<string, any>>(object: T): T {
    if (Object.isFrozen(object)) {
        return object;
    }

    // Retrieve the property names defined on object
    const propNames: Array<keyof T> = Object.getOwnPropertyNames(object);

    // Freeze properties before freezing self
    for (const name of propNames) {
        const value = object[name];

        object[name] =
            value && typeof value === 'object' ? deepFreeze(value) : value;
    }

    return Object.freeze(object);
}

interface ActionTypesHandlers<TState> {
    [key: string]: Reducer;
}

const frozenReducer = (reducer: Reducer) => <TState>(
    state: TState,
    action: AnyAction
) => deepFreeze(reducer(state, action));

export type ActionsMap<TActionCreators extends Record<string, any>> = {
    [K in keyof TActionCreators]: Actions[string];
};

// Adds better types to reduxsauce createActions
export function createActions<TActionCreators extends Record<string, any>>(
    actions: ActionsMap<TActionCreators>,
    options?: Options
) {
    return sauceCreateActions(actions, options) as CreatedActions<
        Record<keyof ScreamingSnakeCasedProperties<TActionCreators>, string>,
        TActionCreators
    >;
}

// create reducers like reduxsauce, but freeze the state if it is not
// the production
export function createReducer<TState>(
    initialState: TState,
    actionTypesHandlers: ActionTypesHandlers<TState>
): Reducer {
    const freezedActionTypesHandlers =
        process.env.NODE_ENV === 'production'
            ? actionTypesHandlers
            : mapObjIndexed(frozenReducer, actionTypesHandlers);
    return sauceCreateReducer(initialState, freezedActionTypesHandlers);
}
