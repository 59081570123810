import Cookie from 'js-cookie';
import { Middleware } from 'redux';

import { PersistedUserTypes } from '@/shared/redux/persisted_user';
import { AuthorizeUserSuccessAction } from '@/shared/redux/persisted_user/Actions';

/**
 * Manages web authorization cookie
 * @type {Middleware}
 */
const authMiddleware: Middleware = (store) => (next) => (action) => {
    switch (action.type) {
        case PersistedUserTypes.AUTHORIZE_USER_SUCCESS:
            const { apiToken } = (action as AuthorizeUserSuccessAction).payload;
            Cookie.set('auth', apiToken, {
                expires: 30, // days
                secure: process.env.NODE_ENV === 'production',
            });
            break;

        case PersistedUserTypes.LOGOUT:
            Cookie.remove('auth');
            break;
    }

    return next(action);
};

export default authMiddleware;
