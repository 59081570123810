import React, { ComponentType, PureComponent } from 'react';
import { connect } from 'react-redux';
import { Persistor } from 'redux-persist';

import { RootState } from '@/shared/redux/RootState';
import { isAuthorized } from '@/shared/redux/Selectors';
import { currentAuthorizationUrl } from '@/shared/services/Auth';
import LoadingPage from '@/components/common/LoadingPage';
import PersistedUserActions from '@/shared/redux/persisted_user';
import PersistorContext from '@/redux/PersistorContext';
import { getBaseUrl } from '@/utils/url';

export interface WithAuthBaseProps {
    component: ComponentType<any>;
    componentProps: object;

    userAuthorized: boolean;
    oauthNonce: string;
    oauthState: string;
    refreshAuthorizationUrl: (redirectUrl: string) => void;
}

export class WithAuthBase extends PureComponent<WithAuthBaseProps> {
    private persistorRef: Persistor;

    public async componentDidMount() {
        if (!this.props.userAuthorized) {
            this.refreshLoginUrl();
            await this.persistorRef.flush();
            window.location.assign(
                currentAuthorizationUrl(
                    this.props.oauthNonce,
                    this.props.oauthState,
                    `${getBaseUrl()}/authorize`
                )
            );
        }
    }

    public render() {
        const { userAuthorized, component, componentProps } = this.props;
        return userAuthorized ? (
            React.createElement(component, componentProps)
        ) : (
            <PersistorContext.Consumer>
                {(persistor: Persistor) => {
                    this.persistorRef = persistor;
                    return <LoadingPage />;
                }}
            </PersistorContext.Consumer>
        );
    }

    private refreshLoginUrl() {
        if (window.location.pathname !== '/authorize') {
            this.props.refreshAuthorizationUrl(window.location.pathname);
        }
    }
}

const mapStateToProps = (state: RootState) => ({
    userAuthorized: isAuthorized(state),
    oauthNonce: state.persistedUser.auth.oauthNonce,
    oauthState: state.persistedUser.auth.oauthState,
});

const mapDispatchToProps = {
    refreshAuthorizationUrl: PersistedUserActions.refreshAuthorizationUrl,
};

export const WithAuthRequest = connect(
    mapStateToProps,
    mapDispatchToProps
)(WithAuthBase);

export default function withAuthorizationRequest<TProps>(
    CmpClass: ComponentType<TProps>
) {
    // tslint:disable-next-line max-classes-per-file
    return class extends PureComponent<TProps> {
        public static displayName = `WithAuthorizationRequest(${
            CmpClass.displayName || CmpClass.name
        })`;

        public render() {
            return (
                <WithAuthRequest
                    component={CmpClass}
                    componentProps={this.props}
                />
            );
        }
    };
}
