import './AfterPaymentPage.scss';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';
import { useGetPaymentStatus } from '@/shared/queries/payments';

const AfterPaymentPage = () => {
    const [paymentFulfilled, setPaymentFullfilled] = useState(false);
    const history = useHistory();
    const location = useLocation();
    const { status: checkoutStatus, id: orderId } = qs.parse(location.search, {
        ignoreQueryPrefix: true,
    });
    const isSuccessful = checkoutStatus == 'SUCCESS';

    const { data } = useGetPaymentStatus(
        orderId,
        Boolean(isSuccessful && !paymentFulfilled && orderId)
    );

    useEffect(() => {
        if ((data?.data as {payment_status: string})?.payment_status === 'paid') {
            setPaymentFullfilled(true);
        }
    }, [data]);

    return (
        <div className="after-payment-page__container">
            <div className="after-payment-page__content">
                <div className="after-payment-page__header keyforge-heading-1">
                    {isSuccessful
                        ? 'Thank you for purchase!'
                        : 'Purchase cancelled'}
                </div>
                <div className="after-payment-page__image-wrapper">
                    <img
                        src={
                            isSuccessful
                                ? 'https://keyforging.com/wp-content/uploads/2023/05/Screenshot-2023-05-17-at-4.27.50-PM.png'
                                : 'https://keyforging.com/wp-content/uploads/2023/03/Screenshot-2023-03-22-at-3.53.26-PM.png'
                        }
                        className="after-payment-page__image"
                    />
                </div>
                <div className="after-payment-page__description">
                    {!isSuccessful ? (
                        <button
                            className="after-payment-page__return-button btn btn-secondary"
                            onClick={() => history.push('/profile')}
                        >
                            Go back to profile
                        </button>
                    ) : paymentFulfilled ? (
                        <>
                            <div className="after-payment-page__header keyforge-heading-2">
                                Payment confirmed!
                            </div>
                            <button
                                className="after-payment-page__return-button btn btn-secondary"
                                onClick={() => history.push('/profile')}
                            >
                                Go back to profile
                            </button>
                        </>
                    ) : (
                        <>
                            <div className="spinner" />
                            <div className="after-payment-page__description-text">
                                We are waiting for the confirmation...
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AfterPaymentPage;
