import './HomeLead.scss';
import DiscoveredDecks from '@/components/home/DiscoveredDecks';
import classNames from 'classnames';

import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '@/shared/redux/RootState';
import { isAuthorized } from '@/shared/redux/Selectors';
import logo from '@/images/common/keyforge-master-vault-logo-full.png';

export interface Props {
    deckCount: number;
}

export const HomeLead = ({ deckCount }: Props) => {
    return (
        <div className="home-lead">
            <div className="home-lead__logo">
                <img src={logo} alt="Keyforge Master Vault logo" />
            </div>
            <div
                className={classNames(
                    'home-lead__counter',
                    !deckCount && 'home-lead__counter--hidden'
                )}
            >
                <DiscoveredDecks deckCount={deckCount} />
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    isUserAuthorized: isAuthorized(state),
});

export default connect(mapStateToProps)(HomeLead);
