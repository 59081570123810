import './DeckStats.scss';

import React, { PureComponent, SyntheticEvent } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { repeat, assoc, equals } from 'ramda';
import classNames from 'classnames';

import NumberInput from '@/components/common/inputs/NumberInput';
import ChainsIcon from '@/images/common/chains-icon.png';
import PowerIcon from '@/images/common/power-level-icon.png';
import { Deck } from '@/shared/typings';
import { CasualScores } from '@/shared/api/ApiTypings';
import { createTimeout } from '@/shared/services/Utils';

export interface Props extends WithNamespaces {
    deck: Deck;
    onCasualScoresUpdate: (casualScores: CasualScores) => void;
}

interface State {
    casualWins: string;
    casualLosses: string;
}

export class DeckStats extends PureComponent<Props, State> {
    public state: State = {
        casualWins: String(this.props.deck.casualWins),
        casualLosses: String(this.props.deck.casualLosses),
    };

    // Holds user input promise `resolve` method
    private resolveUserInput: (value: false) => void;

    private userInputPromise: Promise<false> = new Promise(
        (resolve) => (this.resolveUserInput = resolve)
    );

    public render() {
        const { deck, t } = this.props;
        const numberInputMask = repeat(/\d/, 5);
        return (
            <div
                className={classNames('deck-stats', {
                    'deck-stats--my-deck': deck.isMyDeck,
                })}
            >
                <div className="deck-stats__tournaments deck-stats__section">
                    <h2 className="deck-stats__section-title keyforge-heading-2">
                        {t('single-deck.header.organized-play', {
                            defaultValue: 'Organized Play',
                        })}
                    </h2>
                    <div className="deck-stats__tournaments-stat">
                        <img
                            src={PowerIcon}
                            alt={t('single-deck.hint.power', {
                                defaultValue: 'Power',
                            })}
                            className="deck-stats__stat-image"
                        />
                        <span className="deck-stats__stat-label">
                            {t('single-deck.hint.power', {
                                defaultValue: 'Power',
                            })}
                        </span>
                        <span className="deck-stats__stat-value deck-stats__deck-power">
                            {deck.powerLevel}
                        </span>
                    </div>
                    <div className="deck-stats__tournaments-stat">
                        <img
                            src={ChainsIcon}
                            alt={t('single-deck.hint.chains', {
                                defaultValue: 'Chains',
                            })}
                            className="deck-stats__stat-image"
                        />
                        <span className="deck-stats__stat-label">
                            {t('single-deck.hint.chains', {
                                defaultValue: 'Chains',
                            })}
                        </span>
                        <span className="deck-stats__stat-value deck-stats__deck-chains">
                            {deck.chains}
                        </span>
                    </div>
                    <div className="deck-stats__tournaments-stat-row">
                        <div className="deck-stats__tournaments-stat">
                            <span className="deck-stats__stat-label">
                                {t('single-deck.hint.wins', {
                                    defaultValue: 'Wins',
                                })}
                            </span>
                            <span className="deck-stats__stat-value deck-stats__deck-wins">
                                {deck.wins}
                            </span>
                        </div>
                        <div className="deck-stats__tournaments-stat">
                            <span className="deck-stats__stat-label">
                                {t('single-deck.hint.losses', {
                                    defaultValue: 'Losses',
                                })}
                            </span>
                            <span className="deck-stats__stat-value deck-stats__deck-losses">
                                {deck.losses}
                            </span>
                        </div>
                        <div className="deck-stats__tournaments-stat">
                            <span className="deck-stats__stat-label">
                                {t('single-deck.hint.total', {
                                    defaultValue: 'Total',
                                })}
                            </span>
                            <span className="deck-stats__stat-value deck-stats__total-stats">
                                {deck.wins + deck.losses}
                            </span>
                        </div>
                    </div>
                </div>
                {(deck.isMyDeck || deck.isDeckOwned) && (
                    <div className="deck-stats__casual deck-stats__section">
                        <h2 className="deck-stats__section-title keyforge-heading-2">
                            {t('single-deck.header.casual-play', {
                                defaultValue: 'Casual Play',
                            })}
                        </h2>
                        <div className="deck-stats__casual-table">
                            <div className="deck-stats__casual-stat">
                                <label
                                    htmlFor="deck-casual-input--wins"
                                    className="deck-stats__casual-label"
                                >
                                    {t('single-deck.hint.wins', {
                                        defaultValue: 'Wins',
                                    })}
                                </label>
                                <div className="deck-stats__casual-value deck-stats__deck-casual-wins">
                                    <NumberInput
                                        id="deck-casual-input--wins"
                                        className="deck-stats__casual-input"
                                        value={this.state.casualWins}
                                        mask={numberInputMask}
                                        onChange={this.updateInputScore(
                                            'casualWins'
                                        )}
                                        onPlusClick={this.updateButtonScore(
                                            'casualWins',
                                            1
                                        )}
                                        onMinusClick={this.updateButtonScore(
                                            'casualWins',
                                            -1
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="deck-stats__casual-stat deck-stats__deck-casual-losses">
                                <label
                                    htmlFor="deck-casual-input--losses"
                                    className="deck-stats__casual-label"
                                >
                                    {t('single-deck.hint.losses', {
                                        defaultValue: 'Losses',
                                    })}
                                </label>
                                <div className="deck-stats__casual-value">
                                    <NumberInput
                                        id="deck-casual-input--losses"
                                        className="deck-stats__casual-input"
                                        value={this.state.casualLosses}
                                        mask={numberInputMask}
                                        onChange={this.updateInputScore(
                                            'casualLosses'
                                        )}
                                        onPlusClick={this.updateButtonScore(
                                            'casualLosses',
                                            1
                                        )}
                                        onMinusClick={this.updateButtonScore(
                                            'casualLosses',
                                            -1
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="deck-stats__casual-stat">
                                <div className="deck-stats__casual-label">
                                    {t('single-deck.hint.total', {
                                        defaultValue: 'Total',
                                    })}
                                </div>
                                <div className="deck-stats__casual-value deck-stats__stat-value">
                                    {Number(this.state.casualWins) +
                                        Number(this.state.casualLosses)}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }

    private updateInputScore =
        (type: 'casualWins' | 'casualLosses') =>
        (e: SyntheticEvent<HTMLInputElement>) => {
            this.updateCasualScores(type, e.currentTarget.value);
        };

    private updateButtonScore =
        (type: 'casualWins' | 'casualLosses', value: number) =>
        (e: SyntheticEvent<HTMLButtonElement>) => {
            const newValue = Number(this.state[type]) + value;
            this.updateCasualScores(type, String(newValue));
        };

    private updateCasualScores = (
        type: 'casualWins' | 'casualLosses',
        value: string
    ) => {
        if (value !== '') {
            this.resolveUserInput(false);
        }

        // Warning: it also allows empty strings
        if (Number(value) >= 0) {
            const prevState = this.state;
            this.setState(assoc(type, value), async () => {
                if (value === '') {
                    return;
                }

                this.userInputPromise = new Promise(
                    (resolve) => (this.resolveUserInput = resolve)
                );

                const shouldSendRequest = await Promise.race([
                    createTimeout(500, true),
                    this.userInputPromise,
                ]);

                if (shouldSendRequest && !equals(prevState, this.state)) {
                    this.props.onCasualScoresUpdate({
                        wins: Number(this.state.casualWins),
                        losses: Number(this.state.casualLosses),
                    });
                }
            });
        }
    };
}

export default withNamespaces()(DeckStats);
