// styles are in ./TransactionList.scss

import React, { memo } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';

import { Transaction } from '@/shared/typings';

export interface Props extends WithNamespaces {
    transaction: Transaction;
}

function formatChangedShards(shards: number) {
    if (shards > 0) {
        return `+${shards}`;
    }
    if (shards < 0) {
        return (
            <span className="transaction-list__shards-changed--negative">
                {shards}
            </span>
        );
    }
    return shards;
}

export function AembershardsLogListItem({ transaction, t }: Props) {
    const changedShards = formatChangedShards(transaction.changedShards);
    return (
        <tr className="kf-table__row">
            <td className="kf-table__cell transaction-list__date">
                {t('transactions.table.value.date', {
                    defaultValue: '{{date, shortDate}}',
                    date: new Date(transaction.timestampMs),
                })}
            </td>
            <td className="kf-table__cell transaction-list__description">
                {transaction.description}
            </td>
            <td className="kf-table__cell transaction-list__shards transaction-list__shards-changed">
                {changedShards}
            </td>
            <td className="kf-table__cell transaction-list__shards transaction-list__shards-total">
                {transaction.balance}
            </td>
            <td className="transaction-list__mobile-shards">
                <div>
                    {t('transactions.table.header.shards-changed', {
                        defaultValue: 'Change',
                    })}{' '}
                    <strong>{changedShards}</strong>
                </div>
                <div>
                    {t('transactions.table.header.shards-balance', {
                        defaultValue: 'Balance',
                    })}{' '}
                    <strong>{transaction.balance}</strong>
                </div>
            </td>
        </tr>
    );
}

export default withNamespaces()(memo(AembershardsLogListItem));
