import './LanguageSelect.scss';

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import Cookie from 'js-cookie';

import { HelperActions } from '@/shared/redux/ReduxHelpers';
import LanguageSelect from './LanguageSelect';
import {
    LanguageLabels,
    extractLocaleCode,
} from '@/shared/services/lang/Locales';

interface OwnProps {
    className?: string;
}

interface DispatchProps {
    purgeStore: typeof HelperActions['purgeStore'];
}

export type Props = OwnProps &
    DispatchProps &
    RouteComponentProps &
    WithNamespaces;

export class LanguageSelectContainer extends PureComponent<Props> {
    public render() {
        return (
            <LanguageSelect
                className={this.props.className}
                languageList={LanguageLabels}
                value={this.extractCurrentLanguage()}
                onChange={(locale) => this.changeUserLocale(locale)}
            />
        );
    }

    private async changeUserLocale(locale: string) {
        // delete old, cached state that can have old language data inside
        this.props.purgeStore();
        // set language cookie for SSR node server, 1 year lifespan
        Cookie.set('lang', locale, { expires: 365 });
        // reload entire webpage
        this.props.history.go(0);
    }

    private extractCurrentLanguage(): string {
        const storedLocale = Cookie.get('lang') || this.props.lng;
        return extractLocaleCode(storedLocale);
    }
}

const mapDispatchToProps: DispatchProps = {
    purgeStore: HelperActions.purgeStore,
};

export default withRouter(
    withNamespaces()(connect(null, mapDispatchToProps)(LanguageSelectContainer))
);
