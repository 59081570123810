import { ReactNode, PureComponent } from 'react';

interface Props {
    children: ReactNode;
    fallback?: ReactNode;
}

interface State {
    didMount: boolean;
}

/**
 * Renders children only in client side, skips server-side rendering.
 */
export default class ClientSideOnly extends PureComponent<Props, State> {
    public static defaultProps: Partial<Props> = {
        fallback: null,
    };

    public state: State = {
        didMount: false,
    };

    // this method does not run in SSR, so it's safe
    public componentDidMount() {
        this.setState({ didMount: true });
    }

    public render() {
        return this.state.didMount ? this.props.children : this.props.fallback;
    }
}
