import './DeckTransferPage.scss';
import React, { useState } from 'react';
import SectionLead from '../common/SectionLead';
import { useDeckTransfers } from '@/shared/queries/deck_transfers';
import { PaginatedListHeader } from '../decks/my_decks/PaginatedListHeader';
import PaginatedTable from '../common/tables/PaginatedTable';
import { TransferClaimList } from './TransferClaimList';
import { ModalTransferClaim } from './ModalTransferClaim';
import { Link } from 'react-router-dom';
import Breadcrumbs from '../common/Breadcrumbs';

const PageSize = 10;

export interface ModalData {
    type: string;
    action: string;
    data: {
        id: string;
        deckName: string;
    };
}

export const DeckTransferPage = () => {
    const [page, setPage] = useState<number>(1);
    const [paginationPage, setPaginationPage] = useState<number>(0);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [showInactiveTransfers, setShowInactiveTransfers] = useState<boolean>(
        true
    );
    const [modalData, setModalData] = useState<ModalData>();

    const handleCheckboxChange = () => {
        setShowInactiveTransfers(!showInactiveTransfers);
    };

    const {
        data: transferData,
        isLoading: isTransferLoading,
    } = useDeckTransfers('', '', '', PageSize, page);

    const onPageChange = (chosenPage: number) => {
        setPage(chosenPage + 1);
        setPaginationPage(chosenPage);
    };

    const decksPageCount = () => Math.ceil(transferData.count / PageSize);

    return (
        <div className="deck-transfers-page">
            <SectionLead>Deck Transfers</SectionLead>
            <div className="page-content-layout">
                <Breadcrumbs />
                {transferData?.data.length > 0 ? (
                    <PaginatedTable
                        header={
                            <div className="deck-transfers-page__header-container">
                                <PaginatedListHeader
                                    className={'deck-transfers-page'}
                                    decksCount={transferData.count}
                                />
                                <div className="deck-transfers-page__checkbox-container">
                                    <p className="deck-transfers-page__checkbox-text">
                                        Show inactive Transfers
                                    </p>
                                    <label className="deck-transfers-page__checkbox">
                                        <input
                                            className="deck-transfers-page__checkbox__input"
                                            type="checkbox"
                                            checked={showInactiveTransfers}
                                            onChange={handleCheckboxChange}
                                        />
                                        <span className="deck-transfers-page__checkbox__box">
                                            <span className="deck-transfers-page__checkbox__inner-box" />
                                        </span>
                                    </label>
                                </div>
                            </div>
                        }
                        currentPage={paginationPage}
                        pageCount={decksPageCount()}
                        onPageChange={onPageChange}
                        isLoading={isTransferLoading}
                        showTopPagination={true}
                    >
                        <TransferClaimList
                            data={transferData.data}
                            setIsModalOpen={setIsModalOpen}
                            setModalData={setModalData}
                            showInactiveOperations={showInactiveTransfers}
                            exchangeType={'transfer'}
                            sorting={null}
                            onSortChange={null}
                        />
                    </PaginatedTable>
                ) : (
                    <div className="my-decks-page__no-decks">
                        <p className="my-decks-page__no-decks-description">
                            Your transfer deck list is currently empty
                        </p>
                    </div>
                )}
            </div>
            <h2 className="deck-transfers-page__claims-link-content">
                <Link to="/deck-claims">
                    Click here to view your Deck Claims
                </Link>
            </h2>
            <ModalTransferClaim
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                setModalData={setModalData}
                modalData={modalData}
            />
        </div>
    );
};
