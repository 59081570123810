import React from 'react';
import { User } from '@/shared/api/ApiTypings';
import FormSelectBox from '@/components/common/inputs/FormSelectBox';
import { Friend } from '@/components/friends/Friend';
import './FriendsPage.scss';
import SectionLead from '@/components/common/SectionLead';
import closeIcon from '@/images/common/close-icon.svg';
import {
    useFriends,
    useFriendsSearch,
    useAddFriend,
    useRemoveFriend,
} from '@/shared/queries/friends';
import Breadcrumbs from '../common/Breadcrumbs';

export const FriendsPage = () => {
    const [searchText, setSearchText] = React.useState<string>('');
    const [disabledFriends, setDisabledFriends] = React.useState<string[]>([]);
    const friendsQuery = useFriends();
    const searchQuery = useFriendsSearch(searchText);
    const addFriendMutation = useAddFriend();

    const compareByUsernameLength = (a, b) => a.label.length - b.label.length;

    const removeFriendMutation = useRemoveFriend({
        onMutate(friendId) {
            setDisabledFriends([...disabledFriends, friendId]);
        },
        onSettled(data, err, friendId) {
            setDisabledFriends((friends) =>
                friends.filter((f) => f !== friendId)
            );
        },
    });

    const friendsSearchItems = searchQuery.data
        ? searchQuery.data.data
              .filter(
                  (user) =>
                      !friendsQuery.data.invited_friends.some(
                          (u) => u.id === user.id
                      )
              )
              .map((user) => ({
                  label: user.username,
                  value: user.id,
              }))
              .sort(compareByUsernameLength)
        : [];

    return (
        <div className="friends-page friends-page__gap-lg">
            <SectionLead>Friends</SectionLead>
            <div className="page-content-layout">
                <Breadcrumbs />
                <h2 className="keyforge-heading-2 friends-page__gap">
                    Add Friends
                </h2>
                <div className="friends-page__description">
                    Add friends to share your registered decks with. Friends can
                    then use your decks to create alliances or compete with in
                    tournaments.
                </div>
                <div>
                    <FormSelectBox
                        placeholder={
                            'Search friends to share your decks with...'
                        }
                        value={null}
                        items={friendsSearchItems}
                        onChange={(userId) => {
                            setSearchText('');
                            addFriendMutation.mutate(userId);
                        }}
                        query={searchText}
                        filterable={true}
                        loading={searchQuery.isFetching}
                        onQueryChange={setSearchText}
                        className="friends-page__gap-lg"
                    />
                </div>

                {friendsQuery.isError ? (
                    <div className="friends-page__gap-lg">
                        {friendsQuery.error.message}
                    </div>
                ) : friendsQuery.isLoading ? (
                    <div className="friends-page__gap-lg">
                        <div className="spinner" />
                    </div>
                ) : friendsQuery.data ? (
                    <div>
                        {!!friendsQuery.data.invited_friends.length && (
                            <ul className="friends-page__list friends-page__gap-lg">
                                {[...friendsQuery.data.invited_friends]
                                    .reverse()
                                    .map((friend: any) => (
                                        <li key={friend.id}>
                                            <Friend
                                                user={friend}
                                                suffix={
                                                    <button
                                                        onClick={() => {
                                                            removeFriendMutation.mutate(
                                                                friend.id
                                                            );
                                                        }}
                                                        type="button"
                                                        disabled={disabledFriends.includes(
                                                            friend.id
                                                        )}
                                                        className="friends-page__remove-btn"
                                                        title="Stop sharing decks with this user"
                                                    >
                                                        <img
                                                            src={closeIcon}
                                                            alt="Stop sharing decks with this user"
                                                            width={14}
                                                            height={14}
                                                        />
                                                    </button>
                                                }
                                            />
                                        </li>
                                    ))}
                            </ul>
                        )}
                        {!!friendsQuery.data.invited_by.length && (
                            <>
                                <h2 className="friends-page__gap">
                                    Shared decks with me
                                </h2>
                                <ul className="friends-page__list">
                                    {[...friendsQuery.data.invited_by]
                                        .reverse()
                                        .map((friend: User) => (
                                            <li key={friend.id}>
                                                <Friend user={friend} />
                                            </li>
                                        ))}
                                </ul>
                            </>
                        )}
                    </div>
                ) : null}
            </div>
        </div>
    );
};
