import './DeckPage.scss';

import React, { Component, SyntheticEvent } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';

import { DecksActions } from '@/shared/redux/decks';
import TextInput from '@/components/common/inputs/TextInput';
import { Deck, Expansion } from '@/shared/typings';
import { getTranslatedExpansionName } from '@/shared/services/lang/translationHelpers';
import DeckDetails from './DeckDetails';
import DeckStats from './DeckStats';
import NoteList from './notes/NoteList';
import TextAdaptedToContainer from '@/components/common/TextAdaptedToContainer';
import { CasualScores } from '@/shared/api/ApiTypings';
import { DeckCardTable } from './DeckCardTable';
import { NonDeckCardTable } from './NonDeckCardTable';
import Breadcrumbs from '../common/Breadcrumbs';
import { TransferDeckComponent } from './TransferDeckComponent';
import { Link } from 'react-router-dom';
import OrderDeckCopyComponent from './OrderDeckCopyComponent';
import { AccoladesList } from './AccoladesList';

export interface Props extends WithNamespaces {
    deck: Deck;
    isLegacy: boolean;
    isUserAuthorized: boolean;
    onNoteSave: (typeof DecksActions)['addNoteRequest'];
    handleDeckDelete: () => void;
    handleDeckFavoriteToggle: () => void;
    handleDeckWatchlistToggle: () => void;
    handleDeckCasualScoresUpdate: (casualScores: CasualScores) => void;
    expansions: Expansion[];
}

export interface State {
    noteText: string;
    isTransfered: boolean;
}

export class DeckPage extends Component<Props, State> {
    public state: State = {
        isTransfered: false,
        noteText: '',
    };

    public render() {
        const {
            deck,
            isLegacy,
            expansions,
            isUserAuthorized,
            handleDeckCasualScoresUpdate,
            handleDeckDelete,
            handleDeckFavoriteToggle,
            handleDeckWatchlistToggle,
            t,
        } = this.props;
        const { noteText } = this.state;
        const deckExpansion = expansions.find(
            (exp) => exp.id === deck.expansion
        );
        const expansionName = getTranslatedExpansionName(
            deckExpansion?.id,
            expansions,
            t
        );
        const isDeckOwner = deck.isMyDeck || deck.isDeckOwned;

        return (
            <div className="deck-page">
                <div className="deck-page__content">
                    <div className="deck-page__deck">
                        <Breadcrumbs />
                        <div className="deck-page__deck-details">
                            <DeckDetails
                                deck={deck}
                                expansionName={expansionName}
                                isUserAuthorized={isUserAuthorized}
                                handleDeckDelete={handleDeckDelete}
                                handleDeckFavoriteToggle={
                                    handleDeckFavoriteToggle
                                }
                                handleDeckWatchlistToggle={
                                    handleDeckWatchlistToggle
                                }
                            />
                        </div>
                    </div>
                    <AccoladesList t={t} deck={deck} />
                    {isUserAuthorized && isDeckOwner && (
                        <OrderDeckCopyComponent t={t} deck={deck} />
                    )}
                    <div className="deck-page__cards">
                        <div className="deck-page__cards-header">
                            <h2 className="deck-page__cards-title keyforge-heading-2">
                                {t('single-deck.header.cards', {
                                    defaultValue: 'Cards in this deck',
                                })}
                            </h2>
                        </div>
                        <div className="deck-page__cards-table">
                            <DeckCardTable
                                houses={deck.houses}
                                cards={deck.cards}
                                legacyCardsIds={deck.setEraCards?.Legacy}
                                anomalyCardsIds={deck.setEraCards?.Anomaly}
                            />
                        </div>
                    </div>

                    {deck.nonDeckCards && (
                        <div className="deck-page__cards">
                            <div className="deck-page__cards-header">
                                <h2 className="deck-page__cards-title keyforge-heading-2">
                                    {t('single-deck.header.non-deck-dards', {
                                        defaultValue: 'Non deck cards',
                                    })}
                                </h2>
                            </div>
                            <div className="deck-page__cards-table">
                                <NonDeckCardTable
                                    cards={deck.nonDeckCards}
                                    legacyCardsIds={deck.setEraCards.Legacy}
                                    anomalyCardsIds={deck.setEraCards.Anomaly}
                                />
                            </div>
                        </div>
                    )}
                    <div className="deck-page__deck-stats">
                        <DeckStats
                            deck={deck}
                            onCasualScoresUpdate={
                                handleDeckCasualScoresUpdate
                            }
                        />
                    </div>
                    {isUserAuthorized && isDeckOwner && (
                        <div className="deck-page__notes">
                            <h2 className="deck-page__notes-title keyforge-heading-2">
                                {t('single-deck.header.my-notes', {
                                    defaultValue: 'My Notes',
                                })}
                            </h2>
                            <div className="deck-page__notes-content">
                                <TextInput
                                    asTextArea={true}
                                    value={noteText}
                                    onChange={this.handleNoteChange}
                                    placeholder={t('note.input.placeholder', {
                                        defaultValue: 'Add note',
                                    })}
                                    className="deck-page__notes-input"
                                />
                                <button
                                    className="deck-page__notes-save-btn btn-small-secondary"
                                    onClick={this.handleNoteSave}
                                    disabled={noteText.trim().length === 0}
                                >
                                    <span className="btn-content">Save</span>
                                </button>
                                {deck.notes && (
                                    <NoteList
                                        notes={deck.notes}
                                        deck={deck}
                                        isLegacy={isLegacy}
                                    />
                                )}
                            </div>
                            {deck.isPendingTransfer || this.state.isTransfered ? (
                                <div
                                    className="deck-page__transfer keyforge-heading-3"
                                    style={{
                                        padding: '1rem 2rem',
                                        textAlign: 'center',
                                    }}
                                >
                                    There is a pending transfer of ownership for
                                    this deck. See the{' '}
                                    <Link
                                        className="deck-page__transfers-link"
                                        to="/deck-transfers"
                                    >
                                        Transfers Page
                                    </Link>{' '}
                                    for details.
                                </div>
                            ) : (
                                <TransferDeckComponent
                                    deckToTransfer={deck}
                                    onSuccess={() =>
                                        this.setState({ isTransfered: true })
                                    }
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    private handleNoteChange = (e: SyntheticEvent<HTMLTextAreaElement>) =>
        this.setState({ noteText: e.currentTarget.value });

    private handleNoteSave = () => {
        this.props.onNoteSave(this.props.deck.id, this.state.noteText);
        this.setState({ noteText: '' });
    };
}

export default withNamespaces()(DeckPage);
