import { mergeDeepRight } from 'ramda';

import { createReducer } from '../ReduxHelpers';
import { InitialState, StateType } from './State';
import { successReducerIn, pendingReducerIn, errorReducerIn } from '../Async';
import {
    Types,
    Creators,
    GetGameStoreSuccessAction,
    GetGameStoreFailureAction,
    SearchGameStoresSuccessAction,
    GetGameStoreLeaderboardSuccessAction,
} from './Actions';

export const GameStoresTypes = Types;
export default Creators;

export const reducer = createReducer(InitialState, {
    [Types.GET_GAME_STORE_REQUEST]: pendingReducerIn('getGameStore'),
    [Types.GET_GAME_STORE_SUCCESS]: successReducerIn(
        'getGameStore',
        mapGetGameStoreSuccess
    ),
    [Types.GET_GAME_STORE_FAILURE]: errorReducerIn(
        'getGameStore',
        mapGetGameStoreFailure
    ),

    [Types.SEARCH_GAME_STORES_REQUEST]: pendingReducerIn('searchGameStores'),
    [Types.SEARCH_GAME_STORES_SUCCESS]: successReducerIn(
        'searchGameStores',
        mapSearchGameStoresSuccess
    ),
    [Types.SEARCH_GAME_STORES_FAILURE]: errorReducerIn('searchGameStores'),

    [Types.GET_GAME_STORE_LEADERBOARD_REQUEST]: pendingReducerIn(
        'getGameStoreLeaderboard'
    ),
    [Types.GET_GAME_STORE_LEADERBOARD_SUCCESS]: successReducerIn(
        'getGameStoreLeaderboard',
        getGameStoreLeaderboardSuccess
    ),
    [Types.GET_GAME_STORE_LEADERBOARD_FAILURE]: errorReducerIn(
        'getGameStoreLeaderboard'
    ),
});

function mapGetGameStoreSuccess(
    state: StateType['getGameStore'],
    action: GetGameStoreSuccessAction
): Partial<StateType['getGameStore']> {
    return { gameStore: action.payload };
}

function mapGetGameStoreFailure(
    state: StateType['getGameStore'],
    action: GetGameStoreFailureAction
): Partial<StateType['getGameStore']> {
    return {
        gameStore: mergeDeepRight(InitialState.getGameStore.gameStore, {
            id: action.payload.gameStoreId,
        }),
    };
}

function mapSearchGameStoresSuccess(
    state: StateType['searchGameStores'],
    action: SearchGameStoresSuccessAction
): Partial<StateType['searchGameStores']> {
    return {
        list: action.payload.gameStores,
        totalCount: action.payload.totalCount,
    };
}

function getGameStoreLeaderboardSuccess(
    state: StateType['getGameStoreLeaderboard'],
    action: GetGameStoreLeaderboardSuccessAction
): Partial<StateType['getGameStoreLeaderboard']> {
    return {
        list: action.payload.players,
        totalCount: action.payload.totalCount,
        participantCount: action.payload.participantCount,
        userRank: action.payload.userRank,
    };
}
