// styles are in ./DeckList.scss

import { getDefaultColumns } from '@/components/common/deck_lists/DeckList';
import React, { Fragment, memo, ReactElement, ReactNode } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { Deck, DeckBase, Expansion, House } from '@/shared/typings';
import {
    getTranslatedExpansionName,
    getTranslatedHouseName,
} from '@/shared/services/lang/translationHelpers';
import { AdditionalColumn } from './DeckListTypes';

interface OwnProps<TDeck extends DeckBase> {
    legacy;
    deck: any;
    showExpansionColumn?: boolean;
    showUsernameColumn?: boolean;
    expansions: Expansion[];
    renderDeckRowOverlay: (deck: TDeck) => ReactElement<HTMLTableElement>;
    additionalColumns: Array<AdditionalColumn<TDeck>>;
    renderMobileRank?: (deck: TDeck) => ReactNode;
    renderMobileChains?: (deck: TDeck) => ReactNode;
}

export type Props<TDeck extends DeckBase = Deck> = OwnProps<TDeck> &
    WithNamespaces;

export function DeckListItem<TDeck extends DeckBase>({
    legacy,
    deck,
    expansions,
    showExpansionColumn = true,
    showUsernameColumn = false,
    renderDeckRowOverlay,
    additionalColumns = [],
    renderMobileRank,
    t,
}: Props<TDeck>) {
    const expansionName = (id: number) =>
        getTranslatedExpansionName(id, expansions, t);
    const renderedOverlay = renderDeckRowOverlay && renderDeckRowOverlay(deck);
    const columns = getDefaultColumns(t, showUsernameColumn);

    return (
        <tr className="kf-table__row deck-list__data-row">
            {columns.map((column, i) => {
                const skipExpansion =
                    column.id === 'expansion' && !showExpansionColumn;
                return (
                    <td
                        key={i}
                        className={classNames(
                            `kf-table__cell deck-list__${column.id}-field`
                        )}
                    >
                        {column.id === 'name' ? (
                            <Fragment>
                                <div className="deck-list__deck-name">
                                    <Link
                                        to={{
                                            pathname: `/deck-details/${deck.id}`,
                                            state: {
                                                isLegacy: legacy,
                                            },
                                        }}
                                    >
                                        {renderMobileRank && (
                                            <span className="deck-list__deck-name-rank">
                                                {renderMobileRank(deck)}
                                            </span>
                                        )}
                                        {deck.name}
                                    </Link>
                                </div>
                                <div
                                    className={classNames(
                                        'deck-list__deck-expansion',
                                        showExpansionColumn &&
                                            'deck-list__deck-expansion--only-mobile'
                                    )}
                                >
                                    {t('decks-list.table.hint.expansion', {
                                        defaultValue:
                                            'Expansion: {{expansionsCount}}',
                                        expansionsCount: expansionName(
                                            deck.expansion
                                        ),
                                    })}
                                </div>
                            </Fragment>
                        ) : column.id === 'houses' ? (
                            <ul className="deck-list__houses-list">
                                {deck.houses.map((house: House) => (
                                    <li
                                        key={house.id}
                                        title={getTranslatedHouseName(house, t)}
                                        className="deck-list__house"
                                    >
                                        <img
                                            src={house.image}
                                            alt={getTranslatedHouseName(
                                                house,
                                                t
                                            )}
                                            className="deck-list__house-image"
                                        />
                                    </li>
                                ))}
                            </ul>
                        ) : column.id === 'expansion' ? (
                            skipExpansion ? null : (
                                <span className="deck-list__expansion-field-text">
                                    {expansionName(deck.expansion)}
                                </span>
                            )
                        ) : column.id === 'username' ? (
                            <span className="deck-list__username-field-text">
                                {deck.owner.username}
                            </span>
                        ) : column.id in deck ? (
                            // @ts-ignore newer TS version will not complain here
                            deck[column.id]
                        ) : null}
                    </td>
                );
            })}
            {additionalColumns.map(({ label, valueForDeck, className }) => (
                <td
                    key={label}
                    className={classNames('kf-table__cell', className)}
                >
                    {valueForDeck(deck)}
                </td>
            ))}
            {renderedOverlay && (
                <td className="deck-list__data-row-overlay">
                    {renderedOverlay}
                </td>
            )}
        </tr>
    );
}

// interface instead of type, because type has bugged syntax highlighting
// tslint:disable callable-types
interface DeckListItemType {
    <TDeck extends DeckBase>(props: OwnProps<TDeck>): JSX.Element;
}

// override default export, because HOCs remove generic type from Props
export default withNamespaces()(memo(DeckListItem)) as DeckListItemType;
