import { path, mergeDeepRight } from 'ramda';

import { randomString, unsecureGetRandomValues } from '../../services/Auth';
import { createReducer } from '../ReduxHelpers';
import { InitialState, StateType } from './State';
import { pendingReducerIn, successReducerIn, errorReducerIn } from '../Async';
import {
    Types,
    Creators,
    RefreshUrlAction,
    UpdateOauthMetaAction,
    AuthorizeUserSuccessAction,
    SetLocaleAction,
} from './Actions';

export const PersistedUserTypes = Types;
export default Creators;

export const reducer = createReducer(InitialState, {
    [Types.REFRESH_AUTHORIZATION_URL]: refreshAuthorizationUrl,
    [Types.LOGOUT]: logout,
    [Types.UPDATE_OAUTH_META]: updateOauthMeta,
    [Types.SET_CURRENT_LOCALE]: setCurrentLocale,

    [Types.AUTHORIZE_USER_REQUEST]: pendingReducerIn('auth'),
    [Types.AUTHORIZE_USER_SUCCESS]: successReducerIn(
        'auth',
        authorizeUserSuccess
    ),
    [Types.AUTHORIZE_USER_FAILURE]: errorReducerIn('auth'),
});

/* ----------------- reducers --------------------- */

const getRandomValues =
    typeof window === 'object' && path(['crypto', 'getRandomValues'], window)
        ? window.crypto.getRandomValues.bind(window.crypto)
        : unsecureGetRandomValues;
const generateNonce = randomString(getRandomValues);

function setCurrentLocale(
    state: StateType,
    action: SetLocaleAction
): StateType {
    return { ...state, currentLocale: action.locale };
}

function refreshAuthorizationUrl(
    state: StateType,
    { redirectUrl }: RefreshUrlAction
): StateType {
    return mergeDeepRight(state, {
        auth: {
            oauthState: redirectUrl || '/',
            oauthNonce: generateNonce(8),
        },
    });
}

function updateOauthMeta(
    state: StateType,
    { oauth }: UpdateOauthMetaAction
): StateType {
    return mergeDeepRight(state, {
        auth: {
            oauthNonce: oauth.oauthNonce || state.auth.oauthNonce,
            oauthState: oauth.oauthState || state.auth.oauthState,
        },
    });
}

function logout(): StateType {
    return refreshAuthorizationUrl(
        InitialState,
        Creators.refreshAuthorizationUrl('/')
    );
}

function authorizeUserSuccess(
    state: StateType['auth'],
    action: AuthorizeUserSuccessAction
): Partial<StateType['auth']> {
    return {
        oauthState: action.payload.oauthState,
        oauthNonce: action.payload.oauthNonce,
    };
}
