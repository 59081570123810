import HouseSelectButton from '@/components/alliances/AllianceEditForm/HouseSelectButton';
import CardsList from '@/components/single_deck/CardsList';
import React from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { Card, Deck, House } from '@/shared/typings';
import classNames from 'classnames';

type AllianceColumnProps = WithNamespaces & {
    deckId?: string;
    editable: boolean;
    deckData?: Deck;
    selectedHouse?: string;
    selectedHouses?: string[];
    slotNumber: number;
} & (
        | {
              editable: true;
              onClear: () => void;
              onSelectHouse: (house: House) => void;
          }
        | {
              editable: false;
              onClear?: never;
              onSelectHouse?: never;
          }
    );

export function AllianceColumn(props: AllianceColumnProps) {
    const {
        deckData,
        selectedHouses,
        selectedHouse,
        onSelectHouse,
        slotNumber,
        editable,
        t,
    } = props;
    const cards: Card[] | undefined =
        deckData &&
        selectedHouse &&
        deckData.cards &&
        deckData.cards[selectedHouse];
    const [listHiddenOnMobile, setListHiddenOnMobile] = React.useState(true);

    const title = deckData ? deckData.name : `Pod ${slotNumber}`;

    return (
        <div className="alliance-form__deck">
            <div
                className={classNames(
                    'alliance-form__deck-head',
                    editable && 'alliance-form__deck-head--editable'
                )}
            >
                {editable && deckData && (
                    <button
                        type="button"
                        onClick={props.onClear}
                        className="alliance-form__deck-clear btn-clear"
                        title={t('general.hint.clear', {
                            defaultValue: 'Clear',
                        })}
                    />
                )}
                <h3 className="alliance-form__deck-title">{title}</h3>

                {deckData ? (
                    <div className="alliance-form__deck-houses">
                        {deckData.houses.map((h) => (
                            <HouseSelectButton
                                house={h}
                                key={h.id}
                                name={`house_${slotNumber}_id`}
                                value={h.id}
                                onChange={editable ? onSelectHouse : null}
                                active={selectedHouse === h.id}
                                disabled={
                                    (!editable && selectedHouse !== h.id) ||
                                    (selectedHouses?.includes(h.id) &&
                                        selectedHouse !== h.id)
                                }
                            />
                        ))}
                    </div>
                ) : (
                    <div className="alliance-form__select-house">
                        {`Select Pod ${slotNumber}...`}
                    </div>
                )}
            </div>
            <div className="alliance-form__deck-cards">
                {cards ? (
                    <>
                        <button
                            type="button"
                            className={classNames(
                                'alliance-form__cards-toggle',
                                !listHiddenOnMobile &&
                                    'alliance-form__cards-toggle--expanded'
                            )}
                            onClick={() =>
                                setListHiddenOnMobile(!listHiddenOnMobile)
                            }
                        >
                            {listHiddenOnMobile
                                ? t('single-deck.hint.see-cards', {
                                      defaultValue: 'See cards from this house',
                                  })
                                : t('single-deck.hint.hide-cards', {
                                      defaultValue:
                                          'Hide cards from this house',
                                  })}
                        </button>
                        <CardsList
                            className={classNames(
                                listHiddenOnMobile &&
                                    'alliance-form__cards-list-collapsed'
                            )}
                            cards={cards}
                            legacyCardsIds={deckData.setEraCards.Legacy}
                            anomalyCardsIds={deckData.setEraCards.Anomaly}
                            editable={editable}
                        />
                    </>
                ) : (
                    <div className="alliance-form__no-cards">
                        {selectedHouse ? (
                            <span className="spinner" />
                        ) : deckData ? (
                            t('alliances.forge.select-house', {
                                defaultValue: 'Select a house',
                            })
                        ) : null}
                    </div>
                )}
            </div>
        </div>
    );
}

export default withNamespaces()(AllianceColumn);
