import { Action } from 'redux';

import {
    RequestFailureAction,
    createActions,
    ActionsMap,
} from '@/shared/redux/ReduxHelpers';
import { AsyncStatus } from '@/shared/redux/Async';
import { Deck, House, Note } from '@/shared/typings';
import { StateType } from './State';
import {
    Pagination,
    DecksSearchFilters as ApiDecksSearchFilters,
    DeckWatchlistFilters as ApiWatchlistFilters,
} from '@/shared/api/ApiTypings';
import { LEGACY_OWNERSHIP_ACTION_PREFIX } from '@/shared/redux/user_decks/constants';

/* ------------- Types and Action Creators ------------- */

const actions: ActionsMap<CreatorsType> = {
    setRequestStatus: ['requestName', 'status'],

    getDeckRequest: ['deckId'],
    getDeckSuccess: ['payload'],
    getDeckFailure: ['payload'],

    cacheDeckRequest: ['deckId'],
    cacheDeckSuccess: ['payload'],

    searchDecksRequest: ['searchFilters', 'pagination'],
    searchDecksSuccess: ['payload'],
    searchDecksFailure: ['payload'],

    getRecentDecksRequest: ['pagination'],
    getRecentDecksSuccess: ['payload'],
    getRecentDecksFailure: ['payload'],

    deckCountRequest: null,
    deckCountSuccess: ['payload'],
    deckCountFailure: ['payload'],

    getHousesRequest: null,
    getHousesSuccess: ['payload'],
    getHousesFailure: ['payload'],

    addNoteRequest: ['deckId', 'noteText'],
    addNoteSuccess: ['payload'],
    addNoteFailure: ['payload'],

    updateNoteRequest: ['deckId', 'noteId', 'noteText'],
    updateNoteSuccess: ['payload'],
    updateNoteFailure: ['payload'],

    deleteNoteRequest: ['deckId', 'noteId'],
    deleteNoteSuccess: ['payload'],
    deleteNoteFailure: ['payload'],

    addDeckToFavoritesRequest: ['deckId'],
    addDeckToFavoritesSuccess: ['payload'],
    addDeckToFavoritesFailure: ['payload'],

    removeDeckFromFavoritesRequest: ['deckId'],
    removeDeckFromFavoritesSuccess: ['payload'],
    removeDeckFromFavoritesFailure: ['payload'],

    getWatchlistDecksRequest: ['watchlistFilters', 'pagination'],
    getWatchlistDecksSuccess: ['payload'],
    getWatchlistDecksFailure: ['payload'],

    addDeckToWatchlistRequest: ['deckId'],
    addDeckToWatchlistSuccess: ['payload'],
    addDeckToWatchlistFailure: ['payload'],

    removeDeckFromWatchlistRequest: ['deckId'],
    removeDeckFromWatchlistSuccess: ['payload'],
    removeDeckFromWatchlistFailure: ['payload'],
};
const decksActions = createActions<CreatorsType>(actions);
const legacyActions = createActions<CreatorsType>(actions, {
    prefix: LEGACY_OWNERSHIP_ACTION_PREFIX,
});

export const DecksTypes = decksActions.Types;
export const DecksActions = decksActions.Creators;
export const LegacyDecksTypes = legacyActions.Types;
export const LegacyDecksActions = legacyActions.Creators;

/* --------------- Actions definitions ------------------- */

interface CreatorsType {
    setRequestStatus: (
        requestName: keyof StateType,
        status: AsyncStatus
    ) => SetRequestStatusAction;

    getDeckRequest: (deckId: string) => GetDeckRequestAction;
    getDeckSuccess: (payload: Deck) => GetDeckSuccessAction;
    getDeckFailure: (payload: {
        error: StateType['getDeck']['__error'];
        deckId: Deck['id'];
    }) => GetDeckFailureAction;

    cacheDeckRequest: (deckId: string) => CacheDeckRequestAction;
    cacheDeckSuccess: (payload: Deck) => CacheDeckSuccessAction;

    deckCountRequest: () => Action;
    deckCountSuccess: (payload: {
        deckCount: number;
    }) => DeckCountSuccessAction;
    deckCountFailure: (payload: {
        error: StateType['deckCount']['__error'];
    }) => RequestFailureAction;

    searchDecksRequest: (
        searchFilters: ApiDecksSearchFilters,
        pagination: Pagination
    ) => SearchDecksRequestAction;
    searchDecksSuccess: (
        payload: SearchDecksSuccessAction['payload']
    ) => SearchDecksSuccessAction;
    searchDecksFailure: (payload: {
        error: StateType['searchDecks']['__error'];
    }) => RequestFailureAction;

    getRecentDecksRequest: (pagination: Pagination) => SearchDecksRequestAction;
    getRecentDecksSuccess: (
        payload: SearchDecksSuccessAction['payload']
    ) => SearchDecksSuccessAction;
    getRecentDecksFailure: (payload: {
        error: StateType['recentDecks']['__error'];
    }) => RequestFailureAction;

    getHousesRequest: () => Action;
    getHousesSuccess: (payload: House[]) => GetHousesSuccessAction;
    getHousesFailure: (payload: {
        error: StateType['houses']['__error'];
    }) => RequestFailureAction;

    addNoteRequest: (deckId: string, noteText: string) => AddNoteRequestAction;
    addNoteSuccess: (payload: Note) => AddNoteSuccessAction;
    addNoteFailure: (payload: {
        error: StateType['addNote']['__error'];
    }) => RequestFailureAction;

    updateNoteRequest: (
        deckId: string,
        noteId: string,
        noteText: string
    ) => UpdateNoteRequestAction;
    updateNoteSuccess: (payload: Note) => UpdateNoteSuccessAction;
    updateNoteFailure: (payload: {
        error: StateType['updateNote']['__error'];
    }) => RequestFailureAction;

    deleteNoteRequest: (
        deckId: string,
        noteId: string
    ) => DeleteNoteRequestAction;
    deleteNoteSuccess: (payload: { noteId: string }) => DeleteNoteSuccessAction;
    deleteNoteFailure: (payload: {
        error: StateType['deleteNote']['__error'];
    }) => RequestFailureAction;

    addDeckToFavoritesRequest: (
        deckId: string
    ) => AddDeckToFavoritesRequestAction;
    addDeckToFavoritesSuccess: (payload: {
        deckId: string;
    }) => AddDeckToFavoritesSuccessAction;
    addDeckToFavoritesFailure: (payload: {
        error: StateType['addDeckToFavorites']['__error'];
    }) => RequestFailureAction;

    removeDeckFromFavoritesRequest: (
        deckId: string
    ) => RemoveDeckFromFavoritesRequestAction;
    removeDeckFromFavoritesSuccess: (payload: {
        deckId: string;
    }) => RemoveDeckFromFavoritesSuccessAction;
    removeDeckFromFavoritesFailure: (payload: {
        error: StateType['removeDeckFromFavorites']['__error'];
    }) => RequestFailureAction;

    getWatchlistDecksRequest: (
        watchlistFilters: ApiWatchlistFilters,
        pagination: Pagination
    ) => GetWatchlistDecksRequestAction;
    getWatchlistDecksSuccess: (
        payload: GetWatchlistDecksSuccessAction['payload']
    ) => GetWatchlistDecksSuccessAction;
    getWatchlistDecksFailure: (payload: {
        error: StateType['getWatchlistDecks']['__error'];
    }) => RequestFailureAction;

    addDeckToWatchlistRequest: (
        deckId: string
    ) => AddDeckToWatchlistRequestAction;
    addDeckToWatchlistSuccess: (payload: {
        deckId: string;
    }) => AddDeckToWatchlistSuccessAction;
    addDeckToWatchlistFailure: (payload: {
        error: StateType['addDeckToWatchlist']['__error'];
    }) => RequestFailureAction;

    removeDeckFromWatchlistRequest: (
        deckId: string
    ) => RemoveDeckFromWatchlistRequestAction;
    removeDeckFromWatchlistSuccess: (payload: {
        deckId: string;
    }) => RemoveDeckFromWatchlistSuccessAction;
    removeDeckFromWatchlistFailure: (payload: {
        error: StateType['removeDeckFromWatchlist']['__error'];
    }) => RequestFailureAction;
}

export interface SetRequestStatusAction extends Action {
    requestName: keyof StateType;
    status: AsyncStatus;
}

export interface GetDeckRequestAction extends Action {
    deckId: string;
}

export interface GetDeckSuccessAction extends Action {
    payload: Deck;
}

export interface GetDeckFailureAction extends Action {
    payload: {
        error: StateType['getDeck']['__error'];
        deckId: Deck['id'];
    };
}

export interface CacheDeckRequestAction extends Action {
    deckId: string;
}

export interface CacheDeckSuccessAction extends Action {
    payload: Deck;
}

export interface CacheDeckFailureAction extends Action {
    payload: {
        error: StateType['getDeck']['__error'];
        deckId: Deck['id'];
    };
}

export interface DeckCountSuccessAction extends Action {
    payload: {
        deckCount: number;
    };
}

export interface SearchDecksRequestAction extends Action {
    searchFilters: ApiDecksSearchFilters;
    pagination: Pagination;
}

export interface SearchDecksSuccessAction extends Action {
    payload: {
        decks: Deck[];
        totalCount: number;
    };
}

export interface GetHousesSuccessAction extends Action {
    payload: House[];
}

export interface AddNoteRequestAction extends Action {
    deckId: string;
    noteText: string;
}

export interface AddNoteSuccessAction extends Action {
    payload: Note;
}

export interface UpdateNoteRequestAction extends Action {
    deckId: string;
    noteId: string;
    noteText: string;
}

export interface UpdateNoteSuccessAction extends Action {
    payload: Note;
}

export interface DeleteNoteRequestAction extends Action {
    deckId: string;
    noteId: string;
}

export interface DeleteNoteSuccessAction extends Action {
    payload: {
        noteId: string;
    };
}

export interface AddDeckToFavoritesRequestAction extends Action {
    deckId: string;
}

export interface AddDeckToFavoritesSuccessAction extends Action {
    payload: {
        deckId: string;
    };
}

export interface RemoveDeckFromFavoritesRequestAction extends Action {
    deckId: string;
}

export interface RemoveDeckFromFavoritesSuccessAction extends Action {
    payload: {
        deckId: string;
    };
}

export interface GetWatchlistDecksRequestAction extends Action {
    watchlistFilters: ApiWatchlistFilters;
    pagination: Pagination;
}

export interface GetWatchlistDecksSuccessAction extends Action {
    payload: {
        decks: Deck[];
        totalCount: number;
    };
}

export interface AddDeckToWatchlistRequestAction extends Action {
    deckId: string;
}

export interface AddDeckToWatchlistSuccessAction extends Action {
    payload: {
        deckId: string;
    };
}

export interface RemoveDeckFromWatchlistRequestAction extends Action {
    deckId: string;
}

export interface RemoveDeckFromWatchlistSuccessAction extends Action {
    payload: {
        deckId: string;
    };
}
