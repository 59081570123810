import './TextInput.scss';

import React, {
    SyntheticEvent,
    KeyboardEvent,
    forwardRef,
    ForwardedRef,
} from 'react';
import classNames from 'classnames';
import MaskedInput, { Mask } from 'react-text-mask';

export interface Props {
    className?: string;
    placeholder?: string;
    ariaLabel?: string;
    value?: string;
    onChange: (e: SyntheticEvent<HTMLElement | HTMLTextAreaElement>) => void;
    onKeyPress?: (e: KeyboardEvent<HTMLElement | HTMLTextAreaElement>) => void;
    asTextArea?: boolean;
    disabled?: boolean;
    mask?: Mask;
    rows?: number;
}

const TextInput = forwardRef(function TextInput(
    props: Props,
    ref: ForwardedRef<HTMLElement>
) {
    const {
        asTextArea,
        disabled,
        rows,
        mask,
        onKeyPress,
        className,
        ariaLabel,
        ...rest
    } = props;
    const commonProps = {
        disabled,
        onKeyPress,
        ...rest,
        className: 'text-input__input',
        'aria-label': ariaLabel,
    };

    return (
        <div
            className={classNames('text-input', className, {
                'text-input--text-area': asTextArea,
                'text-input--disabled': disabled,
            })}
        >
            {asTextArea ? (
                <textarea {...commonProps} rows={rows} ref={ref as any} />
            ) : mask ? (
                <MaskedInput
                    type="text"
                    mask={mask}
                    guide={false}
                    {...commonProps}
                    onKeyPress={onKeyPress as any}
                    ref={(i) => {
                        if (typeof ref === 'function') {
                            ref(i ? i.inputElement : null);
                        } else if (ref) {
                            ref.current = i ? i.inputElement : null;
                        }
                    }}
                />
            ) : (
                <input type="text" {...commonProps} ref={ref as any} />
            )}
        </div>
    );
});

export default TextInput;
