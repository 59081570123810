import { Action } from 'redux';

import {
    RequestFailureAction,
    createActions,
} from '@/shared/redux/ReduxHelpers';
import { GameStore, TournamentPlayer } from '@/shared/typings';
import { StateType } from './State';
import {
    Pagination,
    GameStoresSearchFilters as ApiGameStoresSearchFilters,
} from '@/shared/api/ApiTypings';

/* ------------- Types and Action Creators ------------- */

export const { Types, Creators } = createActions<CreatorsType>({
    getGameStoreRequest: ['gameStoreId'],
    getGameStoreSuccess: ['payload'],
    getGameStoreFailure: ['payload'],

    searchGameStoresRequest: ['searchFilters', 'pagination'],
    searchGameStoresSuccess: ['payload'],
    searchGameStoresFailure: ['payload'],

    getGameStoreLeaderboardRequest: ['gameStoreId', 'pagination', 'month'],
    getGameStoreLeaderboardSuccess: ['payload'],
    getGameStoreLeaderboardFailure: ['payload'],
});

/* --------------- Actions definitions ------------------- */

interface CreatorsType {
    getGameStoreRequest: (gameStoreId: string) => GetGameStoreRequestAction;
    getGameStoreSuccess: (payload: GameStore) => GetGameStoreSuccessAction;
    getGameStoreFailure: (payload: {
        error: StateType['getGameStore']['__error'];
        gameStoreId: GameStore['id'];
    }) => GetGameStoreFailureAction;

    searchGameStoresRequest: (
        searchFilters: ApiGameStoresSearchFilters,
        pagination: Pagination
    ) => SearchGameStoresRequestAction;
    searchGameStoresSuccess: (
        payload: SearchGameStoresSuccessAction['payload']
    ) => SearchGameStoresSuccessAction;
    searchGameStoresFailure: (payload: {
        error: StateType['searchGameStores']['__error'];
    }) => RequestFailureAction;

    getGameStoreLeaderboardRequest: (
        gameStoreId: string,
        pagination: Pagination,
        month: Date
    ) => GetGameStoreLeaderboardRequestAction;
    getGameStoreLeaderboardSuccess: (payload: {
        players: TournamentPlayer[];
        totalCount: number;
        participantCount: number;
        userRank: number;
    }) => GetGameStoreLeaderboardSuccessAction;
    getGameStoreLeaderboardFailure: (payload: {
        error: StateType['getGameStoreLeaderboard']['__error'];
    }) => RequestFailureAction;
}

export interface GetGameStoreRequestAction extends Action {
    gameStoreId: string;
}

export interface GetGameStoreSuccessAction extends Action {
    payload: GameStore;
}

export interface GetGameStoreFailureAction extends Action {
    payload: {
        error: StateType['getGameStore']['__error'];
        gameStoreId: GameStore['id'];
    };
}

export interface SearchGameStoresRequestAction extends Action {
    searchFilters: ApiGameStoresSearchFilters;
    pagination: Pagination;
}

export interface SearchGameStoresSuccessAction extends Action {
    payload: {
        gameStores: GameStore[];
        totalCount: number;
    };
}

export interface GetGameStoreLeaderboardRequestAction extends Action {
    gameStoreId: string;
    pagination: Pagination;
    month: Date;
}

export interface GetGameStoreLeaderboardSuccessAction extends Action {
    payload: {
        players: TournamentPlayer[];
        totalCount: number;
        participantCount: number;
        userRank: number;
    };
}
