import { InitialAsyncRequestState } from '../Async';
import { Deck } from '../../typings';

export enum AddDeckStatus {
    Registered,
    Discovered,
    Failure,
    DeckAlreadyExists,
    TooManyRequests,
    BannedDeck,
    NetworkError,
}

const InitialDeck: Deck = {
    id: '',
    name: '',
    isMyDeck: false,
    isDeckOwned: false,
    isPendingTransfer: false,
    favorite: false,
    isOnWatchlist: false,
    houses: [],
    cards: {},
    expansion: 341,
    powerLevel: 0,
    chains: 0,
    wins: 0,
    losses: 0,
    casualWins: 0,
    casualLosses: 0,
    shardsBonus: 0,
};

export const InitialState = {
    userDecks: {
        ...InitialAsyncRequestState,

        /**
         * Number of fetched decks, based on applied filters query
         */
        totalCount: 0,
        /**
         * List of fetched decks, based on applied filters query
         */
        list: [] as Deck[],
    },

    addingDeck: {
        ...InitialAsyncRequestState,

        addStatus: null as AddDeckStatus | null,
        deck: InitialDeck,
    },

    removeDeck: InitialAsyncRequestState,

    updateCasualScores: InitialAsyncRequestState,
};

export type StateType = typeof InitialState;
