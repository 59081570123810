import { default as apisauce, ApisauceInstance } from 'apisauce';
import {
    StoreEventsResponse,
    StoresResponse,
    CoordinatesResponse,
    StoreEventDetailsResponse,
} from './ExternalApiTypings';

const PLACES_URL = 'https://maps.googleapis.com/maps/api/geocode';

export default class ExternalApi {
    private api: ApisauceInstance;
    private placesApi: ApisauceInstance;

    constructor(apisauceRef = apisauce.create) {
        this.api = apisauceRef({
            baseURL: process.env.ASMODEE_API_URL,
            timeout: 10 * 1500,
        });
        this.placesApi = apisauceRef({
            baseURL: PLACES_URL,
            timeout: 10 * 1500,
        });
    }

    public getLocationFromAddress = (
        address: string,
        platform: 'android' | 'ios'
    ): Promise<CoordinatesResponse> => {
        return this.placesApi.get(
            `/json?address=${address}&key=${this.getPlatformKey(platform)}`
        );
    };

    public getAddressFromLocation = (
        lat: number,
        lng: number,
        platform: 'android' | 'ios'
    ): Promise<CoordinatesResponse> =>
        this.placesApi.get(
            `/json?latlng=${lat},${lng}&key=${this.getPlatformKey(platform)}`
        );

    public getNearestStores = (
        radius: number,
        lat: number,
        lng: number
    ): Promise<StoresResponse> =>
        this.api.get('/v1/stores', {
            location: `${lat},${lng}`,
            radius,
        });

    public getNearestEvents = (
        radius: number,
        lat: number,
        lng: number,
        startDate: string,
        endDate: string
    ): Promise<StoreEventsResponse> =>
        this.api.get('/v1/events', {
            location: `${lat},${lng}`,
            radius,
            date_range_a: startDate,
            date_range_b: endDate,
        });

    public getEventDetails = (
        eventId: number
    ): Promise<StoreEventDetailsResponse> =>
        this.api.get('/v1/events', { id: eventId });

    private getPlatformKey = (platform: 'ios' | 'android') =>
        platform === 'android'
            ? process.env.GOOGLE_PLACES_ANDROID_API_KEY
            : process.env.GOOGLE_PLACES_IOS_API_KEY;
}

export const externalApi = new ExternalApi();
