import React, { SyntheticEvent } from 'react';
import TextInput from '@/components/common/inputs/TextInput';
import { useForm, useController } from 'react-hook-form';
import { AlliancesSearchFilters } from '@/shared/api/ApiTypings';
import withMetadata, { TWithMetadata } from '@/shared/components/WithMetadata';
import FormSelectBox from '@/components/common/inputs/FormSelectBox';
import Checkbox from '@/components/common/inputs/Checkbox';
import HouseCheckbox from '@/components/common/inputs/HouseCheckbox';

import './AlliancesSearchForm.scss';
import CollapsibleFilters from '@/components/common/filters/CollapsibleFilters';
import { equals } from 'ramda';

export interface Props extends TWithMetadata {
    onSubmit?: (data: AlliancesSearchFilters) => void;
}

export const defaultValues: AlliancesSearchFilters = {
    search: '',
    show_hidden: false,
    set_id: 0,
    houses: [],
    ordering: '-created_at',
};

export const AlliancesSearchForm = (props: Props) => {
    const { onSubmit, metadata } = props;
    const { setValue, reset, watch, control, register, handleSubmit } = useForm(
        {
            defaultValues,
        }
    );
    const expansionField = useController({
        control,
        name: 'set_id',
    });

    const selectedHouses = watch('houses');

    function handleHouseChange(e: SyntheticEvent<HTMLInputElement>) {
        const { checked, value } = e.currentTarget;
        if (checked && selectedHouses.length < 3) {
            setValue('houses', [...selectedHouses, value]);
        } else {
            setValue(
                'houses',
                selectedHouses.filter((house) => house !== value)
            );
        }
    }
    const isResetDisabled = equals(defaultValues, watch());

    return (
        <form
            className="alliance-search-form"
            onSubmit={handleSubmit((values) => {
                onSubmit?.(values);
            })}
            role="form"
        >
            <div className="alliance-search-form__section alliance-search-form__search">
                <TextInput
                    {...register('search')}
                    placeholder="Search your alliances..."
                />
                <button className="btn btn-okay btn-medium" type="submit">
                    Search
                </button>
            </div>

            <CollapsibleFilters
                title="Filters"
                contentClassName="alliance-search-form__filters"
                resetDisabled={isResetDisabled}
                onReset={() => {
                    reset(defaultValues);
                }}
            >
                <div className="alliance-search-form__section alliance-search-form__section-set">
                    <h4 className="alliance-search-form__section-title keyforge-heading-2">
                        <label htmlFor="set">Set</label>
                    </h4>
                    <FormSelectBox
                        id="set"
                        value={'' + expansionField.field.value}
                        onChange={(value) => {
                            expansionField.field.onChange(Number(value));
                        }}
                        placeholder="Filter by set..."
                        items={[
                            { value: '0', label: '' },
                            ...Object.values(metadata.expansions).map(
                                (exp) => ({
                                    value: '' + exp.id,
                                    label: exp.name,
                                })
                            ),
                        ]}
                    />
                </div>

                <div className="alliance-search-form__section alliance-search-form__section-show-hidden">
                    <label htmlFor="showHidden">
                        <Checkbox
                            {...register('show_hidden')}
                            id="showHidden"
                        />
                        Show Hidden Decks
                    </label>
                </div>

                <div className="alliance-search-form__section alliance-search-form__section-houses">
                    <h4 className="alliance-search-form__section-title keyforge-heading-2">
                        Houses{' '}
                        <span className="alliance-search-form__section-title-hint">
                            (Please select up to 3 Houses)
                        </span>
                    </h4>
                    <ul className="alliance-search-form__houses-list">
                        {Object.values(metadata.houses.dict)
                            .filter((house) => house.inFilters)
                            .map((house) => (
                                <li key={house.id}>
                                    <HouseCheckbox
                                        onChange={handleHouseChange}
                                        checked={selectedHouses.includes(
                                            house.id
                                        )}
                                        house={house}
                                    />
                                </li>
                            ))}
                    </ul>
                </div>

                <div className="alliance-search-form__section alliance-search-form__section-footer">
                    <button type="submit" className="btn btn-okay btn-medium">
                        Search
                    </button>
                </div>
            </CollapsibleFilters>
        </form>
    );
};

export default withMetadata(AlliancesSearchForm);
