import './UserProfilePage.scss';

import React, { useState } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import AembershardsLog from './AembershardsLog/AembershardsLog';
import { UserProfile } from '@/shared/typings';
import { UnlockEventKeyModal } from './UnlockEventKeyModal/UnlockEventKeyModal';
import UserProfileActions from '@/shared/redux/user_profile';
import BuyAemberModal from './shards/BuyAemberModal/BuyAemberModal';
import UserTransactions from './UserTransactions/UserTransactions';
import UserProfileHeader from './UserProfileHeader/UserProfileHeader';
import Breadcrumbs from '../common/Breadcrumbs';

export interface Props extends WithNamespaces {
    refreshUserProfile?: (typeof UserProfileActions)['fetchProfileRequest'];
    profile: UserProfile;
    toggleUserAnonymous: () => void;
}

const UserProfilePage = ({ t, profile, refreshUserProfile }: Props) => {
    const [isBuyAemberModalOpened, setIsBuyAemberModalOpened] = useState(false);
    const [isVaultTourModalOpened, setIsVaultTourModalOpened] = useState(false);

    return (
        <div className="user-profile-page">
            <div className="user-profile-page__breadcrumbs">
                <Breadcrumbs />
            </div>
            <UserProfileHeader
                profile={profile}
                onBuyAember={() => setIsBuyAemberModalOpened(true)}
                onUnlockEventKey={() => setIsVaultTourModalOpened(true)}
            />
            <section className="user-profile-page__table-section">
                <h2 className="user-profile-page__table-section-title keyforge-heading-1">
                    Æmber Log
                </h2>
                <div className="user-profile-page__table-section-body">
                    <AembershardsLog />
                </div>
            </section>

            <section className="user-profile-page__table-section">
                <h2 className="user-profile-page__table-section-title keyforge-heading-1">
                    {t('profile.header.payments', {
                        defaultValue: 'Transactions',
                    })}
                </h2>
                <div className="user-profile-page__table-section-body">
                    <UserTransactions />
                </div>
            </section>

            <UnlockEventKeyModal
                opened={isVaultTourModalOpened}
                onClose={() => {
                    refreshUserProfile();
                    setIsVaultTourModalOpened(false);
                }}
                aemberCost={profile.keyTokenCost}
            />
            <BuyAemberModal
                opened={isBuyAemberModalOpened}
                onClose={() => setIsBuyAemberModalOpened(false)}
            />
        </div>
    );
};

export default withNamespaces()(UserProfilePage);
