import { Action } from 'redux';
import { StoreEvent } from '@/shared/typings/StoreEvent';
import { RequestFailureAction, createActions } from '../ReduxHelpers';
import { StateType } from './State';

/* ------------- Types and Action Creators ------------- */

export const { Types, Creators } = createActions<CreatorsType>({
    getNearestEventsRequest: ['lat', 'lng', 'radius', 'startDate', 'endDate'],
    getNearestEventsSuccess: ['payload'],
    getNearestEventsFailure: ['payload'],

    getCurrentAddressRequest: ['lat', 'lng'],
    getCurrentAddressSuccess: ['payload'],
    getCurrentAddressFailure: ['payload'],
});

/* --------------- Actions definitions ------------------- */

interface CreatorsType {
    getNearestEventsRequest: (
        lat: number,
        lng: number,
        radius: number,
        startDate: string,
        endDate: string
    ) => GetNearestEventsRequestAction;
    getNearestEventsSuccess: (payload: {
        events: StoreEvent[];
        total: number;
    }) => GetNearestEventsSuccessAction;
    getNearestEventsFailure: (payload: {
        error: StateType['getNearestEvents']['__error'];
    }) => RequestFailureAction;
    getCurrentAddressRequest: (
        lat: number,
        lng: number
    ) => GetCurrentAddressRequestAction;
    getCurrentAddressFailure: (payload: {
        error: StateType['getCurrentAddress']['__error'];
    }) => RequestFailureAction;
    getCurrentAddressSuccess: (payload: {
        address: string;
        latitude: number;
        longitude: number;
    }) => GetCurrentAddressSuccessAction;
}

export interface GetNearestEventsRequestAction extends Action {
    lat: number;
    lng: number;
    radius: number;
    startDate: string;
    endDate: string;
    platform: 'android' | 'ios';
}

export interface GetNearestEventsSuccessAction extends Action {
    payload: {
        events: StoreEvent[];
        total: number;
    };
}

export interface GetCurrentAddressRequestAction extends Action {
    lat: number;
    lng: number;
    platform: 'android' | 'ios';
}

export interface GetCurrentAddressSuccessAction extends Action {
    payload: { address: string; latitude: number; longitude: number };
}
