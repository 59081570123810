import React from 'react';
import {
    MyDecksPageComponent,
    MyDecksPageProps,
} from '@/components/decks/my_decks/MyDecksPageComponent';
import { RootState } from '@/shared/redux/RootState';
import { isAuthorized } from '@/shared/redux/Selectors';
import { LegacyUserDecksActions } from '@/shared/redux/user_decks';
import { LegacyDecksActions } from '@/shared/redux/decks';
import { connect } from 'react-redux';

export const MyLegacyDecksPage = (props: MyDecksPageProps) => {
    return (
        <MyDecksPageComponent
            pageTitle="Legacy Decks"
            showAddDeckUI={false}
            legacy
            {...props}
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    decks: state.userDecksLegacy.userDecks,
    addStatus: state.userDecksLegacy.addingDeck.addStatus,
    displayAuthorizedInterface: isAuthorized(state),
});

const mapDispatchToProps = {
    addDeck: LegacyUserDecksActions.addDeckRequest,
    fetchDecks: LegacyUserDecksActions.userDecksRequest,
    requestAddDeckToFavorites: LegacyDecksActions.addDeckToFavoritesRequest,
    requestRemoveDeckFromFavorites:
        LegacyDecksActions.removeDeckFromFavoritesRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyLegacyDecksPage);
