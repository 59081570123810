import { call, select, Effect } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import { curry } from 'ramda';

import { RootState } from '@/shared/redux/RootState';
import { AsyncStatus } from '@/shared/redux/Async';
import API from '@/shared/api/Api';
import { Saga } from '@/shared/typings';
import { getHouses } from './MetadataSaga';

const getHousesStatus = (state: RootState) => state.decks.houses.__status;

function* withMetadataRaw(saga: Saga, api: API, originalAction: AnyAction) {
    const storedHousesReqStatus: AsyncStatus = yield select(getHousesStatus);
    if (
        storedHousesReqStatus === AsyncStatus.Init ||
        storedHousesReqStatus === AsyncStatus.Error
    ) {
        yield call(getHouses, api, null, true);
    }

    yield call(saga, api, originalAction);
}

interface WithMetadata {
    (saga: Saga, api: API, originalAction: AnyAction): IterableIterator<Effect>;
    (saga: Saga, api: API): (
        originalAction: AnyAction
    ) => IterableIterator<Effect>;
    (saga: Saga): (
        api: API,
        originalAction: AnyAction
    ) => IterableIterator<Effect>;
}

export const withMetadata: WithMetadata = curry(withMetadataRaw);
