import React, { useEffect } from 'react';
import { pick } from 'ramda';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { AsyncStatus } from '@/shared/redux/Async';
import { TWithMetadata } from '@/shared/components/WithMetadata';
import { RootState } from '@/shared/redux/RootState';
import AlliancesActions, { AlliancesStateType } from '@/shared/redux/alliances';
import AllianceEditForm, {
    ALLIANCE_CLONABLE_KEYS,
} from '@/components/alliances/AllianceEditForm/AllianceEditForm';

import './AlliancePage.scss';
import Breadcrumbs from '../common/Breadcrumbs';
import { Expansions } from '@/shared/components/WithMetadata';
import { getTranslatedExpansionName } from '@/shared/services/lang/translationHelpers';

type BaseProps = TWithMetadata & WithNamespaces;

export interface Props extends BaseProps {
    getAlliance: typeof AlliancesActions.getAllianceRequest;
    updateAlliance: typeof AlliancesActions.updateAllianceRequest;
    resetState: typeof AlliancesActions.updateAllianceReset;
    alliance: AlliancesStateType['getAllianceData'];
    patchRequestState: AlliancesStateType['updatingAlliance'];
}

export const AlliancePage = (props: Props) => {
    const {
        t,
        getAlliance,
        updateAlliance,
        alliance,
        patchRequestState,
        resetState,
    } = props;
    const allianceId = useParams<{ id: string }>().id;
    const history = useHistory();

    useEffect(() => {
        if (allianceId) {
            getAlliance(allianceId);
        }
    }, [allianceId]);

    useEffect(() => {
        if (patchRequestState.__status === AsyncStatus.Success) {
            resetState();
            history.push('/alliances');
        }
    }, [patchRequestState.__status]);

    return (
        <div className="alliance-page">
            <Breadcrumbs />
            <h1 className="alliance-page__header keyforge-heading-1">
                {alliance?.data?.is_my_alliance && alliance?.data?.name ||
                    t('alliances.alliance.header', {
                        defaultValue: 'Alliance',
                    })}
            </h1>
            <div className='alliance-page__expansion'>
                {t('single-deck.hint.expansion', {
                    defaultValue: 'Expansion: {{expansion}}',
                    expansion: getTranslatedExpansionName(alliance?.data?.set_id, Expansions, t),
                })}
            </div>
            {alliance.data && (
                <AllianceEditForm
                    mode="update"
                    submitting={
                        patchRequestState.__status === AsyncStatus.Pending
                    }
                    onSubmit={updateAlliance}
                    defaultValues={alliance.data}
                    onCancel={() => history.push('/alliances')}
                    onDuplicate={(alliance) => {
                        const params = pick(ALLIANCE_CLONABLE_KEYS, alliance);
                        history.push(
                            '/alliances/forge?' + new URLSearchParams(params)
                        );
                    }}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    alliance: state.alliances.getAllianceData,
    patchRequestState: state.alliances.updatingAlliance,
});

const mapDispatchToProps = {
    getAlliance: AlliancesActions.getAllianceRequest,
    updateAlliance: AlliancesActions.updateAllianceRequest,
    resetState: AlliancesActions.updateAllianceReset,
};

export default withNamespaces()(
    connect(mapStateToProps, mapDispatchToProps)(AlliancePage)
);
