export interface Metadata {
    title: string;
    description: string;
    social?: {
        description?: string;
    };
}

const SiteMetadata: { [key: string]: Metadata } = {
    general: {
        title: 'KeyForge Master Vault',
        description:
            "Explore KeyForge, the world's first Unique Deck Game from Fantasy Flight Games, as you register your Archon Decks and engage with Organized Play!",
    },
    deckDetails: {
        title: '{{deckName}}',
        description:
            '{{deckName}} is an Archon Deck for KeyForge, the world’s first Unique Deck Game! It includes cards from {{house}}, {{house}}, and {{house}}.',
        social: {
            description: 'Check out this KeyForge Archon deck!',
        },
    },
    gameStoreDetails: {
        title: '{{gameStoreName}}',
        description:
            '{{gameStoreName}} is game store organizing Keyforge tournaments.',
        social: {
            description: 'Check out this KeyForge store leaderboard!',
        },
    },
};

export default SiteMetadata;
