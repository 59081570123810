import './DeckCardBadge.scss';

import { CardBadge } from '@/shared/redux/decks/State';
import BrobnarIcon from '@/images/single_deck/card_bonus/brobnar.png';
import DisIcon from '@/images/single_deck/card_bonus/dis.png';
import EkwidonIcon from '@/images/single_deck/card_bonus/ekwidon.png';
import GeistoidIcon from '@/images/single_deck/card_bonus/geistoid.png';
import LogosIcon from '@/images/single_deck/card_bonus/logos.png';
import MarsIcon from '@/images/single_deck/card_bonus/mars.png';
import SkybornIcon from '@/images/single_deck/card_bonus/skyborn.png';
import MaverickIcon from '@/images/single_deck/card_rarity/maverick.svg';
import SkybeastIcon from '@/images/single_deck/card_rarity/skybeast.svg';
import RevenantIcon from '@/images/single_deck/card_rarity/revenant.svg';
import LegacyIcon from '@/images/single_deck/card_rarity/legacy.svg';
import AnomalyIcon from '@/images/single_deck/card_rarity/anomaly.svg';
import AemberIcon from '@/images/single_deck/card_bonus/aember.png';
import CaptureIcon from '@/images/single_deck/card_bonus/capture.png';
import DamageIcon from '@/images/single_deck/card_bonus/damage.png';
import DiscardIcon from '@/images/single_deck/card_bonus/discard.png';
import DrawIcon from '@/images/single_deck/card_bonus/draw.png';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import React, { FC } from 'react';
import { Card, Deck } from '@/shared/typings';
import classNames from 'classnames';

const badgesMap: Record<
    CardBadge,
    { icon: any; name: { key: string; value: string } }
> = {
    maverick: {
        icon: MaverickIcon,
        name: { key: 'card.rarity.maverick', value: 'Maverick' },
    },
    revenant: {
        icon: RevenantIcon,
        name: { key: 'card.rarity.revenant', value: 'Revenant' },
    },
    skybeast: {
        icon: SkybeastIcon,
        name: { key: 'card.rarity.skybeast', value: 'Skybeast'},
    },
    brobnar: {
        icon: BrobnarIcon,
        name: { key: 'card.bonus.brobnar', value: 'Brobnar'},
    },
    dis: {
        icon: DisIcon,
        name: { key: 'card.bonus.dis', value: 'Dis'},
    },
    ekwidon: {
        icon: EkwidonIcon,
        name: { key: 'card.bonus.ekwidon', value: 'Ekwidon'},
    },
    geistoid: {
        icon: GeistoidIcon,
        name: { key: 'card.bonus.geistoid', value: 'Geistoid'},
    },
    logos: {
        icon: LogosIcon,
        name: { key: 'card.bonus.logos', value: 'Logos'},
    },
    mars: {
        icon: MarsIcon,
        name: { key: 'card.bonus.mars', value: 'Mars'},
    },
    skyborn: {
        icon: SkybornIcon,
        name: { key: 'card.bonus.skyborn', value: 'Skyborn'},
    },
    legacy: {
        icon: LegacyIcon,
        name: { key: 'card.rarity.legacy', value: 'Legacy' },
    },
    anomaly: {
        icon: AnomalyIcon,
        name: { key: 'card.rarity.anomaly', value: 'Anomaly' },
    },
    amber: {
        icon: AemberIcon,
        name: { key: 'card.bonus.aember', value: 'Aember' },
    },
    capture: {
        icon: CaptureIcon,
        name: { key: 'card.bonus.capture', value: 'Capture' },
    },
    draw: {
        icon: DrawIcon,
        name: { key: 'card.bonus.draw', value: 'Draw' },
    },
    damage: {
        icon: DamageIcon,
        name: { key: 'card.bonus.damage', value: 'Damage' },
    },
    discard: {
        icon: DiscardIcon,
        name: { key: 'card.bonus.discard', value: 'Discard' },
    },
};

export interface DeckCardBadgeProps extends WithNamespaces {
    badge: CardBadge;
    size?: 'small' | 'large';
}

export const DeckCardBadge: FC<DeckCardBadgeProps> = ({
    badge,
    size = 'small',
    t,
}) => {
    return <img
        className={classNames(
            'deck-card-badge',
            size === 'large' && 'deck-card-badge__large'
        )}
        src={badgesMap[badge].icon}
        alt={t(badgesMap[badge].name.key, {
            defaultValue: badgesMap[badge].name.value,
        })}
        title={t(badgesMap[badge].name.key, {
            defaultValue: badgesMap[badge].name.value,
        })}
    />
    };

export default withNamespaces()(DeckCardBadge);

export const getCardBadges = (
    card: Card,
    legacyCardsIds: Deck['setEraCards']['Legacy'],
    anomalyCardsIds: Deck['setEraCards']['Anomaly']
): CardBadge[] =>
    ([
        ['maverick', card.isMaverick],
        ['legacy', legacyCardsIds.includes(card.id)],
        // House variant 3 for revenant.
        ['revenant', card.houseVariant === 3],
        ['skybeast', card.houseVariant === 4],
        ['anomaly', anomalyCardsIds.includes(card.id)],
    ] as Array<[CardBadge, boolean]>)
        .filter((badge): badge is [CardBadge, true] => badge[1])
        .map(([badge]) => badge)
        .concat(card.bonusIcons ? card.bonusIcons : []);
