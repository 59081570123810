import { default as i18n, Callback, InitOptions } from 'i18next';
import XHR from 'i18next-xhr-backend';
import { reactI18nextModule } from 'react-i18next';
import { mergeDeepRight } from 'ramda';

import I18nCommonConfigFactory from '@/shared/services/lang/I18nCommonConfig';

export default function I18nFactory(
    namespace: string,
    preferredLang: string,
    preloadedTranslations?: { [key: string]: string },
    onDone?: Callback
) {
    const lng = (preferredLang || 'en-us').toLowerCase();
    const config: InitOptions = {
        lng,
        // lng: 'cimode', // use to only render translation keys during debugging
        resources: preloadedTranslations
            ? {
                  [lng]: {
                      web: preloadedTranslations,
                  },
              }
            : undefined,
        // enable code below to collect keys with missing translation.
        // then you need to browse all screens to collect all missing
        // keys. you can see them as JS object on `window.missingKeys`
        // on browser console
        saveMissing: process.env.NODE_ENV === 'development',
        missingKeyHandler:
            process.env.NODE_ENV === 'development'
                ? (lng, ns, key, defaultValue) => {
                      const windowPtr = window as any;
                      windowPtr.missingKeys = windowPtr.missingKeys || {};
                      windowPtr.missingKeys[key] = defaultValue;
                  }
                : undefined,
        saveMissingTo: 'current',
    };
    const initConfig = mergeDeepRight(
        I18nCommonConfigFactory(namespace),
        config
    ) as InitOptions; // mergeDeepRight messes up types

    // make sure that callback is called async. i18n make it sometimes sync,
    // sometimes async and make the code unpredictable
    const asyncOnDone: Callback = onDone
        ? (err, t) => setTimeout(() => onDone(err, t))
        : null;
    return i18n.use(XHR).use(reactI18nextModule).init(initConfig, asyncOnDone);
}
