import './OrderDeckCopyComponent.scss';

import React, { useState } from 'react';
import { Combobox } from '@headlessui/react';
import { LanguageLabels } from '@/shared/services/lang/Locales';
import { useOrderDeckCopyMutation } from '@/shared/queries/deck_copies';
import { Deck } from '@/shared/typings';
import { useFlag } from '@/shared/services/Flags';
import { uniqBy } from 'ramda';

interface OrderDeckCopyComponentProps {
    deck: Deck;
}

const OrderDeckCopyComponent = ({ deck, t }: OrderDeckCopyComponentProps) => {
    const deckCopiesEnabled = useFlag('deckCopies');

    const [error, setError] = useState(undefined);

    const [selectedAccolades, setSelectedAccolades] = useState([]);
    const [selectedLanguage, setSelectedLangugage] = useState(undefined);

    const visibleAccolades = deck.accolades?.filter((a) => a?.visible);

    const availableLanguages = () => {
        return LanguageLabels?.filter((lang) =>
            deck.availablePrintLanguages?.includes(lang.value)
        );
    };

    const availableAccolades = () => {
        return deck?.accolades
            ?.filter((a) => a.visible)
            ?.filter(
                (a) => !selectedAccolades?.map((a) => a?.id).includes(a?.id)
            );
    };

    const addAccolade = (accolade) => {
        if (selectedAccolades?.length <= 2) {
            setSelectedAccolades((accolades) =>
                uniqBy((a) => a?.id, accolades?.concat(accolade))
            );
        } else {
            alert('You can add 3 accolades at most.');
        }
    };

    const removeAccolade = (accolade) => {
        setSelectedAccolades((accolades) =>
            accolades?.filter((a) => a?.id !== accolade?.id)
        );
    };

    const { mutate: orderCopy } = useOrderDeckCopyMutation();

    const handleDeckCopyOrder = (deckId, locale, selectedAccolades) => {
        if (!locale) {
            setError('You need to select language of the deck.');
        }

        if (deckId && locale) {
            orderCopy(
                {
                    deckId,
                    locale,
                    selectedAccolades,
                },
                {
                    onSuccess: (data) => {
                        window.location.href = data?.url;
                    },
                    onError: (error) => {
                        setError(
                            error?.response?.data?.error ||
                            error?.response?.data?.detail
                        );
                    },
                }
            );
        }
    };

    return deckCopiesEnabled && deck.availablePrintLanguages?.length > 0 ? (
        <>
            <div className="deck-page__order-copy-header">
                <h2 className="deck-page__order-copy-title keyforge-heading-2">
                    {t('single-deck.header.order-copy', {
                        defaultValue: 'Order Deck Clone',
                    })}
                </h2>
            </div>
            <div className="deck-page__order-copy-info">
                <div className="deck-page__order-copy-info-text keyforge-heading-2">
                    As the owner of this deck, you may purchase a Deck Clone of
                    it.{' '}
                    <a
                        className="simple-external-link"
                        href="https://keyforging.com/deck-clones/"
                    >
                        Click here to know more about Deck Clones.
                    </a>
                </div>
                <div className="deck-page__order-copy-info-text keyforge-heading-2">
                    After adding your deck to the shopping cart you will be
                    redirected to the Forgefire website, our print on demand
                    partner.
                </div>
            </div>
            <div className="deck-page__order-copy-body">
                {visibleAccolades?.length > 0 && (
                    <>
                        <div className="deck-page__order-copy-accolades">
                            <Combobox
                                as="div"
                                className={'accolade-select'}
                                by="value"
                                value={undefined}
                                onChange={(accolade) => addAccolade(accolade)}
                            >
                                <Combobox.Button className="accolade-select__button">
                                    <div className="accolade-select__value">
                                        Select Accolades
                                    </div>
                                    <span className="accolade-select__caret" />
                                </Combobox.Button>
                                <Combobox.Options className="accolade-select__dropdown">
                                    <div className="accolade-select__values-list">
                                        {availableAccolades()?.map(
                                            (accolade) => (
                                                <Combobox.Option
                                                    value={accolade}
                                                    key={accolade?.id}
                                                    className="accolade-select__item"
                                                >
                                                    {accolade?.name}
                                                </Combobox.Option>
                                            )
                                        )}
                                    </div>
                                </Combobox.Options>
                            </Combobox>
                            {selectedAccolades?.length > 0 && (
                                <div className="deck-page__order-copy-accolades-list">
                                    {selectedAccolades?.map((accolade) => {
                                        return (
                                            <div
                                                className="deck-page__order-copy-accolades-list-item"
                                                key={accolade?.id}
                                            >
                                                <div>{accolade?.name}</div>
                                                <div
                                                    onClick={() =>
                                                        removeAccolade(accolade)
                                                    }
                                                    className="deck-page__order-copy-accolades-list-item-icon"
                                                >
                                                    X
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    </>
                )}
                <div className="deck-page__order-copy-locale">
                    <Combobox
                        as="div"
                        className={'language-select'}
                        by="value"
                        value={availableLanguages().find(
                            (l) => l.value === selectedLanguage?.value
                        )}
                        onChange={(lang) => setSelectedLangugage(lang)}
                    >
                        <Combobox.Button className="language-select__button">
                            <div className="language-select__value">
                                {selectedLanguage?.label || 'Select  Language'}
                            </div>
                            <span className="language-select__caret" />
                        </Combobox.Button>
                        <Combobox.Options className="language-select__dropdown">
                            <div className="language-select__values-list">
                                {availableLanguages().map((item) => (
                                    <Combobox.Option
                                        value={item}
                                        key={item.value}
                                        className="language-select__item"
                                    >
                                        {item.label}
                                    </Combobox.Option>
                                ))}
                            </div>
                        </Combobox.Options>
                    </Combobox>
                </div>
                <div className="deck-page__order-copy-submit">
                    <button
                        className="btn btn-okay"
                        onClick={() =>
                            handleDeckCopyOrder(
                                deck?.id,
                                selectedLanguage?.value,
                                selectedAccolades?.map((a) => a?.name)
                            )
                        }
                    >
                        {t('single-deck.body.orderCopyButton', {
                            defaultValue: 'Add to Cart',
                        })}
                    </button>
                </div>
            </div>
            {error && (
                <div className="deck-page__order-copy-error">{error}</div>
            )}
        </>
    ) : null;
};

export default OrderDeckCopyComponent;
