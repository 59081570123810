import './CardImageTooltip.scss';

import React, { PureComponent } from 'react';
import Tippy, { TippyProps } from '@tippy.js/react';
import classNames from 'classnames';
import DeckCardBadge from '@/components/single_deck/DeckCardBadge';
import { CardBadge } from '@/shared/redux/decks/State';

export interface Props {
    imageUrl: string;
    label: string;
    badges?: CardBadge[];
    children: TippyProps['children'];
}

interface State {
    isImageLoaded: boolean;
    shouldLoadImage: boolean;
}

export class CardImageTooltip extends PureComponent<Props, State> {
    public state: State = {
        isImageLoaded: false,
        shouldLoadImage: false,
    };

    public render() {
        const { isImageLoaded, shouldLoadImage } = this.state;
        return (
            <Tippy
                animateFill={false}
                duration={0}
                distance={12}
                content={this.renderContent(isImageLoaded, shouldLoadImage)}
                interactive={true}
                delay={[150, 0]}
                placement="bottom"
                theme="keyforge"
                trigger="mouseenter focus"
                onShow={this.handleTooltipShow}
            >
                {this.props.children}
            </Tippy>
        );
    }

    private renderContent = (
        isImageLoaded: boolean,
        shouldLoadImage: boolean
    ) => (
        <div className="card-image-tooltip">
            <div className="card-image-tooltip__image-wrapper">
                {shouldLoadImage && (
                    <img
                        className={classNames('card-image-tooltip__image', {
                            'card-image-tooltip__image--loaded': isImageLoaded,
                        })}
                        src={this.props.imageUrl}
                        alt={this.props.label}
                        onLoad={() => this.setState({ isImageLoaded: true })}
                    />
                )}
                {!isImageLoaded && <span className="spinner" />}
                {!!this.props.badges?.length && (
                    <div className="card-image-tooltip__badges">
                        {this.props.badges.map((badge) => {
                            return (
                                <DeckCardBadge
                                    badge={badge}
                                    key={badge}
                                    size="large"
                                />
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );

    private handleTooltipShow = () => {
        if (!this.state.isImageLoaded) {
            this.setState({ shouldLoadImage: true });
        }
    };
}

export default CardImageTooltip;
