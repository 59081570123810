import React from 'react';

const ActionFailed = ({ errorDetails, onClose }) => {
    return (
        <div className="modal-transfer-claim__deck-deleted">
            <h2 className="modal-transfer-claim__header">
                Something went wrong
            </h2>
            <p className="modal-transfer-claim__description">{errorDetails}</p>
            <div className="modal-transfer-claim__buttons">
                <button
                    className="modal-transfer-claim__btn btn btn-secondary"
                    onClick={() => onClose()}
                >
                    Close
                </button>
            </div>
        </div>
    );
};

export default ActionFailed;
