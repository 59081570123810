import { Action } from 'redux';

import { RequestFailureAction, createActions } from '../ReduxHelpers';
import { StateType } from './State';
import { UserProfile } from '../../typings';

/* ------------- Types and Action Creators ------------- */

export const { Types, Creators } = createActions<CreatorsType>({
    refreshAuthorizationUrl: ['redirectUrl'],
    setCurrentLocale: ['locale'],
    logout: null,
    updateOauthMeta: ['oauth'],

    authorizeUserRequest: ['idToken', 'accessToken', 'nonce'],
    authorizeUserSuccess: ['payload'],
    authorizeUserFailure: ['payload'],
});

/* --------------- Actions definitions ------------------- */

interface CreatorsType {
    refreshAuthorizationUrl: (redirectUrl: string) => RefreshUrlAction;
    setCurrentLocale: (locale: string) => SetLocaleAction;
    logout: () => Action;
    updateOauthMeta: (oauth: {
        oauthNonce?: string;
        oauthState?: string;
    }) => UpdateOauthMetaAction;

    authorizeUserRequest: (
        idToken: string,
        accessToken: string,
        nonce: string
    ) => AuthorizeUserRequestAction;
    authorizeUserSuccess: (payload: {
        apiToken: string;
        userProfile: UserProfile;
    }) => AuthorizeUserSuccessAction;
    authorizeUserFailure: (payload: {
        error: StateType['auth']['__error'];
    }) => RequestFailureAction;
}

export interface SetLocaleAction extends Action {
    locale: string;
}

export interface RefreshUrlAction extends Action {
    redirectUrl: string;
}

export interface UpdateOauthMetaAction extends Action {
    oauth: {
        oauthNonce: string;
        oauthState: string;
    };
}

export interface AuthorizeUserRequestAction extends Action {
    idToken: string;
    accessToken: string;
    nonce: string;
}

export interface AuthorizeUserSuccessAction extends Action {
    payload: {
        userProfile: UserProfile;
        apiToken: string;
        oauthState: StateType['auth']['oauthState'];
        oauthNonce: StateType['auth']['oauthNonce'];
    };
}
