import React from 'react';
import {
    RouteComponentProps,
    Route,
    Switch,
    withRouter,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import 'focus-visible';

import ScrollToTop from './ScrollToTop';
import RedirectWithStatus from './RedirectWithStatus';
import withAuthorizationRequest from '@/components/common/WithAuthorizationRequest';
import PageHeaderContainer from '@/components/page_header/PageHeaderContainer';
import PageFooter from '@/components/page_footer/PageFooter';
import AuthorizeContainer from '@/components/common/AuthorizeContainer';
import HomePageContainer from '@/components/home/HomePageContainer';
import UserProfilePageContainer from '@/components/profile/UserProfilePageContainer';
import MyDecksPage from '@/components/decks/my_decks/MyDecksPage';
import MyDecksPageLegacy from '@/components/decks/my_decks/MyDecksPageLegacy';
import DeckPageContainer from '@/components/single_deck/DeckPageContainer';
import MissingPage from '@/components/common/MissingPage';
import SiteMetadata from '@/config/Metadata';
import { getBaseUrl } from '@/utils/url';
import ForgeAlliance from '@/components/alliances/ForgeAlliancePage';
import Alliances from '@/components/alliances/AlliancesPage';
import Alliance from '@/components/alliances/AlliancePage';
import { useFlag } from '@/shared/services/Flags';
import { FriendsPage } from '@/components/friends/FriendsPage';
import { FriendsDecksPage } from '../decks/friends_decks/FriendsDecksPage';
import AfterPaymentPage from '../payments/AfterPaymentPage/AfterPaymentPage';
import { DeckTransferPage } from '../transfers_and_claims/DeckTransferPage';
import { DeckClaimPage } from '../transfers_and_claims/DeckClaimPage';

const MyDecksPageWithAuth = withAuthorizationRequest(MyDecksPage);
const MyLegacyDecksPageWithAuth = withAuthorizationRequest(MyDecksPageLegacy);
const ProfilePageWithAuth = withAuthorizationRequest(UserProfilePageContainer);
const ForgeAlliancePageWithAuth = withAuthorizationRequest(ForgeAlliance);
const AlliancesPageWithAuth = withAuthorizationRequest(Alliances);
const AlliancePageWithAuth = withAuthorizationRequest(Alliance);
const FriendsPageWithAuth = withAuthorizationRequest(FriendsPage);
const FriendsDecksPageWithAuth = withAuthorizationRequest(FriendsDecksPage);
const AfterPaymentPageWithAuth = withAuthorizationRequest(AfterPaymentPage);
const DeckTransferPageWithAuth = withAuthorizationRequest(DeckTransferPage);
const DeckClaimPageWithAuth = withAuthorizationRequest(DeckClaimPage);

export const App = (props: RouteComponentProps) => {
    const { pathname } = props.location;
    const { title, description } = SiteMetadata.general;
    const alliancesEnabled = useFlag('alliances');
    const friendsEnabled = useFlag('friends');

    return (
        <div className="page-container">
            <Route component={ScrollToTop} />

            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta property="og:url" content={getBaseUrl() + pathname} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
            </Helmet>

            <header>
                <Route component={PageHeaderContainer} />
            </header>

            <main className="page-content">
                <Switch>
                    <RedirectWithStatus
                        status={302}
                        from="/deck/:code"
                        to="/"
                    />
                    <RedirectWithStatus status={301} from="/search" to="/" />
                    <RedirectWithStatus status={301} from="/explore" to="/" />
                    <Route path="/authorize" component={AuthorizeContainer} />
                    <Route
                        exact={true}
                        path="/"
                        component={HomePageContainer}
                    />
                    <Route
                        exact={true}
                        path="/profile"
                        component={ProfilePageWithAuth}
                    />
                    <Route
                        exact={true}
                        path="/checkout"
                        component={AfterPaymentPageWithAuth}
                    />
                    <Route
                        exact={true}
                        path="/my-decks"
                        component={MyDecksPageWithAuth}
                    />
                    <Route
                        exact={true}
                        path="/friends-decks"
                        component={FriendsDecksPageWithAuth}
                    />
                    <Route
                        exact={true}
                        path="/deck-transfers"
                        component={DeckTransferPageWithAuth}
                    />
                    <Route
                        exact={true}
                        path="/deck-claims"
                        component={DeckClaimPageWithAuth}
                    />
                    <Route
                        exact={true}
                        path="/my-decks-legacy"
                        component={MyLegacyDecksPageWithAuth}
                    />
                    <Route
                        exact={true}
                        path="/deck-details/:id"
                        component={DeckPageContainer}
                    />
                    {friendsEnabled && (
                        <Route
                            exact={true}
                            path="/friends"
                            component={FriendsPageWithAuth}
                        />
                    )}
                    {alliancesEnabled && (
                        <>
                            <Route
                                exact={true}
                                path="/alliances"
                                component={AlliancesPageWithAuth}
                            />
                            <Route
                                path="/alliance-details/:id"
                                component={Alliance}
                            />
                            <Switch>
                                <Route
                                    exact={true}
                                    path="/alliances/forge"
                                    component={ForgeAlliancePageWithAuth}
                                />
                                <Route
                                    path="/alliances/:id"
                                    component={AlliancePageWithAuth}
                                />
                            </Switch>
                        </>
                    )}
                    <RedirectWithStatus
                        status={302}
                        from="/tournaments/:tournament"
                        to="/"
                    />
                    <RedirectWithStatus
                        status={302}
                        from="/stores/:id"
                        to="/"
                    />
                    <RedirectWithStatus status={302} from="/stores" to="/" />
                    {/*
                        // Keeping for quick rollback when needed
                        <Route
                            exact={true}
                            path="/tournaments/:tournament"
                            component={LeaderboardPage}
                        />
                        <Route
                            exact={true}
                            path="/stores/:id"
                            component={StoreLeaderboardContainer}
                        />
                        <Route
                            exact={true}
                            path="/stores"
                            component={SearchGameStoresPage}
                        />
                        */}
                    <Route
                        render={({ staticContext }) => {
                            if (staticContext) {
                                staticContext.statusCode = 404;
                            }
                            return <MissingPage />;
                        }}
                    />
                </Switch>
            </main>

            <PageFooter />
        </div>
    );
};

export default withRouter(App);
