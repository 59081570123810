type TokenFromStateFn = (state?: any) => string;

/**
 * Utility interface to retrieve user authentication token. It allows for easier
 * usage on each platform, and because each platform has its own ways to perform
 * the extraction (cookies, store etc.), it needs to be configured separately.
 */
const ApiToken: {
    config: (tokenExtractor: TokenFromStateFn) => void;
    get: TokenFromStateFn;
} = {
    config: (tokenExtractor) => (ApiToken.get = tokenExtractor),
    get: () => {
        throw new Error('ApiToken extractor needs to be configured before use');
    },
};

export default ApiToken;
