import {
    SocialLinkData,
    StoreEventData,
    StoreEventsResponseData,
} from '@/shared/api/ExternalApiTypings';
import { indexBy, prop, map, includes } from 'ramda';

import {
    Card,
    Deck,
    GameStore,
    House,
    HousesDict,
    Note,
    ParticipatedTournament,
    StoreEvent,
    TournamentDeck,
    TournamentPlayer,
    Transaction,
    UserProfile,
} from '@/shared/typings';
import { CardRarity, CardType } from '@/shared/redux/decks/State';
import { TransactionType } from '@/shared/redux/user_profile/State';
import { Tournament } from '@/shared/redux/tournaments/State';
import {
    CardResponseData,
    DeckResponseData,
    DeckSearchResponseData,
    GameStoreResponseData,
    HouseResponseData,
    NoteResponseData,
    ParticipatedTournamentResponseData,
    TournamentDeckResponseData,
    TournamentPlayerResponseData,
    TransactionResponseData,
    UserProfileData,
    SetEraCardsData,
    AccoladeResponseData,
} from '@/shared/api/ApiTypings';
import MissingHouseIcon from '@/shared/images/info-icon.png';
import SocialLink from '../typings/SocialLink';
import Accolade from '../typings/Accolade';

const typeMap: { [key: string]: CardType } = {
    Action: CardType.Action,
    Artifact: CardType.Artifact,
    Creature: CardType.Creature,
    Upgrade: CardType.Upgrade,
    'The Tide': CardType.Tide,
    'Token Creature': CardType.TokenCreature,
};

const rarityMap: { [key: string]: CardRarity } = {
    Common: CardRarity.Common,
    Uncommon: CardRarity.Uncommon,
    Rare: CardRarity.Rare,
    Variant: CardRarity.Special,
    FIXED: CardRarity.Special,
    Special: CardRarity.Special,
    'Evil Twin': CardRarity.EvilTwin,
    'The Tide': CardRarity.Common,
};

export const matchCardType = (type: string): CardType | null => {
    if (!(type in typeMap) && process.env.NODE_ENV !== 'test') {
        console.warn(`CardType: Unknown type "${type}" in Normalizers`);
        return null;
    }
    return typeMap[type];
};

export const matchCardRarity = (rarity: string): CardRarity | null => {
    if (!(rarity in rarityMap) && process.env.NODE_ENV !== 'test') {
        console.warn(`CardRarity: Unknown rarity "${rarity}" in Normalizers`);
        return null;
    }
    return rarityMap[rarity];
};

export const normalizeUserProfile = (data: UserProfileData): UserProfile => ({
    id: data.id,
    email: data.email,
    language: data.language,
    profilePhoto: data.avatar,
    username: data.username,
    qrCode: data.qr_code,
    shards: data.amber_shards,
    lifetimeShards: data.amber_shards_collected,
    keys: data.keys,
    keyTokenCost: data.key_token_cost,
    keyTokens: data.key_tokens,
    isAnonymous: data.anonymised,
});

export const normalizeCard = (setEraCards: SetEraCardsData) => (
    card: CardResponseData
): Card => ({
    id: card.id,
    name: card.card_title,
    house: card.house,
    imageUrl: card.front_image,
    description: card.card_text,
    cardType: matchCardType(card.card_type),
    traits: card.traits,
    amber: card.amber,
    power: card.power,
    armor: card.armor,
    rarity: matchCardRarity(card.rarity),
    isMaverick: card.is_maverick,
    isEnhanced: card.is_enhanced,
    flavorText: card.flavor_text,
    indexInExpansion: card.card_number,
    isLegacy: includes(card.id, setEraCards.Legacy),
    isAnomaly: includes(card.id, setEraCards.Anomaly),
    isNonDeck: card.is_non_deck,
    houseVariant: card.house_variant,
});

export const assignCardsToHouses = (
    houses: House[],
    cards: Card[]
): Deck['cards'] =>
    houses.reduce(
        (assigned, house) => ({
            ...assigned,
            [house.id]: cards.filter((card) => card.house === house.id),
        }),
        {}
    );

export const getNonDeckCards = (cards: Card[]) => {
    const nonDeckCards = cards.filter((card) => card.isNonDeck);
    return nonDeckCards.length > 0 ? nonDeckCards : undefined;
};

const MissingHouse: (id: string) => House = (houseId) => ({
    id: houseId,
    name: `House "${houseId}" not supported`,
    image: MissingHouseIcon,
    inFilters: true,
});

export const normalizeNote = (note: NoteResponseData): Note => ({
    id: note.id,
    text: note.text,
    // python returns timestamp in seconds
    creationTimestampMs: note.created_timestamp * 1000,
});

export const normalizeNotes = (notes: NoteResponseData[]): Note[] =>
    notes.map(normalizeNote);

export const normalizeAccolade = (
    accolade: AccoladeResponseData
): Accolade => ({
    id: accolade.id,
    name: accolade.name,
    image: accolade.image,
    visible: accolade.visible,
});

export const normalizeAccolades = (
    accolades: AccoladeResponseData[]
): Accolade[] => accolades.map(normalizeAccolade);

const sortNotesByDateDesc = (note1: Note, note2: Note) =>
    note2.creationTimestampMs - note1.creationTimestampMs;

const MissingCard: (id: string) => Card = (cardId) => ({
    id: cardId,
    name: `Missing card`,
    rarity: CardRarity.Common,
    isMaverick: false,
    isEnhanced: false,
    imageUrl: MissingHouseIcon,
    indexInExpansion: '0',
    house: '',
    description: '',
    cardType: null,
    traits: '',
    amber: 0,
    power: '0',
    armor: '0',
    flavorText: '',
    isNonDeck: false,
});

export const normalizeCasualScores = (
    deck: DeckResponseData
): Pick<Deck, 'casualWins' | 'casualLosses'> => ({
    casualWins: deck.casual_wins,
    casualLosses: deck.casual_losses,
});

export const normalizeDeck = (
    deck: DeckResponseData | DeckSearchResponseData,
    {
        cards,
        notes,
        accolades,
        allHouses,
    }: {
        cards?: CardResponseData[];
        notes?: NoteResponseData[];
        accolades?: AccoladeResponseData[];
        allHouses: HousesDict;
    }
): Deck => {
    const cardDict =
        cards &&
        indexBy<Card>(
            prop('id'),
            map(normalizeCard(deck.set_era_cards), cards)
        );
    const normalizedCards =
        'wins' in deck &&
        deck._links.cards &&
        deck._links.cards.map(
            (cardId) =>
                (cardDict && { ...cardDict[cardId] }) || MissingCard(cardId)
        );

    // assign enhancement icons data to each card
    if (normalizedCards && 'bonus_icons' in deck && deck.bonus_icons.length) {
        const enhancedCards = normalizedCards.filter((card) => card.isEnhanced);
        deck.bonus_icons.forEach(({ card_id, bonus_icons }, i) => {
            const cardIndex = enhancedCards.findIndex(
                ({ id }) => id === card_id
            );
            const [card] = enhancedCards.splice(cardIndex, 1);
            card.bonusIcons = bonus_icons;
        });
    }

    const normalizedHouses = deck._links.houses.map(
        (houseId) => allHouses[houseId] || MissingHouse(houseId)
    );
    const normalizedNotes =
        notes && normalizeNotes(notes).sort(sortNotesByDateDesc);

    const normalizedAccolades = accolades && normalizeAccolades(accolades);

    return {
        id: deck.id,
        name: deck.name,
        setEraCards: deck.set_era_cards,
        expansion: 'wins' in deck ? deck.expansion : deck.expansion.set_id,
        isMyDeck: 'wins' in deck ? deck.is_my_deck : false,
        isDeckOwned: 'wins' in deck ? deck.is_deck_owned : false,
        isPendingTransfer: 'wins' in deck ? deck.is_pending_transfer : false,
        favorite: 'wins' in deck ? deck.is_my_favorite : false,
        isOnWatchlist: 'wins' in deck ? deck.is_on_my_watchlist : false,
        powerLevel: 'wins' in deck ? deck.power_level : 0,
        chains: 'wins' in deck ? deck.chains : 0,
        wins: 'wins' in deck ? deck.wins : 0,
        losses: 'wins' in deck ? deck.losses : 0,
        shardsBonus: 'wins' in deck ? deck.shards_bonus : 0,
        ...('wins' in deck
            ? normalizeCasualScores(deck)
            : {
                  casualWins: 0,
                  casualLosses: 0,
              }),

        houses: normalizedHouses,
        accolades: normalizedAccolades,
        cards:
            normalizedCards &&
            assignCardsToHouses(normalizedHouses, normalizedCards),
        nonDeckCards: normalizedCards && getNonDeckCards(normalizedCards),
        notes: normalizedNotes,
        flavorText: deck.flavor_text,
        availablePrintLanguages: deck.available_print_languages,
    };
};

export const normalizeDecks = (
    decks: Array<DeckResponseData | DeckSearchResponseData>,
    {
        cards,
        allHouses,
    }: {
        cards?: CardResponseData[];
        allHouses: HousesDict;
    }
) => decks.map((deck) => normalizeDeck(deck, { allHouses, cards }));

export const normalizeHouse = (house: HouseResponseData): House => ({
    id: house.id,
    name: house.name,
    image: house.image,
    inFilters: house.in_filters,
});

const matchTransactionType = (
    transactionType: number
): TransactionType | null => {
    if (!(transactionType in TransactionType)) {
        console.warn(
            `TransactionType: Unknown transaction type "${transactionType}" in Normalizers`
        );
        return null;
    }
    return transactionType;
};

export const normalizeTransaction = (
    transaction: TransactionResponseData
): Transaction => ({
    id: transaction.id,
    type: matchTransactionType(transaction.reason),
    // python returns timestamp in seconds
    timestampMs: transaction.timestamp * 1000,
    location: transaction.location,
    description: transaction.description,
    changedShards: transaction.change,
    balance: transaction.balance,
});

export const normalizeTransactions = (
    transactions: TransactionResponseData[]
): Transaction[] => transactions.map(normalizeTransaction);

export const normalizeTournamentPlayer = (
    player: TournamentPlayerResponseData
): TournamentPlayer => ({
    id: player.id,
    name: player.username,
    rank: player.rank,
    shards: player.amber_shards,
});

export const normalizeTournamentPlayers = (
    players: TournamentPlayerResponseData[]
): TournamentPlayer[] => players.map(normalizeTournamentPlayer);

export const normalizeTournamentDeck = (
    deck: TournamentDeckResponseData,
    allHouses: HousesDict
): TournamentDeck => {
    const normalizedHouses = deck._links.houses.map(
        (houseId) => allHouses[houseId] || MissingHouse(houseId)
    );
    return {
        id: deck.id,
        name: deck.name,
        expansion: deck.expansion,
        powerLevel: deck.power_level,
        wins: deck.wins,
        losses: deck.losses,
        isMyDeck: deck.is_my_deck,
        isOnWatchlist: deck.is_on_my_watchlist,
        tournamentRank: deck.rank,
        houses: normalizedHouses,
    };
};

export const normalizeTournamentDecks = (
    decks: TournamentDeckResponseData[],
    allHouses: HousesDict
): TournamentDeck[] =>
    decks.map((deck) => normalizeTournamentDeck(deck, allHouses));

const matchTournament = (tournamentType: number): Tournament | null => {
    if (!(tournamentType in Tournament)) {
        console.warn(
            `Tournament: Unknown tournament type "${tournamentType}" in Normalizers`
        );
        return null;
    }
    return tournamentType;
};

export const normalizeParticipatedTournaments = (
    tournaments: ParticipatedTournamentResponseData[]
): ParticipatedTournament[] =>
    tournaments.map((tournament) => ({
        id: tournament.id,
        userRank: tournament.rank,
        participantCount: tournament.total_participants_number,
        tournamentType: matchTournament(tournament.tournament_type),
    }));

export const normalizeGameStore = (
    gameStore: GameStoreResponseData
): GameStore => {
    return {
        id: gameStore.id,
        name: gameStore.name,
        address: {
            line1: gameStore.address_line1,
            line2: gameStore.address_line2,
            city: gameStore.city,
            postalCode: gameStore.postal_code,
            province: gameStore.province,
            country: gameStore.country,
        },
        url: gameStore.url,
    };
};

export const normalizeGameStores = (stores: GameStoreResponseData[]) =>
    stores.map(normalizeGameStore);

export const normalizeEvents = (events: StoreEventsResponseData) =>
    events.results.map(normalizeEvent);

export const normalizeEvent = (event: StoreEventData): StoreEvent => {
    return {
        id: event.id,
        description: event.description,
        name: event.name,
        location: event.location,
        lat: event.lat,
        lng: event.lng,
        productLines: event.product_lines,
        startDate: event.date_start,
        endDate: event.date_end,
        timezone: event.timezone,
        eventType: event.event_type,
        link: event.link,
        registrationLink: event.registration_link,
        studios: event.studios,
        opProgram: event.op_program,
        socialLinks: normalizeEventSocialLinks(event.social_links),
        address: event.address1,
        city: event.city,
        postalCode: event.postal_code,
        province: event.province,
        country: event.country,
    };
};

export const normalizeEventSocialLinks = (
    socialLinks: SocialLinkData[]
): SocialLink[] =>
    socialLinks.map((link) => ({ url: link.url, socialTag: link.social_tag }));
