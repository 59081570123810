import './ErrorBoundary.scss';

import React, { PureComponent } from 'react';
import Raven from 'raven-js';

const isLocalEnv = () => process.env.SENTRY_ENVIRONMENT_IDENTITY === 'local';

export interface Props {
    children: JSX.Element;
}

interface State {
    error: Error;
}

export default class ErrorBoundary extends PureComponent<Props, State> {
    public state: State = { error: null };

    public componentDidCatch(error: Error, errorInfo: {}) {
        this.setState({ error });
        if (isLocalEnv()) {
            return;
        }
        Raven.captureException(error, {
            extra: errorInfo,
        });
    }

    public render() {
        if (!this.state.error) {
            return this.props.children;
        }

        if (isLocalEnv()) {
            return (
                <div className="error-boundary">
                    <p>Encountered critical error:</p>
                    <pre className="error-boundary__error">
                        {this.state.error.toString()}
                    </pre>
                    <p>Check page console for details.</p>
                </div>
            );
        }

        return (
            <div className="error-boundary">
                <h2 className="error-boundary__title">
                    We&#039;re sorry — something went wrong.
                </h2>
                <p>
                    <span>Our team has been notified, but </span>
                    <button
                        className="btn-link error-boundary__report-btn"
                        onClick={this.showReportDialog}
                    >
                        click here
                    </button>
                    <span> to fill out a report.</span>
                </p>
            </div>
        );
    }

    private showReportDialog() {
        return Raven.lastEventId() && Raven.showReportDialog({});
    }
}
