import './AddDeckModal.scss';

import React, { Component, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { path } from 'ramda';
import { withNamespaces, WithNamespaces, Trans } from 'react-i18next';

import { RootState } from '@/shared/redux/RootState';
import { AsyncStatus } from '@/shared/redux/Async';
import { AddDeckStatus } from '@/shared/redux/user_decks/State';
import {
    UserDecksActions,
    LegacyUserDecksActions,
} from '@/shared/redux/user_decks';
import { Deck } from '@/shared/typings';
import ExternalLink from '@/components/common/ExternalLink';
import Modal from '@/components/common/Modal';
import { useFlag } from '@/shared/services/Flags';
import { ClaimButton } from '@/components/common/ClaimButton';
import ErrorModal from '@/components/common/ErrorModal';

interface HOCProps {
    requestStatus?: AsyncStatus;
    addResult: {
        status: AddDeckStatus;
        errorMsg?: string;
        errorDetails?: string;
    };
    deck?: Deck;
    resetRequest?: () => void;
}

interface OwnProps {
    isOpen: boolean;
    onClose: () => void;
    deckCode: string;
}

let codeForClaim: string;

export interface Props extends WithNamespaces, HOCProps, OwnProps {}

interface State {
    loading: boolean;
    isClaimError: boolean;
    claimError: string;
    isClaimSuccess: boolean;
}

export class AddDeckModal extends Component<Props, State> {
    public state = {
        loading: true,
        isClaimError: false,
        claimError: '',
        isClaimSuccess: false,
    };

    setIsClaimError = (value: boolean) => {
        this.setState({ isClaimError: value });
    };

    setIsClaimSuccess = (value: boolean) => {
        this.setState({ isClaimSuccess: value });
    };

    setClaimError = (value: string) => {
        this.setState({ claimError: value });
    };

    public componentDidUpdate(prevProps: Props) {
        if (this.props.requestStatus !== prevProps.requestStatus) {
            this.setState({
                loading: this.props.requestStatus === AsyncStatus.Pending,
            });
        }
    }

    public render() {
        const { loading, isClaimError, isClaimSuccess, claimError } =
            this.state;
        const { isOpen, addResult, deck, t } = this.props;
        const success =
            addResult.status === AddDeckStatus.Discovered ||
            addResult.status === AddDeckStatus.Registered;
        return (
            <Modal
                isOpen={isOpen}
                onClose={this.requestModalClose}
                className="add-deck-modal"
                loading={loading}
                hideModalOnLoading={true}
                headerImage={this.renderHeaderImage(success, addResult.status)}
            >
                {success
                    ? this.renderSuccessBody(addResult.status, deck)
                    : this.renderFailureBody(addResult)}
                <Modal
                    isOpen={isOpen && isClaimSuccess}
                    onClose={this.requestModalClose}
                    className="error-modal"
                >
                    <h3 className="error-modal__heading">
                        {t('general.hint.something-wrong', {
                            defaultValue: 'Claim Request Submitted',
                        })}
                    </h3>
                    <p className="error-modal__description">
                        Your deck claim request has been submitted! The current
                        Master Vault deck owner has been informed of your
                        request. You can check the status of your claim request
                        on the <Link to={'/deck-claims'}>Deck Claims</Link>{' '}
                        page.
                    </p>
                    <button
                        className="error-modal__close-btn btn btn-okay"
                        onClick={this.requestModalClose}
                    >
                        {t('general.btn.okay', {
                            defaultValue: 'Dismiss',
                        })}
                    </button>
                </Modal>
                <ErrorModal
                    isOpen={isClaimError && isOpen}
                    onClose={this.requestModalClose}
                    description={claimError}
                />
            </Modal>
        );
    }

    private renderHeaderImage = (
        success: boolean,
        addStatus: AddDeckStatus
    ) => (
        <div
            className={classNames('add-deck-modal__header-image', {
                'add-deck-modal__header-image--success': success,
                'add-deck-modal__header-image--failure':
                    addStatus === AddDeckStatus.Failure ||
                    addStatus === AddDeckStatus.TooManyRequests ||
                    addStatus === AddDeckStatus.BannedDeck,
                'add-deck-modal__header-image--already-exists':
                    addStatus === AddDeckStatus.DeckAlreadyExists,
            })}
        />
    );

    private renderFailureBody = (addResult: Props['addResult']) => {
        switch (addResult.status) {
            case AddDeckStatus.Failure:
                return this.renderFailure(
                    this.props.t('modal.add-deck.header.invalid-code', {
                        defaultValue: 'Invalid Deck Code!',
                    }),
                    this.props.t('modal.add-deck.hint.wrong-code', {
                        defaultValue:
                            'Please double check your deck code and try again.',
                    })
                );
            case AddDeckStatus.BannedDeck:
                return this.renderBlacklistedDeckFailure();
            case AddDeckStatus.DeckAlreadyExists:
                if (
                    addResult.errorMsg ===
                    'Master Vault Deck ownership of this deck is claimed by another user.'
                ) {
                    return this.renderFailure(
                        addResult?.errorMsg,
                        addResult?.errorDetails,
                        this.props.t('modal.add-deck.hint.wrong-code', {
                            defaultValue:
                                'Do you want to submit a Claim Request?',
                        })
                    );
                } else {
                    return this.renderFailure(
                        addResult?.errorMsg,
                        addResult?.errorDetails
                    );
                }
            case AddDeckStatus.TooManyRequests:
                return this.renderFailure('Error', addResult.errorMsg);
            default:
                return this.renderFailure(
                    addResult?.errorMsg,
                    addResult?.errorDetails
                );
        }
    };

    private _renderFailure = (
        header: string,
        body: string,
        claimBody?: string
    ) => {
        if (this.props.deckCode) codeForClaim = this.props.deckCode;
        return (
            <Fragment>
                <h2 className="add-deck-modal__status">{header}</h2>
                {claimBody ? (
                    <div style={{ padding: '2rem 0' }}>
                        <p className="add-deck-modal__status-description">
                            Sorry, this deck cannot be registered to your Master
                            Vault Account because it has already been registered
                            to another user.
                        </p>
                        <p className="add-deck-modal__status-description">
                            If you are in physical possession of this deck you
                            may submit a claim request. Upon submitting a claim
                            request, one Aember will be deducted from your
                            account. Read more about Master Vault Ownership
                            Disputes <ExternalLink className='add-deck-modal__info-link' to={'https://keyforging.com/keyforge-deck-ownership/'}>here</ExternalLink>
                        </p>
                        <p className="add-deck-modal__status-description">
                            {claimBody}
                        </p>
                    </div>
                ) : (
                    <p className="add-deck-modal__status-description">{body}</p>
                )}
                <div className="add-deck-modal__buttons-claim">
                    <button
                        className="add-deck-modal__btn btn btn-secondary"
                        onClick={this.requestModalClose}
                    >
                        {this.props.t('general.btn.okay', {
                            defaultValue: 'Dismiss',
                        })}
                    </button>
                    {claimBody && (
                        <div>
                            <ClaimButton
                                deckCode={codeForClaim}
                                setIsClaimError={this.setIsClaimError}
                                setClaimError={this.setClaimError}
                                setIsClaimSuccess={this.setIsClaimSuccess}
                            />
                        </div>
                    )}
                </div>
            </Fragment>
        );
    };
    public get renderFailure() {
        return this._renderFailure;
    }
    public set renderFailure(value) {
        this._renderFailure = value;
    }

    private renderBlacklistedDeckFailure = () => {
        const supportPhone = '855-382-8880';
        const supportEmail = 'customerservice@asmodeena.com';
        return (
            <Fragment>
                <h2 className="add-deck-modal__status">
                    {this.props.t('modal.add-deck.header.invalid-deck', {
                        defaultValue: 'Invalid Deck',
                    })}
                </h2>
                <p className="add-deck-modal__status-description">
                    <Trans
                        i18nKey="modal.add-deck.hint.blacklisted-deck"
                        values={{ supportPhone, supportEmail }}
                    >
                        <span className="add-deck-modal__status-description-part">
                            Oh, no! There is a problem with this deck and it has
                            been flagged for removal from the play environment.
                            This deck will not be registered.
                        </span>
                        <span className="add-deck-modal__status-description-part">
                            Please contact our Customer Service team at{' '}
                            <a
                                className="add-deck-modal__link"
                                href={`tel:${supportPhone}`}
                            >
                                {{ supportPhone }}
                            </a>
                            , or via email at{' '}
                            <a
                                className="add-deck-modal__link"
                                href={`mailto:${supportEmail}`}
                            >
                                {{ supportEmail }}
                            </a>
                            , and they will provide you with further
                            instructions. We apologize for the inconvenience.
                        </span>
                    </Trans>
                </p>
                <div className="add-deck-modal__buttons add-deck-modal__buttons--horizontal">
                    <button
                        className="add-deck-modal__btn btn"
                        onClick={this.requestModalClose}
                    >
                        {this.props.t('my-decks.header.my-decks', {
                            defaultValue: 'My Decks',
                        })}
                    </button>
                    <ExternalLink
                        className="add-deck-modal__btn btn-secondary"
                        to="https://www.fantasyflightgames.com/en/more/customer-service/"
                    >
                        {this.props.t('modal.add-deck.hint.customer-service', {
                            defaultValue: 'Customer Service',
                        })}
                    </ExternalLink>
                </div>
            </Fragment>
        );
    };

    private renderSuccessBody = (addStatus: AddDeckStatus, deck: Deck) => {
        const shardsBonus =
            deck.shardsBonus !== undefined ? `+${deck.shardsBonus}` : '+1';

        const shardsLabel = this.props.t('general.aembershard', {
            defaultValue: 'Æmbershard',
            count: deck.shardsBonus || 1,
        });

        return (
            <Fragment>
                <h2 className="add-deck-modal__status">
                    {addStatus === AddDeckStatus.Discovered
                        ? this.props.t('modal.add-deck.hint.deck-discover', {
                              defaultValue: 'You discovered {{deckName}}!',
                              deckName: deck.name,
                          })
                        : this.props.t('modal.add-deck.hint.deck-register', {
                              defaultValue: 'You registered {{deckName}}!',
                              deckName: deck.name,
                          })}
                </h2>
                <p className="add-deck-modal__status-description">
                    {this.props.t('modal.add-deck.hint.deck-added', {
                        defaultValue:
                            'This deck has been successfully added to your collection!',
                    })}
                </p>
                {addStatus === AddDeckStatus.Discovered && (
                    <div className="add-deck-modal__aembershards">
                        <Trans
                            i18nKey="modal.add-deck.hint.aembershard-acquired"
                            values={{ shardsBonus, shardsLabel }}
                        >
                            <strong>
                                {{ shardsBonus }}
                                <span className="add-deck-modal__aembershards-icon" />
                                {{ shardsLabel }}
                            </strong>{' '}
                            acquired.
                        </Trans>
                    </div>
                )}
                <div className="add-deck-modal__buttons">
                    <Link
                        className="add-deck-modal__btn add-deck-modal__go-to-deck btn"
                        to={`/deck-details/${deck.id}`}
                    >
                        {this.props.t('modal.add-deck.link.go-to-profile', {
                            defaultValue: 'Go to Deck',
                        })}
                    </Link>

                    <button
                        className="add-deck-modal__btn add-deck-modal__go-to-deck btn-secondary"
                        onClick={this.requestModalClose}
                    >
                        {this.props.t('general.btn.okay', {
                            defaultValue: 'Dismiss',
                        })}
                    </button>
                </div>
            </Fragment>
        );
    };

    private requestModalClose = () => {
        this.setIsClaimSuccess(false);
        this.setIsClaimError(false);
        this.setClaimError('');
        this.props.resetRequest();
        this.props.onClose();
    };
}

export const AddDeckModalHOC = (props: OwnProps & WithNamespaces) => {
    const dispatch = useDispatch();
    const useNewOwnership = useFlag('newDeckOwnership');
    const sliceKey = useNewOwnership ? 'userDecks' : 'userDecksLegacy';
    const userDecksActions = useNewOwnership
        ? UserDecksActions
        : LegacyUserDecksActions;

    const requestStatus = useSelector<RootState, Props['requestStatus']>(
        (state) => state[sliceKey].addingDeck.__status
    );
    const addResult = useSelector<RootState, Props['addResult']>((state) => ({
        status: state[sliceKey].addingDeck.addStatus,
        errorMsg: path(
            ['userDecks', 'addingDeck', '__error', 'data', 'detail'],
            state
        ) as string,
        errorDetails: path(
            ['userDecks', 'addingDeck', '__error', 'data', 'message'],
            state
        ) as string,
    }));
    const deck = useSelector<RootState, Props['deck']>(
        (state) => state[sliceKey].addingDeck.deck
    );

    const resetRequest = () => dispatch(userDecksActions.resetAddDeckRequest());

    return (
        <AddDeckModal
            {...{
                requestStatus,
                addResult,
                deck,
                resetRequest,
                ...props,
            }}
        />
    );
};

export default withNamespaces()(AddDeckModalHOC);
