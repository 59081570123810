import './Watchlist.scss';

import React, { PureComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { withNamespaces, WithNamespaces, Trans } from 'react-i18next';
import { assocPath, append, without, includes } from 'ramda';

import WatchableDeckList, {
    Props as WatchableDeckListProps,
} from '@/components/common/deck_lists/WatchableDeckList';
import PaginatedTable from '@/components/common/tables/PaginatedTable';
import IconCheckbox from '@/components/common/inputs/IconCheckbox';
import { DeckWatchlistFilters } from '@/shared/api/ApiTypings';
import { DecksActions, LegacyDecksActions } from '@/shared/redux/decks';
import { AsyncStatus } from '@/shared/redux/Async';
import { RootState } from '@/shared/redux/RootState';
import { Deck } from '@/shared/typings';
import { useFlag } from '@/shared/services/Flags';

const DecksPerPage = 10;

export const DefaultFiltersState: DeckWatchlistFilters = {
    sorting: '-date',
};

export interface HOCProps {
    watchlistReq: RootState['decks']['getWatchlistDecks'];
    fetchWatchlist: typeof DecksActions['getWatchlistDecksRequest'];
    requestAddDeckToWatchlist: typeof DecksActions['addDeckToWatchlistRequest'];
    requestRemoveDeckFromWatchlist: typeof DecksActions['removeDeckFromWatchlistRequest'];
}
export interface Props extends WithNamespaces, HOCProps {}

interface State {
    currentDeckPage: number;
    filters: DeckWatchlistFilters;
    removedDeckIds: Array<Deck['id']>;
}

export class Watchlist extends PureComponent<Props, State> {
    public state: State = {
        currentDeckPage: 0,
        filters: DefaultFiltersState,
        removedDeckIds: [],
    };

    // Fetch decks only on client side, web crawlers don't need this data
    public componentDidMount() {
        this.fetchWatchlistForFilters();
    }

    public render() {
        const { watchlistReq } = this.props;
        const { list, totalCount, __status: status } = watchlistReq;
        const { currentDeckPage, filters } = this.state;
        return (
            <div className="watchlist">
                {totalCount > 0 ? (
                    <PaginatedTable
                        currentPage={currentDeckPage}
                        pageCount={Math.ceil(totalCount / DecksPerPage)}
                        onPageChange={this.onPageChange}
                        isLoading={status !== AsyncStatus.Success}
                    >
                        <WatchableDeckList
                            decks={list}
                            onSortChange={this.onSortChange}
                            sorting={filters.sorting}
                            renderWatchlistIcon={this.renderWatchlistIcon}
                            renderDeckRowOverlay={this.renderDeckRowOverlay}
                            displayAuthorizedInterface={true}
                        />
                    </PaginatedTable>
                ) : (
                    this.renderNoDecks()
                )}
            </div>
        );
    }

    private fetchWatchlistForFilters = () =>
        this.props.fetchWatchlist(this.state.filters, {
            page: this.state.currentDeckPage,
            pageSize: DecksPerPage,
        });

    private onSortChange: WatchableDeckListProps['onSortChange'] = (
        sorting
    ) => {
        const newSorting =
            sorting === this.state.filters.sorting
                ? DefaultFiltersState.sorting
                : sorting;
        this.setState(
            assocPath(['filters', 'sorting'], newSorting),
            this.fetchWatchlistForFilters
        );
    };

    private onPageChange = (currentDeckPage: number) =>
        this.setState({ currentDeckPage }, this.fetchWatchlistForFilters);

    private onAddDeckFromWatchlist = (deckId: Deck['id']) => {
        if (includes(deckId, this.state.removedDeckIds)) {
            this.setState({
                removedDeckIds: without([deckId], this.state.removedDeckIds),
            });
        }
        this.props.requestAddDeckToWatchlist(deckId);
    };

    private onRemoveDeckFromWatchlist = (deckId: Deck['id']) => {
        if (!includes(deckId, this.state.removedDeckIds)) {
            this.setState({
                removedDeckIds: append(deckId, this.state.removedDeckIds),
            });
        }
        this.props.requestRemoveDeckFromWatchlist(deckId);
    };

    private renderWatchlistIcon = (deck: Deck) =>
        !deck.isMyDeck && (
            <IconCheckbox
                variant="watchlist"
                checked={true}
                onChange={() => this.onRemoveDeckFromWatchlist(deck.id)}
                disabled={!deck.isOnWatchlist}
            />
        );

    private renderDeckRowOverlay = (deck: Deck) => {
        const { t } = this.props;
        return includes(deck.id, this.state.removedDeckIds) ? (
            <div className="watchlist__removed-deck">
                <h3 className="watchlist__removed-deck-title">
                    {t('profile.watchlist.deck-was-removed', {
                        defaultValue: 'Deck was removed from “My Watchlist”',
                    })}
                </h3>
                <div className="watchlist__removed-deck-description">
                    <IconCheckbox
                        variant="watchlist"
                        checked={false}
                        onChange={() => this.onAddDeckFromWatchlist(deck.id)}
                        label={t('profile.watchlist.add-to-watchlist-again', {
                            defaultValue: 'Add to Watchlist again',
                        })}
                    />
                </div>
            </div>
        ) : null;
    };

    private renderNoDecks = () => (
        <div className="watchlist__no-decks">
            <h3 className="watchlist__no-decks-heading keyforge-heading-2">
                {this.props.t('profile.watchlist.no-decks-title', {
                    defaultValue: 'No Decks on Watchlist',
                })}
            </h3>
            <p className="watchlist__no-decks-description">
                <Trans i18nKey="profile.watchlist.no-decks">
                    You have no decks on your watchlist.
                    <br />
                    Go to{' '}
                    <Link className="watchlist__no-decks-link" to="/">
                        Home page
                    </Link>{' '}
                    to follow decks!
                </Trans>
            </p>
        </div>
    );
}

const WatchlistHOC = (props: WithNamespaces) => {
    const dispatch = useDispatch();
    const useNewOwnership = useFlag('newDeckOwnership');
    const sliceKey = useNewOwnership ? 'decks' : 'decksLegacy';
    const decksActions = useNewOwnership ? DecksActions : LegacyDecksActions;

    const watchlistReq = useSelector<RootState, Props['watchlistReq']>(
        (state) => state[sliceKey].getWatchlistDecks
    );
    const fetchWatchlist = (
        ...args: Parameters<typeof decksActions.getWatchlistDecksRequest>
    ) => dispatch(decksActions.getWatchlistDecksRequest(...args));
    const requestAddDeckToWatchlist = (
        ...args: Parameters<typeof decksActions.addDeckToWatchlistRequest>
    ) => dispatch(decksActions.addDeckToWatchlistRequest(...args));
    const requestRemoveDeckFromWatchlist = (
        ...args: Parameters<typeof decksActions.removeDeckFromWatchlistRequest>
    ) => dispatch(decksActions.removeDeckFromWatchlistRequest(...args));

    return (
        <Watchlist
            {...{
                watchlistReq,
                fetchWatchlist,
                requestAddDeckToWatchlist,
                requestRemoveDeckFromWatchlist,
                ...props,
            }}
        />
    );
};

export default withNamespaces()(WatchlistHOC);
