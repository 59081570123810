import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Breadcrumbs.scss';

interface IProps {
    deckClaims?: boolean;
}

const Breadcrumbs = ({ deckClaims }: IProps) => {
    const [breadcrumbs, setBreadcrumbs] = useState([]);

    useEffect(() => {
        // Get the current URL path and split it into segments
        const pathSegments = window.location.pathname
            .split('/')
            .filter((segment) => segment !== '');

        // Limit to a maximum of two layers
        const limitedSegments = pathSegments.slice(0, 1);

        // Generate the breadcrumbs with links
        const breadcrumbLinks = limitedSegments.map((segment, index) => {
            // const path = `/${limitedSegments.slice(0, index + 1).join('/')}`; // this can be usefull if component will need to get more depth

            // Capitalize the segment and create the link text
            const segmentText = segment
                .split('-')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');

            return (
                <React.Fragment key={segment}>
                    {index !== 0 && (
                        <span className="breadcrumb-separator">{'>'}</span>
                    )}
                    {segmentText}
                </React.Fragment>
            );
        });

        setBreadcrumbs(breadcrumbLinks);
    }, []);

    return (
        <div className="breadcrumbs">
            <Link to="/" className="breadcrumb-link">
                <p className="breadcrumb-link-text">Master Vault</p>
            </Link>
            {deckClaims && (
                <Link to="/deck-transfers" className="breadcrumb-link">
                    <p className="breadcrumb-link-text">
                        &nbsp;
                        {'>'} Deck Transfers
                    </p>
                </Link>
            )}
            {breadcrumbs.map((breadcrumb, index) => (
                <React.Fragment key={index}>
                    {index === 0 && (
                        <div className="breadcrumb-separator">
                            <span>{'>'}</span>
                        </div>
                    )}
                    <div className="breadcrumb-link">
                        <p className="breadcrumb-link-text">
                            {breadcrumb.props.children}
                        </p>
                    </div>
                </React.Fragment>
            ))}
        </div>
    );
};

export default Breadcrumbs;
