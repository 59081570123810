import { mergeDeepRight } from 'ramda';

import { createReducer } from '@/shared/redux/ReduxHelpers';
import { InitialState, StateType } from './State';
import {
    pendingReducerIn,
    successReducerIn,
    errorReducerIn,
    AsyncStatus,
} from '@/shared/redux/Async';
import {
    Types,
    Creators,
    FetchProfileSuccessAction,
    UpdateProfileSuccessAction,
    GetTransactionsSuccessAction,
} from './Actions';
import {
    Types as PersistedUserTypes,
    AuthorizeUserSuccessAction,
} from '@/shared/redux/persisted_user/Actions';
import { TransactionFilters as ApiTransactionFilters } from '@/shared/api/ApiTypings';

export type TransactionFilters = ApiTransactionFilters;
export const UserProfileTypes = Types;
export default Creators;

export const reducer = createReducer(InitialState, {
    [Types.FETCH_PROFILE_REQUEST]: pendingReducerIn('fetchProfile'),
    [Types.FETCH_PROFILE_SUCCESS]: successReducerIn(
        'fetchProfile',
        fetchProfileSuccess
    ),
    [Types.FETCH_PROFILE_FAILURE]: errorReducerIn('fetchProfile'),

    [Types.UPDATE_PROFILE_REQUEST]: pendingReducerIn('updateProfile'),
    [Types.UPDATE_PROFILE_SUCCESS]: updateProfileSuccess,
    [Types.UPDATE_PROFILE_FAILURE]: errorReducerIn('updateProfile'),

    [Types.GET_TRANSACTIONS_REQUEST]: pendingReducerIn('getTransactions'),
    [Types.GET_TRANSACTIONS_SUCCESS]: successReducerIn(
        'getTransactions',
        getTransactionsSuccess
    ),
    [Types.GET_TRANSACTIONS_FAILURE]: errorReducerIn('getTransactions'),

    [PersistedUserTypes.AUTHORIZE_USER_SUCCESS]: successReducerIn(
        'fetchProfile',
        authorizeUserSuccess
    ),
});

/* ----------------- reducers --------------------- */

function fetchProfileSuccess(
    state: StateType['fetchProfile'],
    action: FetchProfileSuccessAction
): Partial<StateType['fetchProfile']> {
    return { details: action.payload.profile };
}

function updateProfileSuccess(
    state: StateType,
    action: UpdateProfileSuccessAction
): StateType {
    return mergeDeepRight(state, {
        fetchProfile: {
            details: action.payload,
        },
        updateProfile: {
            __status: AsyncStatus.Success,
            __error: null,
        },
    });
}

function getTransactionsSuccess(
    state: StateType['getTransactions'],
    action: GetTransactionsSuccessAction
): Partial<StateType['getTransactions']> {
    return {
        totalCount: action.payload.totalCount,
        list: action.payload.transactions,
    };
}

function authorizeUserSuccess(
    state: StateType['fetchProfile'],
    action: AuthorizeUserSuccessAction
): Partial<StateType['fetchProfile']> {
    // WARN: this will also update `fetchProfile` status to success,
    // this is desired, as we want to keep track of `details` availability,
    // not request itself
    return { details: action.payload.userProfile };
}
