import './AembershardsLogList.scss';

import React, { memo } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';

import AembershardsLogListItem from './AembershardsLogListItem';
import SortInterface from '@/components/common/tables/SortInterface';
import { Transaction } from '@/shared/typings';
import { TransactionFilters } from '@/shared/redux/user_profile';

export interface Props extends WithNamespaces {
    transactions: Transaction[];
    sorting: TransactionFilters['sorting'];
    onSortChange: (sorting: TransactionFilters['sorting']) => void;
}

export function AembershardsLogList({
    transactions,
    sorting,
    onSortChange,
    t,
}: Props) {
    const renderSortInterface = (column: Props['sorting']) => (
        <SortInterface
            column={column}
            sorting={sorting}
            onSortChange={onSortChange}
            className={`transaction-list__sort transaction-list__sort--${column}`}
        />
    );

    return (
        <table className="transaction-list kf-table">
            <thead className="kf-table__head">
                <tr className="kf-table__row">
                    <th className="kf-table__cell transaction-list__date">
                        <span className="kf-table__label-text">
                            {t('transactions.table.header.date', {
                                defaultValue: 'Date',
                            })}
                        </span>
                        {renderSortInterface('date')}
                    </th>
                    <th className="kf-table__cell transaction-list__description">
                        <span className="kf-table__label-text">
                            {t('transactions.table.header.description', {
                                defaultValue: 'Description',
                            })}
                        </span>
                    </th>
                    <th className="kf-table__cell transaction-list__shards transaction-list__shards-changed">
                        <span className="kf-table__label-text">
                            {t('transactions.table.header.shards-changed', {
                                defaultValue: 'Change',
                            })}
                        </span>
                        {renderSortInterface('change')}
                    </th>
                    <th className="kf-table__cell transaction-list__shards transaction-list__shards-total">
                        <span className="kf-table__label-text">
                            {t('transactions.table.header.shards-balance', {
                                defaultValue: 'Balance',
                            })}
                        </span>
                        {renderSortInterface('balance')}
                    </th>
                </tr>
            </thead>
            <tbody className="kf-table__body">
                {transactions.map((transaction) => (
                    <AembershardsLogListItem
                        key={transaction.id}
                        transaction={transaction}
                    />
                ))}
            </tbody>
        </table>
    );
}

export default withNamespaces()(memo(AembershardsLogList));
