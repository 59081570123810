import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withNamespaces, WithNamespaces } from 'react-i18next';

import Homepage from './HomePageV2';
import { RootState } from '@/shared/redux/RootState';
import { DecksActions } from '@/shared/redux/decks';

export interface Props extends WithNamespaces {
    deckCount: number;
    fetchDeckCount: typeof DecksActions['deckCountRequest'];
}

interface State {
    // we're keeping deck count copy in state to prevent clearing its value
    // on store purge (eg. user logout). best option would be to separate it
    // in store, but let's keep it clean for now
    deckCount: number;
}

export class HomePageContainer extends PureComponent<Props, State> {
    public state: State = {
        deckCount: null,
    };

    // Refetch deck count on each mount (when entering this page).
    // We don't care about SSR, because it's not a crucial data for crawlers
    // and slows down response time.
    public componentDidMount() {
        this.props.fetchDeckCount();
        if (this.props.deckCount) {
            this.setState({ deckCount: this.props.deckCount });
        }
    }

    public componentDidUpdate(prevProps: Props) {
        if (
            this.props.deckCount &&
            this.props.deckCount !== 0 &&
            this.props.deckCount !== prevProps.deckCount &&
            this.props.deckCount !== this.state.deckCount
        ) {
            this.setState({ deckCount: this.props.deckCount });
        }
    }

    public render() {
        return <Homepage deckCount={this.state.deckCount || 0} />;
    }
}

const mapStateToProps = (state: RootState) => ({
    deckCount: state.decks.deckCount.count,
});

const mapDispatchToProps = {
    fetchDeckCount: DecksActions.deckCountRequest,
};

export default withNamespaces()(
    connect(mapStateToProps, mapDispatchToProps)(HomePageContainer)
);
