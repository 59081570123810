import { Action } from 'redux';
import {
    DecksSearchFilters as ApiDecksSearchFilters,
    Pagination,
    CreateAlliancePayload,
    AlliancesSearchFilters,
    UpdateAlliancePayload,
    DecksSearchFilters,
} from '@/shared/api/ApiTypings';
import {
    createActions,
    PayloadAction,
    PayloadOnlyActionHandler,
    RequestFailureActionHandler,
} from '@/shared/redux/ReduxHelpers';
import { Deck } from '@/shared/typings';
import { Alliance } from '@/shared/typings/Alliance.d';
import { StateType } from './State';

export const { Types, Creators } = createActions<{
    deckSuggestionsRequest: (
        searchFilters: DecksSearchFilters,
        pagination: Pagination
    ) => DeckSuggestionsRequestAction;
    deckSuggestionsSuccess: PayloadOnlyActionHandler<{
        decks: Deck[];
        totalCount: number;
    }>;
    deckSuggestionsFailure: RequestFailureActionHandler<{
        error: StateType['deckSuggestions']['__error'];
    }>;

    createAllianceRequest: PayloadOnlyActionHandler<CreateAlliancePayload>;
    createAllianceSuccess: () => Action;
    createAllianceFailure: PayloadOnlyActionHandler<{
        error: StateType['creatingAlliance']['__error'];
    }>;
    createAllianceReset: () => Action;

    updateAllianceRequest: (
        alliance: UpdateAlliancePayload
    ) => UpdateAllianceRequestAction;
    updateAllianceSuccess: () => Action;
    updateAllianceFailure: PayloadOnlyActionHandler<{
        error: StateType['updatingAlliance']['__error'];
    }>;
    updateAllianceReset: () => Action;

    userAlliancesRequest: (
        searchFilters: DecksSearchFilters,
        pagination: Pagination
    ) => UserAlliancesRequestAction;
    userAlliancesSuccess: PayloadOnlyActionHandler<{
        list: Alliance[];
        totalCount: number;
    }>;
    userAlliancesFailure: PayloadOnlyActionHandler<{
        error: StateType['userAlliances']['__error'];
    }>;

    getAllianceRequest: (allianceId: string) => GetAllianceRequestAction;
    getAllianceSuccess: PayloadOnlyActionHandler<Alliance>;
    getAllianceFailure: PayloadOnlyActionHandler<{
        error: StateType['getAllianceData']['__error'];
    }>;
}>({
    // Action types & creators
    deckSuggestionsRequest: ['searchFilters', 'pagination'],
    deckSuggestionsSuccess: ['payload'],
    deckSuggestionsFailure: ['payload'],

    createAllianceRequest: ['payload'],
    createAllianceSuccess: ['payload'],
    createAllianceFailure: ['payload'],
    createAllianceReset: null,

    updateAllianceRequest: ['payload'],
    updateAllianceSuccess: ['payload'],
    updateAllianceFailure: ['payload'],
    updateAllianceReset: null,

    userAlliancesRequest: ['searchFilters', 'pagination'],
    userAlliancesSuccess: ['payload'],
    userAlliancesFailure: ['payload'],

    getAllianceRequest: ['payload'],
    getAllianceSuccess: ['payload'],
    getAllianceFailure: ['payload'],
});

export interface DeckSuggestionsRequestAction extends Action {
    searchFilters: ApiDecksSearchFilters;
    pagination: Pagination;
}

export interface UserAlliancesRequestAction extends Action {
    searchFilters: AlliancesSearchFilters;
    pagination: Pagination;
}

export interface UserAlliancesSuccessAction
    extends PayloadAction<{
        list: Alliance[];
        totalCount: number;
    }> {}

export interface GetAllianceRequestAction extends PayloadAction<string> {}
export interface GetAllianceSuccessAction extends PayloadAction<Alliance> {}
export interface UpdateAllianceRequestAction
    extends PayloadAction<UpdateAlliancePayload> {}
