import DeckList from '@/components/common/deck_lists/DeckList';
import TextInput from '@/components/common/inputs/TextInput';
import PaginatedTable from '@/components/common/tables/PaginatedTable';
import { AsyncStatus } from '@/shared/redux/Async';
import { DecksActions } from '@/shared/redux/decks';
import { RootState } from '@/shared/redux/RootState';
import { UserDecksActions } from '@/shared/redux/user_decks';
import { DecksSearchFilters } from '@/shared/api/ApiTypings';
import classNames from 'classnames';
import { assoc, assocPath, evolve } from 'ramda';
import React, { Component, SyntheticEvent } from 'react';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import './SearchDecks.scss';

export interface Props extends WithNamespaces {
    searchedDecks: RootState['userDecks']['userDecks'];
    searchDecks: typeof UserDecksActions.userDecksRequest;
    fetchRecentDecks: typeof DecksActions.getRecentDecksRequest;
    recentDecks: RootState['decks']['recentDecks'];
    className?: string;
}

export interface State {
    searchedDecksCurrentPage: number;
    recentDecksCurrentPage: number;
    /* UI filters, e.g. user types search text */
    filters: DecksSearchFilters;
    /* Gets updated after form submit */
    appliedFilters: DecksSearchFilters;
}

const DecksPerPage = 10;

export const DefaultFiltersState: DecksSearchFilters = {
    searchText: '',
    sorting: undefined,
};

export class SearchDecks extends Component<Props, State> {
    public state: State = {
        searchedDecksCurrentPage: 0,
        recentDecksCurrentPage: 0,
        filters: DefaultFiltersState,
        appliedFilters: DefaultFiltersState,
    };

    public componentDidMount() {
        this.fetchRecentDecks();
    }

    public render() {
        const { searchedDecks, recentDecks, className, t } = this.props;
        const {
            searchedDecksCurrentPage,
            recentDecksCurrentPage,
            appliedFilters,
        } = this.state;

        return (
            <div className={classNames('search-decks', className)}>
                <form
                    className="search-decks__input search-decks__search"
                    onSubmit={(event) => {
                        event.preventDefault();
                        this.applyNewFilters();
                    }}
                >
                    <h2 className="search-decks__input-label keyforge-heading-1">
                        {t('homepage.header.search', {
                            defaultValue: 'Search Decks',
                        })}
                    </h2>

                    <div className="search-decks__input-group">
                        <div className="search-decks__search-input">
                            <TextInput
                                className="search-decks__input-group-field"
                                placeholder={t(
                                    'search-decks.input.placeholder',
                                    {
                                        defaultValue: 'Input deck name',
                                    }
                                )}
                                ariaLabel={t('search-decks.input.label', {
                                    defaultValue: 'Search text input',
                                })}
                                value={this.state.filters.searchText}
                                onChange={this.handleSearchTextChange}
                            />
                            <button
                                type="button"
                                disabled={!this.state.filters.searchText}
                                className="search-decks__search-clear btn-clear"
                                onClick={this.clearSearch}
                            >
                                {t('general.hint.clear', {
                                    defaultValue: 'Clear',
                                })}
                            </button>
                        </div>
                        <button
                            className="search-decks__input-group-btn search-decks__btn-search btn-small"
                            type="submit"
                        >
                            <span className="btn-content">
                                {t('search-decks.btn.search', {
                                    defaultValue: 'Search',
                                })}
                            </span>
                        </button>
                    </div>
                </form>

                {appliedFilters.searchText && (
                    <PaginatedTable
                        className="search-decks__search-table"
                        header={
                            <div className="search-decks__decks-list-header">
                                <div className="search-decks__decks-list-total">
                                    {this.props.t(
                                        'decks-list.hint.results-count',
                                        {
                                            defaultValue:
                                                'Search Results ({{decksCount}})',
                                            decksCount: this.props.searchedDecks
                                                .totalCount,
                                        }
                                    )}
                                </div>
                            </div>
                        }
                        currentPage={searchedDecksCurrentPage}
                        pageCount={this.getPageCount(searchedDecks.totalCount)}
                        onPageChange={this.onSearchPageChange}
                        isLoading={
                            searchedDecks.__status !== AsyncStatus.Success
                        }
                    >
                        <DeckList
                            decks={searchedDecks.list}
                            showExpansionColumn={false}
                            onSortChange={this.onSortChange}
                            sorting={appliedFilters.sorting}
                        />
                    </PaginatedTable>
                )}

                <PaginatedTable
                    header={
                        <h2 className="keyforge-heading-1">
                            {t('search-decks.header.recent-decks', {
                                defaultValue: 'Recently Discovered Decks',
                            })}
                        </h2>
                    }
                    showTopPagination={false}
                    currentPage={recentDecksCurrentPage}
                    pageCount={this.getPageCount(recentDecks.totalCount)}
                    onPageChange={this.onRecentsPageChange}
                    isLoading={recentDecks.__status !== AsyncStatus.Success}
                >
                    <DeckList
                        decks={recentDecks.list}
                        showExpansionColumn={false}
                        sorting={null}
                    />
                </PaginatedTable>
            </div>
        );
    }

    private getPageCount = (totalCount: number) =>
        Math.ceil(totalCount / DecksPerPage);

    private onSortChange = (sorting: DecksSearchFilters['sorting']) => {
        const newSorting =
            sorting === this.state.filters.sorting
                ? DefaultFiltersState.sorting
                : sorting;
        this.setState(
            evolve({
                filters: assoc('sorting', newSorting),
                appliedFilters: assoc('sorting', newSorting),
            }),
            this.fetchDecksForFilters
        );
    };

    private onSearchPageChange = (page: number) =>
        this.setState(
            { searchedDecksCurrentPage: page },
            this.fetchDecksForFilters
        );

    private onRecentsPageChange = (page: number) =>
        this.setState({ recentDecksCurrentPage: page }, this.fetchRecentDecks);

    private fetchDecksForFilters = () => {
        this.props.searchDecks(this.state.appliedFilters, {
            page: this.state.searchedDecksCurrentPage,
            pageSize: DecksPerPage,
        });
    };

    private fetchRecentDecks = () => {
        this.props.fetchRecentDecks({
            page: this.state.recentDecksCurrentPage,
            pageSize: DecksPerPage,
        });
    };

    private applyNewFilters = () =>
        this.setState(
            {
                searchedDecksCurrentPage: 0,
                appliedFilters: this.state.filters,
            },
            this.fetchDecksForFilters
        );

    private handleSearchTextChange = (e: SyntheticEvent<HTMLInputElement>) => {
        this.setState(
            assocPath(['filters', 'searchText'], e.currentTarget.value)
        );
    };

    private clearSearch = () => {
        this.setState(
            assocPath(['filters', 'searchText'], ''),
            this.state.appliedFilters.searchText
                ? this.applyNewFilters
                : undefined
        );
    };
}

const mapStateToProps = (state: RootState) => ({
    searchedDecks: state.decks.searchDecks,
    recentDecks: state.decks.recentDecks,
});

const mapDispatchToProps = {
    searchDecks: DecksActions.searchDecksRequest,
    fetchRecentDecks: DecksActions.getRecentDecksRequest,
};

export default withNamespaces()(
    connect(mapStateToProps, mapDispatchToProps)(SearchDecks)
);
