import { put, call, select } from 'redux-saga/effects';

import TournamentsActions from '@/shared/redux/tournaments';
import {
    GetPlayerLeaderboardRequestAction,
    GetDeckLeaderboardRequestAction,
} from '@/shared/redux/tournaments/Actions';
import API from '@/shared/api/Api';
import {
    TournamentPlayerLeaderboardResponse,
    TournamentDeckLeaderboardResponse,
    GeneralErrorResponse,
} from '@/shared/api/ApiTypings';
import {
    normalizeTournamentPlayers,
    normalizeTournamentDecks,
} from '@/shared/sagas/Normalizers';
import { getAllHousesDict } from '@/shared/redux/Selectors';

export function* fetchPlayerLeaderboard(
    api: API,
    action: GetPlayerLeaderboardRequestAction
) {
    const response: TournamentPlayerLeaderboardResponse = yield call(
        api.getPlayerLeaderboard,
        action.tournament,
        action.pagination
    );

    if (response.status === 200) {
        const players = normalizeTournamentPlayers(response.data.data);
        yield put(
            TournamentsActions.getPlayerLeaderboardSuccess({
                players,
                totalCount: response.data.count,
                participantCount: response.data.total_participants_number,
                userRank: response.data.user_rank,
            })
        );
    } else {
        yield put(
            TournamentsActions.getPlayerLeaderboardFailure({
                error: (response as unknown) as GeneralErrorResponse,
            })
        );
    }
}

export function* fetchDeckLeaderboard(
    api: API,
    action: GetDeckLeaderboardRequestAction
): Iterator<any> {
    const response: TournamentDeckLeaderboardResponse = yield call(
        api.getDeckLeaderboard,
        action.tournament,
        action.filters,
        action.pagination
    );
    if (response.status === 200) {
        const allHouses = yield select(getAllHousesDict);
        yield put(
            TournamentsActions.getDeckLeaderboardSuccess({
                decks: normalizeTournamentDecks(response.data.data, allHouses),
                totalCount: response.data.count,
            })
        );
    } else {
        yield put(
            TournamentsActions.getDeckLeaderboardFailure({
                error: (response as unknown) as GeneralErrorResponse,
            })
        );
    }
}
