import './PaginatedTable.scss';
import classNames from 'classnames';

import React, { ReactNode, ReactElement } from 'react';

import Pagination from './Pagination';

export interface Props {
    // table component
    children: ReactElement<any>;
    header?: ReactNode;
    isLoading?: boolean;
    className?: string;

    currentPage: number;
    pageCount: number;
    onPageChange: (page: number) => void;
    showTopPagination?: boolean;
}

export function PaginatedTable(props: Props) {
    const { showTopPagination = true, className } = props;

    const pagination = (
        <div className="paginated-table__pagination">
            <Pagination
                currentPage={props.currentPage}
                pageCount={props.pageCount}
                onPageChange={props.onPageChange}
            />
        </div>
    );

    return (
        <div className={classNames('paginated-table', className)}>
            {(props.header || showTopPagination) && (
                <div className="paginated-table__header">
                    {props.header}
                    {showTopPagination && pagination}
                </div>
            )}

            <div className="paginated-table__table">
                {props.children}
                {props.isLoading && <div className="spinner" />}
            </div>

            <div className="paginated-table__footer">{pagination}</div>
        </div>
    );
}

export default PaginatedTable;
