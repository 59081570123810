import React from 'react';
import { connect } from 'react-redux';

import { RootState } from '@/shared/redux/RootState';
import UserProfilePage from './UserProfilePage';
import UserProfileActions from '@/shared/redux/user_profile';

interface StateProps {
    profile: RootState['userProfile']['fetchProfile']['details'];
    userRank: number;
    participantCount: number;
}

interface DispatchProps {
    refreshUserProfile: typeof UserProfileActions['fetchProfileRequest'];
    toggleUserAnonymous: (isAnonymous: boolean) => void;
}

export type Props = StateProps & DispatchProps;

export class UserProfilePageContainer extends React.PureComponent<Props> {
    public componentDidMount() {
        this.props.refreshUserProfile();
    }

    public render() {
        const { profile } = this.props;
        return (
            <UserProfilePage
                refreshUserProfile={this.props.refreshUserProfile}
                profile={profile}
                toggleUserAnonymous={this.toggleUserAnonymous}
            />
        );
    }

    private toggleUserAnonymous = () => {
        this.props.toggleUserAnonymous(!this.props.profile.isAnonymous);
    };
}

const mapStateToProps = (state: RootState): StateProps => ({
    profile: state.userProfile.fetchProfile.details,
    userRank: state.tournaments.getPlayerLeaderboard.userRank,
    participantCount: state.tournaments.getPlayerLeaderboard.participantCount,
});

const mapDispatchToProps: DispatchProps = {
    refreshUserProfile: UserProfileActions.fetchProfileRequest,
    toggleUserAnonymous: (isAnonymous) =>
        UserProfileActions.updateProfileRequest({ isAnonymous }),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserProfilePageContainer);
