import './AembershardsLog.scss';

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { assocPath } from 'ramda';

import AembershardsLogList, { Props as ListProps } from './AembershardsLogList';
import PaginatedTable from '@/components/common/tables/PaginatedTable';
import { AsyncStatus } from '@/shared/redux/Async';
import { RootState } from '@/shared/redux/RootState';
import { Transaction } from '@/shared/typings';
import UserProfileActions, {
    TransactionFilters,
} from '@/shared/redux/user_profile';

const TransactionsPerPage = 5;

export const DefaultFiltersState: TransactionFilters = {
    sorting: '-date',
};

interface StateProps {
    list: Transaction[];
    totalCount: number;
    fetchingStatus: AsyncStatus;
}

interface DispatchProps {
    fetchTransactions: (typeof UserProfileActions)['getTransactionsRequest'];
}

export type Props = StateProps & DispatchProps & WithNamespaces;

interface State {
    currentPage: number;
    filters: TransactionFilters;
}

export class AembershardsLog extends PureComponent<Props, State> {
    public state: State = {
        currentPage: 0,
        filters: DefaultFiltersState,
    };

    // Fetch transactions only on client side, web crawlers don't need this data
    public componentDidMount() {
        this.fetchTransactionsForFilters();
    }

    public render() {
        const { list, totalCount, fetchingStatus, t } = this.props;
        const { currentPage, filters } = this.state;
        return (
            <div className="transactions">
                {totalCount > 0 ? (
                    <PaginatedTable
                        currentPage={currentPage}
                        pageCount={Math.ceil(totalCount / TransactionsPerPage)}
                        onPageChange={this.onPageChange}
                        isLoading={fetchingStatus !== AsyncStatus.Success}
                    >
                        <AembershardsLogList
                            transactions={list}
                            sorting={filters.sorting}
                            onSortChange={this.onSortChange}
                        />
                    </PaginatedTable>
                ) : (
                    <div className="transactions__empty">
                        <h3 className="transactions__empty-heading keyforge-heading-2">
                            {t('transactions.hint.no-transactions', {
                                defaultValue: 'You have no transactions',
                            })}
                        </h3>
                    </div>
                )}
            </div>
        );
    }

    private fetchTransactionsForFilters = () => {
        this.props.fetchTransactions(this.state.filters, {
            page: this.state.currentPage,
            pageSize: TransactionsPerPage,
        });
    };

    private onSortChange: ListProps['onSortChange'] = (sorting) => {
        // don't update if user selects the default sorting twice
        if (
            sorting === this.state.filters.sorting &&
            sorting === DefaultFiltersState.sorting
        ) {
            return;
        }

        // reset to default if user selects the same sorting twice
        const newSorting =
            sorting === this.state.filters.sorting
                ? DefaultFiltersState.sorting
                : sorting;

        this.setState(
            assocPath(['filters', 'sorting'], newSorting),
            this.fetchTransactionsForFilters
        );
    };

    private onPageChange = (currentPage: number) =>
        this.setState({ currentPage }, this.fetchTransactionsForFilters);
}

const mapStateToProps = (state: RootState): StateProps => ({
    list: state.userProfile.getTransactions.list,
    totalCount: state.userProfile.getTransactions.totalCount,
    fetchingStatus: state.userProfile.getTransactions.__status,
});

const mapDispatchToProps: DispatchProps = {
    fetchTransactions: UserProfileActions.getTransactionsRequest,
};

export default withNamespaces()(
    connect(mapStateToProps, mapDispatchToProps)(AembershardsLog)
);
