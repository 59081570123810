import { propEq } from 'ramda';
import { WithNamespaces } from 'react-i18next';
import { Expansion, House } from '@/shared/typings';

const parseHouseId = (houseId: string) =>
    houseId.toLowerCase().replace(' ', '-');

export const getTranslatedHouseName = (house: House, t: WithNamespaces['t']) =>
    t(`general.house.${parseHouseId(house.id)}`, {
        defaultValue: house.name,
    });

export function getTranslatedExpansionName(
    id: number | undefined,
    expansions: Expansion[],
    t: WithNamespaces['t']
): string {
    const exp = expansions.find(propEq('id', id));
    return exp
        ? t(`general.expansions.${id}`, { defaultValue: exp.name })
        : t('general.expansions.unknown', {
              defaultValue: 'Unknown expansion',
          });
}
