import './HomeAside.scss';
import GoToMyDecks from '@/components/common/GoToMyDecks';

import TextAdaptedToContainer from '@/components/common/TextAdaptedToContainer';
import { RootState } from '@/shared/redux/RootState';
import { isAuthorized } from '@/shared/redux/Selectors';
import classNames from 'classnames';
import React from 'react';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

export type Props = WithNamespaces & {
    className?: string;
    isUserAuthorized: boolean;
};

export const HomeAside = ({ isUserAuthorized, t, className }: Props) => {
    return (
        <aside className={classNames('home-aside', className)}>
            <div className="home-aside__section home-aside__my-decks">
                <GoToMyDecks isUserAuthorized={isUserAuthorized} />
            </div>

            <div className="home-aside__section">
                <h2 className="keyforge-heading-2 home-aside__section-title">
                    <TextAdaptedToContainer>
                        {t('home-page.header.manage-keyforge', {
                            defaultValue: 'Manage My KeyForge',
                        })}
                    </TextAdaptedToContainer>
                </h2>
                <p className="home-aside__section-description">
                    {t('home-page.header-hint.manage-keyforge', {
                        defaultValue:
                            'Record each of your unique Archon Decks here and track their progress as you participate in events and explore the world of the Crucible.',
                    })}
                </p>
                <Link
                    to="/profile"
                    className="home-aside__section-link home-aside__link"
                >
                    <TextAdaptedToContainer>
                        {t('home-page.btn.manage-keyforge', {
                            defaultValue: 'My Profile & Decks',
                        })}
                    </TextAdaptedToContainer>
                </Link>
            </div>
        </aside>
    );
};

const mapStateToProps = (state: RootState) => ({
    isUserAuthorized: isAuthorized(state),
});

export default withNamespaces()(connect(mapStateToProps)(HomeAside));
