import React, { ReactNode } from 'react';

interface Props {
    to: string;
    className: string;
    children: ReactNode;
}

const ExternalLink = ({ to, className, children }: Props) => (
    <a
        href={to}
        className={className}
        target="_blank"
        rel="noopener noreferrer"
    >
        {children}
    </a>
);

export default ExternalLink;
