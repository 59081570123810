import React from 'react';
import { Route, Redirect, RedirectProps } from 'react-router-dom';

interface Props extends RedirectProps {
    status: 301 | 302;
}

export default function RedirectWithStatus({ from, to, status }: Props) {
    return (
        <Route
            render={({ staticContext }) => {
                if (staticContext) {
                    staticContext.statusCode = status;
                }
                return <Redirect from={from} to={to} />;
            }}
        />
    );
}
