import './LinkMenuItem.scss';

import { NavLink } from 'react-router-dom';
import React, { FC } from 'react';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import ExternalLink from '@/components/common/ExternalLink';
import classNames from 'classnames';
import { MenuVariant } from './PageHeader';

interface OwnProps {
    text?: {
        key: string;
        value: string;
    };
    menuVariant?: MenuVariant;
    className?: string;
    link: {
        to: string;
        external: boolean;
    };
}

export type Props = OwnProps & WithNamespaces;

export const LinkMenuItem: FC<Props> = ({
    text,
    menuVariant,
    className,
    link,
    t,
    children,
}) => {
    const LinkComponent = link.external ? ExternalLink : NavLink;
    return (
        <li
            className={classNames(
                'menu-item',
                menuVariant ? `menu-item--${menuVariant}` : '',
                className
            )}
        >
            <LinkComponent
                to={link.to}
                className="menu-item__link"
                activeClassName="menu-item__link--active"
            >
                {children}
                {text.value &&
                    t(text.key, {
                        defaultValue: text.value,
                    })}
            </LinkComponent>
        </li>
    );
};

export default withNamespaces()(LinkMenuItem);
