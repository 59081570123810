import { useMutation, useQuery } from '@tanstack/react-query';
import { API } from '@/shared/api/Api';

export const useInitPayment = () => {
    return useMutation({
        mutationFn: () => API.initPayment(),
        onSuccess: ({ data }) => {
            window.location.replace(
                (data as { redirectUrl: string }).redirectUrl
            );
        },
    });
};

export const useGetPaymentStatus = (paymentId, enabled = false) => {
    return useQuery({
        queryKey: ['payment-status', paymentId],
        queryFn: () => API.getPaymentStatus(paymentId),
        refetchInterval: 5000,
        enabled: enabled,
    });
};

export const useGetUserPayments = (page, page_size, opts = {}) => {
    return useQuery<any, Record<string, any>[], any>({
        queryKey: ['payments', page, page_size],
        queryFn: () => API.getUserPayments(page, page_size),
        ...opts
    });
};
