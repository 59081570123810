import CardImageTooltip from '@/components/single_deck/CardImageTooltip';
import DeckCardBadge, {
    getCardBadges,
} from '@/components/single_deck/DeckCardBadge';
import RarityCommon from '@/images/single_deck/card_rarity/common.svg';
import RarityEvilTwin from '@/images/single_deck/card_rarity/evil-twin.svg';
import RarityRare from '@/images/single_deck/card_rarity/rare.svg';
import RaritySpecial from '@/images/single_deck/card_rarity/special.svg';
import RarityUncommon from '@/images/single_deck/card_rarity/uncommon.svg';
import TypeAction from '@/images/single_deck/card_type/action.svg';
import TypeArtifact from '@/images/single_deck/card_type/artifact.svg';
import TypeCreature from '@/images/single_deck/card_type/creature.svg';
import TypeTide from '@/images/single_deck/card_type/tide.svg';
import TypeUpgrade from '@/images/single_deck/card_type/upgrade.svg';
import TypeTokenCreature from '../../images/single_deck/card_type/token_creature.svg';
import { CardRarity, CardType } from '@/shared/redux/decks/State';
import { Card, Deck } from '@/shared/typings';
import classNames from 'classnames';
import React, { Component } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import './CardsList.scss';
import { BonusIconType } from '@/shared/api/ApiTypings';
import * as process from 'process';

interface IconSettings {
    icon: string;
    tooltip: {
        key: string;
        value: string;
    };
}

const typeIconSettings: Record<CardType, IconSettings> = {
    [CardType.Action]: {
        icon: TypeAction,
        tooltip: { key: 'card.type.action', value: 'Action' },
    },
    [CardType.Artifact]: {
        icon: TypeArtifact,
        tooltip: { key: 'card.type.artifact', value: 'Artifact' },
    },
    [CardType.Creature]: {
        icon: TypeCreature,
        tooltip: { key: 'card.type.creature', value: 'Creature' },
    },
    [CardType.Upgrade]: {
        icon: TypeUpgrade,
        tooltip: { key: 'card.type.upgrade', value: 'Upgrade' },
    },
    [CardType.Tide]: {
        icon: TypeTide,
        tooltip: { key: 'card.type.tide', value: 'The Tide' },
    },
    [CardType.TokenCreature]: {
        icon: TypeTokenCreature,
        tooltip: { key: 'card.type.tokenCreature', value: 'Token Creature' },
    },
};

const rarityIconSettings: Record<CardRarity, IconSettings> = {
    [CardRarity.Common]: {
        icon: RarityCommon,
        tooltip: { key: 'card.rarity.common', value: 'Common' },
    },
    [CardRarity.Uncommon]: {
        icon: RarityUncommon,
        tooltip: { key: 'card.rarity.uncommon', value: 'Uncommon' },
    },
    [CardRarity.Rare]: {
        icon: RarityRare,
        tooltip: { key: 'card.rarity.rare', value: 'Rare' },
    },
    [CardRarity.Special]: {
        icon: RaritySpecial,
        tooltip: { key: 'card.rarity.special', value: 'Special' },
    },
    [CardRarity.EvilTwin]: {
        icon: RarityEvilTwin,
        tooltip: { key: 'card.rarity.evil_twin', value: 'Evil Twin' },
    },
};

export interface CardListProps extends WithNamespaces {
    cards: Card[];
    className?: string;
    legacyCardsIds: Deck['setEraCards']['Legacy'];
    anomalyCardsIds: Deck['setEraCards']['Anomaly'];
    editable?: boolean;
    otherCards?: boolean;
}

export class CardsList extends Component<CardListProps> {
    public render = () => {
        const { cards, className, editable, otherCards } = this.props;

        return (
            <ul className={classNames('cards-list', className)}>
                {cards.map((card: Card, index: number) => {
                    const badges = getCardBadges(
                        card,
                        this.props.legacyCardsIds,
                        this.props.anomalyCardsIds
                    );
                    const [bonusIcons, otherBadges] = badges.reduce(
                        (acc, badge) => {
                            const bonuses: BonusIconType[] = [
                                'amber',
                                'damage',
                                'draw',
                                'capture',
                                'discard',
                                'brobnar',
                                'dis',
                                'ekwidon',
                                'geistoid',
                                'logos',
                                'mars',
                                'skyborn'
                            ];
                            const arr = bonuses.includes(badge as any) ? 0 : 1;
                            acc[arr].push(badge);
                            return acc;
                        },
                        [[], []]
                    );

                    if(card.cardType === 5 && !otherCards) return null;

                    return (
                        <li className="cards-list__card" key={index}>
                            <span className="cards-list__card-type">
                                {this.renderCardType(card.cardType)}
                            </span>
                            <span className="cards-list__card-number">
                                {card.indexInExpansion}
                            </span>
                            <span className="cards-list__card-rarity">
                                {this.renderCardRarity(card.rarity)}
                            </span>
                            <div className="cards-list__card-name">
                                <CardImageTooltip
                                    imageUrl={card.imageUrl}
                                    label={card.name}
                                    badges={badges}
                                >
                                    <div className="cards-list__card-name-wrap">
                                        <span
                                            className={classNames(
                                                'cards-list__card-name-text',
                                                card.isEnhanced &&
                                                    'cards-list__card-name-text--enhanced'
                                            )}
                                        >
                                            {card.name}
                                        </span>
                                        {otherBadges.length > 0 && (
                                            <div className="cards-list__card-badges">
                                                {otherBadges.map((badge) => (
                                                    <DeckCardBadge
                                                        key={badge}
                                                        badge={badge}
                                                    />
                                                ))}
                                            </div>
                                        )}
                                        {bonusIcons.length > 0 && (
                                            <div className="cards-list__card-bonus-icons">
                                                {bonusIcons.map((badge, i) => (
                                                    <DeckCardBadge
                                                        key={badge + i}
                                                        badge={badge}
                                                    />
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </CardImageTooltip>
                            </div>
                        </li>
                    );
                })}
            </ul>
        );
    };

    private renderCardType = (type: Card['cardType']) => {
        if (type === null) {
            if (process.env.NODE_ENV !== 'test') {
                console.warn(`CardType: Unknown type "${type} in CardTable"`);
            }
            return null;
        }

        const translatedType = this.props.t(
            typeIconSettings[type].tooltip.key,
            {
                defaultValue: typeIconSettings[type].tooltip.value,
            }
        );

        return this.renderCardIcon(typeIconSettings[type].icon, translatedType);
    };

    private renderCardRarity = (rarity: Card['rarity']) => {
        if (rarity === null) {
            console.warn(`CardRarity: Unknown rarity "${rarity} in CardTable"`);
            return null;
        }

        const translatedRarity = this.props.t(
            rarityIconSettings[rarity].tooltip.key,
            {
                defaultValue: rarityIconSettings[rarity].tooltip.value,
            }
        );

        return this.renderCardIcon(
            rarityIconSettings[rarity].icon,
            translatedRarity
        );
    };

    private renderCardIcon = (src: string, title: string) => (
        <img
            className="cards-list__card-icon"
            src={src}
            alt={title}
            title={title}
        />
    );
}

export default withNamespaces()(CardsList);
