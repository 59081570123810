import {
    default as apisauce,
    ApisauceInstance,
    Monitor,
    ApiResponse,
} from 'apisauce';
import {
    EmptyResponse,
    LoginResponse,
    AddDeckResponse,
    GetDeckResponse,
    SearchDecksResponse,
    DecksSearchFilters,
    DeckWatchlistFilters,
    TransactionFilters,
    TournamentDeckFilters,
    GetGameStoreResponse,
    SearchGameStoresResponse,
    GameStoresSearchFilters,
    Pagination,
    CasualScores,
    GetHousesResponse,
    DeckCountResponse,
    NoteResponse,
    UserProfileResponse,
    UserTransactionsResponse,
    AddDeckToFavoritesResponse,
    AddDeckToWatchlistResponse,
    UpdateDeckCasualScoresResponse,
    AvailableTournaments,
    TournamentPlayerLeaderboardResponse,
    TournamentDeckLeaderboardResponse,
    PublicSearchDecksResponse,
    CreateAllianceResponse,
    CreateAlliancePayload,
    AlliancesSearchFilters,
    GetAllianceResponse,
    UpdateAlliancePayload,
    UpdateAllianceResponse,
    GetUserAlliancesResponse,
    GetFriendsPayload,
    SearchUsersPayload,
    FriendsDecksPayload,
    DeckTransfersPayload,
} from './ApiTypings';
import { isBrowser } from '../services/Utils';

const v2 = (addV2?: boolean) => (addV2 ? 'v2/' : '');

let API: Api;

export default class Api {
    private static unSauce = <T>(response: ApiResponse<T>): T => {
        // Cancel out apisauce silencing errors and wrapping data.
        // For usage with react-query it's more convenient if failed requests throw.
        if (!response.ok) {
            throw response.originalError;
        }
        return response.data;
    };

    private api: ApisauceInstance;

    constructor(apisauceRef = apisauce.create) {
        if (
            process.env.NODE_ENV === 'test' &&
            apisauceRef === apisauce.create &&
            // Allow creating the singleton,
            // but force to pass a factory in test env
            !!API
        ) {
            throw new Error(
                'In tests you should use mock instead of using real API instance'
            );
        }
        this.api = apisauceRef({
            baseURL: process.env.SSR
                ? process.env.LOCAL_API_URL || process.env.API_URL
                : process.env.API_URL,
            timeout: 10 * 1500,
        });

        // Prevent JSON circulation and reduce response size in SSR
        if (!isBrowser) {
            this.api.addResponseTransform((response) => {
                if (!response.ok) {
                    response.originalError = null;
                }
            });
        }
    }

    public addMonitor(monitor: Monitor) {
        this.api.addMonitor(monitor);
    }

    public setAcceptLanguage(lang = 'en-US') {
        return this.api.setHeader(
            'Accept-Language',
            lang + (lang.toLowerCase() === 'en-us' ? '' : ',en-US;q=0.9')
        );
    }

    public setApiToken = (apiToken: string) => {
        if (apiToken) {
            const authToken = `Token ${apiToken}`;
            // we use both headers, as in Safari/IE there is a bug that overrides
            // `Authorization` XHR headers when website is using basic auth
            this.api.setHeader('X-Authorization', authToken);
            this.api.setHeader('Authorization', authToken);
        } else if (apiToken === null) {
            delete this.api.headers['X-Authorization'];
            delete this.api.headers.Authorization;
        }
    };

    public authorizeUser = (
        idToken: string,
        accessToken: string,
        nonce: string
    ): Promise<LoginResponse> =>
        this.api.post('/users/login/keybringer/', {
            id_token: idToken,
            access_token: accessToken,
            nonce,
        });

    public logout = () => {
        this.api.post('/users/logout/');
        this.setApiToken(null);
    };

    public addDeck = (
        code: string,
        legacy?: boolean
    ): Promise<AddDeckResponse> =>
        this.api.post(`/decks/` + v2(!legacy), {
            code: code ? code.toUpperCase() : '',
        });

    public getUserDecks = (
        userId: string,
        filters: DecksSearchFilters,
        pagination: Pagination,
        legacy?: boolean
    ): Promise<SearchDecksResponse> =>
        this.api.get(`/users/${v2(!legacy)}${userId}/decks/`, {
            // api indexing pages from 1 instead of 0 for some unknown reason ;)
            page: pagination.page + 1,
            page_size: pagination.pageSize,
            search: filters.searchText,
            expansion: filters.expansionId
                ? parseInt(filters.expansionId, 10)
                : null,
            houses:
                filters.houses && filters.houses.length > 0
                    ? filters.houses.join(',')
                    : null,
            legacy_ownership: filters.legacyOwnership,
            ordering: filters.sorting,
            only_favorites: filters.favoriteOnly ? 1 : undefined,
            links: filters.includeCardsDetails ? 'cards' : undefined,
        });

    public searchDecks = (
        filters: DecksSearchFilters,
        pagination: Pagination
    ): Promise<PublicSearchDecksResponse> =>
        this.api.get('/decks/explore/', {
            // api indexing pages from 1 instead of 0 for some unknown reason ;)
            page: pagination.page + 1,
            page_size: pagination.pageSize,
            search: filters.searchText,
            order: filters.sorting,
        });

    public getTotalDeckCount = (): Promise<DeckCountResponse> =>
        this.api.get('/decks/count/');

    public getDeck = (
        deckId: string,
        legacy?: boolean
    ): Promise<GetDeckResponse> =>
        this.api.get(`/decks/${v2(!legacy)}${deckId}/`, {
            links: 'cards,notes,accolades',
        });

    public deleteUserDeck = (
        deckId: string,
        legacy?: boolean
    ): Promise<EmptyResponse> =>
        this.api.delete(`/decks/${v2(!legacy)}${deckId}/`);

    public getHouses = (): Promise<GetHousesResponse> =>
        this.api.get('/decks/houses/');

    public addNoteToDeck = (
        userId: string,
        deckId: string,
        noteText: string,
        legacy: boolean
    ): Promise<NoteResponse> =>
        this.api.post(`/users/${v2(!legacy)}${userId}/decks/${deckId}/notes/`, {
            text: noteText,
        });

    public updateDeckNote = (
        userId: string,
        deckId: string,
        noteId: string,
        noteText: string,
        legacy: boolean
    ): Promise<NoteResponse> =>
        this.api.put(
            `/users/${v2(!legacy)}${userId}/decks/${deckId}/notes/${noteId}/`,
            {
                text: noteText,
            }
        );

    public removeNoteFromDeck = (
        userId: string,
        deckId: string,
        noteId: string,
        legacy: boolean
    ): Promise<EmptyResponse> =>
        this.api.delete(
            `/users/${v2(!legacy)}${userId}/decks/${deckId}/notes/${noteId}/`
        );

    public getUserProfile = (): Promise<UserProfileResponse> =>
        this.api.get(`/users/self/`, {
            links: 'participated_tournaments',
        });

    public updateUserProfile = (
        userId: string,
        profileOptions: {
            isAnonymous: boolean;
        }
    ): Promise<UserProfileResponse> =>
        this.api.patch(`/users/${userId}/`, {
            anonymised: profileOptions.isAnonymous,
        });

    public addDeckToFavorites = (
        userId: string,
        deckId: string,
        legacy?: boolean
    ): Promise<AddDeckToFavoritesResponse> =>
        this.api.post(`/users/${v2(!legacy)}${userId}/decks/favorites/`, {
            deck_id: deckId,
        });

    public removeDeckFromFavorites = (
        userId: string,
        deckId: string,
        legacy?: boolean
    ): Promise<EmptyResponse> =>
        this.api.delete(
            `/users/${v2(!legacy)}${userId}/decks/favorites/`,
            {},
            { data: { deck_id: deckId } }
        );

    public addDeckToWatchlist = (
        userId: string,
        deckId: string,
        legacy?: boolean
    ): Promise<AddDeckToWatchlistResponse> =>
        this.api.post(`/users/${v2(!legacy)}${userId}/decks/watchlist/`, {
            deck_id: deckId,
        });

    public removeDeckFromWatchlist = (
        userId: string,
        deckId: string,
        legacy?: boolean
    ): Promise<EmptyResponse> =>
        this.api.delete(
            `/users/${v2(!legacy)}${userId}/decks/watchlist/`,
            {},
            { data: { deck_id: deckId } }
        );

    public updateDeckCasualScores = (
        userId: string,
        deckId: string,
        scores: CasualScores,
        legacy?: boolean
    ): Promise<UpdateDeckCasualScoresResponse> =>
        this.api.post(
            `/users/${v2(!legacy)}${userId}/decks/${deckId}/casual_scores/`,
            scores
        );

    public getDecksOnWatchlist = (
        filters: DeckWatchlistFilters,
        pagination: Pagination,
        legacy?: boolean
    ): Promise<SearchDecksResponse> =>
        this.api.get('/decks/' + v2(!legacy), {
            // api indexing pages from 1 instead of 0 for some unknown reason ;)
            page: pagination.page + 1,
            page_size: pagination.pageSize,

            only_watchlist: 1,
            ordering: filters.sorting,
        });

    public getUserTransactions = (
        userId: string,
        filters: TransactionFilters,
        pagination: Pagination
    ): Promise<UserTransactionsResponse> =>
        this.api.get(`/users/${userId}/transactions/`, {
            // api indexing pages from 1 instead of 0 for some unknown reason ;)
            page: pagination.page + 1,
            page_size: pagination.pageSize,

            ordering: filters.sorting,
        });

    public getPlayerLeaderboard = (
        tournament: AvailableTournaments,
        pagination: Pagination
    ): Promise<TournamentPlayerLeaderboardResponse> =>
        // FIXME: trailing slash doesn't work for this particular endpoint,
        // blocked on backend
        this.api.get(`/tournaments/${tournament}/leaderboards/users`, {
            // api indexing pages from 1 instead of 0 for some unknown reason ;)
            page: pagination.page + 1,
            page_size: pagination.pageSize,
        });

    public getDeckLeaderboard = (
        tournament: AvailableTournaments,
        filters: TournamentDeckFilters,
        pagination: Pagination
    ): Promise<TournamentDeckLeaderboardResponse> =>
        // FIXME: trailing slash doesn't work for this particular endpoint,
        // blocked on backend
        this.api.get(`/tournaments/${tournament}/leaderboards/decks`, {
            // api indexing pages from 1 instead of 0 for some unknown reason ;)
            page: pagination.page + 1,
            page_size: pagination.pageSize,

            ordering: filters.sorting,
        });

    public getGameStore = (
        gameStoreId: string
    ): Promise<GetGameStoreResponse> => {
        return this.api.get(`/tournaments/stores/${gameStoreId}/`);
    };

    public searchGameStores = (
        filters: GameStoresSearchFilters,
        pagination: Pagination
    ): Promise<SearchGameStoresResponse> =>
        this.api.get(`/tournaments/stores/`, {
            // api indexing pages from 1 instead of 0 for some unknown reason ;)
            page: pagination.page + 1,
            page_size: pagination.pageSize,

            store_full_search: filters.searchText,
            only_leaderboard: filters.leaderboardOnly,
        });

    public getGameStoreLeaderboard = (
        gameStoreId: string,
        pagination: Pagination,
        month: Date
    ): Promise<TournamentPlayerLeaderboardResponse> =>
        // FIXME: trailing slash doesn't work for this particular endpoint,
        // blocked on backend
        this.api.get(`/tournaments/stores/${gameStoreId}/leaderboards/users`, {
            // api indexing pages from 1 instead of 0 for some unknown reason ;)
            page: pagination.page + 1,
            page_size: pagination.pageSize,
            // months are indexed from 0 in JS
            month: month.getMonth() + 1,
            year: month.getFullYear(),
        });

    public getAlliance = (
        allianceId: string,
        isPublic?: boolean
    ): Promise<GetAllianceResponse> => {
        if (isPublic)
            return this.api.get(`/decks/public-alliances/${allianceId}/`);
        else return this.api.get(`/decks/alliances/${allianceId}/`);
    };

    public createAlliance = (
        alliance: CreateAlliancePayload
    ): Promise<CreateAllianceResponse> =>
        this.api
            .post<CreateAllianceResponse>(`/decks/alliances/`, alliance)
            .then(Api.unSauce);

    public updateAlliance = (
        alliance: UpdateAlliancePayload
    ): Promise<UpdateAllianceResponse> =>
        this.api.patch(`/decks/alliances/${alliance.id}/`, alliance);

    public getUserAlliances = (
        filters: AlliancesSearchFilters,
        pagination: Pagination
    ): Promise<GetUserAlliancesResponse> => {
        return this.api.get(`/decks/alliances/`, {
            // api indexing pages from 1 instead of 0 for some unknown reason ;)
            page: pagination.page + 1,
            page_size: pagination.pageSize,
            houses: filters.houses.join(','),
            search: filters.search,
            show_hidden: filters.show_hidden,
            set_id: filters.set_id || null,
            ordering: filters.ordering,
            expansion_id: filters.set_id || null,
        });
    };

    public getFriendsList = (): Promise<GetFriendsPayload> => {
        return this.api
            .get<GetFriendsPayload>('/users/v2/friends/')
            .then(Api.unSauce);
    };

    public addFriend = (friendId: string): Promise<void> => {
        return this.api
            .post<void>(`/users/v2/friends/`, {
                friend_id: friendId,
            })
            .then(Api.unSauce);
    };

    public removeFriend = (friendId: string): Promise<void> => {
        return this.api
            .delete<void>(
                '/users/v2/friends/',
                // apisauce doesn't support DELETE requests with body,
                // so we pass friend_id in axios config
                {},
                {
                    data: {
                        friend_id: friendId,
                    },
                }
            )
            .then(Api.unSauce);
    };

    public searchUsers = (username: string, pagination: Pagination) => {
        return this.api
            .get<SearchUsersPayload>('/users/v2/search/', {
                username,
                page: pagination.page + 1,
                page_size: pagination.pageSize,
            })
            .then(Api.unSauce);
    };

    public getFriendsDecks = (
        search?: string,
        expansion?: number | string,
        only_watchlist?: boolean,
        houses?: string[],
        ordering?: string,
        page_size?: number,
        page?: number,
        friendId?: string
    ) => {
        return this.api
            .get<FriendsDecksPayload>('/decks/friends-decks/', {
                search,
                expansion,
                only_watchlist,
                houses,
                ordering,
                page_size,
                page,
                user_id: friendId,
            })
            .then(Api.unSauce);
    };

    public buyKeyToken = async () => {
        const res = await this.api.post('/users/buy-key-token/');
        return Api.unSauce(res);
    };

    public initPayment = async () => {
        const response = await this.api.post('/orders/checkout/');
        return response;
    };

    public getPaymentStatus = async (orderId) => {
        const response = await this.api.get(`/orders/${orderId}/`);
        return response;
    };

    public getUserPayments = async (page: number, page_size: number) => {
        const response = await this.api.get('/orders/', { page, page_size });
        return Api.unSauce(response);
    };

    public transferDeck = (deckId: string, userId: string) => {
        return this.api
            .post<void>(`/decks/transfers/`, {
                deck_id: deckId,
                to_user_id: userId,
            })
            .then(Api.unSauce);
    };

    public orderDeckCopy = (
        deckId: string,
        locale: string,
        selectedAccolades: string[]
    ) => {
        return this.api
            .post<void>(`/decks/order-deck-copy/`, {
                id: deckId,
                locale: locale,
                selectedAccolades: selectedAccolades,
            })
            .then(Api.unSauce);
    };

    public getTransfers = (
        status?: string,
        type?: string,
        ordering?: string,
        page_size?: number,
        page?: number
    ) => {
        return this.api
            .get<DeckTransfersPayload>('/decks/transfers', {
                status,
                type,
                ordering,
                page_size,
                page,
            })
            .then(Api.unSauce);
    };

    public acceptTransfer = (transferId: string) => {
        return this.api
            .post<void>(`/decks/transfers/${transferId}/accept/`)
            .then(Api.unSauce);
    };

    public denyTransfer = (transferId: string) => {
        return this.api
            .post<void>(`/decks/transfers/${transferId}/deny/`)
            .then(Api.unSauce);
    };

    public cancelTransfer = (transferId: string) => {
        return this.api
            .post<void>(`/decks/transfers/${transferId}/cancel/`)
            .then(Api.unSauce);
    };

    public claimDeck = (code: string): Promise<void> => {
        return this.api
            .post<void>(`/decks/claims/`, {
                code: code,
            })
            .then(Api.unSauce);
    };

    public getClaims = (
        status?: string,
        type?: string,
        ordering?: string,
        page_size?: number,
        page?: number
    ) => {
        return this.api
            .get<DeckTransfersPayload>('/decks/claims', {
                status,
                type,
                ordering,
                page_size,
                page,
            })
            .then(Api.unSauce);
    };

    public acceptClaim = (claimId: string) => {
        return this.api
            .post<void>(`/decks/claims/${claimId}/accept/`)
            .then(Api.unSauce);
    };

    public denyClaim = (claimId: string) => {
        return this.api
            .post<void>(`/decks/claims/${claimId}/deny/`)
            .then(Api.unSauce);
    };

    public cancelClaim = (claimId: string) => {
        return this.api
            .post<void>(`/decks/claims/${claimId}/cancel/`)
            .then(Api.unSauce);
    };
}

API = new Api();

export { API };
