import './TextAdaptedToContainer.scss';

import React from 'react';
import fitty, { FittyInstance } from 'fitty';

interface TextProps {
    children: string;
}

export default class TextAdaptedToContainer extends React.Component<TextProps> {
    private textToAdjust = React.createRef<HTMLSpanElement>();
    private fitty: FittyInstance;

    public componentDidMount() {
        this.fitty = fitty(this.textToAdjust.current);
    }

    public componentWillUnmount() {
        this.fitty.unsubscribe();
    }

    public render() {
        return (
            <div className="text-adapted-to-container">
                <span ref={this.textToAdjust}>{this.props.children}</span>
            </div>
        );
    }
}
