import './LogoutButton.scss';

import { withNamespaces, WithNamespaces } from 'react-i18next';
import { Persistor } from 'redux-persist/es/types';
import { MenuItems, MenuVariant } from '@/components/page_header/PageHeader';
import classNames from 'classnames';
import PersistorContext from '@/redux/PersistorContext';
import React, { FC } from 'react';

export interface LogoutButtonProps extends WithNamespaces {
    logout: (storePersistor: Persistor) => Promise<void>;
    menuVariant?: MenuVariant;
}

export const LogoutButton: FC<LogoutButtonProps> = ({
    logout,
    t,
    menuVariant,
}) => (
    <li
        className={classNames(
            'menu-item',
            menuVariant ? `menu-item--${menuVariant}` : '',
            'menu-item--logout',
            'logout-button'
        )}
    >
        <PersistorContext.Consumer>
            {(persistor) => (
                <button
                    onClick={() => logout(persistor)}
                    className={classNames(
                        'btn-link',
                        'logout-button__button',
                        MenuItems.SignOut.className
                    )}
                >
                    {t(MenuItems.SignOut.text.key, {
                        defaultValue: MenuItems.SignOut.text.value,
                    })}
                </button>
            )}
        </PersistorContext.Consumer>
    </li>
);

export default withNamespaces()(LogoutButton);
