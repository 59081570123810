import './ScanDeckQR.scss';
import { useHasCamera } from '@/components/common/useQrScanner';
import ScanQRCodeModal from '@/components/decks/my_decks/ScanDeckQRModal';

import React, { useCallback, useState } from 'react';
import { WithNamespaces, withNamespaces } from 'react-i18next';

import qrImg from '@/images/common/qr-icon@2x.png';

interface Props extends WithNamespaces {
    onCodeScanned?: (code: string) => void;
    className?: string;
}

export function ScanDeckQR({ onCodeScanned, className, t }: Props) {
    const [open, setOpen] = useState(false);
    const hasCamera = useHasCamera();
    const onScanSuccess = useCallback(
        (code) => {
            setOpen(false);
            if (onCodeScanned) {
                onCodeScanned(code);
            }
        },
        [onCodeScanned]
    );

    return hasCamera ? (
        <div className={className}>
            <button
                className="btn-medium-secondary"
                onClick={() => {
                    setOpen(true);
                }}
            >
                <img
                    src={qrImg}
                    alt=""
                    className="scan-deck-qr__btn-icon"
                    width="20"
                />
                {t('scan-deck.title', {
                    defaultValue: 'Scan Deck',
                })}
            </button>

            <ScanQRCodeModal
                isOpen={open}
                onScanSuccess={onScanSuccess}
                onClose={() => {
                    setOpen(false);
                }}
            />
        </div>
    ) : null;
}

export default withNamespaces()(ScanDeckQR);
