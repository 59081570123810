import { useMutation } from '@tanstack/react-query';
import { API } from '@/shared/api/Api';

export const useBuyKeyTokenMutation = () => {
    return useMutation<
        void,
        { response: { data: { message: string; detail: string } } },
        void
    >({
        // @ts-ignore
        mutationFn: () => API.buyKeyToken(),
    });
};
