import { useMutation } from '@tanstack/react-query';
import { API } from '@/shared/api/Api';
import { Alliance } from '../typings/Alliance';
import { useHistory } from 'react-router-dom';
import { FormValues } from '@/components/alliances/AllianceEditForm/AllianceEditForm';
import { CreateAllianceResponse } from '../api/ApiTypings';

export const useCreateAlliance = () => {
    const history = useHistory();
    // @ts-ignore
    return useMutation<CreateAllianceResponse, any, FormValues>({
        mutationFn: (alliance: Alliance) => {
            if (!alliance) {
                return;
            }
            return API.createAlliance(alliance);
        },
        onSuccess: () => {
            history.push('/alliances');
        },
    });
};
