import QrScanner from 'qr-scanner';
import { useEffect, useState } from 'react';

// NB: Make sure to pass stable references to all callbacks

export interface Props {
    video?: HTMLVideoElement;
    onDecode?: (data: string) => void;
    onError?: (e: any) => void;
    autoStartScan?: boolean;
    enabled?: boolean;
}

export const useQrScanner = ({
    video,
    onDecode,
    onError,
    enabled = true,
}: Props) => {
    const [scanner, setScanner] = useState<QrScanner | null>(null);

    useEffect(() => {
        if (enabled && video) {
            const qrScanner = new QrScanner(
                video,
                ({ data }) => {
                    if (onDecode) {
                        onDecode(data);
                    }
                },
                {
                    preferredCamera: 'environment',
                    returnDetailedScanResult: true,
                    highlightScanRegion: true,
                }
            );
            setScanner(qrScanner);
            qrScanner.start().catch((reason) => {
                if (onError) {
                    onError(reason);
                    return;
                }
                throw reason;
            });

            return () => {
                qrScanner.destroy();
            };
        }
    }, [video, enabled, onDecode, onError]);

    return scanner;
};

export const useHasCamera = () => {
    const [hasCamera, setHasCamera] = useState(false);

    useEffect(() => {
        QrScanner.hasCamera().then(setHasCamera);
    }, []);

    return hasCamera;
};
