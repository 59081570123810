import './UnlockEventKeyModal.scss';

import React from 'react';
import Modal from '../../common/Modal';
import { useBuyKeyTokenMutation } from '@/shared/queries/keyToken';

interface IUnlockEventKeyModalProps {
    opened: boolean;
    onClose: () => void;
    aemberCost: number;
}

export const UnlockEventKeyModal = ({
    opened,
    onClose,
    aemberCost,
}: IUnlockEventKeyModalProps) => {
    const {
        mutate: unlockEventKey,
        error,
        isLoading,
        isError,
        isSuccess,
        reset,
    } = useBuyKeyTokenMutation();

    const handleSuccess = () => {
        reset();
        onClose();
    };

    const handleError = () => {
        reset();
        onClose();
    };

    const UnlockEventKey = () => (
        <div className="unlock-event-key-modal__deck-deleted">
            <h2 className="unlock-event-key-modal__header">Event Key Pass</h2>
            <div className="unclock-event-key-modal__description-container">
                <h3 className="unlock-event-key-modal__description">
                    Would you like to spend {aemberCost} Æmber to unlock an Event Key?
                </h3>
                <h3 className="unlock-event-key-modal__description-red">
                    This action cannot be reversed.
                </h3>
            </div>
            <div className="unlock-event-key-modal__buttons">
                <button
                    className="unlock-event-key-modal__btn btn btn-secondary"
                    onClick={onClose}
                >
                    Cancel
                </button>
                <button
                    className="unlock-event-key-modal__btn btn btn-okay"
                    onClick={() => unlockEventKey()}
                >
                    Unlock Event Key
                </button>
            </div>
        </div>
    );

    const EventKeyUnlocked = () => (
        <div className="unlock-event-key-modal__deck-deleted">
            <h2 className="unlock-event-key-modal__header">
                Event Key was added to your account.
            </h2>
            <div className="unlock-event-key-modal__buttons">
                <button
                    className="unlock-event-key-modal__btn btn btn-okay"
                    onClick={() => handleSuccess()}
                >
                    OK
                </button>
            </div>
        </div>
    );

    const EventKeyUnlockError = () => (
        <div className="unlock-event-key-modal__deck-deleted">
            <h2 className="unlock-event-key-modal__header">
                {error?.response?.data?.message || 'Something went wrong'}
            </h2>
            <h3 className="unlock-event-key-modal__description">
                {error?.response?.data?.detail}
            </h3>
            <div className="unlock-event-key-modal__buttons">
                <button
                    className="unlock-event-key-modal__btn btn btn-secondary"
                    onClick={() => handleError()}
                >
                    Close
                </button>
            </div>
        </div>
    );

    const headerImage = (
        <div className="unlock-event-key-modal__header-image" />
    );

    return (
        <Modal
            isOpen={opened}
            className={'remove-deck-modal'}
            headerImage={headerImage}
            loading={isLoading}
        >
            {isSuccess ? (
                <EventKeyUnlocked />
            ) : isError ? (
                <EventKeyUnlockError />
            ) : (
                <UnlockEventKey />
            )}
        </Modal>
    );
};
