import { DecksSearchFilters } from '@/shared/api/ApiTypings';
import { Action } from 'redux';

import {
    RequestFailureAction,
    createActions,
    ActionsMap,
} from '../ReduxHelpers';
import { Deck } from '../../typings';
import {
    Pagination,
    CasualScores,
    DecksSearchFilters as ApiDecksSearchFilters,
} from '../../api/ApiTypings';
import { StateType } from './State';
import { LEGACY_OWNERSHIP_ACTION_PREFIX } from '@/shared/redux/user_decks/constants';

/* ------------- Types and Action Creators ------------- */

const actions: ActionsMap<CreatorsType> = {
    userDecksRequest: ['searchFilters', 'pagination'],
    userDecksSuccess: ['payload'],
    userDecksFailure: ['payload'],

    resetAddDeckRequest: null,

    addDeckRequest: ['code'],
    addDeckSuccess: ['payload'],
    addDeckFailure: ['payload'],

    removeDeckRequest: ['deckId'],
    removeDeckSuccess: ['payload'],
    removeDeckFailure: ['payload'],

    updateCasualScoresRequest: ['deckId', 'casualScores'],
    updateCasualScoresSuccess: ['payload'],
    updateCasualScoresFailure: ['payload'],
};

const userDecksActions = createActions<CreatorsType>(actions);
const legacyActions = createActions<CreatorsType>(actions, {
    prefix: LEGACY_OWNERSHIP_ACTION_PREFIX,
});

export const UserDecksTypes = userDecksActions.Types;
export const UserDecksActions = userDecksActions.Creators;
export const LegacyUserDecksActionTypes = legacyActions.Types;
export const LegacyUserDecksActions = legacyActions.Creators;

export const isLegacyOwnershipAction = (action: Action<string>): boolean =>
    !!action.type?.startsWith(LEGACY_OWNERSHIP_ACTION_PREFIX);

/* --------------- Actions definitions ------------------- */

interface CreatorsType {
    userDecksRequest: (
        searchFilters: DecksSearchFilters,
        pagination: Pagination
    ) => UserDecksRequestAction;
    userDecksSuccess: (
        payload: UserDecksSuccessAction['payload']
    ) => UserDecksSuccessAction;
    userDecksFailure: (payload: {
        error: StateType['userDecks']['__error'];
    }) => RequestFailureAction;

    resetAddDeckRequest: () => Action;

    addDeckRequest: (code: string) => AddDeckRequestAction;
    addDeckSuccess: (payload: {
        deck: Deck;
        addStatus: StateType['addingDeck']['addStatus'];
    }) => AddDeckSuccessAction;
    addDeckFailure: (payload: {
        error: StateType['addingDeck']['__error'];
        addStatus: StateType['addingDeck']['addStatus'];
    }) => AddDeckFailureAction;

    removeDeckRequest: (deckId: string) => AddDeckRequestAction;
    removeDeckSuccess: () => Action;
    removeDeckFailure: (payload: {
        error: StateType['removeDeck']['__error'];
    }) => RequestFailureAction;

    updateCasualScoresRequest: (
        deckId: string,
        casualScores: CasualScores
    ) => UpdateCasualScoresRequestAction;
    updateCasualScoresSuccess: (
        payload: Pick<Deck, 'casualWins' | 'casualLosses' | 'id'>
    ) => UpdateCasualScoresSuccessAction;
    updateCasualScoresFailure: (payload: {
        error: StateType['updateCasualScores']['__error'];
    }) => RequestFailureAction;
}

// fetch user decks request handle

export interface UserDecksRequestAction extends Action {
    searchFilters: ApiDecksSearchFilters;
    pagination: Pagination;
}

export interface UserDecksSuccessAction extends Action {
    payload: {
        decks: Deck[];
        totalCount: number;
    };
}

// register/discover deck for user request handle

export interface AddDeckRequestAction extends Action {
    code: string;
}

export interface AddDeckSuccessAction extends Action {
    payload: {
        deck: StateType['addingDeck']['deck'];
        addStatus: StateType['addingDeck']['addStatus'];
    };
}

export interface AddDeckFailureAction extends Action {
    payload: {
        error: StateType['addingDeck']['__error'];
        addStatus: StateType['addingDeck']['addStatus'];
    };
}

// remove deck request handler

export interface RemoveDeckRequestAction extends Action {
    deckId: string;
}

// update casual scores handlers

export interface UpdateCasualScoresRequestAction extends Action {
    deckId: string;
    casualScores: CasualScores;
}

export interface UpdateCasualScoresSuccessAction extends Action {
    payload: Pick<Deck, 'casualWins' | 'casualLosses' | 'id'>;
}
