import HouseCardsList from '@/components/single_deck/CardsList';
import MissingHouseIcon from '@/images/header/close-icon.svg';
import { Card, Deck, House } from '@/shared/typings';
import { getTranslatedHouseName } from '@/shared/services/lang/translationHelpers';
import classNames from 'classnames';
import { assocPath } from 'ramda';
import React, { Component } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import './CardTable.scss';

export interface HouseCards {
    houseId: string;
    cards: Card[];
    house?: House;
}
export interface CardTableProps extends WithNamespaces {
    houseCards: HouseCards[];
    showHouseIcon: boolean;
    legacyCardsIds: Deck['setEraCards']['Legacy'];
    anomalyCardsIds: Deck['setEraCards']['Anomaly'];
    otherCards?: boolean;
    noHeader?: boolean;
}

interface CardTableState {
    showCards: [boolean, boolean, boolean];
}

export class CardTable extends Component<CardTableProps, CardTableState> {
    public state: CardTableState = {
        showCards: [false, false, false],
    };

    public render() {
        const {
            houseCards,
            legacyCardsIds,
            anomalyCardsIds,
            showHouseIcon,
            t,
            otherCards,
            noHeader,
        } = this.props;

        return (
            <div className="card-table">
                {houseCards.map((cards, index) => {
                    const showDeckCards = this.state.showCards[index];
                    const house =
                        cards.house || this.missingHouse(cards.houseId);
                    const houseName = getTranslatedHouseName(house, t);

                    return (
                        <div
                            key={index}
                            className={classNames('card-table__deck', {
                                'card-table__deck--expanded': showDeckCards,
                            })}
                        >
                            {!noHeader && (
                                <div className="card-table__deck-house">
                                    {showHouseIcon && (
                                        <img
                                            src={house.image}
                                            alt={houseName}
                                            className="card-table__deck-house-image"
                                        />
                                    )}
                                    <div className="card-table__deck-house-name">
                                        {houseName}
                                    </div>
                                </div>
                            )}
                            <button
                                className="card-table__deck-cards-toggle"
                                onClick={() => this.toggleCardVisibility(index)}
                            >
                                {showDeckCards
                                    ? t('single-deck.hint.hide-cards', {
                                          defaultValue:
                                              'Hide cards from this house',
                                      })
                                    : t('single-deck.hint.see-cards', {
                                          defaultValue:
                                              'See cards from this house',
                                      })}
                            </button>
                            <div className="card-table__deck-cards">
                                <HouseCardsList
                                    key={index}
                                    cards={cards.cards}
                                    legacyCardsIds={legacyCardsIds}
                                    anomalyCardsIds={anomalyCardsIds}
                                    otherCards={otherCards}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }

    private missingHouse: (id: string) => House = (houseId) => ({
        id: houseId,
        name: `House "${houseId}" not supported`,
        image: MissingHouseIcon,
        inFilters: true,
    });

    private toggleCardVisibility = (index: number) => {
        this.setState(
            assocPath(['showCards', index], !this.state.showCards[index])
        );
    };
}

export default withNamespaces()(CardTable);
