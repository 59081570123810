import { InitialAsyncRequestState } from '@/shared/redux/Async';
import { Deck } from '@/shared/typings';
import { Alliance } from '@/shared/typings/Alliance.d';

export const InitialState = {
    deckSuggestions: {
        ...InitialAsyncRequestState,

        /**
         * Number of fetched decks, based on applied filters query
         */
        totalCount: 0,

        /**
         * List of fetched decks, based on applied filters query
         */
        list: [] as Deck[],
    },
    creatingAlliance: InitialAsyncRequestState,
    updatingAlliance: InitialAsyncRequestState,
    userAlliances: {
        ...InitialAsyncRequestState,

        totalCount: 0,
        list: [] as Alliance[],
    },
    getAllianceData: {
        ...InitialAsyncRequestState,

        data: null as Alliance | null,
    },
};

export type StateType = typeof InitialState;
