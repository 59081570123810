import './DeckTransferPage.scss';
import React, { useState } from 'react';
import SectionLead from '../common/SectionLead';
import { PaginatedListHeader } from '../decks/my_decks/PaginatedListHeader';
import PaginatedTable from '../common/tables/PaginatedTable';
import { TransferClaimList } from './TransferClaimList';
import { ModalTransferClaim } from './ModalTransferClaim';
import { useDeckClaims } from '@/shared/queries/deck_claims';
import Breadcrumbs from '../common/Breadcrumbs';
import { ClaimList } from './ClaimList';

const PageSize = 10;

export interface ModalData {
    type: string;
    action: string;
    data: {
        id: string;
        deckName: string;
    };
}

export const DeckClaimPage = () => {
    const [page, setPage] = useState<number>(1);
    const [paginationPage, setPaginationPage] = useState<number>(0);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [showInactiveClaims, setShowInactiveClaims] = useState<boolean>(true);
    const [modalData, setModalData] = useState<ModalData>();

    const { data: claimData, isLoading: isClaimLoading } = useDeckClaims(
        '',
        '',
        '',
        PageSize,
        page
    );

    const handleCheckboxChange = () => {
        setShowInactiveClaims(!showInactiveClaims);
    };

    const onPageChange = (chosenPage: number) => {
        setPage(chosenPage + 1);
        setPaginationPage(chosenPage);
    };

    const decksPageCount = () => Math.ceil(claimData.count / PageSize);

    return (
        <div className="deck-transfers-page-page">
            <SectionLead>Deck Claims</SectionLead>
            <div className="page-content-layout">
                <Breadcrumbs deckClaims />
                {claimData?.data.length > 0 ? (
                    <PaginatedTable
                        header={
                            <div className="deck-transfers-page__header-container">
                                <PaginatedListHeader
                                    className={'deck-transfers-page'}
                                    decksCount={claimData.count}
                                />
                                <div className="deck-transfers-page__checkbox-container">
                                    <p className="deck-transfers-page__checkbox-text">
                                        Show inactive Claims
                                    </p>
                                    <label className="deck-transfers-page__checkbox">
                                        <input
                                            className="deck-transfers-page__checkbox__input"
                                            type="checkbox"
                                            checked={showInactiveClaims}
                                            onChange={handleCheckboxChange}
                                        />
                                        <span className="deck-transfers-page__checkbox__box">
                                            <span className="deck-transfers-page__checkbox__inner-box" />
                                        </span>
                                    </label>
                                </div>
                            </div>
                        }
                        currentPage={paginationPage}
                        pageCount={decksPageCount()}
                        onPageChange={onPageChange}
                        isLoading={isClaimLoading}
                        showTopPagination={true}
                    >
                        <ClaimList
                            data={claimData.data}
                            setIsModalOpen={setIsModalOpen}
                            setModalData={setModalData}
                            showInactiveOperations={showInactiveClaims}
                            exchangeType={'claim'}
                            sorting={null}
                            onSortChange={null}
                        />
                    </PaginatedTable>
                ) : (
                    <div className="my-decks-page__no-decks">
                        <p className="my-decks-page__no-decks-description">
                            Your transfer deck list is currently empty
                        </p>
                    </div>
                )}
            </div>
            <ModalTransferClaim
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                setModalData={setModalData}
                modalData={modalData}
            />
        </div>
    );
};
