import './IconCheckbox.scss';

import React, { SyntheticEvent } from 'react';
import classNames from 'classnames';

export interface Props {
    label?: string;
    variant?: 'favorite' | 'watchlist' | 'owned' | 'custom';
    className?: string;
    checked: boolean;
    onChange: (e: SyntheticEvent<HTMLInputElement>) => void;
    disabled?: boolean;
}

export default function IconCheckbox({
    label,
    variant = 'custom',
    className,
    checked,
    onChange,
    disabled,
}: Props) {
    const fullClassName = classNames('icon-checkbox', className, {
        'icon-checkbox--checked': checked,
    });

    const iconClassName = classNames('icon-checkbox__icon', {
        'icon-checkbox__icon--favorite': variant === 'favorite',
        'icon-checkbox__icon--watchlist': variant === 'watchlist',
        'icon-checkbox__icon--owned': variant === 'owned',
        'icon-checkbox__icon--custom': variant === 'custom',
    });

    return (
        <label className={fullClassName}>
            {label && <span className="icon-checkbox__label">{label}</span>}
            <input
                checked={checked}
                onChange={onChange}
                type="checkbox"
                className="icon-checkbox__checkbox"
                disabled={disabled}
            />
            <span className={iconClassName} />
        </label>
    );
}
