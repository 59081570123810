import './MyDecksPageComponent.scss';

import React, { SyntheticEvent, ReactNode } from 'react';
import { repeat, evolve, assoc } from 'ramda';
import classNames from 'classnames';

import InfoTooltip from '@/components/common/InfoTooltip';
import { withNamespaces, Trans } from 'react-i18next';
import ScanDeckQR from '@/components/decks/my_decks/ScanDeckQR';

import DecksPageBase, {
    DecksPageBaseProps,
    DecksPageBaseState,
    DefaultFiltersState,
} from './DecksPageBase';

import { UserDecksActions } from '@/shared/redux/user_decks';
import { DecksActions } from '@/shared/redux/decks';
import { RootState } from '@/shared/redux/RootState';
import { AsyncStatus } from '@/shared/redux/Async';
import { AddDeckStatus } from '@/shared/redux/user_decks/State';
import { Deck } from '@/shared/typings';

import TextInput from '@/components/common/inputs/TextInput';
import TextAdaptedToContainer from '@/components/common/TextAdaptedToContainer';
import DecksFiltersContainer from '@/components/common/decks_filters/DecksFiltersContainer';
import PaginatedTable from '@/components/common/tables/PaginatedTable';
import DeckList from '@/components/common/deck_lists/DeckList';
import IconCheckbox from '@/components/common/inputs/IconCheckbox';
import SectionLead from '@/components/common/SectionLead';
import AddDeckModal from './AddDeckModal';
import Watchlist from '@/components/profile/Watchlist';
import { AdditionalColumn } from '@/components/common/deck_lists/DeckListTypes';
import Tippy from '@tippy.js/react';
import { Link } from 'react-router-dom';
import Breadcrumbs from '@/components/common/Breadcrumbs';

const DecksPartMask = repeat(/[a-zA-Z0-9]/, 5);
const DeckCodeInputMask = [
    ...DecksPartMask,
    '-',
    ...DecksPartMask,
    '-',
    ...DecksPartMask,
];

export interface MyDecksPageProps extends DecksPageBaseProps {
    addStatus: RootState['userDecks']['addingDeck']['addStatus'];
    addDeck?: typeof UserDecksActions.addDeckRequest;
    requestAddDeckToFavorites: (typeof DecksActions)['addDeckToFavoritesRequest'];
    requestRemoveDeckFromFavorites: (typeof DecksActions)['removeDeckFromFavoritesRequest'];
    showAddDeckUI?: boolean;
    legacy?: boolean;
    pageTitle?: string;
    noDecksMessage?: ReactNode;
    noDecksHeader?: string;
}

export interface MyDecksPageState extends DecksPageBaseState {
    isAddDeckModalOpen: boolean;
    codeText: string;
    userHasDecks: boolean;
}

// todo merge with DecksPageBase
export class MyDecksPageComponent extends DecksPageBase<
    MyDecksPageProps,
    MyDecksPageState
> {
    public state: MyDecksPageState = {
        decksCurrentPage: 0,
        filters: DefaultFiltersState,
        appliedFilters: DefaultFiltersState,
        codeText: '',
        isAddDeckModalOpen: false,
        userHasDecks: null, // null represents 'loading' state
    };

    public componentDidUpdate(
        prevProps: MyDecksPageProps,
        prevState: MyDecksPageState
    ) {
        const { userHasDecks } = this.state;
        const { decks, addStatus } = this.props;

        // Update 'userHasDecks' after deck count information is available
        if (userHasDecks === null && decks.__status === AsyncStatus.Success) {
            this.setState({ userHasDecks: decks.totalCount > 0 });
        }

        // Handle state after adding a deck success
        const addSuccess =
            addStatus === AddDeckStatus.Discovered ||
            addStatus === AddDeckStatus.Registered;
        if (addStatus !== prevProps.addStatus && addSuccess) {
            this.setState(
                { filters: DefaultFiltersState, userHasDecks: true },
                this.fetchDecksForFilters
            );
        }

        // Clear code field when add status is not Failure
        // (so user can check their code). Also ignore null value
        // that is set when modal is closed.
        if (
            addStatus !== prevProps.addStatus &&
            addStatus !== null &&
            addStatus !== AddDeckStatus.Failure
        ) {
            this.setState({ codeText: '' });
        }
    }

    public render() {
        const {
            decks,
            pageTitle,
            legacy = false,
            showAddDeckUI = true,
        } = this.props;
        const { decksCurrentPage, appliedFilters, filters, userHasDecks } =
            this.state;

        const additionalColumns: AdditionalColumn<Deck>[] = legacy
            ? [
                  {
                      id: 'favorite',
                      label: 'Favorite',
                      valueForDeck: this.renderFavoriteIcon,
                      className: 'deck-list__toggle-field',
                      listClassName: 'deck-list--with-toggle',
                  },
                  {
                      id: 'ownership',
                      valueForDeck: this.renderOwnedIcon,
                      className: 'deck-list__toggle-field',
                      listClassName: 'deck-list--with-toggle',
                  },
              ]
            : [
                  {
                      id: 'favorite',
                      label: 'Favorite',
                      valueForDeck: this.renderFavoriteIcon,
                      className: 'deck-list__toggle-field',
                      listClassName: 'deck-list--with-toggle',
                  },
              ];

        return (
            <div className="my-decks-page">
                <SectionLead>{pageTitle || 'My Decks'}</SectionLead>
                {legacy && (
                    <div className="my-decks-page__breadcrumbs">
                        <Breadcrumbs />
                    </div>
                )}
                {legacy && (
                    <div className="my-decks-page__legacy-decks-info">
                        Legacy Decks are all the decks that you previously
                        registered before Ghost Galaxy launched the new deck
                        ownership system in May 2023.
                        <br />
                        If you did not register a deck before this change, this
                        list will not show any decks.
                    </div>
                )}
                <div className="page-content-layout">
                    {!legacy && (<Breadcrumbs />)}
                    <div className="my-decks-page__inputs">
                        {this.renderSearchInput(
                            'Input deck name, card name',
                            !userHasDecks
                        )}
                        {showAddDeckUI && this.renderAddDeckInput()}
                    </div>
                    <div
                        className={classNames('my-decks-page__filters', {
                            'my-decks-page__filters--no-decks':
                                userHasDecks === false,
                        })}
                    >
                        <DecksFiltersContainer
                            onSearch={this.applyNewFilters}
                            onReset={this.handleFiltersReset}
                            onChange={this.handleFiltersChange}
                            value={filters}
                            legacy={legacy}
                            disableToggleBtn={!userHasDecks}
                        />
                    </div>
                </div>
                <div
                    className={classNames('my-decks-page__decks-list', {
                        'my-decks-page__decks-list--loading':
                            userHasDecks === null,
                    })}
                >
                    {userHasDecks === null ? (
                        <span className="spinner" />
                    ) : userHasDecks ? (
                        <PaginatedTable
                            header={this.paginatedListHeader()}
                            currentPage={decksCurrentPage}
                            pageCount={this.decksPageCount()}
                            onPageChange={this.onPageChange}
                            isLoading={decks.__status !== AsyncStatus.Success}
                        >
                            <DeckList
                                legacy={legacy}
                                decks={decks.list}
                                onSortChange={this.onSortChange}
                                sorting={appliedFilters.sorting}
                                additionalColumns={additionalColumns}
                            />
                        </PaginatedTable>
                    ) : (
                        this.renderNoDecks()
                    )}
                </div>

                <section className="user-profile-page__table-section">
                    <h2 className="user-profile-page__table-section-title keyforge-heading-1">
                        My Watchlist
                    </h2>
                    <div className="user-profile-page__table-section-body">
                        <Watchlist />
                    </div>
                </section>
            </div>
        );
    }

    protected className() {
        return 'my-decks-page';
    }

    protected headerExtension() {
        return (
            <div className="my-decks-page__decks-list-favorite">
                <IconCheckbox
                    variant="favorite"
                    label="Show Only Favorites"
                    checked={this.state.filters.favoriteOnly}
                    onChange={this.handleFavoriteOnlyChange}
                />
            </div>
        );
    }

    private handleFavoriteOnlyChange = (ev: SyntheticEvent<HTMLInputElement>) =>
        this.setState(
            evolve({
                filters: assoc('favoriteOnly', ev.currentTarget.checked),
                appliedFilters: assoc('favoriteOnly', ev.currentTarget.checked),
            }),
            this.applyNewFilters
        );

    private renderNoDecks = () => (
        <div className="my-decks-page__no-decks">
            {this.props.noDecksHeader ? (
                <h3 className="my-decks-page__no-decks-heading keyforge-heading-2">
                    No Owned Decks
                </h3>
            ) : (
                <h3 className="my-decks-page__no-decks-heading keyforge-heading-2">
                    {this.props.legacy
                        ? 'No Legacy Decks'
                        : 'No Decks Registered'}
                </h3>
            )}
            <p className="my-decks-page__no-decks-description">
                {this.props.noDecksMessage || (
                    <>
                        {this.props.legacy ? (
                            <>
                                Legacy decks are decks registered to your
                                account before May 2023. Please go to{' '}
                                <strong>
                                    <Link to={`/my-decks`}>My Decks</Link>
                                </strong>{' '}
                                to see your decks and register new decks.
                            </>
                        ) : (
                            <>
                                You have no decks registered to your account.
                                <br />
                                Use the "<strong>Add Deck</strong>" feature
                                above to register your decks!
                            </>
                        )}
                    </>
                )}
            </p>
        </div>
    );

    private renderAddDeckInput = () => {
        const infoTooltip = (
            <div className="my-decks-page__add-deck-tooltip">
                Each KeyForge deck has a unique 15 digit code located below the
                QR code on the back of the Archon card. Enter the code in the
                box below to register your deck and add it to your My Decks
                page!
            </div>
        );
        return (
            <div className="my-decks-page__input my-decks-page__add-deck">
                <div className="my-decks-page__input-label">
                    <h2 className="keyforge-heading-2 my-decks-page__input-label-heading">
                        Add Deck
                    </h2>
                    <InfoTooltip>{infoTooltip}</InfoTooltip>
                </div>
                <div className="my-decks-page__input-group my-decks-page__code-input">
                    <TextInput
                        className="my-decks-page__input-group-field"
                        placeholder="Enter your 15 digit deck code"
                        ariaLabel="Add deck code text input"
                        mask={DeckCodeInputMask}
                        onChange={this.handleCodeChange}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                this.handleAddDeck();
                            }
                        }}
                        value={this.state.codeText}
                    />
                    <button
                        className="my-decks-page__input-group-btn btn-small-secondary"
                        onClick={this.handleAddDeck}
                        disabled={this.state.codeText.trim().length === 0}
                    >
                        <span className="btn-content">
                            <TextAdaptedToContainer>
                                Add Deck
                            </TextAdaptedToContainer>
                        </span>
                    </button>
                    <AddDeckModal
                        isOpen={this.state.isAddDeckModalOpen}
                        onClose={() => this.toggleAddDeckModal(false)}
                        deckCode={this.state.codeText}
                    />
                </div>
                <ScanDeckQR
                    className="my-decks-page__qr"
                    onCodeScanned={this.handleCodeScan}
                />
            </div>
        );
    };

    private handleCodeScan = (codeText: string) => {
        this.setState({ codeText }, this.handleAddDeck);
    };

    private toggleAddDeckModal = (isAddDeckModalOpen: boolean) =>
        this.setState({ isAddDeckModalOpen });

    private handleAddDeck = () => {
        if (this.state.codeText.trim().length !== 0) {
            this.props.addDeck(this.state.codeText);
            this.toggleAddDeckModal(true);
        }
    };

    private handleCodeChange = (e: SyntheticEvent<HTMLInputElement>) =>
        this.setState({ codeText: e.currentTarget.value });

    private renderFavoriteIcon = (deck: Deck) => {
        const onChange = deck.favorite
            ? this.props.requestRemoveDeckFromFavorites
            : this.props.requestAddDeckToFavorites;
        return (
            <IconCheckbox
                variant="favorite"
                checked={deck.favorite}
                onChange={() => onChange(deck.id)}
            />
        );
    };

    private renderOwnedIcon = (deck: Deck) => {
        return (
            <>
                {deck.isDeckOwned && (
                    <Tippy
                        content={
                            <div className="owned-deck-tooltip">
                                You own this deck.
                            </div>
                        }
                    >
                        <IconCheckbox
                            variant="owned"
                            checked={deck.isDeckOwned}
                            onChange={() => {}}
                        />
                    </Tippy>
                )}
            </>
        );
    };
}

export default withNamespaces()(MyDecksPageComponent);
