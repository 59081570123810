import { put, call, select } from 'redux-saga/effects';
import { Action } from 'redux';

import { RootState } from '../../redux/RootState';
import { AsyncStatus } from '../../redux/Async';
import { DecksActions } from '../../redux/decks';
import API from '../../api/Api';
import { GetHousesResponse, GeneralErrorResponse } from '../../api/ApiTypings';
import { normalizeHouse } from '../Normalizers';

const getHousesStatus = (state: RootState) => state.decks.houses.__status;

/**
 * Checks if houses metadata is available in store, and if not - fetches from api
 */
export function* getHouses(
    api: API,
    action: Action,
    calledBySaga = false
): Iterator<any> {
    const storedRequestStatus = yield select(getHousesStatus);
    if (storedRequestStatus === AsyncStatus.Success) {
        return;
    }

    // Send request action, so reducers can set proper request status
    if (calledBySaga) {
        yield put(DecksActions.setRequestStatus('houses', AsyncStatus.Pending));
    }

    const response: GetHousesResponse = yield call(api.getHouses);
    if (response.status === 200) {
        const houses = response.data.data.map(normalizeHouse);
        yield put(DecksActions.getHousesSuccess(houses));
    } else {
        yield put(
            DecksActions.getHousesFailure({
                error: (response as unknown) as GeneralErrorResponse,
            })
        );
    }
}
