import './DropdownMenuItem.scss';

import React, { FC } from 'react';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import classNames from 'classnames';
import { MenuVariant } from './PageHeader';

interface OwnProps {
    text?: {
        key: string;
        value: string;
    };
    menuVariant?: MenuVariant;
    className?: string;
    dropdown: {
        active: boolean;
        open: boolean;
    };
}

export type Props = OwnProps & WithNamespaces;

export const DropdownMenuItem: FC<Props> = ({
    text,
    menuVariant,
    className,
    dropdown,
    t,
    children,
}) => {
    return (
        <li
            className={classNames(
                'menu-item',
                'menu-item--dropdown',
                menuVariant ? `menu-item--${menuVariant}` : '',
                className,
                {
                    'menu-item--dropdown-open': dropdown.open,
                    'menu-item--dropdown-active': dropdown.active,
                }
            )}
        >
            <span
                className={classNames('menu-item__link', {
                    'menu-item__link--active': dropdown.active,
                })}
            >
                {children}
                {text.value &&
                    t(text.key, {
                        defaultValue: text.value,
                    })}
            </span>
        </li>
    );
};

export default withNamespaces()(DropdownMenuItem);
