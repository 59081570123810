import { InitialAsyncRequestState } from '../Async';
import { GameStore, TournamentPlayer } from '../../typings';

const InitialGameStore: GameStore = {
    id: '',
    name: '',
    address: {
        line1: '',
        line2: '',
        city: '',
        postalCode: '',
        province: '',
        country: '',
    },
    url: '',
};

export const InitialState = {
    getGameStore: {
        ...InitialAsyncRequestState,

        gameStore: InitialGameStore,
    },
    searchGameStores: {
        ...InitialAsyncRequestState,

        totalCount: 0,
        list: [] as GameStore[],
    },
    getGameStoreLeaderboard: {
        ...InitialAsyncRequestState,

        list: [] as TournamentPlayer[],
        /**
         * Number of records for pagination
         */
        totalCount: 0,
        /**
         * Number of all qualified participants in the tournament
         */
        participantCount: 0,
        /**
         * Rank of currenlty logged-in user in the tournament,
         * null if user is not logged-in, or hasn't participated in the event
         */
        userRank: null as number,
    },
};

export type StateType = typeof InitialState;
