import { put, call, select, take } from 'redux-saga/effects';

import PersistedUserActions from '@/shared/redux/persisted_user';
import UserProfileActions from '@/shared/redux/user_profile';
import { AuthorizeUserRequestAction } from '@/shared/redux/persisted_user/Actions';
import {
    UpdateProfileRequestAction,
    GetTransactionsRequestAction,
} from '@/shared/redux/user_profile/Actions';
import API from '@/shared/api/Api';
import {
    LoginResponse,
    LoginResponseData,
    UserProfileResponse,
    UserTransactionsResponse,
    GeneralErrorResponse,
} from '@/shared/api/ApiTypings';
import {
    normalizeUserProfile,
    normalizeTransactions,
    normalizeParticipatedTournaments,
} from '@/shared/sagas/Normalizers';
import { getApiToken, getUserId } from '@/shared/redux/Selectors';

const normalizeAuthResponse = (data: LoginResponseData) => ({
    apiToken: data.token,
    userProfile: normalizeUserProfile(data.user),
});

export function* setApiToken(api: API): Iterator<any> {
    const apiToken = yield select(getApiToken);
    yield call(api.setApiToken, apiToken);
}

export function* fetchUserProfile(api: API) {
    const response: UserProfileResponse = yield call(api.getUserProfile);
    if (response.status === 200) {
        const profile = normalizeUserProfile(response.data.data);
        const tournaments = response.data._linked
            ? normalizeParticipatedTournaments(
                  response.data._linked.participated_tournaments
              )
            : [];
        yield put(
            UserProfileActions.fetchProfileSuccess({ profile, tournaments })
        );
    } else {
        yield put(
            UserProfileActions.fetchProfileFailure({
                error: response as unknown as GeneralErrorResponse,
            })
        );
    }
}

export function* updateUserProfile(
    api: API,
    action: UpdateProfileRequestAction
): Iterator<any> {
    const userId = yield ensureUserId();
    const response: UserProfileResponse = yield call(
        api.updateUserProfile,
        userId,
        action.profileOptions
    );
    if (response.status === 200) {
        const successData = normalizeUserProfile(response.data.data);
        yield put(UserProfileActions.updateProfileSuccess(successData));
    } else {
        yield put(
            UserProfileActions.updateProfileFailure({
                error: response as unknown as GeneralErrorResponse,
            })
        );
    }
}

export function* authorizeUser(api: API, action: AuthorizeUserRequestAction) {
    const response: LoginResponse = yield call(
        api.authorizeUser,
        action.idToken,
        action.accessToken,
        action.nonce
    );
    if (response.status === 200) {
        const successData = normalizeAuthResponse(response.data.data);
        yield call(api.setApiToken, response.data.data.token);
        yield put(PersistedUserActions.authorizeUserSuccess(successData));
    } else {
        yield put(
            PersistedUserActions.authorizeUserFailure({
                error: response as unknown as GeneralErrorResponse,
            })
        );
    }
}

export function* logout(api: API) {
    // We don't care about any response from the API, as the user is already
    // logged out and the local session is over
    yield call(api.logout);
}

export function* ensureUserId<Y extends any, R extends string>() {
    let userId: string = yield select(getUserId);

    if (!userId) {
        yield put(UserProfileActions.fetchProfileRequest());

        while (true) {
            yield take('*');
            userId = yield select(getUserId);
            if (userId) {
                break;
            }
        }
    }

    return userId;
}

export function* getUserTransactions(
    api: API,
    action: GetTransactionsRequestAction
): Iterator<any> {
    const userId = yield ensureUserId();

    const response: UserTransactionsResponse = yield call(
        api.getUserTransactions,
        userId,
        action.filters,
        action.pagination
    );
    if (response.status === 200) {
        const transactions = normalizeTransactions(response.data.data);
        yield put(
            UserProfileActions.getTransactionsSuccess({
                transactions,
                totalCount: response.data.count,
            })
        );
    } else {
        yield put(
            UserProfileActions.getTransactionsFailure({
                error: response as unknown as GeneralErrorResponse,
            })
        );
    }
}
