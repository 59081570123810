import { BoostrappedCallback } from 'redux-persist';

import createStore from '@/shared/redux/Store';
import persistConfig from './PersistConfig';
import authMiddleware from './authMiddleware';

import { reducer as SsrReducer } from './ssr';

const platformReducersMap = {
    ssr: SsrReducer,
};

export default function createStoreInstance(
    initialState?: object,
    onStoreReady?: BoostrappedCallback
) {
    return createStore({
        persistConfig,
        platformReducersMap,
        initialState,
        onStoreReady,
        middlewares: [authMiddleware],
    });
}
