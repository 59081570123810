import './HomePageV2.scss';
import SearchDecks from '@/components/decks/search_decks/SearchDecks';
import HomeAside from '@/components/home/HomeAside';
import HomeLead from '@/components/home/HomeLead';

import React, { Fragment } from 'react';

export interface Props {
    deckCount: number;
}

export const HomePageV2 = ({ deckCount }: Props) => {
    return (
        <Fragment>
            <HomeLead deckCount={deckCount} />
            <div className="home-page-v2__content-wrap">
                <HomeAside className="home-page-v2__aside" />
                <SearchDecks className="home-page-v2__content" />
            </div>
        </Fragment>
    );
};

export default HomePageV2;
