import React from 'react';

interface IProps {
    className: string;
    decksCount: number;
}

export const PaginatedListHeader = ({ className, decksCount }: IProps) => {
    return (
        <div className={`${className}__decks-list-header`}>
            <div className={`${className}__decks-list-total`}>
                Search Results ({decksCount})
            </div>
        </div>
    );
};
