import { assoc, mergeDeepRight, propEq } from 'ramda';

import { createReducer } from '@/shared/redux/ReduxHelpers';
import { InitialState, StateType, CurrentTournament } from './State';
import {
    pendingReducerIn,
    successReducerIn,
    errorReducerIn,
} from '@/shared/redux/Async';
import {
    Types,
    Creators,
    GetPlayerLeaderboardSuccessAction,
    GetDeckLeaderboardSuccessAction,
} from './Actions';
import { UserProfileTypes } from '@/shared/redux/user_profile';
import {
    DecksTypes,
    AddDeckToWatchlistSuccessAction,
    RemoveDeckFromWatchlistSuccessAction,
} from '@/shared/redux/decks';
import { FetchProfileSuccessAction } from '@/shared/redux/user_profile/Actions';
import { TournamentDeck } from '@/shared/typings';
import { adjustWhere } from '@/shared/services/Utils';

export const TournamentsTypes = Types;
export default Creators;

export const reducer = createReducer(InitialState, {
    [Types.GET_PLAYER_LEADERBOARD_REQUEST]: pendingReducerIn(
        'getPlayerLeaderboard'
    ),
    [Types.GET_PLAYER_LEADERBOARD_SUCCESS]: successReducerIn(
        'getPlayerLeaderboard',
        getPlayerLeaderboardSuccess
    ),
    [Types.GET_PLAYER_LEADERBOARD_FAILURE]: errorReducerIn(
        'getPlayerLeaderboard'
    ),

    [Types.GET_DECK_LEADERBOARD_REQUEST]: pendingReducerIn(
        'getDeckLeaderboard'
    ),
    [Types.GET_DECK_LEADERBOARD_SUCCESS]: successReducerIn(
        'getDeckLeaderboard',
        getDeckLeaderboardSuccess
    ),
    [Types.GET_DECK_LEADERBOARD_FAILURE]: errorReducerIn('getDeckLeaderboard'),

    [UserProfileTypes.FETCH_PROFILE_SUCCESS]: fetchProfileSuccess,

    [DecksTypes.ADD_DECK_TO_WATCHLIST_SUCCESS]: successReducerIn(
        'getDeckLeaderboard',
        mapAddDeckToWatchlistSuccess
    ),
    [DecksTypes.REMOVE_DECK_FROM_WATCHLIST_SUCCESS]: successReducerIn(
        'getDeckLeaderboard',
        mapRemoveDeckFromWatchlistSuccess
    ),
});

/* ----------------- reducers --------------------- */

function getPlayerLeaderboardSuccess(
    state: StateType['getPlayerLeaderboard'],
    action: GetPlayerLeaderboardSuccessAction
): Partial<StateType['getPlayerLeaderboard']> {
    return {
        list: action.payload.players,
        totalCount: action.payload.totalCount,
        participantCount: action.payload.participantCount,
        userRank: action.payload.userRank,
    };
}

function getDeckLeaderboardSuccess(
    state: StateType['getDeckLeaderboard'],
    action: GetDeckLeaderboardSuccessAction
): Partial<StateType['getDeckLeaderboard']> {
    return {
        list: action.payload.decks,
        totalCount: action.payload.totalCount,
    };
}

function fetchProfileSuccess(
    state: StateType,
    action: FetchProfileSuccessAction
): StateType {
    const tournament = action.payload.tournaments.find(
        (tournament) => tournament.tournamentType === CurrentTournament.id
    );
    return mergeDeepRight(state, {
        getPlayerLeaderboard: {
            userRank: (tournament && tournament.userRank) || null,
            participantCount:
                (tournament && tournament.participantCount) || null,
        },
    });
}

function mapAddDeckToWatchlistSuccess(
    state: StateType['getDeckLeaderboard'],
    action: AddDeckToWatchlistSuccessAction
): Partial<StateType['getDeckLeaderboard']> {
    const list = adjustWhere<TournamentDeck>(
        propEq('id', action.payload.deckId),
        assoc('isOnWatchlist', true),
        state.list
    );
    return { list };
}

function mapRemoveDeckFromWatchlistSuccess(
    state: StateType['getDeckLeaderboard'],
    action: RemoveDeckFromWatchlistSuccessAction
): Partial<StateType['getDeckLeaderboard']> {
    const list = adjustWhere<TournamentDeck>(
        propEq('id', action.payload.deckId),
        assoc('isOnWatchlist', false),
        state.list
    );
    return { list };
}
