import './Submenu.scss';

import NavigationDropdown from './NavigationDropdown';
import React, { FC, ReactElement, useState } from 'react';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Props as LinkMenuItemProps } from './LinkMenuItem';
import { MenuVariant } from './PageHeader';
import DropdownMenuItem from './DropdownMenuItem';

interface OwnProps {
    text: {
        key: string;
        value: string;
    };
    className: string;
}

export type Props = WithNamespaces & OwnProps & RouteComponentProps;

export const Submenu: FC<Props> = ({ location, children, text, className }) => {
    const [isDropdownVisible, setDropdownVisible] = useState(false);

    const onDropdownToggle = (isDropdownVisible: boolean) => {
        setDropdownVisible(isDropdownVisible);
    };

    const isActive = React.Children.toArray(children).some(
        (element) =>
            React.isValidElement(element) &&
            (element.props as LinkMenuItemProps).link &&
            location.pathname.startsWith(
                (element.props as LinkMenuItemProps).link.to
            )
    );

    const childrenFlat = React.Children.toArray(children)
        .filter(
            (element) =>
                React.isValidElement(element) &&
                (element.props as LinkMenuItemProps).menuVariant !==
                    MenuVariant.horizontal
        )
        .map((element) =>
            React.cloneElement(element as ReactElement<any>, {
                menuVariant: MenuVariant.hamburger,
            })
        );

    return (
        <>
            {childrenFlat}
            <NavigationDropdown
                content={<ul className="submenu__dropdown">{children}</ul>}
                onToggle={onDropdownToggle}
            >
                <DropdownMenuItem
                    text={text}
                    dropdown={{ active: isActive, open: isDropdownVisible }}
                    className={className}
                    menuVariant={MenuVariant.horizontal}
                />
            </NavigationDropdown>
        </>
    );
};

export default withNamespaces()(withRouter(Submenu));
