import './NumberInput.scss';

import React, { SyntheticEvent } from 'react';
import classNames from 'classnames';
import MaskedInput, { Mask } from 'react-text-mask';

import PlusIcon from '@/images/common/plus-icon.svg';
import MinusIcon from '@/images/common/minus-icon.svg';

export interface Props {
    className?: string;
    ariaLabel?: string;
    value: string | number;
    onChange: (e: SyntheticEvent<HTMLInputElement>) => void;
    onPlusClick: (e: SyntheticEvent<HTMLButtonElement>) => void;
    onMinusClick: (e: SyntheticEvent<HTMLButtonElement>) => void;
    mask?: Mask;
    id?: string;
}

export default function NumberInput(props: Props) {
    const {
        className,
        ariaLabel,
        mask,
        onPlusClick,
        onMinusClick,
        ...rest
    } = props;

    const inputProps = {
        type: 'tel', // enable numeric keyboard on mobile devices
        className: 'number-input__input',
        'aria-label': ariaLabel,
        ...rest,
    };

    return (
        <div className={classNames('number-input', className)}>
            <button
                className="number-input__btn number-input__btn-minus"
                onClick={onMinusClick}
            >
                <img className="number-input__btn-image" src={MinusIcon} />
            </button>

            {mask ? (
                <MaskedInput guide={false} mask={mask} {...inputProps} />
            ) : (
                <input {...inputProps} />
            )}

            <button
                className="number-input__btn number-input__btn-plus"
                onClick={onPlusClick}
            >
                <img className="number-input__btn-image" src={PlusIcon} />
            </button>
        </div>
    );
}
