import 'pure-react-carousel/dist/react-carousel.es.css';
import 'tippy.js/dist/tippy.css';

import './styles/index.scss';

import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { hydrate } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import Cookie from 'js-cookie';
import { I18nextProvider } from 'react-i18next';
import { i18n } from 'i18next';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';

import ErrorBoundary from '@/components/app/ErrorBoundary';
import App from '@/components/app/App';
import createStore from '@/redux';
import PersistorContext from '@/redux/PersistorContext';
import configureSentry from '@/raven';
import { API } from '@/shared/api/Api';
import { createAuthListener } from '@/shared/services/AuthListener';
import I18nFactory from '@/shared/services/lang/I18n';
import { RootState } from '@/shared/redux/RootState';
import ApiToken from '@/shared/services/ApiToken';
import { Flags } from '@/shared/services/Flags';

declare global {
    interface Window {
        __INITIAL_STATE__: RootState;
        __INITIAL_TRANSLATIONS__: Record<string, string>;
        __INITIAL_LANG__: string;
    }
}

window.Raven = configureSentry();

const preloadedState: RootState = window.__INITIAL_STATE__;
const preloadedTranslations = window.__INITIAL_TRANSLATIONS__;

delete window.__INITIAL_STATE__;
delete window.__INITIAL_TRANSLATIONS__;

const { store, persistor } = createStore(preloadedState, prepareI18n);

createAuthListener(API, store);

ApiToken.config(() => Cookie.get('auth'));

const rootElement = document.getElementById('root');

let i18nInstance: i18n;

function prepareI18n() {
    i18nInstance = I18nFactory(
        'web',
        window.__INITIAL_LANG__,
        preloadedTranslations,
        () => {
            API.setAcceptLanguage(i18nInstance.language);
            hydrateEntireTree();
        }
    );
}

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: 0,
        },
    },
});

function hydrateEntireTree(AppClass: typeof App = App) {
    const jsx = (
        <ErrorBoundary>
            <QueryClientProvider client={queryClient}>
                <PersistorContext.Provider value={persistor}>
                    <ReduxProvider store={store}>
                        <I18nextProvider i18n={i18nInstance}>
                            <BrowserRouter>
                                <Flags>
                                    <AppClass />
                                </Flags>
                            </BrowserRouter>
                        </I18nextProvider>
                    </ReduxProvider>
                </PersistorContext.Provider>
            </QueryClientProvider>
        </ErrorBoundary>
    );
    hydrate(jsx, rootElement);
}

if (process.env.NODE_ENV === 'development') {
    if ((module as any).hot) {
        (module as any).hot.accept('@/components/app/App', () => {
            const NextApp = require('@/components/app/App').default;
            hydrateEntireTree(NextApp);
        });
    }
}
