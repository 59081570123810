import './PageFooter.scss';

import React from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import LazyLoad from 'react-lazyload';

import ExternalLink from '@/components/common/ExternalLink';
import LanguageSelectContainer from '@/components/common/inputs/lang/LanguageSelectContainer';
import GGLogo from '@/images/common/ghost-galaxy-logo-white.svg';
import TwitterIcon from '@/images/footer/twitter-icon.svg';

export type PageFooterProps = WithNamespaces;

export function PageFooter({ t }: PageFooterProps) {
    return (
        <footer className="page-footer">
            <p className="page-footer__lang-notice">
                Select a language to display card names and card images in your
                preferred language.
            </p>
            <p className="page-footer__lang-notice">
                Cards not available in the selected language will be displayed
                in English.
            </p>
            <div className="page-footer__lang">
                <LanguageSelectContainer className="page-footer__lang-select" />
            </div>
            <div className="page-footer__social-links">
                <ExternalLink
                    to="https://twitter.com/ghostgalaxygam1"
                    className="page-footer__social-link"
                >
                    <img
                        src={TwitterIcon}
                        alt={t('footer.social.twitter', {
                            defaultValue: 'Twitter',
                        })}
                        className="page-footer__social-link-image"
                    />
                    <div className="page-footer__social-link-text">
                        {t('footer.social.twitter', {
                            defaultValue: 'Twitter',
                        })}
                    </div>
                </ExternalLink>
            </div>
            <div className="page-footer__site-links">
                <ExternalLink
                    className="page-footer__site-link"
                    to="https://www.ghostgalaxy.com/policies/privacy-policy"
                >
                    {t('footer.link.privacy', {
                        defaultValue: 'Privacy Policy',
                    })}
                </ExternalLink>
                <ExternalLink
                    className="page-footer__site-link"
                    to={t('home-page.header-section.link.learn-more', {
                        defaultValue: 'https://keyforging.com/',
                    })}
                >
                    {t('footer.link.about', {
                        defaultValue: 'About KeyForge',
                    })}
                </ExternalLink>
                <ExternalLink
                    className="page-footer__site-link"
                    to={t('footer.link.rules.href', {
                        defaultValue: 'https://keyforging.com/the-game/#rules',
                    })}
                >
                    {t('footer.link.rules', {
                        defaultValue: 'Rules',
                    })}
                </ExternalLink>
                <ExternalLink
                    className="page-footer__site-link"
                    to="https://keyforging.com/terms-of-use/"
                >
                    {t('footer.link.terms', {
                        defaultValue: 'Terms of Use',
                    })}
                </ExternalLink>
            </div>
            <ExternalLink
                to="https://www.ghostgalaxy.com/"
                className="page-footer__gg"
            >
                <LazyLoad
                    once={true}
                    placeholder={<div className="page-footer__gg-logo" />}
                >
                    <img
                        src={GGLogo}
                        alt={t('footer.hint.logo', {
                            defaultValue: 'Ghost Galaxy',
                        })}
                        className="page-footer__gg-logo"
                    />
                </LazyLoad>
                {t('footer.hint.logo', {
                    defaultValue: 'Ghost Galaxy',
                })}
            </ExternalLink>
            <div className="page-footer__copyright">
                {t('footer.hint.copyright', {
                    defaultValue:
                        'Copyright 2023. KeyForge and the KeyForge logo are registered trademarks of Ghost Galaxy, Inc. Ghost Galaxy is a trademark of Ghost Galaxy, Inc. The Ghost Galaxy logo is a registered trademark of Ghost Galaxy, Inc. All Rights Reserved.',
                })}
            </div>
            {/* it's for easier identification of production build */}
            <div
                className="page-footer__version"
                data-build={process.env.BUILD_VERSION}
                aria-hidden={true}
            />
        </footer>
    );
}

export default withNamespaces()(PageFooter);
