import './DiscoveredDecks.scss';

import React from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';

export interface Props extends WithNamespaces {
    deckCount: number;
}

export function DiscoveredDecks({ deckCount, t }: Props) {
    return (
        <div className="discovered-decks">
            <h3 className="discovered-decks__title">
                {t('home-page.hint.discovered-decks', {
                    defaultValue: 'Decks Discovered',
                })}
            </h3>
            <p className="discovered-decks__counter">
                {t('home-page.hint.discovered-decks-value', {
                    defaultValue: '{{deckCount, number}}',
                    deckCount,
                })}
            </p>
        </div>
    );
}

export default withNamespaces()(DiscoveredDecks);
