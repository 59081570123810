import { InitialAsyncRequestState } from '@/shared/redux/Async';
import { TournamentPlayer, TournamentDeck } from '@/shared/typings';
import { AvailableTournaments } from '@/shared/api/ApiTypings';

export enum Tournament {
    ChainBound = 1,
    VaultTour = 2,
}

/**
 * Tournament that is currently displayed in the Profile page
 */
export const CurrentTournament: {
    id: Tournament;
    slug: AvailableTournaments;
} = {
    id: Tournament.VaultTour,
    slug: 'vault-tour',
};

export const InitialState = {
    getPlayerLeaderboard: {
        ...InitialAsyncRequestState,

        list: [] as TournamentPlayer[],
        /**
         * Number of records for pagination
         */
        totalCount: 0,
        /**
         * Number of all qualified participants in the tournament
         */
        participantCount: 0,
        /**
         * Rank of currenlty logged-in user in the tournament,
         * null if user is not logged-in, or hasn't participated in the event
         */
        userRank: null as number,
    },
    getDeckLeaderboard: {
        ...InitialAsyncRequestState,

        list: [] as TournamentDeck[],
        totalCount: 0,
    },
};

export type StateType = typeof InitialState;
