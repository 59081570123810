import './PageHeader.scss';

import React, { PureComponent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { Persistor } from 'redux-persist';
import classNames from 'classnames';

import { UserProfile } from '@/shared/typings';
import Logo from './Logo';
import LinkMenuItem from './LinkMenuItem';
import UserMenu from './UserMenu';

export const MenuItems = {
    TheGame: {
        text: {
            key: 'navbar.menu.the-game',
            value: 'The Game',
        },
        link: { to: 'https://keyforging.com/the-game/', external: true },
    },
    News: {
        text: {
            key: 'navbar.menu.news',
            value: 'News',
        },
        link: { to: 'https://keyforging.com/new-summary/', external: true },
    },
    MyProfile: {
        text: {
            key: 'navbar.menu.my-profile',
            value: 'My Profile',
        },
        className: 'menu-item--my-profile',
        link: { to: '/profile', external: false },
    },
    MyLegacyDecks: {
        text: {
            key: 'navbar.menu.my-legacy-decks',
            value: 'Legacy Decks',
        },
        link: { to: '/my-decks-legacy', external: false },
    },
    MyDecks: {
        text: {
            key: 'navbar.menu.my-decks',
            value: 'My Decks',
        },
        className: 'menu-item--my-decks',
        link: { to: '/my-decks', external: false },
    },
    MyAlliances: {
        text: {
            key: 'navbar.menu.my-alliances',
            value: 'My Alliances',
        },
        className: 'menu-item--my-alliances',
        link: { to: '/alliances', external: false },
    },
    Friends: {
        text: {
            key: 'navbar.menu.friends',
            value: 'Friends',
        },
        className: 'menu-item--friends',
        link: { to: '/friends', external: false },
    },
    MyFriendsDecks: {
        text: {
            key: 'navbar.menu.friends-decks',
            value: "Friends' Decks",
        },
        className: 'menu-item--friends-decks',
        link: { to: '/friends-decks', external: false },
    },
    Transfers: {
        text: {
            key: 'navbar.menu.deck-transfer',
            value: "Deck Transfers",
        },
        className: 'menu-item--deck-transfers',
        link: { to: '/deck-transfers', external: false },
    },
    SignOut: {
        text: {
            key: 'navbar.btn.sing-out',
            value: 'Sign Out',
        },
        className: 'menu-item--sign-out',
    },
};

export enum MenuVariant {
    hamburger = 'hamburger',
    horizontal = 'horizontal',
}

interface OwnProps {
    isUserAuthorized: boolean;

    user: UserProfile;
    authUrl: string;
    logout: (storePersistor: Persistor) => Promise<void>;
}

export type Props = OwnProps & WithNamespaces & RouteComponentProps;

interface State {
    isMenuExpanded: boolean;
    isDropdownVisible: boolean;
}

export class PageHeader extends PureComponent<Props, State> {
    public state: Readonly<State> = {
        isMenuExpanded: false,
        isDropdownVisible: false,
    };

    private removeHistorySubscription: () => void;

    public componentDidMount() {
        window.addEventListener('resize', this.handleWindowResize);

        // close the nav menu when url changes
        this.removeHistorySubscription = this.props.history.listen(() => {
            this.setState({ isMenuExpanded: false });
        });
    }

    public componentDidUpdate(prevProps: Props, prevState: State) {
        const { isMenuExpanded } = this.state;
        if (prevState.isMenuExpanded !== isMenuExpanded) {
            document.body.style.overflow = isMenuExpanded ? 'hidden' : null;
        }
    }

    public componentWillUnmount() {
        this.removeHistorySubscription();
        window.removeEventListener('resize', this.handleWindowResize);
        document.body.style.overflow = null;
    }

    public render() {
        const { t } = this.props;
        const { isMenuExpanded } = this.state;

        return (
            <div className={`page-header page-header--common`}>
                <Logo />
                <div
                    className={classNames('page-header__navigation', {
                        'page-header__navigation--expanded': isMenuExpanded,
                    })}
                >
                    <button
                        onClick={this.toggleMenu}
                        className="page-header__navigation-toggle"
                        aria-controls="page-header__navigation-menu"
                        aria-expanded={isMenuExpanded}
                        aria-label={t('navbar.menu.btn.label', {
                            defaultValue: 'Toggle navigation menu',
                        })}
                    />
                    <nav
                        className="page-header__navigation-menu"
                        id="page-header__navigation-menu"
                    >
                        <ul className="page-header__navigation-links">
                            <LinkMenuItem {...MenuItems.TheGame} />
                            <LinkMenuItem {...MenuItems.News} />
                            <UserMenu {...this.props} />
                        </ul>
                    </nav>
                </div>
            </div>
        );
    }

    private toggleMenu = () => {
        this.setState({ isMenuExpanded: !this.state.isMenuExpanded });
    };

    private handleWindowResize = () => {
        if (window.innerWidth >= 768 && this.state.isMenuExpanded) {
            this.setState({ isMenuExpanded: false });
        }
    };
}

export default withNamespaces()(withRouter(PageHeader));
