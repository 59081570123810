import { combineReducers, AnyAction, ReducersMapObject } from 'redux';
import { persistReducer, PersistConfig } from 'redux-persist';
import { pick } from 'ramda';

import { RootState } from './RootState';
import {
    reducer as PersistedUserReducer,
    PersistedUserTypes,
} from './persisted_user';
import { reducer as UserProfileReducer } from './user_profile';
import {
    userDecksReducer as UserDecksReducer,
    LegacyUserDecksReducer,
} from './user_decks';
import { reducer as TournamentsReducer } from './tournaments';
import { DecksReducer, LegacyDecksReducer } from './decks';
import { reducer as GameStoresReducer } from './game_stores';
import { reducer as EventLocatorReducer } from './event_locator';
import { ACTION_PURGE_STORE } from './ReduxHelpers';
import { isSSRBuild } from '../services/Utils';
import { reducer as AlliancesReducer } from './alliances';

export const CommonReducers = {
    decks: DecksReducer,
    decksLegacy: LegacyDecksReducer,
    userDecks: UserDecksReducer,
    userDecksLegacy: LegacyUserDecksReducer,
    persistedUser: PersistedUserReducer,
    tournaments: TournamentsReducer,
    userProfile: UserProfileReducer,
    gameStores: GameStoresReducer,
    eventLocator: EventLocatorReducer,
    alliances: AlliancesReducer,
};

function baseReducer(state: RootState, action: AnyAction): RootState {
    switch (action.type) {
        case PersistedUserTypes.LOGOUT:
            return pick(['ssr'], state) as RootState;
        case ACTION_PURGE_STORE:
            return pick(
                ['ssr', 'persistedUser', 'userAuth', 'userProfile'],
                state
            ) as RootState;
        default:
            return state;
    }
}

export default (
    persistConfig: PersistConfig,
    platformReducersMap: ReducersMapObject = {}
) => {
    const reducers = { ...platformReducersMap, ...CommonReducers };
    const appReducer = combineReducers(reducers);
    const rootReducer = (state: RootState, action: AnyAction) =>
        appReducer(baseReducer(state, action), action);

    return isSSRBuild()
        ? rootReducer
        : persistReducer(persistConfig, rootReducer);
};
