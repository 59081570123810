import React from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';

import Modal from '@/components/common/Modal';

interface Props extends WithNamespaces {
    isOpen: boolean;
    onCancel: () => void;
    onSuccess: () => void;
}

export function DiscardChangesModal({ isOpen, onCancel, onSuccess, t }: Props) {
    return (
        <Modal
            onClose={onCancel}
            isOpen={isOpen}
            className="note-modal note-modal--small"
        >
            <div className="note-modal__confirm-discard">
                <h3 className="note-modal__prompt-heading">
                    {t('modal.note-discard.header.discard-changes', {
                        defaultValue: 'The changes you made won’t be saved',
                    })}
                </h3>
                <div className="note-modal__prompt-buttons">
                    <button
                        className="note-modal__prompt-btn btn"
                        onClick={onCancel}
                    >
                        {t('modal.note-discard.btn.cancel', {
                            defaultValue: 'Go Back',
                        })}
                    </button>
                    <button
                        className="note-modal__prompt-btn btn-secondary"
                        onClick={onSuccess}
                    >
                        {t('modal.note-discard.btn.ok', {
                            defaultValue: 'Discard Changes',
                        })}
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default withNamespaces()(DiscardChangesModal);
