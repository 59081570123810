import { useQuery } from '@tanstack/react-query';
import { AxiosError } from '../api/ApiTypings';
import { API } from '../api/Api';

export const useDecks = (userId, filters, pagination, options = {}) =>
    useQuery<any, AxiosError>({
        queryKey: ['decks', {userId, filters, pagination}],
        queryFn: async ({queryKey}) => {
          const [_key, { userId, filters, pagination }] = queryKey

          const decksResponse = await API.getUserDecks(userId, filters, pagination)

          return decksResponse.data;
        },
        ...options
    });