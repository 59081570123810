import React from 'react';
import QRCode from 'qrcode.react';

interface IProps {
    id: string;
}

const QRCodeGenerator = ({ id }: IProps) => {
    const jsonString = JSON.stringify({ alliance: id });

    return (
        <div>
            <h2>Alliance QRCode</h2>
            <QRCode value={jsonString} size={128} />
        </div>
    );
};

export default QRCodeGenerator;
