import React from 'react';
import Tippy from '@tippy.js/react';
import './AccoladesList.scss';

export const AccoladesList = ({ deck, t }) => {
    const visibleAccolades = deck.accolades?.filter((a) => a?.visible);

    return (
        <>
            {visibleAccolades?.length > 0 && (
                <div className="accolades__container">
                    <h2 className="accolades-title keyforge-heading-2">
                        {t('single-deck.header.accolades', {
                            defaultValue: 'Accolades',
                        })}
                    </h2>
                    <div className="accolades-info keyforge-heading-2">
                        This deck has earned the following accolades, learn more
                        about Accolades{' '}
                        <a
                            className="simple-external-link"
                            href="https://keyforging.com/deck-accolades/"
                        >
                            here
                        </a>
                        .
                    </div>
                    <div className="accolades">
                        {visibleAccolades?.map((accolade) => {
                            return (
                                <Tippy
                                    key={accolade?.id}
                                    animateFill={false}
                                    duration={0}
                                    distance={12}
                                    content={
                                        <div className="accolade-name">
                                            {accolade?.name}
                                        </div>
                                    }
                                    interactive={true}
                                    delay={[150, 0]}
                                    placement="bottom"
                                    theme="keyforge"
                                    trigger="mouseenter focus"
                                >
                                    <div className="accolade">
                                        <div className="accolade-image">
                                            <img
                                                width={128}
                                                src={accolade?.image}
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </Tippy>
                            );
                        })}
                    </div>
                </div>
            )}
        </>
    );
};
