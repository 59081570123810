import { assoc, propEq } from 'ramda';
import { createReducer } from '../ReduxHelpers';
import { InitialState, StateType } from './State';
import { successReducerIn, pendingReducerIn, errorReducerIn } from '../Async';
import {
    UserDecksTypes as Types,
    UserDecksSuccessAction,
    AddDeckSuccessAction,
    AddDeckFailureAction,
} from './Actions';
import {
    DecksTypes,
    AddDeckToFavoritesSuccessAction,
    RemoveDeckFromFavoritesSuccessAction,
} from '../decks/Actions';
import { Deck } from '@/shared/typings';
import { adjustWhere } from '@/shared/services/Utils';
import { LEGACY_OWNERSHIP_ACTION_PREFIX } from './constants';

export * from './Actions';
export { LEGACY_OWNERSHIP_ACTION_PREFIX };

const reducersMap = {
    [Types.USER_DECKS_REQUEST]: pendingReducerIn('userDecks'),
    [Types.USER_DECKS_SUCCESS]: successReducerIn(
        'userDecks',
        mapUserDecksSuccess
    ),
    [Types.USER_DECKS_FAILURE]: errorReducerIn('userDecks'),

    [Types.RESET_ADD_DECK_REQUEST]: resetAddDeckState,

    [Types.ADD_DECK_REQUEST]: pendingReducerIn('addingDeck'),
    [Types.ADD_DECK_SUCCESS]: successReducerIn('addingDeck', mapAddDeckSuccess),
    [Types.ADD_DECK_FAILURE]: errorReducerIn('addingDeck', mapAddDeckFailure),

    [Types.REMOVE_DECK_REQUEST]: pendingReducerIn('removeDeck'),
    [Types.REMOVE_DECK_SUCCESS]: successReducerIn('removeDeck'),
    [Types.REMOVE_DECK_FAILURE]: errorReducerIn('removeDeck'),

    [Types.UPDATE_CASUAL_SCORES_REQUEST]:
        pendingReducerIn('updateCasualScores'),
    [Types.UPDATE_CASUAL_SCORES_SUCCESS]:
        successReducerIn('updateCasualScores'),
    [Types.UPDATE_CASUAL_SCORES_FAILURE]: errorReducerIn('updateCasualScores'),

    [DecksTypes.ADD_DECK_TO_FAVORITES_SUCCESS]: successReducerIn(
        'userDecks',
        mapAddDeckToFavoritesSuccess
    ),
    [DecksTypes.REMOVE_DECK_FROM_FAVORITES_SUCCESS]: successReducerIn(
        'userDecks',
        mapRemoveDeckFromFavoritesSuccess
    ),
};

export const userDecksReducer = createReducer(InitialState, reducersMap);

export const LegacyUserDecksReducer = createReducer(
    InitialState,
    // add the same reducer functions, but prefix action names with LEGACY_OWNERSHIP_ACTION_PREFIX
    Object.entries(reducersMap).reduce(
        (acc, [type, reducer]) =>
            assoc(LEGACY_OWNERSHIP_ACTION_PREFIX + type, reducer, acc),
        {}
    )
);

function mapUserDecksSuccess(
    state: StateType['userDecks'],
    action: UserDecksSuccessAction
): Partial<StateType['userDecks']> {
    return {
        list: action.payload.decks,
        totalCount: action.payload.totalCount,
    };
}

function mapAddDeckSuccess(
    state: StateType['addingDeck'],
    action: AddDeckSuccessAction
): Partial<StateType['addingDeck']> {
    return {
        deck: action.payload.deck,
        addStatus: action.payload.addStatus,
    };
}

function mapAddDeckFailure(
    state: StateType['addingDeck'],
    action: AddDeckFailureAction
): Partial<StateType['addingDeck']> {
    return {
        addStatus: action.payload.addStatus,
    };
}

function resetAddDeckState(state: StateType): StateType {
    return assoc('addingDeck', InitialState.addingDeck, state);
}

function mapAddDeckToFavoritesSuccess(
    state: StateType['userDecks'],
    action: AddDeckToFavoritesSuccessAction
): Partial<StateType['userDecks']> {
    const list = adjustWhere<Deck>(
        propEq('id', action.payload.deckId),
        assoc('favorite', true),
        state.list
    );
    const totalCount = state.totalCount;
    return { list, totalCount };
}

function mapRemoveDeckFromFavoritesSuccess(
    state: StateType['userDecks'],
    action: RemoveDeckFromFavoritesSuccessAction
): Partial<StateType['userDecks']> {
    const list = adjustWhere<Deck>(
        propEq('id', action.payload.deckId),
        assoc('favorite', false),
        state.list
    );
    const totalCount = state.totalCount;
    return { list, totalCount };
}
