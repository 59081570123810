import { InitOptions } from 'i18next';

import {
    formatDateByLocale,
    formatShortDateByLocale,
    formatFullDateByLocale,
    formatNumberByLocale,
} from './formatHelpers';

export default function I18nCommonConfigFactory(
    namespace: string
): InitOptions {
    // Replacing URL for getting translations with a stub from express.
    // See also src/ssr/server.ts
    const ApiUrl = process.env.API_URL.replace(/\/api\/?$/, '');
    // const ApiUrl = process.env.LOCAL_API_URL || process.env.API_URL;

    return {
        load: 'currentOnly',
        defaultNS: namespace,
        // we treat "defaultValue" fields as fallback for specific translation,
        // we do not want to load another entire translation file for fallback
        // only mechanism
        fallbackLng: false,
        // debug: true,

        ns: [namespace],
        interpolation: {
            escapeValue: false,
            format: (value, format, lng) => {
                if (value instanceof Date && format === 'byLanguage') {
                    return formatDateByLocale(value, lng);
                }
                if (value instanceof Date && format === 'shortDate') {
                    return formatShortDateByLocale(value, lng);
                }
                if (value instanceof Date && format === 'fullDate') {
                    return formatFullDateByLocale(value, lng);
                }
                if (typeof value === 'number' && format === 'number') {
                    return formatNumberByLocale(value, lng);
                }
                return value;
            },
        },
        lowerCaseLng: true,

        keySeparator: false,
        react: {
            wait: true,
            bindI18n: 'languageChanged loaded',
            // bindStore: 'added removed',
            nsMode: 'fallback',
        },
        backend: {
            // path where resources get loaded from, or a function
            // returning a path:
            // function(lngs, namespaces) { return customPath; }
            // the returned path will interpolate lng, ns if provided like giving a static path
            loadPath: ApiUrl + '/locales/{{lng}}/{{ns}}.json',

            // path to post missing resources
            addPath: false,
            // ajax: console.log
        },
    };
}
