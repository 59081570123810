import './ScanDeckQRModal.scss';

import Modal from '@/components/common/Modal';
import { useQrScanner } from '@/components/common/useQrScanner';
import React, { useCallback, useState } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';

export interface Props extends WithNamespaces {
    isOpen: boolean;
    onClose: () => void;
    onScanSuccess: (code: string) => void;
}

export const ScanDeckQRModal = ({
    isOpen,
    onClose,
    onScanSuccess,
    t,
}: Props) => {
    const [videoEl, setVideoEl] = useState<HTMLVideoElement | null>(null);
    const [error, setError] = useState('');
    useQrScanner({
        video: videoEl,
        onDecode: useCallback(
            (url) => {
                // QR code contains URL of shape https://keyforgegame.com/deck/{CODE}.
                // If user scanned an invalid code we don't care here,
                // validation will occur in a HOC.
                const code = url.split('/').reverse()[0] || '';
                onScanSuccess(code);
            },
            [onScanSuccess]
        ),
        onError: useCallback(
            (error: any) => {
                const msg =
                    typeof error === 'string'
                        ? error
                        : error && error.message
                        ? error.message
                        : t('my-decks.scan-deck.error.unknown-error-message', {
                              defaultValue:
                                  'Scanning error. Try to input the code manually.',
                          });
                setError(msg);
            },
            [t]
        ),
        enabled: isOpen && !!videoEl,
    });

    return (
        <Modal isOpen={isOpen} onClose={onClose} className="scan-deck-qr-modal">
            <h1 className="scan-deck-qr-modal__header">
                {t('scan-deck.title', { defaultValue: 'Scan Deck' })}
            </h1>
            {!error ? (
                <video
                    ref={setVideoEl}
                    muted={true}
                    className="scan-deck-qr-modal__video"
                />
            ) : (
                <div className="scan-deck-qr-modal__error">
                    <h2>
                        {t('my-decks.scan-deck.error.heading', {
                            defaultValue: "Couldn't perform scanning",
                        })}
                    </h2>
                    <p>{error}</p>
                    <p>
                        {t('my-decks.scan-deck.error.hint', {
                            defaultValue:
                                'Make sure this device has a camera and you have given permission to use it.',
                        })}
                    </p>
                </div>
            )}
            <div className="scan-deck-qr-modal__footer">
                <button className="btn btn-okay" onClick={onClose}>
                    {t('general.hint.close', { defaultValue: 'Close' })}
                </button>
            </div>
        </Modal>
    );
};

export default withNamespaces()(ScanDeckQRModal);
