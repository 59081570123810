import { InitialAsyncRequestState } from '../Async';
import { Deck, HousesDict } from '../../typings';
import { BonusIconType } from '@/shared/api/ApiTypings';

export enum CardRarity {
    Common,
    Uncommon,
    Rare,
    Special,
    EvilTwin,
}

export enum CardType {
    Action,
    Artifact,
    Creature,
    Upgrade,
    Tide,
    TokenCreature,
}

export type CardBadge = 'maverick' | 'legacy' | 'anomaly' | BonusIconType;

const InitialDeck: Deck = {
    id: '',
    name: '',
    isMyDeck: false,
    isDeckOwned: false,
    isPendingTransfer: false,
    favorite: false,
    isOnWatchlist: false,
    houses: [],
    cards: {},
    notes: [],
    expansion: 341,
    powerLevel: 0,
    chains: 0,
    wins: 0,
    losses: 0,
    casualWins: 0,
    casualLosses: 0,
    shardsBonus: 0,
};

const InitialHousesDict: HousesDict = {};
const decksCache: Record<string, Deck> = {};

export const InitialState = {
    getDeck: {
        ...InitialAsyncRequestState,

        deck: InitialDeck,
    },
    searchDecks: {
        ...InitialAsyncRequestState,

        totalCount: 0,
        list: [] as Deck[],
    },
    recentDecks: {
        ...InitialAsyncRequestState,

        totalCount: 0,
        list: [] as Deck[],
    },
    deckCount: {
        ...InitialAsyncRequestState,

        count: 0,
    },
    decksCache,
    houses: {
        ...InitialAsyncRequestState,

        dict: InitialHousesDict,
    },

    addNote: InitialAsyncRequestState,
    updateNote: InitialAsyncRequestState,
    deleteNote: InitialAsyncRequestState,

    addDeckToFavorites: InitialAsyncRequestState,
    removeDeckFromFavorites: InitialAsyncRequestState,

    getWatchlistDecks: {
        ...InitialAsyncRequestState,

        totalCount: 0,
        list: [] as Deck[],
    },
    addDeckToWatchlist: InitialAsyncRequestState,
    removeDeckFromWatchlist: InitialAsyncRequestState,
};

export type StateType = typeof InitialState;
