import './UserMenu.scss';

import { withNamespaces, WithNamespaces } from 'react-i18next';
import classNames from 'classnames';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import Submenu from './Submenu';
import LinkMenuItem from './LinkMenuItem';
import { MenuItems, MenuVariant } from './PageHeader';
import { UserProfile } from '@/shared/typings';
import { Persistor } from 'redux-persist/es/types';
import LogoutButton from './LogoutButton';
import avatarStub from '@/images/header/avatar.jpg';
import { useFlag } from '@/shared/services/Flags';

export interface AvatarProps extends WithNamespaces {
    imageUrl: string;
    menuVariant?: MenuVariant;
}

export const Avatar = withNamespaces()((({ t, menuVariant }) => (
    <Link
        className={classNames(
            'avatar',
            menuVariant ? `avatar--${menuVariant}` : ''
        )}
        to="/profile"
    >
        <img
            className="avatar__image"
            // Forcing all users to have the same avatar as now
            // we don't have the right to use images from asmodee.
            src={avatarStub}
            alt={t('navbar.img.alt.avatar', {
                defaultValue: 'Avatar',
            })}
        />
    </Link>
)) as FC<AvatarProps>);

interface UserProps {
    isUserAuthorized: boolean;
    user: UserProfile;
    authUrl: string;
    logout: (storePersistor: Persistor) => Promise<void>;
}

export type Props = UserProps & WithNamespaces;

export const UserMenu: FC<Props> = (props) => {
    const { isUserAuthorized, user, authUrl, t } = props;
    const newOwnership = useFlag('newDeckOwnership');
    const alliancesEnabled = useFlag('alliances');
    const friendsEnabled = useFlag('friends');

    const LogOutButton = () => {
        return (
            <div
                className={classNames('user-menu', 'user-menu--not-logged-in')}
            >
                <a
                    // there might be some rehydration warnings around this url,
                    // but as long as the only difference is '&state=' part,
                    // it's not a critical problem.
                    // this is blocked by asmodee oauth api.
                    // rehydration warnings are hidden in production env by default
                    href={authUrl}
                    className="user-menu__login"
                >
                    {t('navbar.btn.log-in', {
                        defaultValue: 'Log in',
                    })}
                </a>
            </div>
        );
    }

    return (
        <>
            {!isUserAuthorized && !user.id ?
                <LogOutButton /> 
                :
                (
                    <>
                        <Avatar
                                imageUrl={user.profilePhoto}
                                menuVariant={MenuVariant.horizontal} /><Submenu
                            text={{ key: 'general.nonexistent-key', value: user.username }}
                            className="menu-item--user"
                        >
                            <LinkMenuItem {...MenuItems.MyProfile} />
                            <LinkMenuItem {...MenuItems.MyDecks} />
                            {newOwnership && <LinkMenuItem {...MenuItems.MyLegacyDecks} />}
                            {alliancesEnabled && (
                                <LinkMenuItem {...MenuItems.MyAlliances} />
                            )}
                            {friendsEnabled && <LinkMenuItem {...MenuItems.Friends} />}
                            <LinkMenuItem {...MenuItems.MyFriendsDecks} />
                            <LinkMenuItem {...MenuItems.Transfers} />
                            <LogoutButton {...props} menuVariant={MenuVariant.horizontal} />
                        </Submenu>
                        <div
                            className={classNames(
                                'user-menu',
                                'user-menu--short',
                                'user-menu--logged-in'
                            )}
                        >
                            <Avatar imageUrl={user.profilePhoto} />
                            <div className="user-menu__profile">
                                <span className="user-menu__name" title={user.username}>
                                    {user.username}
                                </span>
                                <LogoutButton {...props} />
                            </div>
                        </div>
                    </>
                )
            }
        </>
    );
};

export default withNamespaces()(UserMenu);
