import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Persistor } from 'redux-persist';

import PageHeader from './PageHeader';
import { RootState } from '@/redux/RootState';
import UserProfileActions from '@/shared/redux/user_profile';
import PersistedUserActions from '@/shared/redux/persisted_user';
import { isAuthorized } from '@/shared/redux/Selectors';
import { AsyncStatus } from '@/shared/redux/Async';
import { currentAuthorizationUrl } from '@/shared/services/Auth';
import { getBaseUrl } from '@/utils/url';
import { isSSRBuild } from '@/shared/services/Utils';
import { UserProfile } from '@/shared/typings';

interface StateProps {
    oauthNonce: string;
    oauthState: string;
    isUserAuthorized: boolean;
    user: UserProfile;
    userProfileFetchStatus: AsyncStatus;
}

interface DispatchProps {
    refreshAuthorizationUrl: typeof PersistedUserActions['refreshAuthorizationUrl'];
    updateOauthMeta: typeof PersistedUserActions['updateOauthMeta'];
    logout: typeof PersistedUserActions['logout'];
    fetchUserProfile: typeof UserProfileActions['fetchProfileRequest'];
}

export type Props = StateProps & DispatchProps & RouteComponentProps;

export class PageHeaderContainer extends PureComponent<Props> {
    private removeHistorySubscription: () => void;

    // we use WillMount hook, as it is the only hook that runs during SSR
    public UNSAFE_componentWillMount() {
        if (
            isSSRBuild() &&
            this.props.isUserAuthorized &&
            this.props.userProfileFetchStatus === AsyncStatus.Init
        ) {
            this.props.fetchUserProfile();
        }
    }

    public componentDidUpdate() {
        if (
            !this.props.isUserAuthorized &&
            this.props.location.pathname !== '/authorize'
        ) {
            this.props.updateOauthMeta({
                oauthNonce: this.props.oauthNonce,
                oauthState: this.props.location.pathname,
            });
        }
    }

    public componentDidMount() {
        if (
            !this.props.isUserAuthorized &&
            this.props.location.pathname !== '/authorize'
        ) {
            this.props.updateOauthMeta({
                oauthNonce: this.props.oauthNonce,
                oauthState: this.props.location.pathname,
            });
        }

        if (
            this.props.isUserAuthorized &&
            this.props.userProfileFetchStatus !== AsyncStatus.Success
        ) {
            this.props.fetchUserProfile();
        }

        this.removeHistorySubscription = this.props.history.listen(
            (location) => {
                if (location.pathname !== this.props.location.pathname) {
                    this.refreshLoginUrl(location.pathname);
                }
            }
        );
    }

    public componentWillUnmount() {
        this.removeHistorySubscription();
    }

    public render() {
        return (
            <PageHeader
                user={this.props.user}
                authUrl={this.currentAuthUrl()}
                logout={this.logout}
                isUserAuthorized={this.props.isUserAuthorized}
            />
        );
    }

    private refreshLoginUrl(pathname: string) {
        if (pathname !== '/authorize') {
            this.props.refreshAuthorizationUrl(pathname);
        }
    }

    private logout = async (storePersistor: Persistor) => {
        this.props.logout();
        this.refreshLoginUrl(this.props.location.pathname);
        await storePersistor.flush();
        this.props.history.push('/');
    };

    private currentAuthUrl() {
        return currentAuthorizationUrl(
            this.props.oauthNonce,
            this.props.oauthState,
            `${getBaseUrl()}/authorize`
        );
    }
}

const getOauthNonce = (isAuthorized: boolean, state: RootState) => {
    return isAuthorized
        ? state.persistedUser.auth.oauthNonce
        : state.ssr.auth.oauthNonce;
};

const mapStateToProps = (state: RootState): StateProps => {
    const isUserAuthorized = isAuthorized(state);
    const oauthNonce = getOauthNonce(isUserAuthorized, state);
    return {
        isUserAuthorized,
        user: state.userProfile.fetchProfile.details,
        userProfileFetchStatus: state.userProfile.fetchProfile.__status,
        oauthNonce,
        oauthState: state.persistedUser.auth.oauthState,
    };
};

const mapDispatchToProps: DispatchProps = {
    refreshAuthorizationUrl: PersistedUserActions.refreshAuthorizationUrl,
    updateOauthMeta: PersistedUserActions.updateOauthMeta,
    logout: PersistedUserActions.logout,
    fetchUserProfile: UserProfileActions.fetchProfileRequest,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PageHeaderContainer);
