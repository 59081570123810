import React, { PureComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import DeckPage from './DeckPage';
import LoadingPage from '@/components/common/LoadingPage';
import MissingPage from '@/components/common/MissingPage';
import { DecksActions, LegacyDecksActions } from '@/shared/redux/decks';
import {
    UserDecksActions,
    LegacyUserDecksActions,
} from '@/shared/redux/user_decks';
import { RootState } from '@/shared/redux/RootState';
import { AsyncStatus } from '@/shared/redux/Async';
import { isAuthorized, getRequestErrorCode } from '@/shared/redux/Selectors';
import { Deck } from '@/shared/typings';
import withMetadata, { TWithMetadata } from '@/shared/components/WithMetadata';
import { isSSRBuild } from '@/shared/services/Utils';
import SiteMetadata from '@/config/Metadata';
import { CasualScores } from '@/shared/api/ApiTypings';
import { getBaseUrl } from '@/utils/url';

type BaseProps = TWithMetadata & RouteComponentProps<{ id: string }>;
export interface HOCProps {
    deck: Deck;
    getDeckStatus: RootState['decks']['getDeck']['__status'];
    getDeckErrorStatus: number;
    requestDeckData: (typeof DecksActions)['getDeckRequest'];
    requestNoteAdd: (typeof DecksActions)['addNoteRequest'];
    requestAddDeckToFavorites: (typeof DecksActions)['addDeckToFavoritesRequest'];
    requestRemoveDeckFromFavorites: (typeof DecksActions)['removeDeckFromFavoritesRequest'];
    requestAddDeckToWatchlist: (typeof DecksActions)['addDeckToWatchlistRequest'];
    requestRemoveDeckFromWatchlist: (typeof DecksActions)['removeDeckFromWatchlistRequest'];
    updateCasualScores: (typeof UserDecksActions)['updateCasualScoresRequest'];
    isUserAuthorized: boolean;
}

export interface Props extends BaseProps, HOCProps {}

export class DeckPageContainer extends PureComponent<Props> {
    // we use WillMount hook, as it is the only hook that runs during SSR
    public UNSAFE_componentWillMount() {
        if (isSSRBuild() && this.props.getDeckStatus === AsyncStatus.Init) {
            this.props.requestDeckData(this.props.match.params.id);
        }
    }

    public componentDidMount() {
        const { match, deck, getDeckStatus, getDeckErrorStatus } = this.props;
        const deckId = match.params.id;
        if (
            deckId !== deck.id ||
            (getDeckErrorStatus !== 404 &&
                getDeckStatus !== AsyncStatus.Success)
        ) {
            this.props.requestDeckData(deckId);
        }
    }

    public render() {
        const {
            getDeckErrorStatus,
            getDeckStatus,
            deck,
            metadata,
            isUserAuthorized,
            requestNoteAdd,
        } = this.props;

        if (getDeckErrorStatus === 404) {
            return (
                <Route
                    render={({ staticContext }) => {
                        if (staticContext) {
                            staticContext.statusCode = 404;
                        }
                        return <MissingPage />;
                    }}
                />
            );
        }

        const [title, description, socialDescription] = this.getHeadMeta(deck);
        return getDeckStatus === AsyncStatus.Success &&
            metadata.isMetadataSuccess() ? (
            <>
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" content={description} />
                    <meta property="og:title" content={title} />
                    <meta
                        property="og:description"
                        content={socialDescription}
                    />
                    <link
                        rel="canonical"
                        href={`${getBaseUrl()}/deck-details/${deck.id}`}
                    />
                </Helmet>
                <DeckPage
                    expansions={metadata.expansions}
                    isUserAuthorized={isUserAuthorized}
                    onNoteSave={requestNoteAdd}
                    deck={deck}
                    isLegacy={this.props.location.state?.isLegacy}
                    handleDeckDelete={this.handleDeckDelete}
                    handleDeckFavoriteToggle={this.handleDeckFavoriteToggle}
                    handleDeckWatchlistToggle={this.handleDeckWatchlistToggle}
                    handleDeckCasualScoresUpdate={
                        this.handleDeckCasualScoresUpdate
                    }
                />
            </>
        ) : (
            <LoadingPage />
        );
    }

    private getHeadMeta = (deck: Deck) => {
        const { title, description, social } = SiteMetadata.deckDetails;
        const formattedTitle = title.replace('{{deckName}}', deck.name);
        let formattedDescription = description.replace(
            /{{deckName}}/g,
            deck.name
        );
        deck.houses.forEach((house) => {
            formattedDescription = formattedDescription.replace(
                '{{house}}',
                house.name
            );
        });
        return [formattedTitle, formattedDescription, social.description];
    };

    private handleDeckDelete = () => {
        this.props.history.push('/my-decks');
    };

    private handleDeckFavoriteToggle = () => {
        const { id, favorite } = this.props.deck;
        favorite
            ? this.props.requestRemoveDeckFromFavorites(id)
            : this.props.requestAddDeckToFavorites(id);
    };

    private handleDeckWatchlistToggle = () => {
        const { id, isOnWatchlist } = this.props.deck;
        isOnWatchlist
            ? this.props.requestRemoveDeckFromWatchlist(id)
            : this.props.requestAddDeckToWatchlist(id);
    };

    private handleDeckCasualScoresUpdate = (casualScores: CasualScores) => {
        this.props.updateCasualScores(this.props.deck.id, casualScores);
    };
}

const DeckPageContainerHOC = (props: BaseProps) => {
    const dispatch = useDispatch();
    const isLegacy = props.location.state?.isLegacy;
    const sliceKey = !isLegacy ? 'decks' : 'decksLegacy';

    const userDecksActions = !isLegacy
        ? UserDecksActions
        : LegacyUserDecksActions;
    const decksActions = !isLegacy ? DecksActions : LegacyDecksActions;
    const deck = useSelector<RootState, Props['deck']>(
        (state) => state[sliceKey].getDeck.deck
    );
    const getDeckStatus = useSelector<RootState, Props['getDeckStatus']>(
        (state) => state[sliceKey].getDeck.__status
    );
    const getDeckErrorStatus = useSelector<
        RootState,
        Props['getDeckErrorStatus']
    >((state) => getRequestErrorCode(state[sliceKey].getDeck.__error));
    const isUserAuthorized = useSelector<RootState, Props['isUserAuthorized']>(
        isAuthorized
    );
    const requestDeckData = (id: string) =>
        dispatch(decksActions.getDeckRequest(id));
    const requestNoteAdd = (id: string, note: string) =>
        dispatch(decksActions.addNoteRequest(id, note));
    const requestAddDeckToFavorites = (id: string) =>
        dispatch(decksActions.addDeckToFavoritesRequest(id));
    const requestRemoveDeckFromFavorites = (id: string) =>
        dispatch(decksActions.removeDeckFromFavoritesRequest(id));
    const requestAddDeckToWatchlist = (id: string) =>
        dispatch(decksActions.addDeckToWatchlistRequest(id));
    const requestRemoveDeckFromWatchlist = (id: string) =>
        dispatch(decksActions.removeDeckFromWatchlistRequest(id));
    const updateCasualScores = (
        ...args: Parameters<typeof userDecksActions.updateCasualScoresRequest>
    ) => dispatch(userDecksActions.updateCasualScoresRequest(...args));

    return (
        <DeckPageContainer
            {...{
                deck,
                getDeckStatus,
                getDeckErrorStatus,
                isUserAuthorized,
                requestNoteAdd,
                requestDeckData,
                requestAddDeckToFavorites,
                requestRemoveDeckFromFavorites,
                requestAddDeckToWatchlist,
                requestRemoveDeckFromWatchlist,
                updateCasualScores,
                ...props,
            }}
        />
    );
};

export default withMetadata(DeckPageContainerHOC);
